import {FC} from 'react'

// locals
import {useStyles} from './style'
import {assets} from './assets'
import {useData} from './useData'
import UploadImageChunk from '../UploadImageChunk'

interface IUploadImages {
  maxLength?: number
  aspect?: number
  placeholderImage?: string
  width?: string
  height?: string
  circular?: boolean
  disabled?: boolean
  photos?: {photoId: string; isFull: boolean}[]
  setPhotos?: (value: {photoId: string; isFull: boolean}[]) => void
  isSubmitted?: boolean
  onSubmitFinish?: () => void
  clean?: boolean
  setIsSubmitted?: (value: boolean) => void
}

const UploadImagesChunk: FC<IUploadImages> = ({
  aspect = 4 / 3,
  placeholderImage = assets.images.placeholder.src,
  width = '72px',
  height = '84px',
  circular = false,
  disabled = false,
  photos = [],
  setPhotos = () => {},
  isSubmitted = false,
  onSubmitFinish = () => {},
  clean = false,
  setIsSubmitted = () => {},
}) => {
  const {classes} = useStyles()

  const {
    xFileNames,
    setXFileNames,
    loadingComplete,
    formData,
    onChangeOriginalImage,
    onChangeCroppedImage,
  } = useData(photos, setPhotos, isSubmitted, setIsSubmitted, onSubmitFinish, clean)

  const renderItems = () => {
    return photos
      .filter((photo, index) => !(index > 0 && !photos[index - 1].isFull))
      .map((p, index) => (
        <UploadImageChunk
          key={'upload-images-' + index}
          aspect={aspect}
          originalImage={formData.originalImages[index]}
          croppedImage={formData.croppedImages[index]}
          onChangeOriginalImage={(image) => onChangeOriginalImage(image, index)}
          onChangeCroppedImage={(image) => onChangeCroppedImage(image, index)}
          setXFileName={(value) => {
            let currXFileNames = xFileNames
            currXFileNames[index] = value
            setXFileNames([...currXFileNames])
            let currPhotos = photos.map(
              (photo: {photoId: string; isFull: boolean}, index: number) => {
                return {
                  photoId: photo.photoId,
                  isFull: !!xFileNames[index] || !!photo.photoId,
                }
              }
            )
            setPhotos([...currPhotos])
          }}
          onClearIsFull={() => {
            let currPhotos = photos
            currPhotos.splice(index, 1)
            setPhotos([
              ...currPhotos,
              {
                photoId: '',
                isFull: false,
              },
            ])
            let currXFilenames = xFileNames
            currXFilenames.splice(index, 1)
            setXFileNames([...currXFilenames, ''])
          }}
          photoId={p.photoId}
          disabled={disabled || loadingComplete || isSubmitted}
          placeholderImage={placeholderImage}
          width={width}
          height={height}
          circular={circular}
        />
      ))
  }

  return <div className={classes.root}>{renderItems()}</div>
}

export default UploadImagesChunk
