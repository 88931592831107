import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'
import config from './config'
// models
import {IDto, ITicket, ITicketResponse} from 'models'
// dtos
import {CreateResponseToTicketRequestDto, CreateTicketRequestDto} from 'dtos'

const {rootAddress} = config

const axios = new ClientApi()

const prefix = '/dashboard/tickets'

export const getTicketsList = async (query: StringifiableRecord): Promise<IDto<ITicket>> =>
  await axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix, query}))

export const createTicket = async (body: CreateTicketRequestDto): Promise<ITicket> =>
  await axios.http.post(rootAddress + prefix, body)

export const getTicketDetails = async (ticketId: string): Promise<ITicket> =>
  await axios.http.get(rootAddress + prefix + '/' + ticketId)

export const createResponse = async (
  ticketId: string,
  body: CreateResponseToTicketRequestDto
): Promise<ITicket> =>
  await axios.http.post(rootAddress + prefix + '/' + ticketId + '/responses', body)

export const getTicketResponsesList = async (
  ticketId: string,
  query: StringifiableRecord
): Promise<IDto<ITicketResponse>> =>
  await axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix + '/' + ticketId + '/responses', query})
  )
