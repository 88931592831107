import ClientApi from './clientApi'
import config from './config'
import queryString, {StringifiableRecord} from 'query-string'
// models
import {IDto, IVariety} from 'models'
// dtos
import {CreateVarietyRequestDto, UpdateVarietyRequestDto} from 'dtos'

const {rootAddress} = config

const prefix = '/dashboard/varieties'

const axios = new ClientApi()

export const getVarietiesList = (query: StringifiableRecord): Promise<IDto<IVariety>> =>
  axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix, query}, {arrayFormat: 'comma'})
  )

export const createNewVariety = (body: CreateVarietyRequestDto) =>
  axios.http.post(rootAddress + prefix, body)

export const getVarietyDetail = (varietyId: string): Promise<IVariety> =>
  axios.http.get(rootAddress + prefix + '/' + varietyId)

export const editVariety = (varietyId: string, body: UpdateVarietyRequestDto): Promise<IVariety> =>
  axios.http.put(rootAddress + prefix + '/' + varietyId, body)
