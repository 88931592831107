import {useEffect} from 'react'
import {useMutation} from 'react-query'
import {useSnackbar} from 'notistack'
import {useForm} from 'react-hook-form'
import {classValidatorResolver} from '@hookform/resolvers/class-validator'
// services
import services, {ServerError} from 'services'
// dtos
import {InviteNewAdminRequestDto} from 'dtos'
// hooks
import useText from 'hooks/useText'
import useToolbarRefetch from 'hooks/useToolbarRefetch'
// enums
import {AdminRolesEnum} from 'enums'
// locals
import {texts} from './texts'

const useData = (show: boolean, handleClose: () => void) => {
  const {TX} = useText()
  const {enqueueSnackbar} = useSnackbar()
  const {setIsRefetchOn} = useToolbarRefetch()

  const radioOptions = [
    AdminRolesEnum.procurement,
    AdminRolesEnum.sorting,
    AdminRolesEnum.packaging,
    AdminRolesEnum.inventory,
    AdminRolesEnum.delivery,
    AdminRolesEnum.customer_management,
  ]

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: {isSubmitting, errors, isValid},
  } = useForm<InviteNewAdminRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(InviteNewAdminRequestDto),
    defaultValues: {
      firstName: '',
      lastName: '',
      mobileNumber: '',
      temporaryPassword: '',
      roles: [],
    },
  })

  /* ------------------------------------------------------------ */
  /*                           Services                           */
  /* ------------------------------------------------------------ */

  const handleInviteNewAdmin = async () => await services.admins.inviteAdmin({...watch()})

  /* ------------------------------------------------------------ */
  /*                         Mutations                            */
  /* ------------------------------------------------------------ */

  const {isLoading, mutate} = useMutation(handleInviteNewAdmin, {
    onSuccess: async (data) => {
      handleClose()
      setIsRefetchOn(true)
      enqueueSnackbar(TX(texts.creation_success), {
        variant: 'success',
      })
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* ------------------------------------------------------------ */
  /*                          Handlers                            */
  /* ------------------------------------------------------------ */

  const onSubmit = async () => {
    await mutate()
  }

  /* ------------------------------------------------------------ */
  /*                         LifeCycles                           */
  /* ------------------------------------------------------------ */

  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return {
    onSubmit: handleSubmit(onSubmit),
    control,
    watch,
    setValue,
    isSubmitting,
    errors,
    isValid,
    loading: isLoading,
    radioOptions,
    TX,
  }
}

export {useData}
