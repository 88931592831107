import {Expose} from 'class-transformer'
import {IsEnum, IsOptional, IsString} from 'class-validator'
import {GetCouponsUsagesRequestDtoSortEnum} from 'enums'
import {SortTypeEnum} from 'models'
import {PaginateRequestDto} from './paginate.request.dto'

export class GetCouponUsagesRequestDtoFiltersDto extends PaginateRequestDto {
  @IsOptional()
  @Expose()
  @IsString()
  'filters[fromDate]'?: string

  @IsOptional()
  @Expose()
  @IsString()
  'filters[toDate]'?: string

  @IsEnum(SortTypeEnum)
  @IsOptional()
  @Expose()
  sortType?: SortTypeEnum

  @IsEnum(GetCouponsUsagesRequestDtoSortEnum)
  @IsOptional()
  @Expose()
  sort?: GetCouponsUsagesRequestDtoSortEnum

  @IsOptional()
  @IsString()
  search?: string
}
