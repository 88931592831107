import {Expose} from 'class-transformer'
import {IsBoolean, IsEnum, IsOptional, IsString} from 'class-validator'
import {GetCouponsCustomersRequestDtoSortEnum} from 'enums'
import {SortTypeEnum} from 'models'
import {PaginateRequestDto} from './paginate.request.dto'

export class GetCouponCustomersRequestDtoFiltersDto extends PaginateRequestDto {
  @IsOptional()
  @Expose()
  @IsBoolean()
  'filters[hasUsedTheCoupon]'?: boolean

  @IsEnum(SortTypeEnum)
  @IsOptional()
  @Expose()
  sortType?: SortTypeEnum

  @IsEnum(GetCouponsCustomersRequestDtoSortEnum)
  @IsOptional()
  @Expose()
  sort?: GetCouponsCustomersRequestDtoSortEnum

  @IsOptional()
  @IsString()
  search?: string
}
