import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  product_image_title: 'pages.protected.productionline.products.components.product-image-title',
  supplies_breadcrumbs: 'pages.protected.breadcrumbsItems.supplies',
  product_modal_btn:
    'pages.protected.production-line,supplies.products.components.create-product-modal.btn-title',
  product_name_form:
    'pages.protected.production-line,supplies.products.components.create-product-modal.form-variety-name',
  product_type_form:
    'pages.protected.production-line,supplies.products.components.create-product-modal.form-variety-type',
  create_product_cancel:
    'pages.protected.production-line,supplies.products.components.create-product-modal.cancel',
  create_product_submit:
    'pages.protected.production-line,supplies.products.components.create-product-modal.submit',
  product_form_name_required:
    'pages.protected.production-line,supplies.products.components.create-product-modal.form-variety-name-required-error',
  product_from_type_required:
    'pages.protected.production-line,supplies.products.components.create-product-modal.form-variety-type-required-error',
  creation_success:
    'pages.protected.production-line,supplies.products.components.create-product-modal.success-creation_admin',
  mobile_error: 'pages.public.login.mobile_error',
  new_admin: "pages.protected.system-preferences.admin.components.new_admin",
  add_mobile_number_hint: "pages.protected.system-preferences.admin.components.add_mobile_number_hint",
  name: "pages.protected.system-preferences.admin.components.name",
  last_name: "pages.protected.system-preferences.admin.components.last_name",
  mobile_number: "pages.protected.system-preferences.admin.components.mobile_number",
  use_mobile_number_for_user_name: "pages.protected.system-preferences.admin.components.use_mobile_number_for_user_name",
  temporary_password: "pages.protected.system-preferences.admin.components.temporary_password",
  add_admin: "pages.protected.system-preferences.admin.components.add_admin",
  mobile_number_required: "pages.protected.system-preferences.admin.components.mobile_number_required",
  temporary_password_min: "pages.protected.system-preferences.admin.components.temporary_password_min",
  temporary_password_max: "pages.protected.system-preferences.admin.components.temporary_password_max",
  temporary_password_required:"pages.protected.system-preferences.admin.components.temporary_password_required",
  role_min: "pages.protected.system-preferences.admin.components.role_min",
  role_required: "pages.protected.system-preferences.admin.components.role_required",
}
