// hooks
import useText from 'hooks/useText'
import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import {useQuery} from 'react-query'

// services
import services, {ServerError} from 'services'

// utils
import {convertBase64ImageToFile} from 'utils/basic/image'

// local
import {texts} from './texts'

const useArrayBuffer = (result: ArrayBuffer | null | undefined): number => {
  if (result) return result.byteLength
  return 0
}

export const useData = (
  setXFileName: (value: string) => void,
  photoId: string,
  onClearIsFull: () => void,
  originalImage: string,
  croppedImage: string,
  onChangeOriginalImage: (value: string) => void,
  onChangeCroppedImage: (value: string) => void,
  xFileName: string
) => {
  const inputRef = useRef<HTMLInputElement>()
  const [loading, setLoading] = useState(false)
  const [loadingPercentage, setLoadingPercentage] = useState(0)
  const [insideSubmitted, setInsideSubmitted] = useState(false)
  const {enqueueSnackbar} = useSnackbar()
  const {TX} = useText()
  const [showImageCropper, setShowImageCropper] = useState(false)
  const [showImageViewer, setShowImageViewer] = useState(false)
  const [crop, setCrop] = useState({x: 0, y: 0})
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const [zoom, setZoom] = useState(2)

  const onChangeInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeOriginalImage(URL.createObjectURL(e.target?.files?.[0]!))

    setShowImageCropper(true)
  }

  const onClickButton = () => {
    if (croppedImage) setShowImageViewer(true)
    else {
      inputRef.current?.click()
    }
  }

  const onCloseImageCropperDialog = () => {
    setShowImageCropper(false)
  }

  const onCloseImageViewer = () => {
    setShowImageViewer(false)
  }

  const getCroppedImg = () => {
    const myImage: any = new Image()
    myImage.src = originalImage
    const canvas = document.createElement('canvas')
    canvas.width = croppedAreaPixels.width
    canvas.height = croppedAreaPixels.height
    const ctx = canvas.getContext('2d')

    const scaleX = myImage.naturalWidth / myImage.width
    const scaleY = myImage.naturalHeight / myImage.height
    canvas.width = croppedAreaPixels.width
    canvas.height = croppedAreaPixels.height
    ctx!.drawImage(
      myImage,
      croppedAreaPixels.x * scaleX,
      croppedAreaPixels.y * scaleY,
      croppedAreaPixels.width * scaleX,
      croppedAreaPixels.height * scaleY,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    )
    return canvas.toDataURL('image/png', 1)
  }

  const onClickEdit = () => {
    setShowImageCropper(true)
    setShowImageViewer(false)
  }

  const onClickChange = () => {
    setShowImageViewer(false)
    inputRef.current?.click()
  }

  const onClickClear = () => {
    onChangeOriginalImage('')
    onChangeCroppedImage('')
    onClearIsFull()
    setShowImageViewer(false)
  }

  const onSubmitImage = async () => {
    setInsideSubmitted(false)
    setLoading(true)
    const imageFile = await convertBase64ImageToFile(croppedImage, 'upload-image')
    let fileSize = imageFile.size
    let chunkSize = 0.2
    let filename = Date.now() + '-' + imageFile.name
    const fileReader = new FileReader()
    fileReader.onload = async (ev) => {
      const CHUNK_SIZE = 1024 * 1024 * chunkSize
      const result = ev.target?.result
      let byteLength = 0
      if (typeof result !== 'string') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        byteLength = useArrayBuffer(result)
      }
      const chunkCount = Math.ceil(byteLength / CHUNK_SIZE)

      try {
        for (let chunkId = 0; chunkId < chunkCount; chunkId++) {
          let startChunk = chunkId * CHUNK_SIZE
          let endingChunk = Math.min(chunkId * CHUNK_SIZE + CHUNK_SIZE, fileSize)

          let endChunk = endingChunk === fileSize ? endingChunk + 1 : endingChunk
          const chunk = ev.target?.result?.slice(startChunk, endChunk)

          await services.upload.chunkFile(filename, fileSize, startChunk, chunk)
          setLoadingPercentage(chunkId * (100 / chunkCount))
        }
        setXFileName(filename)
      } catch (error) {
        setXFileName('')
        onChangeCroppedImage('')
        onChangeOriginalImage('')
        enqueueSnackbar(TX(texts.error), {
          variant: 'error',
        })
      }
      setLoading(false)
      setShowImageCropper(false)
      onCloseImageCropperDialog()
    }
    fileReader.readAsArrayBuffer(imageFile)
  }

  const getImage = (photoId: string) => async () => await services.upload.getPhoto(photoId)

  const {isLoading: loadingImage} = useQuery(['get-image', photoId], getImage(photoId), {
    enabled: !!photoId && !croppedImage,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      onChangeOriginalImage(data)
      onChangeCroppedImage(data)
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  useEffect(() => {
    if (croppedImage && insideSubmitted) {
      onSubmitImage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedImage, insideSubmitted])

  return {
    inputRef,
    crop,
    setCrop,
    croppedAreaPixels,
    setCroppedAreaPixels,
    zoom,
    setZoom,
    showImageCropper,
    setShowImageCropper,
    showImageViewer,
    setShowImageViewer,
    onChangeInputFile,
    onCloseImageCropperDialog,
    onSubmitImage,
    onCloseImageViewer,
    onClickButton,
    onClickEdit,
    onClickChange,
    onClickClear,
    getCroppedImg,
    loadingImage,
    insideSubmitted,
    setInsideSubmitted,
    loading,
    loadingPercentage,
  }
}
