import {FeatureGroup, Polygon} from 'react-leaflet'
import {EditControl} from 'react-leaflet-draw'

import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import React, {FC} from 'react'
import {ICoordinates} from 'services/regions'
import {LatLngExpression} from 'leaflet'
import {useData} from './useData'

interface IDrawTools {
  coordinates: ICoordinates[][]
  onChangeList: React.Dispatch<React.SetStateAction<ICoordinates[][]>>
}

const DrawTools: FC<IDrawTools> = ({onChangeList, coordinates}) => {
  const data = useData(coordinates, onChangeList)

  return (
    <FeatureGroup>
      {/* {coordinates.length === 0 && ( */}
      <EditControl
        onEdited={data._onEdited}
        onCreated={data._onCreated}
        onDeleted={data._onDeleted}
        position='topright'
        draw={{
          polyline: false,
          rectangle: false,
          circlemarker: false,
          circle: false,
          marker: false,
        }}
        edit={{edit: false, remove: false}}
      />
      {/* )} */}
      {coordinates.length > 0 &&
        coordinates?.map((coordinate) => (
          <Polygon
            positions={
              coordinate.length > 0
                ? (coordinate?.map((cor) => ({
                    lat: Number(cor.lat),
                    lng: Number(cor.long),
                  })) as LatLngExpression[])
                : []
            }
          />
        ))}
    </FeatureGroup>
  )
}

export default DrawTools
