import useText from 'hooks/useText'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {useMutation} from 'react-query'
import services from 'services'
import {removeEmptyValuesStringArray} from 'utils/basic/object'
import {texts} from './texts'

export const useData = (
  photos: {photoId: string; isFull: boolean}[],
  setPhotos: (value: {photoId: string; isFull: boolean}[]) => void,
  isSubmitted: boolean,
  setIsSubmitted: (value: boolean) => void,
  onSubmitFinish: () => void,
  clean: boolean
) => {
  const {enqueueSnackbar} = useSnackbar()
  const [xFileNames, setXFileNames] = useState<string[]>(Array(photos.length).fill('') as string[])
  const {TX} = useText()

  const [isCompleteCalled, setIsCompleteCalled] = useState(false)
  const [formData, setFormData] = useState<{
    originalImages: string[]
    croppedImages: string[]
  }>({
    originalImages: Array(10).fill(''),
    croppedImages: Array(10).fill(''),
  })

  const {isLoading: loadingComplete, mutate: complete} = useMutation(
    'complete-upload-image',
    services.upload.complete,
    {
      onError: (error) => {
        setXFileNames(Array(photos.length).fill(''))
        setPhotos(Array(photos.length).fill({photoId: '', isFull: false}))
        setFormData({
          originalImages: Array(photos.length).fill(''),
          croppedImages: Array(photos.length).fill(''),
        })
        setIsSubmitted(false)
        enqueueSnackbar(TX(texts.error), {variant: 'error'})
      },
      onSuccess: (data) => {
        let currPhoto: {photoId: string; isFull: boolean}[] = []
        let index_ = 0
        xFileNames.forEach((xFileName, index) => {
          if (xFileName) {
            currPhoto.push({
              photoId: data.photoIds[index_],
              isFull: true,
            })
            index_++
          } else if (photos[index].photoId) {
            currPhoto.push({
              photoId: photos[index].photoId,
              isFull: true,
            })
          }
        })
        setPhotos(currPhoto)
        setIsCompleteCalled(true)
      },
    }
  )

  useEffect(() => {
    if (clean) {
      setXFileNames(Array(photos.length).fill(''))
      setPhotos(Array(photos.length).fill({photoId: '', isFull: false}))
      setFormData({
        originalImages: Array(photos.length).fill(''),
        croppedImages: Array(photos.length).fill(''),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clean])

  useEffect(() => {
    if (isSubmitted && isCompleteCalled) {
      onSubmitFinish()
      setIsCompleteCalled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, isCompleteCalled])

  useEffect(() => {
    if (isSubmitted && xFileNames.join('').length > 0) {
      complete(removeEmptyValuesStringArray(xFileNames))
    } else if (isSubmitted && xFileNames.join('').length === 0) {
      onSubmitFinish()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted])

  const onChangeOriginalImage = (value: string, index: number) => {
    let changedImages = formData.originalImages.map((imageData, index_) =>
      index_ === index ? value : imageData
    )
    let changedOrderImages: string[] = changedImages
    if (!value) {
      changedOrderImages = []
      for (const index in changedImages) {
        if (changedImages[index]) changedOrderImages.push(changedImages[index])
      }
      changedOrderImages = [...changedOrderImages, ...changedImages.map((item) => '')].splice(
        0,
        changedImages.length
      )
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      originalImages: changedOrderImages,
    }))
  }

  const onChangeCroppedImage = (value: string, index: number) => {
    let changedImages = formData.croppedImages.map((imageData, index_) =>
      index_ === index ? value : imageData
    )

    let changedOrderImages: string[] = changedImages
    if (!value) {
      changedOrderImages = []
      for (const index in changedImages) {
        if (changedImages[index]) changedOrderImages.push(changedImages[index])
      }
      changedOrderImages = [...changedOrderImages, ...changedImages.map((item) => '')].splice(
        0,
        changedImages.length
      )
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      croppedImages: changedOrderImages,
    }))
  }

  return {
    xFileNames,
    setXFileNames,
    loadingComplete,
    formData,
    setFormData,
    onChangeOriginalImage,
    onChangeCroppedImage,
  }
}
