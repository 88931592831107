import React from 'react'
//models
import {Transaction, displayTransactionStatusEnum} from 'models/Transaction'

// utils
import {KTSVG, toPersianDigit} from 'utils/template/helpers'

//components
import {ITableColumn, Table} from 'components/elements/Table'
import {Typography} from 'components/elements/Typography'
import {TableToolbar} from 'components/elements/TableToolbar'
import {Pagination} from 'components/elements/Pagination'
import {Chip} from 'components/elements/Chip'

//locals
import useData from './useData'
import UploadDocumentModal from './components/UploadDocumentModal'
import Context from './context'
import {Select} from 'components/elements/Select'
import {DisplayJalaliDate} from 'utils/basic/date'
import Image from 'components/elements/Image'
import {texts} from './texts'
import {TransactionStatusEnum} from 'enums'

const TransactionsList = () => {
  const data = useData()

  const columns: ITableColumn<Transaction>[] = [
    {
      name: 'name',
      label: data.TX(texts.name),
      cell: (item) => (
        <Typography className='text-dark fs-6 ' type='span'>
          {item.getCustomer().getFullName()}
        </Typography>
      ),
    },
    {
      name: 'price',
      label: data.TX(texts.price),
      cell: (item) => (
        <Typography className='text-dark fs-6' type='span'>
          {toPersianDigit(item.getAmountDisplay())}
        </Typography>
      ),
    },
    {
      name: 'creation_date',
      label: data.TX(texts.creation_date),
      cell: (item) => (
        <Typography type='span' className='text-dark fs-6'>
          {DisplayJalaliDate(item.getCreatedAt())}
        </Typography>
      ),
    },
    {
      name: 'creation_hour',
      label: data.TX(texts.creation_hour),
      cell: (item) => (
        <Typography type='span' className='text-dark  fs-6'>
          {toPersianDigit(item.getCreatedAtTimeDisplay())}
        </Typography>
      ),
    },
    {
      name: 'mobile_number',
      label: data.TX(texts.mobile_number),
      cell: (item) => (
        <Typography type='span' className='text-dark fs-6'>
          {toPersianDigit(item.getCustomer().getPhone())}
        </Typography>
      ),
    },
    {
      name: 'status',
      label: data.TX(texts.status),
      cell: (item) => (
        <div className='d-flex flex-row justify-content-cetner align-items-center w-75px'>
          <Chip
            text={item.displayStatus()}
            classNameColor={`${
              item.getStatus() === TransactionStatusEnum.paid
                ? 'badge-light-success'
                : 'text-warning'
            }`}
          />
        </div>
      ),
    },
    {
      name: 'photoUses',
      label: data.TX(texts.show_receipt),
      cell: (item, index) => (
        <div className='d-flex align-items-center'>
          {!!item.getPhotoUses()?.[0]?.getPhoto()?.getFilename() ? (
            <Image photo={item.getPhotoUses()?.[0]?.getPhoto()} className='me-5' />
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      name: 'edit',
      label: data.TX(texts.edit),
      cell: (item, index) => (
        <div className='d-flex justify-content-start align-items-center'>
          {item.getStatus() !== TransactionStatusEnum.failed ? (
            <span
              onClick={() => {
                data.setIsUploadDocumentModalOpen(true)
                data.setSelectedTransaction(item)
              }}
            >
              <KTSVG
                path='/media/icons/duotune/art/add-blue-sarsabad.svg'
                className='svg-icon-1 ms-2 me-0 my-2 mx-3 cursor-pointer'
              />{' '}
            </span>
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      name: 'status',
      label: data.TX(texts.determining_status),
      cell: (item, index) => (
        <div className='d-flex justify-content-start align-items-center'>
          {item.getStatus() === TransactionStatusEnum.waiting_to_accept && (
            <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
              <span
                className='dark-green cursor-pointer'
                onClick={() => {
                  data.accept(item.getId())
                }}
              >
                {data.TX(texts.accept)}
              </span>
              <span
                className='text-danger cursor-pointer'
                onClick={() => {
                  data.reject(item.getId())
                }}
              >
                {data.TX(texts.reject)}
              </span>
            </div>
          )}
        </div>
      ),
    },
  ]

  const renderFilter = () => {
    return (
      <>
        <div className='mb-5'>
          <Select
            label={data.TX(texts.status)}
            options={Object.values(TransactionStatusEnum)}
            formatOption={displayTransactionStatusEnum}
            value={data.query['filters[status]']}
            onChange={(type) =>
              data.setQuery((query) => ({
                ...query,
                'filters[status]': type as TransactionStatusEnum,
              }))
            }
          />
        </div>
      </>
    )
  }

  const renderTransactionsList = () => {
    return (
      <>
        <div className='row gy-5 gx-xl-8'>
          <div className='col-12'>
            <div className='card card-xxl-stretch mb-5 mb-xl-8'>
              <div className='card-header border-0 pt-5'>
                <Typography type='h3' className='card-title align-items-start flex-column'>
                  <Typography type='span' className='card-label fw-bold fs-3 mb-1'>
                    {data.TX(texts.transactions)}
                  </Typography>
                  <Typography type='span' className='text-muted mt-1 fw-semibold fs-7'>
                    {/* {TX(texts.customer, [toPersianDigit(data.meta.getTotalItems())])} */}
                  </Typography>
                </Typography>
                <div className='d-flex justify-content-center align-items-center gap-9'>
                  <TableToolbar
                    query={data.query}
                    setQuery={data.setQuery}
                    onClear={data.onClean}
                    onSubmit={data.onSubmit}
                    filter={renderFilter()}
                  />
                </div>
              </div>
              <Table
                rows={data.transactions}
                loading={data.isLoading}
                columns={columns}
                // columnSort={data.convertQueryToColumnSort(data.query)}
                // onColumnSortClick={data.onColumnSortClick}
              />
              <Pagination
                disabled={data.isLoading}
                meta={data.meta}
                onPageChange={(page) => {
                  data.setQuery((query) => ({...query, page}))
                  data.handleChangeUrl({...data.query, page})
                }}
              />
            </div>
          </div>
        </div>
        <UploadDocumentModal
          show={data.isUploadDocumentModalOpen}
          handleClose={() => data.setIsUploadDocumentModalOpen(false)}
        />
      </>
    )
  }

  return (
    <Context.Provider
      value={{
        selectedTransaction: data.selectedTransaction,
        setSelectedTransaction: data.setSelectedTransaction,
      }}
    >
      {renderTransactionsList()}
    </Context.Provider>
  )
}

export default TransactionsList
