import {Controller} from 'react-hook-form'
// components
import {Modal} from 'components/elements/Modal'
import {Textfield} from 'components/elements/Textfield'
import {Button} from 'components/elements/Button'
import {Checkbox} from 'components/elements/Checkbox'
// local
import {useData} from './useData'
import {texts} from './texts'

type Props = {
  show: boolean
  handleClose: () => void
}

const CreateTypeModal = ({show, handleClose}: Props) => {
  const {onSubmit, control, watch, setValue, isSubmitting, errors, isValid, loading, TX} = useData(
    show,
    handleClose
  )

  return (
    <Modal
      id='create-type-modal'
      show={show}
      handleClose={handleClose}
      title={TX(texts.new_type)}
      children={
        <form onSubmit={onSubmit} encType='multipart/form-data'>
          <div className='stepper stepper-pills stepper-column' id='kt_modal_create_app_stepper'>
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <div className='fv-row d-flex mb-7 gap-3'>
                <Controller
                  name='name'
                  control={control}
                  render={({field}) => (
                    <Textfield
                      {...field}
                      label={TX(texts.name_type)}
                      type='text'
                      name='name'
                      autoComplete='off'
                      error={!!errors.name}
                      touched={!!errors.name?.message}
                      errorText={errors.name?.message}
                    />
                  )}
                />
                <Controller
                  name='faName'
                  control={control}
                  render={({field}) => (
                    <Textfield
                      {...field}
                      label={TX(texts.fa_name_type)}
                      type='text'
                      name='faName'
                      autoComplete='off'
                      error={!!errors.faName}
                      touched={!!errors.faName?.message}
                      errorText={errors.faName?.message}
                    />
                  )}
                />
              </div>
              <div className='d-flex flex-row  justify-content-start align-items-start gap-5 mt-5'>
                <div className='d-flex justify-content-start align-items-center w-100'>
                  <Controller
                    name='isActive'
                    control={control}
                    render={({field}) => (
                      <Checkbox
                        {...field}
                        label={watch().isActive ? TX(texts.active) : TX(texts.deactive)}
                        name={'isActive'}
                        disabled={loading}
                        value={watch().isActive}
                        onClick={() => setValue('isActive', !watch().isActive)}
                      />
                    )}
                  />
                </div>
              </div>
              <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
                <Button
                  text={TX(texts.variery_cancel)}
                  className='main-red-background btn btn-lg background-inherit justify-content-center'
                  onClick={handleClose}
                  type='button'
                />

                <Button
                  text={TX(texts.submit)}
                  isLoading={loading}
                  className='btn btn-lg btn-primary main-gradient-background'
                  type='submit'
                  disabled={isSubmitting || !isValid}
                />
              </div>
            </div>
          </div>
        </form>
      }
    />
  )
}

export {CreateTypeModal}
