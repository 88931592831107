import {IsBoolean, IsEnum, IsOptional, IsString} from 'class-validator'
import {AdminRolesEnum} from 'enums'

export class UpdateResponseToTicketRequestDto {
  @IsOptional()
  @IsString()
  body?: string

  @IsEnum(AdminRolesEnum, {each: true})
  @IsOptional({each: true})
  assigned?: AdminRolesEnum[]

  @IsOptional()
  @IsBoolean()
  isReference?: boolean

  @IsOptional()
  @IsBoolean()
  isFinal?: boolean

  constructor() {
    this.body = ''
    this.assigned = []
    this.isReference = false
    this.isFinal = false
  }
}
