import {Controller} from 'react-hook-form'
// components
import {Modal} from 'components/elements/Modal'
import {Textfield} from 'components/elements/Textfield'
import {Button} from 'components/elements/Button'
import {Textarea} from 'components/elements/Textarea'
import UploadImagesChunk from 'components/elements/UploadImagesChunk'
import {JalaliDatePicker} from 'components/elements/JalaliCalendar'
import {Typography} from 'components/elements/Typography'
import {Checkbox} from 'components/elements/Checkbox'
import {Counter} from 'components/elements/Counter'
// hooks
import useText from 'hooks/useText'
// models
import {displayBoxStatusEnum} from 'models'
// enums
import {BoxStatusEnum} from 'enums'
// local
import {useData} from './useData'
import {texts} from './texts'

type Props = {
  show: boolean
  handleClose: () => void
}

const CreateNewBoxModal = ({show, handleClose}: Props) => {
  const {
    onSubmit,
    control,
    watch,
    setFormValue,
    isSubmitting,
    errors,
    isValid,
    loading,
    isSubmitted,
    onSubmitFinish,
    photos,
    setPhotos,
    handleChangeExpiryDate,
  } = useData(show, handleClose)

  const {TX} = useText()

  return (
    <Modal
      id='create-box-modal'
      show={show}
      handleClose={handleClose}
      title={TX(texts.header)}
      children={
        <form onSubmit={onSubmit} encType='multipart/form-data'>
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <div className='d-flex flex-column justify-content-start align-items-start'>
              <Typography type='h4' className='d-block fw-bold fs-5'>
                {TX(texts.box_image)}
              </Typography>

              <UploadImagesChunk
                disabled={loading}
                photos={photos}
                setPhotos={setPhotos}
                isSubmitted={isSubmitted}
                onSubmitFinish={onSubmitFinish}
                aspect={3.2 / 1.1}
                width={'320px'}
                height={'110px'}
              />
            </div>
          </div>
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <div className='d-flex justify-content-space-between gap-9 my-5'>
              <Controller
                name='name'
                control={control}
                render={({field}) => (
                  <Textfield
                    {...field}
                    label={TX(texts.box_name)}
                    type='text'
                    name='name'
                    autoComplete='off'
                    error={!!errors.name}
                    touched={!!errors.name?.message}
                    errorText={errors.name?.message}
                    disabled={loading}
                  />
                )}
              />
              <Controller
                name='expiryDate'
                control={control}
                render={({field}) => (
                  <JalaliDatePicker
                    {...field}
                    name='expiryDate'
                    label={TX(texts.expiration_date)}
                    value={watch().expiryDate}
                    error={!!errors.expiryDate}
                    errorText={errors.expiryDate?.message as string}
                    onChange={handleChangeExpiryDate}
                    disabled={loading}
                    minDate={new Date()}
                  />
                )}
              />
              <Controller
                name='priority'
                control={control}
                render={({field}) => (
                  <Counter
                    className='p-4'
                    {...field}
                    name='priority'
                    label={TX(texts.priority)}
                    value={watch().priority}
                    onChange={(value) =>
                      setFormValue('priority', parseInt(value.toString()), {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }
                    disabled={loading}
                  />
                )}
              />
            </div>
            <div className='d-flex flex-column justify-content-space-around mb-7'>
              <Controller
                name='description'
                control={control}
                render={({field}) => (
                  <Textarea
                    {...field}
                    name='description'
                    label={TX(texts.description)}
                    className='form-control form-control-lg form-control-solid h-25'
                    autoComplete='off'
                    disabled={loading}
                  />
                )}
              />
            </div>
            <div className='d-flex flex-row  justify-content-start align-items-start gap-5 mt-5'>
              <div className='d-flex justify-content-start align-items-center w-100'>
                <Controller
                  name='status'
                  control={control}
                  render={({field}) => (
                    <Checkbox
                      {...field}
                      label={displayBoxStatusEnum(watch().status)}
                      name={'status'}
                      disabled={loading}
                      value={watch().status === BoxStatusEnum.activated}
                      onChange={() =>
                        setFormValue(
                          'status',
                          watch().status === BoxStatusEnum.activated
                            ? BoxStatusEnum.deactivated
                            : BoxStatusEnum.activated
                        )
                      }
                    />
                  )}
                />
              </div>
            </div>

            <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
              <Button
                text={TX(texts.cancel)}
                className='main-red-background btn btn-lg background-inherit justify-content-center'
                onClick={handleClose}
                type='button'
              />

              <Button
                text={TX(texts.submit)}
                isLoading={loading}
                className='btn btn-lg btn-primary main-gradient-background'
                type='submit'
                disabled={
                  isSubmitting || !isValid || photos.filter((photo) => photo.isFull).length === 0
                }
              />
            </div>
          </div>
        </form>
      }
    />
  )
}

export {CreateNewBoxModal}
