import {IsBoolean, IsOptional, IsString} from 'class-validator'

export class SubmitQuoteRequestDto {
  @IsOptional()
  @IsString()
  comments?: string

  @IsBoolean()
  isOriginalOffer?: boolean

  constructor() {
    this.comments = ''
    this.isOriginalOffer = false
  }
}
