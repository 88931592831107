import ClientApi from './clientApi'
import config from './config'
import queryString, {StringifiableRecord} from 'query-string'
// models
import {IDto, IProduct, IProductType, IProductUnit} from 'models'
// dtos
import {
  CreateProductInstanceRequestDto,
  CreateProductRequestDto,
  CreateProductTypeRequestDto,
  UpdateProductRequestDto,
  UpdateProductTypeRequestDto,
} from 'dtos'

const {rootAddress} = config

const prefix = '/dashboard/products'

const axios = new ClientApi()

const queryParamsGetAll = queryString.stringify(
  {getAll: true, 'filters[isActive]': true},
  {arrayFormat: 'comma'}
)

export const getAllProductTypes = (): Promise<IProductType[]> =>
  axios.http.get(rootAddress + prefix + `/types?${queryParamsGetAll}`)

export const getProductTypes = (query: StringifiableRecord): Promise<IDto<IProductType>> =>
  axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix + '/types', query}, {arrayFormat: 'comma'})
  )

export const createProductType = (body: CreateProductTypeRequestDto): Promise<IProductType> =>
  axios.http.post(rootAddress + prefix + '/types', body)

export const getProductTypeDetails = (productTypeId: string): Promise<IProductType> =>
  axios.http.get(rootAddress + prefix + '/types/' + productTypeId)

export const editProductType = (
  productTypeId: string,
  body: UpdateProductTypeRequestDto
): Promise<IProductType> => axios.http.patch(rootAddress + prefix + '/types/' + productTypeId, body)

export const getProductUnits = (): Promise<IProductUnit[]> =>
  axios.http.get(rootAddress + prefix + '/units')

export const getProductsList = (query: StringifiableRecord): Promise<IDto<IProduct>> =>
  axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix, query}, {arrayFormat: 'comma'})
  )

export const createNewProduct = (body: CreateProductRequestDto) =>
  axios.http.post(rootAddress + prefix, body)

export const getProductDetail = (productId: string): Promise<IProduct> =>
  axios.http.get(rootAddress + prefix + '/' + productId)

export const editProduct = (productId: string, body: UpdateProductRequestDto) =>
  axios.http.put(rootAddress + prefix + '/' + productId, body)

export const createInstance = (productId: string, body: CreateProductInstanceRequestDto) =>
  axios.http.post(rootAddress + prefix + '/' + productId + '/instances', body)

export const removeInstance = (productId: string, productInstanceId: string) =>
  axios.http.delete(rootAddress + prefix + '/' + productId + '/instances/' + productInstanceId)

export const downloadPdf = (query: StringifiableRecord, fileName: string) =>
  axios.http
    .get(
      queryString.stringifyUrl(
        {url: rootAddress + prefix + '/product-price-list-pdf', query},
        {arrayFormat: 'comma'}
      ),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      }
    )
    // @ts-ignore
    .then((response: BlobPart) => {
      const url = window.URL.createObjectURL(new Blob([response], {type: 'application/pdf'}))
      const link = document.createElement('a')
      link.href = url
      document.body.appendChild(link)
      link.download = fileName
      link.click()
      link.parentNode!.removeChild(link)
    })
