import {IProduct, Product} from './Product'
import {IVariety, Variety} from './Variety'

export interface IProductVariety {
  id?: string
  createdAt?: string
  updateAt?: string
  productId?: string
  varietyId?: string
  product?: IProduct
  variety?: IVariety
}

export class ProductVariety {
  public props: IProductVariety = {}

  constructor(data?: IProductVariety) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updateAt ? new Date(this.props.updateAt) : undefined
  }

  getVarietyId(): string {
    return this.props.varietyId || ''
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getVariety(): Variety {
    return new Variety(this.props.variety || {})
  }

  getProduct(): Product {
    return new Product(this.props.product || {})
  }
}
