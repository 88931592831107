import {useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {classValidatorResolver} from '@hookform/resolvers/class-validator'
import {useMutation, useQuery} from 'react-query'
import {useSnackbar} from 'notistack'
// services
import services, {ServerError} from 'services'
// models
import {Province, City} from 'models'
// dtos
import {CreateVendorRequestDto} from 'dtos'
// hooks
import useText from 'hooks/useText'
import useToolbarRefetch from 'hooks/useToolbarRefetch'
// local
import {texts} from './texts'

const useData = (show: boolean, handleClose: () => void) => {
  const {TX} = useText()
  const {enqueueSnackbar} = useSnackbar()
  const [cities, setCities] = useState<City[]>([])
  const [provinces, setProvinces] = useState<Province[]>([])
  const {setIsRefetchOn} = useToolbarRefetch()

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: {isSubmitting, errors, isValid},
  } = useForm<CreateVendorRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(CreateVendorRequestDto),
    defaultValues: {
      firstName: '',
      lastName: '',
      mobileNumber: '',
      address: '',
      cityId: '',
      provinceId: '',
    },
  })

  /* ------------------------------------------------------------ */
  /*                           Services                           */
  /* ------------------------------------------------------------ */

  const getProvinces = async () => await services.location.getProvinces()

  const getCities = async () => await services.location.getProvinceCities(watch().provinceId!)

  const handleCreateNewVendor = async () => await services.vendors.createNewVendor({...watch()})

  /* ------------------------------------------------------------ */
  /*                           Queries                            */
  /* ------------------------------------------------------------ */

  const {isLoading: isLoadingProvince} = useQuery(['provinces'], getProvinces, {
    onSuccess: (data) => {
      setProvinces(data.map((city) => new Province(city)))
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* ------------------------------------------------------------ */
  /*                         Mutations                            */
  /* ------------------------------------------------------------ */

  const {isLoading: isLoadingCities, mutate: mutateCities} = useMutation(['cities'], getCities, {
    onSuccess: (data) => {
      setCities(data.map((city) => new City(city)))
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  const {isLoading: isLoadingMutate, mutate} = useMutation(handleCreateNewVendor, {
    onSuccess: async (data) => {
      handleClose()
      setIsRefetchOn(true)
      enqueueSnackbar(TX(texts.success), {
        variant: 'success',
      })
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* ------------------------------------------------------------ */
  /*                          Handlers                            */
  /* ------------------------------------------------------------ */

  const onSubmit = async () => {
    await mutate()
  }

  /* ------------------------------------------------------------ */
  /*                         LifeCycles                           */
  /* ------------------------------------------------------------ */

  useEffect(() => {
    if (!show) {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  useEffect(() => {
    if (!watch().provinceId) {
      setCities([])
      setValue('cityId', '')
    } else {
      mutateCities()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch().provinceId])

  return {
    onSubmit: handleSubmit(onSubmit),
    control,
    watch,
    setValue,
    isSubmitting,
    errors,
    isValid,
    isLoadingProvince,
    isLoadingCities,
    isLoadingMutate,
    cities,
    provinces,
    TX,
  }
}

export {useData}
