export enum TransactionTypeEnum {
  change_to_cheaper_box = 'change_to_cheaper_box',
  change_to_more_expensive_box = 'change_to_more_expensive_box',
  buy_subscription = 'buy_subscription',
  refunded = 'refunded',
  wallet = 'wallet',
  cancel_subscription = 'cancel_subscription',
  cancel_delivery = 'cancel_delivery',
  wallet_deposit = 'wallet_deposit',
  wallet_withdrawal = 'wallet_withdrawal',
  wallet_admin_gift = 'admin_wallet_gift',
  wallet_coupon_activation = 'wallet_coupon_activation',
}

export enum TransactionCreatedByEnum {
  admin = 'admin',
  customer = 'customer',
}

export enum TransactionStatusEnum {
  paid = 'paid',
  pending = 'pending',
  waiting_to_accept = 'waiting_to_accept',
  failed = 'failed',
  expired = 'expired',
  refunded = 'refunded',
}

export enum TransactionPaymentTypeEnum {
  credit = 'credit',
  online = 'online',
  wallet = 'wallet',
}
