import {Type} from 'class-transformer'
import {IsLatitude, IsLongitude, IsNotEmpty, IsNumber, IsOptional, IsString} from 'class-validator'

export class CreateCustomerAddressRequestDto {
  @IsString({message: 'نام الزامی است'})
  @IsNotEmpty({message: 'نام الزامی است'})
  name: string

  @IsString({message: 'استان الزامی است'})
  @IsNotEmpty({message: 'استان الزامی است'})
  provinceId: string

  @IsString({message: 'شهر الزامی است'})
  @IsNotEmpty({message: 'شهر الزامی است'})
  cityId: string

  @IsString()
  @IsOptional()
  districtId?: string

  @IsString({message: 'خیابان الزامی است'})
  @IsNotEmpty({message: 'خیابان الزامی است'})
  streetOne: string

  @IsNumber({}, {message: 'پلاک الزامی است'})
  @IsNotEmpty({message: 'پلاک الزامی است'})
  @Type(() => Number)
  houseNumber: number

  @IsNumber({}, {message: 'واحد الزامی است'})
  @IsNotEmpty({message: 'واحد الزامی است'})
  @Type(() => Number)
  unitNumber: number

  @IsString()
  @IsOptional()
  streetTwo?: string

  @IsString()
  @IsOptional()
  address?: string

  @IsLatitude()
  @IsOptional()
  lat?: string

  @IsLongitude()
  @IsOptional()
  long?: string

  @IsString()
  @IsOptional()
  description?: string

  constructor() {
    this.name = ''
    this.provinceId = ''
    this.cityId = ''
    this.districtId = ''
    this.streetOne = ''
    this.houseNumber = 0
    this.unitNumber = 0
    this.streetTwo = ''
    this.address = ''
    this.lat = ''
    this.long = ''
    this.description = ''
  }
}
