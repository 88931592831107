import {Expose} from 'class-transformer'
import {IsEnum, IsOptional, IsString} from 'class-validator'
import {PaginateRequestDto} from './paginate.request.dto'
import {DeliveryStateEnum, DeliveryStatusEnum} from 'enums'

export class GetBoxDeliveriesRequestFilterDto extends PaginateRequestDto {
  @IsEnum(DeliveryStateEnum)
  @IsOptional()
  @Expose()
  'filters[state]'?: DeliveryStateEnum

  @IsEnum(DeliveryStatusEnum)
  @IsOptional()
  @Expose()
  'filters[status]'?: DeliveryStatusEnum

  @IsOptional()
  @IsString()
  search?: string
}
