import {FC, ReactNode} from 'react'

// hooks
import useText from 'hooks/useText'

// components
import {Button} from 'components/elements/Button'
import {Typography} from 'components/elements/Typography'

// local
import {texts} from '../texts'

interface IFilter {
  menuState?: 'advanced' | 'main'
  setMenuState: React.Dispatch<React.SetStateAction<'advanced' | 'main'>>
  children?: ReactNode
  onSubmit?: () => void
  onClear?: () => void
}
const Filter: FC<IFilter> = ({
  menuState,
  setMenuState,
  children,
  onSubmit = () => {},
  onClear = () => {},
}) => {
  const {TX} = useText()
  return (
    <div className={`pt-1 ${menuState === 'advanced' ? '' : 'd-none'}`}>
      <Typography type='h4' className='fw-bold text-dark mb-7'>
        {TX(texts.filter)}
      </Typography>

      {children}

      <div className='d-flex justify-content-end'>
        <Button
          className='w-50 justify-content-center align-items-center btn btn-sm fw-bolder btn-primary  me-2'
          data-kt-search-element='advanced-options-form-search'
          onClick={onSubmit}
        >
          {TX(texts.submit)}
        </Button>
        <Button
          onClick={() => {
            // onClear()
            setMenuState('main')
          }}
          type='button'
          className='w-50 main-red-background btn btn-sm background-inherit justify-content-center'
        >
          {TX(texts.cancel)}
        </Button>
      </div>
    </div>
  )
}
export default Filter
