export interface ITag {
  id?: string
  createdAt?: string
  updatedAt?: string
  label?: string
}

export class Tag {
  public props: ITag = {}

  constructor(data?: ITag) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getLabel(): string {
    return this.props.label || ''
  }
}
