import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    boxPositionMap: {
      backgroundColor: 'transparent',
      borderRadius: '100px',
      boxShadow: '0 0 12px 0 #0000001F',
      fontSize: '12px !important',
      gap: '0 !important',
      position: 'absolute',
      top: '10px',
      right: '50%',
      transform: 'translate(50%,0)',
      zIndex: 9999,
      textAlign: 'right',
      justifyContent: 'flex-start !important',
      width: '90%',
      img: {
        width: '18px',
        height: '18px',
      },
    },
    buttonPositionMapDown: {
      backgroundColor: 'white !important',
      color: 'black !important',
      borderRadius: '100px',
      boxShadow: '0 0 12px 0 #0000001F',
      padding: '7px 10px !important',
      fontSize: '12px !important',
      gap: '0 !important',
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 9999,
    },
    searchFieldWrapper: {
      position: 'relative',
    },
    searchInput: {
      borderRadius: '1.2rem',
      paddingLeft: '40px',
      '& > :nth-child(4)': {
        maxHeight: '200px',
        overflow: 'scroll',
      },
    },
    searchIcon: {
      width: '20px !important',
      height: '20px !important',
      '& path': {
        fill: '#7C7C7C',
      },
      position: 'absolute',
      top: '1rem',
      left: '1rem',
    },
    container: {
      width: '100%',
      height: '100%',
    },
    totalBoxAddress: {
      backgroundColor: '#FFFFFF',
      border: '0.5px solid #D7D7D7',
      borderRadius: '12px',
    },
    totalButtons: {
      width: '100%',
      marginTop: '30px',
      padding: 0,
    },
    buttonSave: {
      padding: '7px 57px !important',
      fontSize: '14px !important',
      gap: '0 !important',
      color: '#FFFFFF !important',
    },
    buttonFullWidth: {
      width: '100%',
      marginRight: '10px',
      minWidth: 'auto !important',
    },
    buttonBack: {
      padding: '9px 20px !important',
      fontSize: '12px !important',
      gap: '0 !important',
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      textAlign: 'center',
      marginBottom: '16px',
      '& h3': {
        fontWeight: 400,
        fontSize: '22px',
      },
      '& h6': {
        fontWeight: 400,
        fontSize: '14px',
      },
    },

    noReserveDesc: {
      padding: theme.spacing(2),
      '& h6': {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
      },
    },
  }
})
