import {useState} from 'react'
import {NewPurchaseModal} from 'pages/protected/Support/OrderHistory/components/NewPurchaseModal'
import useText from 'hooks/useText'
import {KTSVG} from 'utils/template/helpers'
import {texts} from './texts'

const ToolbarOrderHistory = () => {
  const {TX} = useText()
  const [showCreatePurchaseOrderModal, setShowCreatePurchaseOrderModal] = useState<boolean>(false)

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div
        onClick={() => setShowCreatePurchaseOrderModal(true)}
        className='btn btn-sm main-gradient-background fw-bold d-flex align-items-center px-4 gap-1'
      >
        <KTSVG
          path='/media/icons/duotune/art/add-circle-sarsabad.svg'
          className='svg-icon-1 ms-2 me-0 text-white'
        />
        <div className='text-white fw-bold'>{TX(texts.new_purchase)}</div>
      </div>
      <NewPurchaseModal
        show={showCreatePurchaseOrderModal}
        handleClose={() => {
          setShowCreatePurchaseOrderModal(false)
        }}
      />
    </div>
  )
}

export {ToolbarOrderHistory}
