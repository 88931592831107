import {FC, forwardRef, memo} from 'react'
import {Pagination as MuiPagination, PaginationProps as MuiPaginationProps} from '@mui/material'
// models
import {Meta} from 'models/Meta'
// locals
import {useStyles} from './useStyle'

export type PaginationProps = MuiPaginationProps & {
  meta?: Meta
  onPageChange?: (page: number) => void
}

export const Pagination: FC<PaginationProps> = memo(
  forwardRef(
    (
      {
        meta = new Meta(),
        onPageChange = () => undefined,
        variant = 'outlined',
        shape = 'rounded',
        sx = () => {},
        ...props
      },
      ref
    ) => {
      const {classes} = useStyles({size: props.size})

      return (
        <MuiPagination
          ref={ref}
          variant={variant}
          shape={shape}
          count={meta.getItemsPerPage() && Math.ceil(meta.getTotalItems() / meta.getItemsPerPage())}
          page={meta.getCurrentPage()}
          onChange={(e, page) => onPageChange(page)}
          className={classes.paginationButton}
          boundaryCount={3}
          {...props}
        />
      )
    }
  )
)
