import {ProductStatusEnum} from 'enums'
import {Admin, IAdmin} from './Admin'
import {IOrderLine, OrderLine} from './OrderLine'
import {Photo} from './Photo'
import {IPhotoUse, PhotoUse} from './PhotoUse'
import {IProductInstance, ProductInstance} from './ProductInstance'
import {IProductType, ProductType} from './ProductType'
import {IProductUnit, ProductUnit} from './ProductUnit'
import {IProductVariety, ProductVariety} from './ProductVariety'
import {IVariety, Variety} from './Variety'

export interface IProduct {
  id?: string
  createdAt?: string
  updatedAt?: string
  name?: string
  percentagePrice?: number
  status?: ProductStatusEnum
  description?: string
  icon?: string | null
  averageRefuse?: number
  weightInKilogram?: number
  price?: number
  orderLineId?: string
  adminId?: string
  productTypeId?: string
  productType?: IProductType
  productUnitId?: string
  productUnit?: IProductUnit
  orderLine?: IOrderLine
  admin?: IAdmin
  photoUses?: IPhotoUse[]
  varieties?: IVariety[]
  productVarieties?: IProductVariety[]
  productInstances?: IProductInstance[]
  customPrice?: number
  priceUpdatedAt?: string
  useCustomPrice?: boolean
}

export class Product {
  public props: IProduct = {}

  constructor(data?: IProduct) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getName(): string {
    return this.props.name || ''
  }

  getPercentagePrice(): number {
    return (this.getUseCustomPrice() ? 0 : this.props.percentagePrice) || 0
  }

  getWeightInKilogram(): number {
    return this.props.weightInKilogram || 0
  }

  getStatus(): ProductStatusEnum {
    return this.props.status || ProductStatusEnum.deactivated
  }

  getDescription(): string {
    return this.props.description || ''
  }

  getOrderLineId(): string {
    return this.props.orderLineId || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getProductTypeId(): string {
    return this.props.productTypeId || ''
  }

  getProductType(): ProductType {
    return new ProductType(this.props.productType || {})
  }

  getProductUnitId(): string {
    return this.props.productUnitId || ''
  }

  getProductUnit(): ProductUnit {
    return new ProductUnit(this.props.productUnit || {})
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getOrderLine(): OrderLine {
    return new OrderLine(this.props.orderLine || {})
  }

  getAverageRefuse(): number {
    return this.props.averageRefuse || 0
  }

  getPrice(): number {
    return (this.getUseCustomPrice() ? this.props.customPrice : this.props.price) || 0
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || [])
      .map((photoUse) => new PhotoUse(photoUse))
      .sort((a, b) => a.getOrderNumber() - b.getOrderNumber())
  }

  getMainImage(): Photo {
    if (this.props.photoUses && this.props.photoUses.length > 0) {
      return new PhotoUse(this.props.photoUses[0]).getPhoto()
    }
    return new Photo()
  }

  getVarieties(): Variety[] {
    return (this.props.varieties || []).map((variety: IVariety) => new Variety(variety))
  }

  getProductInstances(): ProductInstance[] {
    return (this.props.productInstances || []).map(
      (productInstance: IProductInstance) => new ProductInstance(productInstance)
    )
  }

  getProductVarieties(): ProductVariety[] {
    return (this.props.productVarieties || []).map(
      (productVariety: IProductVariety) => new ProductVariety(productVariety)
    )
  }

  getCustomPrice(): number {
    return this.props.customPrice || 0
  }

  getPriceUpdatedAt(): string {
    return this.props.priceUpdatedAt || ''
  }

  getUseCustomPrice(): boolean {
    return this.props.useCustomPrice || false
  }

  // custom
  getDisplayStatus(): string {
    return displayProductStatusEnum(this.getStatus())
  }
}

export const displayProductStatusEnum = (productStatusEnum?: ProductStatusEnum): string => {
  switch (productStatusEnum) {
    case ProductStatusEnum.activated:
      return 'فعال'
    case ProductStatusEnum.deactivated:
      return 'غیرفعال'
    default:
      return ''
  }
}
