import queryString, {StringifiableRecord} from 'query-string'
import apiConfig from 'services/config'
import ClientApi from './clientApi'
// models
import {ICourier, ICustomer, IDeliveryChain, IDto, IRegion, IWeeklyPlan} from 'models'
import {ICourierCustomer} from 'models/CourierCustomer'
// dtos
import {CreateCourierRequestDto, UpdateCourierRequestDto} from 'dtos'

const {rootAddress} = apiConfig

const prefix = '/dashboard/couriers'

const axios = new ClientApi()

export const getWeeklyPlans = async (): Promise<IWeeklyPlan[]> =>
  await axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix + '/weekly-plans'}))

export const getCouriersList = async (query: StringifiableRecord): Promise<IDto<ICourier>> =>
  await axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix, query}))

export const getCourierDetails = async (courierId: string): Promise<ICourier> =>
  await axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix + `/${courierId}`}))

export const getCourierRegions = async (
  courierId: string,
  query: StringifiableRecord = {'filters[isActive]': true}
): Promise<IDto<IRegion>> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + `/delivery-region/${courierId}`, query},
      {arrayFormat: 'comma'}
    )
  )

export const getCourierCustomers = async (
  courierId: string,
  query: StringifiableRecord
): Promise<IDto<ICourierCustomer>> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/' + courierId + '/customers', query},
      {arrayFormat: 'comma'}
    )
  )

export const changeCourierStatusToActive = async (courierId: string) =>
  await axios.http.patch(rootAddress + prefix + `/${courierId}/activate`)

export const changeCourierStatusToDeactive = async (courierId: string) =>
  await axios.http.patch(rootAddress + prefix + `/${courierId}/deactivate`)

export const deleteCourier = async (courierId: string) =>
  await axios.http.delete(rootAddress + prefix + `/${courierId}`)

export const createCourier = async (body: CreateCourierRequestDto) =>
  await axios.http.post(rootAddress + prefix, body)

export const updateCourier = async (
  courierId: string,
  body: UpdateCourierRequestDto
): Promise<ICourier> => await axios.http.put(rootAddress + prefix + `/${courierId}`, body)

export const getCourierDeliveryChains = async (
  courierId: string,
  query: StringifiableRecord
): Promise<IDto<IDeliveryChain>> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + `/${courierId}/delivery-chains`, query},
      {arrayFormat: 'comma'}
    )
  )

export const getCourierDeliveryChainDetails = async (
  courierId: string,
  deliveryChainId: string
): Promise<IDeliveryChain> =>
  await axios.http.get(rootAddress + prefix + `/${courierId}/delivery-chains/${deliveryChainId}`)

export const getCourierDeliveryChainCustomers = async (
  courierId: string,
  deliveryChainId: string,
  query: StringifiableRecord
): Promise<IDto<ICustomer>> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {
        url: rootAddress + prefix + `/${courierId}/delivery-chains/${deliveryChainId}/customers`,
        query,
      },
      {arrayFormat: 'comma'}
    )
  )
