import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from 'components/template/layout/header'
import {ScrollTop} from 'components/template/layout/scroll-top'
import {Content} from 'components/template/layout/content'
import {Sidebar} from 'components/template/layout/sidebar'
import {ThemeModeProvider} from 'providers/theme-mode/ThemeModeProvider'
import {PageDataProvider} from 'providers/layout'
import {reInitMenu} from 'utils/template/helpers'
import {ToolbarWrapper} from 'components/template/layout/toolbar'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <>
      <PageDataProvider>
        <ThemeModeProvider>
          <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
            <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
              <HeaderWrapper />
              <div
                className='app-wrapper flex-column flex-row-fluid'
                id='kt_app_wrapper'
                style={{marginLeft: 0}}
              >
                <Sidebar />
                <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                  <div className='d-flex flex-column flex-column-fluid'>
                    <ToolbarWrapper />
                    <Content>
                      <Outlet />
                    </Content>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ScrollTop />
        </ThemeModeProvider>
      </PageDataProvider>
    </>
  )
}

export {MasterLayout}
