import {useState} from 'react'

// services
import service from 'services'

// models
import {Photo} from 'models'

// hooks
import {useQuery} from 'react-query'

export const useData = (photo: Photo) => {
  const [avatarSrc, setAvatarSrc] = useState('')
  const [openDialog, setOpenDialog] = useState(false)

  const getImage = (photoId: string) => async () => await service.upload.getPhoto(photoId)
  const photoId = photo.getId()
  const {isLoading} = useQuery(['get-image', photoId], getImage(photoId), {
    enabled: Boolean(photoId),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setAvatarSrc(data)
    },
  })

  return {
    avatarSrc,
    isLoading,
    openDialog,
    setOpenDialog,
  }
}
