import {IsString} from 'class-validator'

export class AddDeliveryToDeliveryChainRequestDto {
  @IsString({message: 'مشترک الزامی است'})
  deliveryId: string

  constructor() {
    this.deliveryId = ''
  }
}
