import {IsString, MaxLength, MinLength} from 'class-validator'
import {Match} from 'utils/decorators'

export class AdminResetPasswordRequestDto {
  @IsString()
  token: string

  @IsString({message: 'رمزعبور الزامی است'})
  @MinLength(4, {message: 'رمزعبور نباید کمتر از ۴ حرف باشد'})
  @MaxLength(20, {message: 'رمزعبور نباید بیشتر از ۲۰ حرف باشد'})
  password: string

  @IsString({message: 'تکرار رمزعبور الزامی است'})
  @MinLength(4, {message: 'تکرار رمزعبور نباید کمتر از ۴ حرف باشد'})
  @MaxLength(20, {message: 'تکرار رمزعبور نباید بیشتر از ۲۰ حرف باشد'})
  @Match('password', {message: 'رمز عبور یکسان نیست'})
  confirmPassword: string

  constructor() {
    this.token = ''
    this.password = ''
    this.confirmPassword = ''
  }
}
