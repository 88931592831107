import {useEffect, useState} from 'react'

// services
import services, {ServerError} from 'services'
// enums
import {FAQStatusEnum} from 'enums'
// hooks
import useText from 'hooks/useText'
import {useFormik} from 'formik'
import {useMutation} from 'react-query'
import {useSnackbar} from 'notistack'
import {useSchema} from './useSchema'
import useToolbarRefetch from 'hooks/useToolbarRefetch'
import {useParams} from 'react-router-dom'

// locals
import {texts} from './texts'
import {removeEmptyValues} from 'utils/basic/object'

const useData = (show: boolean, handleClose: () => void) => {
  const {TX} = useText()
  const {enqueueSnackbar} = useSnackbar()
  const {setIsRefetchOn} = useToolbarRefetch()
  const schemas = useSchema()
  const {faqCategoryId} = useParams()
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const initialValues = {
    question: '',
    answer: '',
  }

  /* ================================= */
  /*            Create FAQ             */
  /* ================================= */

  const createFAQHandler = async (body: {
    question: string
    answer: string
    status?: FAQStatusEnum
    faqCategoryId: string
  }) => await services.faq.createFAQ(body)

  const {isLoading: isLoadingFAQ, mutate: mutateFAQ} = useMutation(createFAQHandler, {
    onSuccess: async (data) => {
      handleClose()
      setIsRefetchOn(true)
      enqueueSnackbar(TX(texts.creation_success), {
        variant: 'success',
      })
    },
    onError: (error) => {
      const {message} = error as ServerError
      form.setSubmitting(false)
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  const form = useFormik({
    initialValues,
    validationSchema: schemas,
    onSubmit: async () => {
      setIsSubmitted(!isSubmitted)
      await mutateFAQ(
        removeEmptyValues({
          question: form.values.question,
          answer: form.values.answer,
          status: FAQStatusEnum.activated,
          faqCategoryId: faqCategoryId!,
        })
      )
    },
  })

  useEffect(() => {
    if (!show) {
      form.resetForm()
      form.setValues(initialValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return {
    form,
    loading: isLoadingFAQ,
    TX,
    isSubmitted,
    setIsSubmitted,
  }
}

export {useData}
