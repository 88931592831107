// components
import {TableToolbar} from 'components/elements/TableToolbar'
import {WEEKLY_PACKAGING_ROUTE} from 'constants/routes'
import queryString from 'query-string'
import {useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {removeEmptyValues} from 'utils/basic/object'

interface IQuery {
  page?: number
  limit?: number
  search?: string
}

const ToolbarArrangement = () => {
  const navigate = useNavigate()
  const initialQuery = {
    page: 1,
    limit: 10,
    search: '',
    date: undefined,
    sortType: undefined,
    sort: undefined,
  }

  const convertSearchParamsToQuery = (searchParams: URLSearchParams): IQuery => {
    return {
      page: Number(searchParams.get('page')) || 1,
      limit: Number(searchParams.get('limit')) || 10,

      search: searchParams.get('search') || '',
    }
  }

  const [searchParams] = useSearchParams()
  const [query, setQuery] = useState<IQuery>(convertSearchParamsToQuery(searchParams))

  const handleChangeUrl = (newQuery: IQuery) => {
    navigate(
      queryString.stringifyUrl({
        url: WEEKLY_PACKAGING_ROUTE,
        query: {page: 1, limit: 10, ...newQuery},
      })
    )
  }

  const onSubmit = () => {
    const {page, limit, ...restQuery} = query
    handleChangeUrl(removeEmptyValues(restQuery))
  }

  const onClean = () => {
    setQuery(initialQuery)
    handleChangeUrl(removeEmptyValues(initialQuery))
  }

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <TableToolbar
        // filter={filter}
        onSubmit={onSubmit}
        onClear={onClean}
        query={query}
        setQuery={setQuery}
      />
    </div>
  )
}

export {ToolbarArrangement}
