import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    border: '.2rem solid transparent',
    padding: '1rem',
    borderRadius: '1rem',
    transition: 'all .2s',
    background: 'rgb(255, 243, 224)',
    textAlign: 'center',
    cursor: 'pointer',
    position: 'relative',
  },

  selected: {
    borderColor: '#029C45',
  },

  text: {
    color: '#5D5D5D !important',
  },

  icon: {
    width: '1.5rem !important',
    height: '1.5rem !important',
    position: 'absolute',
    top: '.2rem',
    left: '.1rem',
  },
}))

export default useStyles
