import {useState} from 'react'
import {KTSVG} from 'utils/template/helpers'

import {CreateNewLineModal} from 'pages/protected/ProductionLine/Packaging/Lines/components/CreateNewLineModal'
import useText from 'hooks/useText'
import { texts } from './texts'

const ToolbarNewPackingLine = () => {
  const {TX} = useText()
  const [showCreateBoxModal, setShowCreateBoxModal] = useState<boolean>(false)

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div
        className='btn btn-sm main-gradient-background fw-bold d-flex align-items-center px-4'
        onClick={() => setShowCreateBoxModal(true)}
      >
        <KTSVG
          path='/media/icons/duotune/art/add-circle-sarsabad.svg'
          className='svg-icon-1 ms-2 me-0 text-white'
        />
        <div className='text-white fw-bold'>{TX(texts.new_line)}</div>
      </div>
      <CreateNewLineModal
        show={showCreateBoxModal}
        handleClose={() => setShowCreateBoxModal(false)}
      />
    </div>
  )
}

export {ToolbarNewPackingLine}
