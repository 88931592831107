import {FC, ReactNode} from 'react'
import {TableToolbar} from './TableToolbar'
import {Typography} from './Typography'

interface TableHeaderProps {
  title?: string
  description?: string
  filter?: ReactNode
  onSubmit?: () => void
  onClear?: () => void
  query?: any
  setQuery?: React.Dispatch<React.SetStateAction<any>>
}

const TableHeader: FC<TableHeaderProps> = ({
  title = '',
  description = '',
  filter,
  onSubmit,
  onClear,
  query,
  setQuery,
}) => {
  return (
    <div className='card-header border-0 pt-5'>
      <Typography type='h3' className='card-title align-items-start flex-column'>
        <Typography type='span' className='card-label fw-bold fs-3 mb-1'>
          {title}
        </Typography>
        <Typography type='span' className='text-muted mt-1 fw-semibold fs-7'>
          {description}
        </Typography>
      </Typography>
      <TableToolbar
        filter={filter}
        onSubmit={onSubmit}
        onClear={onClear}
        query={query}
        setQuery={setQuery}
      />
    </div>
  )
}

export {TableHeader}
