import {IsEnum, IsOptional} from 'class-validator'
import {ContextEnum} from 'enums'

export class EngagedOrderLineByScaleRequestDto {
  @IsEnum(ContextEnum)
  @IsOptional()
  context?: ContextEnum

  constructor() {
    this.context = ContextEnum.inventory_arrived
  }
}
