import {useEffect, useMemo, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useMutation, useQuery} from 'react-query'
import {useSnackbar} from 'notistack'
import {useForm} from 'react-hook-form'
import {classValidatorResolver} from '@hookform/resolvers/class-validator'
// services
import services, {ServerError} from 'services'
import {ICoordinates} from 'services/regions'
// models
import {Address, City, District, Dto, Province, Region} from 'models'
//utils
import {removeEmptyValues} from 'utils/basic/object'
// enums
import {CustomerStatusEnum} from 'enums'
// hooks
import useText from 'hooks/useText'
import useToolbarRefetch from 'hooks/useToolbarRefetch'
// locals
import {texts} from './texts'
import {CreateCustomerRequestDto} from 'dtos'

interface IQuery {
  page: number
  limit: number
  search: string
  'filters[districtId]': string
}

const useData = (show: boolean, handleClose: () => void) => {
  const {TX} = useText()
  const {enqueueSnackbar} = useSnackbar()
  const {setIsRefetchOn} = useToolbarRefetch()
  const [searchParams] = useSearchParams()
  const [addressOnMap, setAddressOnMap] = useState<Address>(new Address())
  const [cities, setCities] = useState<City[]>([])
  const [provinces, setProvinces] = useState<Province[]>([])
  const [districts, setDistrict] = useState<District[]>([])
  const [coordinates, setCoordinates] = useState<ICoordinates>({
    lat: '35.7219',
    long: '51.3347',
  })

  const convertSearchParamsToQuery = (searchParams: URLSearchParams): IQuery => {
    return removeEmptyValues({
      page: Number(searchParams.get('page')) || 1,
      limit: Number(searchParams.get('limit')) || 10,
      search: searchParams.get('search') || '',
      'filters[districtId]': searchParams.get('filters[districtId]') || '',
    })
  }

  const [query, setQuery] = useState(convertSearchParamsToQuery(searchParams))
  const [selectedRegion, setSelectedRegion] = useState(new Region())
  const [districtsCount, setDistrictsCount] = useState(0)
  const [regionCount, setRegionCount] = useState(0)

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue: setFormValue,
    formState: {errors, isValid},
  } = useForm<CreateCustomerRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(CreateCustomerRequestDto),
    defaultValues: {
      firstName: '',
      lastName: '',
      mobileNumber: '',
      description: '',
      email: '',
      secondMobileNumber: '',
      birthDate: new Date(),
      status: CustomerStatusEnum.activated,
      address: {
        name: '',
        provinceId: '',
        cityId: '',
        districtId: '',
        streetOne: '',
        houseNumber: 0,
        unitNumber: 0,
        streetTwo: '',
        address: '',
      },
    },
  })

  /* -------------------------------------------------------------------------- */
  /*                             Services                                     */
  /* -------------------------------------------------------------------------- */

  const getDistricts = async () => await services.location.getDistricts()

  const getProvinces = async () => await services.location.getProvinces()

  const getCities = async () =>
    await services.location.getProvinceCities(watch().address?.provinceId!)

  const getRegions = async () => await services.regions.getRegions({...query})

  const handleCreateCustomerService = async () => {
    await services.customers.createCustomer(
      removeEmptyValues({
        ...watch(),
        address: {...removeEmptyValues(watch().address), unitNumber: 0, houseNumber: 0},
      })
    )
  }

  /* -------------------------------------------------------------------------- */
  /*                             Queries                                     */
  /* -------------------------------------------------------------------------- */

  const {isLoading: isLoadingProvince} = useQuery(
    ['get-provinces', watch().address.provinceId],
    getProvinces,
    {
      onSuccess: (data) => {
        setProvinces(data.map((province) => new Province(province)))
      },
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
    }
  )

  const {isLoading: isLoadingCities} = useQuery(
    ['get-cities', watch().address?.provinceId],
    getCities,
    {
      onSuccess: (data) => {
        setCities(data.map((city) => new City(city)))
      },
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
      enabled: !!watch().address?.provinceId,
    }
  )

  const {isLoading: isLoadingDistricts} = useQuery(
    ['get-districts', watch().address?.cityId],
    getDistricts,
    {
      onSuccess: (data) => {
        setDistrict(data.map((district) => new District(district)))
      },
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
    }
  )

  const {data: regionData, isLoading: isLoadingRegions} = useQuery(
    ['get-regions', query.search, query['filters[districtId]'], watch().address.districtId],
    getRegions,
    {
      onSuccess: (data) => {},
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
      enabled: !!watch().address.districtId,
    }
  )

  /* -------------------------------------------------------------------------- */
  /*                             Mutations                                     */
  /* -------------------------------------------------------------------------- */

  const {isLoading, mutate} = useMutation(handleCreateCustomerService, {
    onSuccess: () => {
      handleClose()
      setIsRefetchOn(true)
      reset()
      enqueueSnackbar(TX(texts.customer_added_successfully), {
        variant: 'success',
      })
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* -------------------------------------------------------------------------- */
  /*                                  Handlers                                  */
  /* -------------------------------------------------------------------------- */

  const onSubmit = async () => {
    await mutate()
  }

  const handleChangeBirthDate = (value: Date | null) => {
    if (!value) return

    setFormValue('birthDate', value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  /* -------------------------------------------------------------------------- */
  /*                             Lifecycles                                       */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (!!addressOnMap.getAddress()) {
      setFormValue('address.streetOne', addressOnMap.getAddress())
      setFormValue('address.cityId', addressOnMap.getCity().getId())
      setFormValue('address.provinceId', addressOnMap.getProvince().getId())
      setFormValue('address.districtId', addressOnMap.getDistrict().getId())
      setFormValue('address.lat', coordinates.lat)
      setFormValue('address.long', coordinates.long)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressOnMap])

  useEffect(() => {
    if (show) {
      reset()
      setCoordinates({
        lat: '35.7219',
        long: '51.3347',
      })
      setAddressOnMap(new Address())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  /* -------------------------------------------------------------------------- */
  /*                             Data                                       */
  /* -------------------------------------------------------------------------- */

  const dto = new Dto(regionData)
  const regions = dto.getItems ? dto.getItems().map((region) => new Region(region)) : []

  const statusValue = useMemo(() => {
    return watch().status
  }, [watch().status])

  const birthDateValue = useMemo(() => {
    return watch().birthDate
  }, [watch().birthDate])

  const provinceId = useMemo(() => {
    return watch().address.provinceId
  }, [watch().address.provinceId])

  const cityId = useMemo(() => {
    return watch().address.cityId
  }, [watch().address.cityId])

  return {
    onSubmit: handleSubmit(onSubmit),
    control,
    statusValue,
    birthDateValue,
    provinceId,
    cityId,
    reset,
    setFormValue,
    errors,
    isValid,
    regionCount,
    setRegionCount,
    isLoading,
    TX,
    isLoadingProvince,
    isLoadingCities,
    cities,
    provinces,
    districts,
    isLoadingDistricts,
    isLoadingRegions,
    regions,
    setQuery,
    selectedRegion,
    setSelectedRegion,
    districtsCount,
    setDistrictsCount,
    coordinates,
    setCoordinates,
    addressOnMap,
    setAddressOnMap,
    handleChangeBirthDate,
  }
}

export {useData}
