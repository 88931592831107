import queryString, {StringifiableRecord} from 'query-string'
import ClientApi from './clientApi'
import config from './config'
// models
import {ICoupon, ICouponCustomer, ICouponUsage, IDto} from 'models'
// dtos
import {CreateCouponRequestDto} from 'dtos/create-coupon-request.dto'
import {UpdateCouponRequestDto} from 'dtos/update-coupon-request.dto'

const {rootAddress} = config

const prefix = '/dashboard/coupons'

const axios = new ClientApi()

export const createNewCoupon = (body: CreateCouponRequestDto): Promise<ICoupon> =>
  axios.http.post(rootAddress + prefix, body)

export const updateCoupon = (couponId: string, body: UpdateCouponRequestDto): Promise<ICoupon> =>
  axios.http.put(rootAddress + prefix + '/' + couponId, body)

export const getCouponsList = async (query?: StringifiableRecord): Promise<IDto<ICoupon>> =>
  await axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix, query}, {arrayFormat: 'comma'})
  )

export const getCouponDetails = (couponId: string): Promise<ICoupon> =>
  axios.http.get(rootAddress + prefix + '/' + couponId)

export const getCouponCustomersList = async (
  couponId: string,
  query?: StringifiableRecord
): Promise<IDto<ICouponCustomer>> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + `/${couponId}/customers`, query},
      {arrayFormat: 'comma'}
    )
  )

export const getCouponUsagesList = async (
  couponId: string,
  query?: StringifiableRecord
): Promise<IDto<ICouponUsage>> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + `/${couponId}/usages`, query},
      {arrayFormat: 'comma'}
    )
  )
