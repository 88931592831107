import {useEffect, useState} from 'react'

//locals
import {IDayPicker, IValue} from './index'

interface IUseData {
  onChange: IDayPicker['onChange']
  value: IDayPicker['value']
}
const useData = ({onChange, value}: IUseData) => {
  const [selectedOptions, setSelectedOptions] = useState<IValue[]>(value || [])
  const [isValueSet, setIsValueSet] = useState(false)

  useEffect(() => {
    if (value.length && !isValueSet) {
      setSelectedOptions(value)
      setIsValueSet(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return {selectedOptions, setSelectedOptions}
}

export default useData
