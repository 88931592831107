import React from 'react'
// import queryString from 'query-string'
// import {getDateStringForQuery} from 'utils/basic/date'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {
  DASHBOARD_ROUTE,
  SUPPLIES_ROUTE,
  WEEKLY_PACKAGING_ROUTE,
  PURCHASE_ORDER_ROUTE,
  PRODUCTS_ROUTE,
  VENDORS_ROUTE,
  VARIETY_LIST_ROUTE,
  EDIT_BOXES_ROUTE,
  WAREHOUSE_ROUTE,
  WAREHOUSE_WEIGHING_ROUTE,
  // WAREHOUSE_PROCESSING_STATE_ROUTE,
  // FAQ_ROUTE,
  ADMIN_MANAGEMENT_ROUTE,
  SCALES_ROUTE,
  SORTING_ROUTE,
  EMPLOYEE_ROUTE,
  SORTING_PRODUCTS_ROUTE,
  SORTING_LINES_ROUTE,
  SORTING_REPORTS_ROUTE,
  SUBSCRIBERS_ROUTE,
  SUBSCRIBERS_LIST_ACTIVE_LIST_ROUTE,
  SUBSCRIBERS_LIST_DEACTIVE_LIST_ROUTE,
  SUBSCRIBERS_LIST_WAITING_LIST_ROUTE,
  SUBSCRIBERS_LIST_OUTSIDE_LIST_ROUTE,
  DELIVERY_ROUTE,
  DELIVERY_CHAINS_ROUTE,
  DELIVERY_REGIONS_ROUTE,
  DELIVERY_COURIERS_LIST_ROUTE,
  DELIVERY_REPORTS_ROUTE,
  TRANSACTIONS_LIST_ROUTE,
  SUPPORT_ROUTE,
  SUPPORT_TICKETS_OPEN_TICKETS_ROUTE,
  SUPPORT_TICKETS_CLOSED_TICKETS_ROUTE,
  SUBSCRIBERS_LIST_FULL_CAPACITY_LIST_ROUTE,
  REPORTS_ROUTE,
  PACKAGING_ROUTE,
  START_PACKAGING_ROUTE,
  PACKAGING_LINES_ROUTE,
  PACKAGING_LINE_REPORT_ROUTE,
  ORDER_HISTORY_LIST_ROUTE,
  SUPPORT_TICKETS_ROUTE,
  COUPON_LIST_ROUTE,
} from 'constants/routes'

import useText from 'hooks/useText'

import {texts} from './texts'
import useUser from 'hooks/useUser'
import {AdminRolesEnum} from 'enums'

const SidebarMenuMain = () => {
  const {TX} = useText()
  const {admin} = useUser()

  return (
    <>
      <SidebarMenuItem
        to={DASHBOARD_ROUTE}
        icon='/media/icons/duotune/art/art002-sarsabad.svg'
        title={TX(texts.dashboard)}
        fontIcon='bi-app-indicator'
      />
      {(admin.getRoles().includes(AdminRolesEnum.admin) ||
        admin.getRoles().includes(AdminRolesEnum.procurement) ||
        admin.getRoles().includes(AdminRolesEnum.inventory) ||
        admin.getRoles().includes(AdminRolesEnum.sorting) ||
        admin.getRoles().includes(AdminRolesEnum.packaging)) && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted fs-8'>{TX(texts.production_line)}</span>
          </div>
        </div>
      )}
      {(admin.getRoles().includes(AdminRolesEnum.admin) ||
        admin.getRoles().includes(AdminRolesEnum.procurement)) && (
        <SidebarMenuItemWithSub
          to={SUPPLIES_ROUTE}
          title={TX(texts.supplies)}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen025.svg'
        >
          <SidebarMenuItem
            to={WEEKLY_PACKAGING_ROUTE}
            title={TX(texts.weekly_packages)}
            hasBullet={true}
          />
          <SidebarMenuItem
            to={PURCHASE_ORDER_ROUTE}
            title={TX(texts.purchase_order)}
            hasBullet={true}
          />
          <SidebarMenuItem to={EDIT_BOXES_ROUTE} title={TX(texts.boxes)} hasBullet={true} />
          <SidebarMenuItem to={PRODUCTS_ROUTE} title={TX(texts.products)} hasBullet={true} />
          <SidebarMenuItem to={VARIETY_LIST_ROUTE} title={TX(texts.varieties)} hasBullet={true} />
          <SidebarMenuItem to={VENDORS_ROUTE} title={TX(texts.vendors)} hasBullet={true} />
        </SidebarMenuItemWithSub>
      )}
      {(admin.getRoles().includes(AdminRolesEnum.admin) ||
        admin.getRoles().includes(AdminRolesEnum.inventory)) && (
        <SidebarMenuItemWithSub
          to={WAREHOUSE_ROUTE}
          title={TX(texts.warehouse)}
          icon='/media/icons/duotune/general/gen025.svg'
          fontIcon='bi-person'
        >
          <SidebarMenuItem
            to={WAREHOUSE_WEIGHING_ROUTE}
            title={TX(texts.warehouse_overall)}
            icon='/media/icons/duotune/general/gen022.svg'
          />
          {/* <SidebarMenuItem
            to={WAREHOUSE_PROCESSING_STATE_ROUTE}
            title={TX(texts.warehouse_processing_status)}
            icon='/media/icons/duotune/general/gen022.svg'
          /> */}
          <SidebarMenuItem
            to={REPORTS_ROUTE}
            title={TX(texts.reports)}
            icon='/media/icons/duotune/general/gen022.svg'
          />
          <SidebarMenuItem
            to={SCALES_ROUTE}
            title={TX(texts.scales)}
            icon='/media/icons/duotune/general/gen022.svg'
          />
        </SidebarMenuItemWithSub>
      )}
      {(admin.getRoles().includes(AdminRolesEnum.admin) ||
        admin.getRoles().includes(AdminRolesEnum.sorting)) && (
        <SidebarMenuItemWithSub
          to={SORTING_ROUTE}
          title={TX(texts.distinguish)}
          icon='/media/icons/duotune/general/gen025.svg'
        >
          <SidebarMenuItem
            to={SORTING_PRODUCTS_ROUTE}
            title={TX(texts.products)}
            icon='/media/icons/duotune/general/gen022.svg'
          />
          <SidebarMenuItem
            to={SORTING_LINES_ROUTE}
            title={TX(texts.lines)}
            icon='/media/icons/duotune/general/gen022.svg'
          />
          <SidebarMenuItem
            to={SORTING_REPORTS_ROUTE}
            title={TX(texts.reports)}
            icon='/media/icons/duotune/general/gen022.svg'
          />
        </SidebarMenuItemWithSub>
      )}
      {(admin.getRoles().includes(AdminRolesEnum.admin) ||
        admin.getRoles().includes(AdminRolesEnum.packaging)) && (
        <SidebarMenuItemWithSub
          to={PACKAGING_ROUTE}
          title={TX(texts.packaging)}
          icon='/media/icons/duotune/general/gen025.svg'
        >
          <SidebarMenuItem
            to={START_PACKAGING_ROUTE}
            title={TX(texts.startPackaging)}
            icon='/media/icons/duotune/general/gen022.svg'
          />
          <SidebarMenuItem
            to={PACKAGING_LINES_ROUTE}
            title={TX(texts.lines)}
            icon='/media/icons/duotune/general/gen022.svg'
          />
          <SidebarMenuItem
            to={PACKAGING_LINE_REPORT_ROUTE}
            title={TX(texts.linesReports)}
            icon='/media/icons/duotune/general/gen022.svg'
          />
        </SidebarMenuItemWithSub>
      )}

      {(admin.getRoles().includes(AdminRolesEnum.admin) ||
        admin.getRoles().includes(AdminRolesEnum.customer_management)) && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted fs-8'>{TX(texts.customers_and_support)}</span>
          </div>
        </div>
      )}
      {(admin.getRoles().includes(AdminRolesEnum.admin) ||
        admin.getRoles().includes(AdminRolesEnum.customer_management)) && (
        <>
          <SidebarMenuItemWithSub
            to={SUBSCRIBERS_ROUTE}
            title={TX(texts.customers)}
            fontIcon='bi-archive'
            icon='/media/icons/duotune/general/gen025.svg'
          >
            <SidebarMenuItem
              to={SUBSCRIBERS_LIST_ACTIVE_LIST_ROUTE}
              title={TX(texts.active)}
              icon='/media/icons/duotune/general/gen022.svg'
            />
            <SidebarMenuItem
              to={SUBSCRIBERS_LIST_DEACTIVE_LIST_ROUTE}
              title={TX(texts.deactive)}
              icon='/media/icons/duotune/general/gen022.svg'
            />
            <SidebarMenuItem
              to={SUBSCRIBERS_LIST_WAITING_LIST_ROUTE}
              title={TX(texts.waiting_list)}
              icon='/media/icons/duotune/general/gen022.svg'
            />
            <SidebarMenuItem
              to={SUBSCRIBERS_LIST_OUTSIDE_LIST_ROUTE}
              title={TX(texts.outside)}
              icon='/media/icons/duotune/general/gen022.svg'
            />
            <SidebarMenuItem
              to={SUBSCRIBERS_LIST_FULL_CAPACITY_LIST_ROUTE}
              title={TX(texts.full_capacity)}
              icon='/media/icons/duotune/general/gen022.svg'
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to={SUPPORT_TICKETS_ROUTE}
            title={TX(texts.support)}
            fontIcon='bi-archive'
            icon='/media/icons/duotune/general/gen025.svg'
          >
            <SidebarMenuItem
              to={SUPPORT_TICKETS_OPEN_TICKETS_ROUTE}
              title={TX(texts.not_answered)}
              icon='/media/icons/duotune/general/gen022.svg'
            />
            <SidebarMenuItem
              to={SUPPORT_TICKETS_CLOSED_TICKETS_ROUTE}
              title={TX(texts.solved)}
              icon='/media/icons/duotune/general/gen022.svg'
            />
          </SidebarMenuItemWithSub>

          <SidebarMenuItem
            to={TRANSACTIONS_LIST_ROUTE}
            title={TX(texts.transactions)}
            icon='/media/icons/duotune/general/gen025.svg'
          />

          <SidebarMenuItem
            to={ORDER_HISTORY_LIST_ROUTE}
            title={TX(texts.order_history)}
            icon='/media/icons/duotune/general/gen025.svg'
          />

          <SidebarMenuItem
            to={COUPON_LIST_ROUTE}
            title={TX(texts.coupons)}
            icon='/media/icons/duotune/general/gen025.svg'
          />
        </>
      )}
      {(admin.getRoles().includes(AdminRolesEnum.admin) ||
        admin.getRoles().includes(AdminRolesEnum.delivery)) && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted fs-8'>{TX(texts.delivery_submenu)}</span>
          </div>
        </div>
      )}
      {(admin.getRoles().includes(AdminRolesEnum.admin) ||
        admin.getRoles().includes(AdminRolesEnum.delivery)) && (
        <SidebarMenuItemWithSub
          to={DELIVERY_ROUTE}
          title={TX(texts.delivery_submenu)}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen025.svg'
        >
          <SidebarMenuItem
            to={DELIVERY_CHAINS_ROUTE}
            title={TX(texts.chains)}
            icon='/media/icons/duotune/general/gen022.svg'
          />
          <SidebarMenuItem
            to={DELIVERY_REGIONS_ROUTE}
            title={TX(texts.regions)}
            icon='/media/icons/duotune/general/gen022.svg'
          />
          <SidebarMenuItem
            to={DELIVERY_COURIERS_LIST_ROUTE}
            title={TX(texts.couriers)}
            icon='/media/icons/duotune/general/gen022.svg'
          />
          <SidebarMenuItem
            to={DELIVERY_REPORTS_ROUTE}
            title={TX(texts.reports)}
            icon='/media/icons/duotune/general/gen022.svg'
          />
        </SidebarMenuItemWithSub>
      )}
      {admin.getRoles().includes(AdminRolesEnum.admin) && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted fs-8'>{TX(texts.system_config)}</span>
          </div>
        </div>
      )}
      {admin.getRoles().includes(AdminRolesEnum.admin) && (
        <>
          {/* <SidebarMenuItem
            to={FAQ_ROUTE}
            title={TX(texts.faq)}
            icon='/media/icons/duotune/general/gen025.svg'
          /> */}
          <SidebarMenuItem
            to={ADMIN_MANAGEMENT_ROUTE}
            title={TX(texts.admin_management)}
            icon='/media/icons/duotune/general/gen025.svg'
          />
          <SidebarMenuItem
            to={EMPLOYEE_ROUTE}
            title={TX(texts.employee_management)}
            icon='/media/icons/duotune/general/gen025.svg'
          />
        </>
      )}
    </>
  )
}

export {SidebarMenuMain}
