import {FC} from 'react'
import {Box} from '@mui/material'
// Components
import {Typography} from 'components/elements/Typography'
// Utils
import {KTSVG, toPersianDigit} from 'utils/template/helpers'
// models
import {DeliveryChainCustomer} from 'models'
// hooks
import useText from 'hooks/useText'
// Local
import useStyles from './useStyles'
import {texts} from '../../texts'

type Props = {
  customer?: DeliveryChainCustomer
  selected?: boolean
}

const CustomerCard: FC<Props> = ({selected, customer}) => {
  const {TX} = useText()
  const {classes} = useStyles()

  return (
    <Box className={[classes.wrapper, selected ? classes.selected : ''].join(' ')}>
      <Typography type='span'>{customer?.getCustomer().getFullName()}</Typography>
      <Typography type='span'>
        {TX(texts.order_number)}:{' '}
        {toPersianDigit(customer?.getDelivery().getSubscription().getSubscriptionNumber() || 0)}
      </Typography>
      {customer?.getPackingDelivery().getStatus() === 'finish' && (
        <KTSVG path='/media/sarsabad/done_all.svg' svgClassName={classes.icon} />
      )}
    </Box>
  )
}

export default CustomerCard
