import queryString, {StringifiableRecord} from 'query-string'
import ClientApi from './clientApi'
import config from './config'
// models
import {IDto, IOrderLine, IVendor} from 'models'
// dtos
import {CreateVendorRequestDto, UpdateVendorRequestDto, UpdateVendorStatusRequestDto} from 'dtos'

const {rootAddress} = config

const prefix = '/dashboard/vendors'

const axios = new ClientApi()

export const getVendorsList = (query: StringifiableRecord): Promise<IDto<IVendor>> =>
  axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix, query}))

export const createNewVendor = (body: CreateVendorRequestDto) =>
  axios.http.post(rootAddress + prefix, body)

export const getVendorDetail = (vendorId: string): Promise<IVendor> =>
  axios.http.get(rootAddress + prefix + '/' + vendorId)

export const getVendorOrderLinesDetail = (
  vendorId: string,
  query: StringifiableRecord
): Promise<IDto<IOrderLine>> =>
  axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix + '/' + vendorId + '/order-line', query})
  )

export const editVendor = (vendorId: string, body: UpdateVendorRequestDto): Promise<IVendor> =>
  axios.http.put(rootAddress + prefix + '/' + vendorId, body)

export const updateStatus = (
  vendorId: string,
  body: UpdateVendorStatusRequestDto
): Promise<IVendor> => axios.http.patch(rootAddress + prefix + '/' + vendorId, body)
