import {FC} from 'react'

type TextareaProps = {
  className?: string
  labelClassName?: string
  label?: string
  name?: string
  placeholder?: string
  helperText?: string
  helperTextColor?: string
  value?: string
  onChange?: (e: any) => void
  rows?: number
} & React.HTMLProps<HTMLTextAreaElement>

const Textarea: FC<TextareaProps> = ({
  className = '',
  labelClassName = '',
  label = '',
  name,
  placeholder = '',
  helperText = '',
  helperTextColor = '#001A0B',
  value,
  onChange,
  rows = 3,
  ...props
}) => {
  return (
    <div className='fv-row w-100'>
      <label
        className={['d-flex align-items-center fs-5 fw-semibold mb-2', labelClassName].join(' ')}
      >
        <span>{label}</span>
      </label>
      <textarea
        {...props}
        className={['form-control form-control-lg form-control-solid', className].join(' ')}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        rows={rows}
      />
      {helperText && (
        <div
          className='fv-plugins-message-container'
          style={{color: `${helperTextColor} !important`}}
        >
          <div className='fv-help-block'>
            <span role='alert'>{helperText}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export {Textarea}
