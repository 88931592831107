import {useTheme} from '@mui/material/styles'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => {
  const theme = useTheme()
  return {
    root: {
      position: 'relative',
      width: '100%',
      // padding: theme.spacing(2),
    },
    googleMap: {
      width: '100%',
      height: '400px',
    },
    findMe: {
      position: 'absolute',
      top: '0',
      right: '0',
      zIndex: theme.zIndex.tooltip,
      cursor: 'pointer',
      width: '70px',
    },
    googleMapIcon: {
      position: 'absolute',
      bottom: '10px',
      left: '20px',
      zIndex: theme.zIndex.tooltip,
      cursor: 'pointer',
    },
    icon: {
      backgrond: 'transparent',
      border: 'none',
    },
    searchContainer: {
      position: 'absolute',
      top: '10px',
      left: '10px',
      width: '100%',
      zIndex: theme.zIndex.snackbar,
    },
    list: {
      padding: theme.spacing(2),
      display: 'flex',
      gap: theme.spacing(1.5),
      flexDirection: 'column',
      position: 'absolute',
      bottom: '40px',
      left: '0',
      zIndex: theme.zIndex.tooltip,
      width: '100%',
    },
    listHeader: {
      backgroundColor: theme.palette.common.white + '!important',
      fontWeight: 600,
      fontSize: '10px',
      color: theme.palette.primary.main,
      width: '115px',
      height: '26px',
      borderRadius: '13px',
    },
    listContent: {
      display: 'flex',
      gap: theme.spacing(1.5),
      overflow: 'auto',
    },
    listTag: {
      backgroundColor: theme.palette.error.contrastText,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(1),
      borderRadius: '18px',
      minWidth: 'fit-content',
      padding: theme.spacing(0.25, 1.5, 0.25, 0.25),
    },
    profileImage: {
      width: '32px',
      height: '32px',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    infoContent: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      minWidth: 'fit-content',
      paddingBottom: theme.spacing(0.5),
      borderBottom: `1px dashed ${theme.palette.primary.main}`,
    },
    infoButton: {
      height: '30px',
      fontSize: '12px',
      marginTop: theme.spacing(0.5),
    },
  }
})
