export enum CustomerStatusEnum {
  activated = 'activated',
  deactivated = 'deactivated',
  on_waiting_list = 'on_waiting_list',
  full_capacity = 'full_capacity',
  out_of_range = 'out_of_range',
}

export enum GetCustomersRequestDtoSortEnum {
  firstName = 'customer.firstName',
  lastName = 'customer.lastName',
  status = 'customer.status',
  createdAt = 'customer.createdAt',
}
