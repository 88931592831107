import {Equals, IsDate, IsEnum, IsNotEmpty, IsNumber, IsPositive, IsString} from 'class-validator'
import {ScaleActionEnum} from 'enums'

export class WeighingScaleRequestDto {
  @IsEnum(ScaleActionEnum)
  @Equals(ScaleActionEnum.weighing)
  action: ScaleActionEnum

  @IsDate()
  timestamp: Date

  @IsString({message: 'ترازو الزامی است'})
  @IsNotEmpty({message: 'ترازو الزامی است'})
  scale_id: string

  @IsNumber({}, {message: 'وزن الزامی است'})
  @IsPositive({message: 'وزن باید عدد مثبت باشد'})
  @IsNotEmpty({message: 'وزن الزامی است'})
  weight: number

  constructor() {
    this.action = ScaleActionEnum.weighing
    this.timestamp = new Date()
    this.scale_id = '1'
    this.weight = 0
  }
}
