import {Type} from 'class-transformer'
import {IsArray, IsString} from 'class-validator'

class ICoordinate {
  @IsString({message: 'موقعیت جغرافیایی خود را مشخص کنید'})
  lat: string

  @IsString({message: 'موقعیت جغرافیایی خود را مشخص کنید'})
  long: string

  constructor() {
    this.lat = ''
    this.long = ''
  }
}

export class CreateRegionRequestDto {
  @IsString({message: 'نام محدوده الزامی است'})
  name: string

  @IsArray({message: 'موقعیت جغرافیایی الزامی است'})
  @Type(() => ICoordinate)
  coordinates: ICoordinate[][]

  @IsArray({message: 'محله الزامی است'})
  districtIds: string[]

  constructor() {
    this.name = ''
    this.coordinates = []
    this.districtIds = []
  }
}
