import {FC, useState} from 'react'

// local
import Context from './context'

const ToolbarRefetchProvider: FC<{children: React.ReactNode}> = ({children}) => {
  const [isRefetchOn, setIsRefetchOn] = useState(false)

  return (
    <Context.Provider
      value={{
        isRefetchOn,
        setIsRefetchOn,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default ToolbarRefetchProvider
