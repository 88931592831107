import {Equals, IsDate, IsEnum, IsNotEmpty, IsString} from 'class-validator'
import {ScaleActionEnum} from 'enums'

export class InitializedScaleRequestDto {
  @IsEnum(ScaleActionEnum)
  @Equals(ScaleActionEnum.initialize)
  action: ScaleActionEnum

  @IsDate({message: 'تاریخ نامعتبر است'})
  timestamp: Date

  @IsString({message: 'ترازو الزامی است'})
  @IsNotEmpty({message: 'ترازو الزامی است'})
  scale_id: string

  constructor() {
    this.action = ScaleActionEnum.initialize
    this.timestamp = new Date()
    this.scale_id = '1'
  }
}
