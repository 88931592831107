import {useContext, useEffect, useState} from 'react'
import {useMutation} from 'react-query'
import {useForm} from 'react-hook-form'
import {classValidatorResolver} from '@hookform/resolvers/class-validator'
import {useSnackbar} from 'notistack'
//services
import services, {ServerError} from 'services'
// dtos
import {CreateTransactionToSubscriptionRequestDto} from 'dtos'
//utils
import {removeEmptyValues} from 'utils/basic/object'
//hooks
import useToolbarRefetch from 'hooks/useToolbarRefetch'
import useText from 'hooks/useText'
//locals
import {Props} from '.'
import context from '../../context'
import {texts} from '../../texts'

interface IUseData {
  show: Props['show']
  handleClose: Props['handleClose']
}

const useData = ({show, handleClose}: IUseData) => {
  const {TX} = useText()
  const {enqueueSnackbar} = useSnackbar()
  const {setIsRefetchOn} = useToolbarRefetch()
  const {selectedTransaction} = useContext(context)

  const [photos, setPhotos] = useState<{photoId: string; isFull: boolean}[]>(
    Array(1).fill({photoId: '', isFull: false})
  )
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue: setFormValue,
    formState: {errors, isValid},
  } = useForm<CreateTransactionToSubscriptionRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(CreateTransactionToSubscriptionRequestDto),
  })

  /* -------------------------------------------------------------------------- */
  /*                             Services                                       */
  /* -------------------------------------------------------------------------- */

  const handleCreateSubscriptionPayment = async (
    values: CreateTransactionToSubscriptionRequestDto
  ) =>
    services.subscriptions.createSubscriptionPayment(
      selectedTransaction.getSubscriptionId(),
      removeEmptyValues({...values, amount: selectedTransaction.getAmount()})
    )

  /* -------------------------------------------------------------------------- */
  /*                             Mutations                                       */
  /* -------------------------------------------------------------------------- */

  const {isLoading, mutateAsync} = useMutation(handleCreateSubscriptionPayment, {
    onSuccess: () => {
      handleClose()
      setIsRefetchOn(true)
      reset()
      setPhotos([{isFull: false, photoId: ''}])
      enqueueSnackbar(TX(texts.upload_document_success), {
        variant: 'success',
      })
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* -------------------------------------------------------------------------- */
  /*                             Handlers                                       */
  /* -------------------------------------------------------------------------- */

  const onSubmit = async () => {
    setIsSubmitted(true)
  }

  const onSubmitFinish = async () => {
    setIsSubmitted(false)
    setFormValue('photoId', photos[0].photoId)
    await mutateAsync({...watch(), photoId: photos[0].photoId})
  }

  /* -------------------------------------------------------------------------- */
  /*                             LifeCycles                                       */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (!show) {
      setPhotos(Array(1).fill({photoId: '', isFull: false}))
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return {
    TX,
    photos,
    setPhotos,
    isSubmitted,
    setIsSubmitted,
    isLoading,
    onSubmitFinish,
    onSubmit: handleSubmit(onSubmit),
    control,
    isValid,
    errors,
  }
}

export default useData
