export enum WalletTransactionReasonEnum {
  // برداشت
  pay = 'pay',
  back_to_card = 'back_to_card',

  // واریز
  admin_gift = 'admin_gift',
  refund_delivery = 'refund_delivery',
  refund_subscription = 'refund_subscription',
}

export enum WalletTransactionTypeEnum {
  deposit = 'deposit',
  withdrawal = 'withdrawal',
}

export enum WalletTransactionCreatedByEnum {
  admin = 'admin',
  customer = 'customer',
}
