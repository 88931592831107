import ClientApi from './clientApi'
import config from './config'
import storage from './storage'
// models
import {IAdmin, IUser} from 'models'
// dtos
import {
  AdminChangeTemporaryPasswordRequestDto,
  AdminLoginRequestDto,
  AdminResetPasswordRequestDto,
  AdminSentVerificationCodeRequestDto,
  AdminVerifyVerificationCodeRequestDto,
  GetLoginResponseDto,
  GetSentVerificationCodeResponseDto,
} from 'dtos'

export interface ICurrentUserData {
  user: IUser
  admin: IAdmin
}

const {rootAddress} = config

const prefix = '/dashboard/auth'

const axios = new ClientApi()

export const sentVerificationCode = (
  body: AdminSentVerificationCodeRequestDto
): Promise<GetSentVerificationCodeResponseDto> =>
  axios.http.post(rootAddress + prefix + '/sent-verification-code', body)

export const verifyVerificationCode = (body: AdminVerifyVerificationCodeRequestDto) =>
  axios.http.post(rootAddress + prefix + '/verify-verification-code', body)

export const changeTemporaryPassword = (body: AdminChangeTemporaryPasswordRequestDto) =>
  axios.http.post(rootAddress + prefix + '/change-temporary-password', {
    ...body,
    token: storage.get(config.verification_token),
  })

export const resetPassword = (body: AdminResetPasswordRequestDto) =>
  axios.http.post(rootAddress + prefix + '/reset-password', {
    ...body,
    token: storage.get(config.verification_token),
  })

export const currentUser = (): Promise<ICurrentUserData> =>
  axios.http.get(rootAddress + prefix + '/current-user')

export const login = (body: AdminLoginRequestDto): Promise<GetLoginResponseDto> =>
  axios.http.post(rootAddress + prefix + '/login', body)
