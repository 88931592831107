import {IDto} from './Dto'
import {Meta} from './Meta'

export interface ITotal {
  averageEvaporationPercentage?: number
  averageEvaporationWeight?: number
  averageUnitPrice?: number
  averageWastePercentage?: number
  averageWasteWeight?: number
  totalOrderWeight?: number
  totalPrice?: number
  totalReceivedWeight?: number
}

export class Total {
  public props: ITotal = {}

  constructor(data?: ITotal) {
    if (data) {
      this.props = data
    }
  }

  getAverageEvaporationPercentage(): number {
    return this.props.averageEvaporationPercentage || 0
  }

  getAverageEvaporationWeight(): number {
    return this.props.averageEvaporationWeight || 0
  }

  getAverageUnitPrice(): number {
    return this.props.averageUnitPrice || 0
  }

  getAverageWastePercentage(): number {
    return this.props.averageWastePercentage || 0
  }

  getAverageWasteWeight(): number {
    return this.props.averageWasteWeight || 0
  }

  getTotalOrderWeight(): number {
    return this.props.totalOrderWeight || 0
  }

  getTotalPrice(): number {
    return this.props.totalPrice || 0
  }

  getTotalReceivedWeight(): number {
    return this.props.totalReceivedWeight || 0
  }
}

export interface IDtoWithTotal<T> extends IDto<T> {
  total?: ITotal
}

export class DtoWithTotal<T> {
  public props: IDtoWithTotal<T> = {}

  constructor(data?: IDtoWithTotal<T>) {
    if (data) {
      this.props = data
    }
  }

  getItems(): T[] {
    return (this.props && this.props.items) || []
  }

  getMeta(): Meta {
    return new Meta((this.props && this.props.meta) || {})
  }

  getTotal(): Total {
    return new Total((this.props && this.props.total) || {})
  }
}
