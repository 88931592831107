import {District, IDistrict} from './District'
import {IRegion, Region} from './Region'

export interface IRegionDistrict {
  id?: string
  createdAt?: string
  updatedAt?: string
  districtId?: string
  regionId?: string
  region?: IRegion
  district?: IDistrict
}

export class RegionDistrict {
  public props: IRegionDistrict = {}

  constructor(data?: IRegionDistrict) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDistrictId(): string {
    return this.props.districtId || ''
  }

  getRegionId(): string {
    return this.props.regionId || ''
  }

  getRegion(): Region {
    return new Region(this.props.region || {})
  }

  getDistrict(): District {
    return new District(this.props.district || {})
  }
}
