import {IsNotEmpty, IsNumber, IsString} from 'class-validator'

export class StartSortingProductBoxRequestDto {
  @IsNumber()
  @IsNotEmpty({message: 'نمونه الزامی است'})
  unitQuantity: number

  @IsString({message: 'جایگاه سفارش الزامی است'})
  @IsNotEmpty({message: 'جایگاه سفارش الزامی است'})
  orderLineId: string

  constructor() {
    this.unitQuantity = 0
    this.orderLineId = ''
  }
}
