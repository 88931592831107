import Select from 'react-select'
import {FC, useEffect, useState} from 'react'
import useText from 'hooks/useText'
import {texts} from './texts'
import {ActionMeta, InputActionMeta, OptionProps} from 'react-select/dist/declarations/src'

type AutoCompleteProps = {
  className?: string
  labelClassName?: string
  label?: string
  helperText?: string
  helperTextColor?: string
  error?: boolean
  errorText?: string
  options?: OptionProps[] | any
  onChange?: (option: OptionProps | null, actionMeta?: ActionMeta<OptionProps>) => void | any
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void
  loading?: boolean
  required?: boolean
  value?: string | string[]
  inputValue?: string
  disabled?: boolean
  placeholder?: string
  multiple?: boolean
  menuPlacement?: 'bottom' | 'top'
}

const AutoComplete: FC<AutoCompleteProps> = ({
  className = 'form-control-solid',
  labelClassName = '',
  label = '',
  helperText = '',
  helperTextColor = '#001A0B',
  error = false,
  errorText = '',
  placeholder = '',
  options = [],
  onChange = () => {},
  onInputChange = () => {},
  disabled = false,
  multiple = false,
  menuPlacement = 'bottom',
  ...props
}) => {
  const {TX} = useText()

  const [tempValue, setTempValue] = useState<string | string[]>()

  useEffect(() => {
    if (typeof props.value === 'object') {
      let values: string[] = []
      props.value.forEach((value: any) => {
        values.push(options.find((option: any) => option.value === value))
      })
      setTempValue(values)
    }
  }, [options, props.value])

  let values: string[] = []
  typeof props.value === 'object' &&
    props.value.forEach((value: any) => {
      values.push(options.find((option: any) => option.value === value))
    })

  return (
    <div className='fv-row w-100'>
      {label && (
        <label
          className={[
            'd-flex align-items-center fs-5 fw-semibold mb-2',
            labelClassName,
            props.required ? 'required' : '',
          ].join(' ')}
        >
          <span>{label}</span>
        </label>
      )}
      <Select
        options={options}
        isMulti={multiple}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            padding: 0,
            height: multiple ? 'auto' : 44,
          }),
        }}
        value={
          multiple
            ? tempValue
            : options.find((option: any) => option.value === props.value)
            ? options.find((option: any) => option.value === props.value)
            : null
        }
        getOptionLabel={(x: any) => x.label}
        getOptionValue={(x: any) => x.value}
        inputValue={props.inputValue}
        isLoading={props.loading}
        noOptionsMessage={() => TX(texts.no_options_text)}
        loadingMessage={() => TX(texts.loading)}
        placeholder={placeholder}
        isClearable={!multiple}
        className={['form-control form-control-lg p-0', className].join(' ')}
        onChange={onChange}
        isRtl
        onInputChange={onInputChange}
        isDisabled={disabled}
        menuPlacement={menuPlacement}
      />
      {helperText && (
        <div
          className='fv-plugins-message-container'
          style={{color: `${helperTextColor} !important`}}
        >
          <div className='fv-help-block'>
            <span role='alert'>{helperText}</span>
          </div>
        </div>
      )}
      {error && (
        <div className='fv-plugins-message-container' style={{color: '#001A0B'}}>
          <div className='fv-help-block'>
            <span role='alert'>{errorText}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export {AutoComplete}
