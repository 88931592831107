export interface ISubscriptionBoxProduct {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date | null
  packagingContentTargetId?: string
  quantity?: number
  price?: number
  weight?: number
  productId?: string
  productPriceId?: string
  productInstanceId?: string
  subscriptionBoxId?: string
}

export class SubscriptionBoxProduct {
  public props: ISubscriptionBoxProduct = {}

  constructor(data?: ISubscriptionBoxProduct) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date {
    return this.props.createdAt || new Date()
  }

  getUpdatedAt(): Date {
    return this.props.updatedAt || new Date()
  }

  getDeletedAt(): Date | null {
    return this.props.deletedAt ? new Date(this.props.deletedAt) : null
  }

  getPackagingContentTargetId(): string {
    return this.props.packagingContentTargetId || ''
  }

  getQuantity(): number {
    return this.props.quantity || 0
  }

  getPrice(): number {
    return this.props.price || 0
  }

  getWeight(): number {
    return this.props.weight || 0
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getProductPriceId(): string {
    return this.props.productPriceId || ''
  }

  getProductInstanceId(): string {
    return this.props.productInstanceId || ''
  }

  getSubscriptionBoxId(): string {
    return this.props.subscriptionBoxId || ''
  }
}
