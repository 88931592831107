import apiConfig from 'services/config'
import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'
// models
import {IDailyTarget} from 'models'
// dtos
import {GetDailyTargetBoxResponseDto} from 'dtos'

const axios = new ClientApi()

const {rootAddress} = apiConfig

const prefix = '/dashboard/daily-target'

export const getDailyTargets = (query: StringifiableRecord): Promise<IDailyTarget> =>
  axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix, query}, {arrayFormat: 'comma'})
  )

export const getDailyTargetBoxes = (
  dailyTargetId: string,
  productId: string
): Promise<GetDailyTargetBoxResponseDto[]> =>
  axios.http.get(rootAddress + prefix + '/' + dailyTargetId + '/products/' + productId + '/boxes')
