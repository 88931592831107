import {Box, IBox} from './Box'
import {IPackingTarget, PackingTarget} from './PackingTarget'
import {ISubscription, Subscription} from './Subscription'
import {ISubscriptionBoxProduct, SubscriptionBoxProduct} from './SubscriptionBoxProduct'

export interface ISubscriptionBox {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  quantity?: number
  totalPrice?: number
  totalWeight?: number
  subscriptionId?: string
  packagingTargetId?: string
  boxId?: string
  subscription?: ISubscription
  packagingTarget?: IPackingTarget
  box?: IBox
  subscriptionBoxProducts?: ISubscriptionBoxProduct[]
}

export class SubscriptionBox {
  public props: ISubscriptionBox = {}

  constructor(data?: ISubscriptionBox) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date {
    return this.props.createdAt || new Date()
  }

  getUpdatedAt(): Date {
    return this.props.updatedAt || new Date()
  }

  getQuantity(): number {
    return this.props.quantity || 0
  }

  getTotalPrice(): number {
    return this.props.totalPrice || 0
  }

  getTotalWeight(): number {
    return this.props.totalWeight || 0
  }

  getSubscriptionId(): string {
    return this.props.subscriptionId || ''
  }

  getPackagingTargetId(): string {
    return this.props.packagingTargetId || ''
  }

  getBoxId(): string {
    return this.props.boxId || ''
  }

  getSubscription(): Subscription {
    return new Subscription(this.props.subscription || {})
  }

  getPackagingTarget(): PackingTarget {
    return new PackingTarget(this.props.packagingTarget || {})
  }

  getBox(): Box {
    return new Box(this.props.box || {})
  }

  getSubscriptionBoxProducts(): SubscriptionBoxProduct[] {
    return (this.props.subscriptionBoxProducts || []).map(
      (subscriptionBoxProduct: ISubscriptionBoxProduct) =>
        new SubscriptionBoxProduct(subscriptionBoxProduct)
    )
  }
}
