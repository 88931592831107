import {ContextEnum, ScaleActionEnum, ScaleStateEnum, ScaleStatusEnum} from 'enums'
import {IOrderLine, OrderLine} from './OrderLine'

export interface IScale {
  id?: string
  scaleId?: string
  createdAt?: string
  updatedAt?: string
  name?: string
  context?: ContextEnum
  lastAction?: ScaleActionEnum
  lastHeartbeatDate?: string
  timestamp?: string
  state?: ScaleStateEnum
  status?: ScaleStatusEnum
  orderLineId?: string
  orderLine?: IOrderLine
}

export class Scale {
  public props: IScale = {}

  constructor(data?: IScale) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getScaleId(): string {
    return this.props.scaleId || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getName(): string {
    return this.props.name || ''
  }

  getContext(): ContextEnum | undefined {
    return this.props.context
  }

  getLastAction(): ScaleActionEnum | undefined {
    return this.props.lastAction
  }

  getLastHeartBeatDate(): Date | undefined {
    return this.props.lastHeartbeatDate ? new Date(this.props.lastHeartbeatDate) : undefined
  }

  getTimestamp(): Date | undefined {
    return this.props.timestamp ? new Date(this.props.timestamp) : undefined
  }

  getStatus(): ScaleStatusEnum {
    return this.props.status ? this.props.status : ScaleStatusEnum.free
  }

  getState(): ScaleStateEnum {
    return this.props.state ? this.props.state : ScaleStateEnum.offline
  }

  getOrderLineId(): string {
    return this.props.orderLineId || ''
  }

  getOrderLine(): OrderLine {
    return new OrderLine(this.props.orderLine || {})
  }

  //custom

  getDisplayStatus(): string {
    return displayScaleStatus(this.getStatus())
  }

  getDisplayState(): string {
    return displayScaleState(this.getState())
  }
}

export const displayScaleStatus = (scaleStatus: ScaleStatusEnum): string => {
  switch (scaleStatus) {
    case ScaleStatusEnum.engaged:
      return 'درگیر'
    case ScaleStatusEnum.free:
      return 'آزاد'
    default:
      return '-'
  }
}

export const displayScaleState = (scaleState: ScaleStateEnum): string => {
  switch (scaleState) {
    case ScaleStateEnum.online:
      return 'روشن'
    case ScaleStateEnum.offline:
      return 'خاموش'
    default:
      return '-'
  }
}
