import {Admin, IAdmin} from './Admin'
import {Box, IBox} from './Box'
import {IPackagingContentTarget, PackagingContentTarget} from './PackagingContentTarget'

export interface IPackingTarget {
  id?: string
  createdAt?: string
  updatedAt?: string
  startDate?: string
  endDate?: string
  adminId?: string
  status?: string
  isActive?: boolean
  boxId?: string
  box?: IBox
  admin?: IAdmin
  packagingContentTargets?: IPackagingContentTarget[]
  price?: number
  customPrice?: number
  weight?: number
}

export class PackingTarget {
  public props: IPackingTarget = {}

  constructor(data?: IPackingTarget) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getStartDate(): Date | undefined {
    return this.props.startDate ? new Date(this.props.startDate) : undefined
  }

  getEndDate(): Date | undefined {
    return this.props.endDate ? new Date(this.props.endDate) : undefined
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getStatus(): string {
    return this.props.status || ''
  }

  getIsActive(): boolean {
    return this.props.isActive || false
  }

  getBoxId(): string {
    return this.props.boxId || ''
  }

  getBox(): Box {
    return new Box(this.props.box || {})
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getPackagingContentTargets(): PackagingContentTarget[] {
    return (this.props.packagingContentTargets || []).map(
      (packagingContentTarget) => new PackagingContentTarget(packagingContentTarget)
    )
  }

  getPrice(): number {
    return this.props.price || 0
  }

  getCustomPrice(): number {
    return this.props.customPrice || 0
  }

  getWeight(): number {
    return this.props.weight || 0
  }
}
