import {Admin, IAdmin} from './Admin'
import {IScale, Scale} from './Scale'
import {Employee, IEmployee} from './Employee'
import {toPersianDigit} from 'utils/basic/digit'

export enum LineStatusEnum {
  activated = 'activated',
  deactivated = 'deactivated',
}

export enum LineHistoryTypeEnum {
  create = 'create',
  change_employee = 'change_employee',
  change_scale = 'change_scale',
  activate = 'activate',
  deactivate = 'deactivate',
  sorting_product = 'sorting_product',
}

export interface ILine {
  id?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string | null
  isActive?: boolean
  startDate?: string
  endDate?: string | null
  type?: LineHistoryTypeEnum
  productId?: string
  name?: string
  lineNumber?: number
  status?: LineStatusEnum
  adminId?: string
  admin?: IAdmin
  scaleId?: string
  scale?: IScale
  employeeId?: string
  employee?: IEmployee
  description?: string
}

export class Line {
  public props: ILine = {}

  constructor(data?: ILine) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDeletedAt(): Date | null {
    return this.props.deletedAt ? new Date(this.props.deletedAt) : null
  }

  getIsActive(): boolean {
    return this.props.isActive || false
  }

  getStartDate(): Date | undefined {
    return this.props.startDate ? new Date(this.props.startDate) : undefined
  }

  getEndDate(): Date | null {
    return this.props.endDate ? new Date(this.props.endDate) : null
  }

  getType(): LineHistoryTypeEnum {
    return this.props.type ? this.props.type : LineHistoryTypeEnum.create
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getName(): string {
    return this.props.name || ''
  }

  getLineNumber(): number {
    return this.props.lineNumber || 0
  }

  getStatus(): LineStatusEnum {
    return this.props.status ? this.props.status : LineStatusEnum.activated
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getScaleId(): string {
    return this.props.scaleId || ''
  }

  getScale(): Scale {
    return new Scale(this.props.scale || {})
  }

  getEmployeeId(): string {
    return this.props.employeeId || ''
  }

  getEmployee(): Employee {
    return new Employee(this.props.employee || {})
  }

  getDescription(): string {
    return this.props.description || ''
  }

  //custom

  getDisplayStatus(): string {
    return displayLineStatus(this.getStatus())
  }

  getDisplayTypeHistory(): string {
    return displayLineStatus(this.getStatus())
  }
}

export const displayLineStatus = (lineStatus: LineStatusEnum): string => {
  switch (lineStatus) {
    case LineStatusEnum.activated:
      return 'فعال'
    case LineStatusEnum.deactivated:
      return 'غیرفعال'
    default:
      return '-'
  }
}

export const displayLineHistoryType = (lineHistoryType: LineHistoryTypeEnum): string => {
  switch (lineHistoryType) {
    case LineHistoryTypeEnum.create:
      return 'ایجاد'
    case LineHistoryTypeEnum.change_employee:
      return 'تغییر کارمند (مسئول)'
    case LineHistoryTypeEnum.change_scale:
      return 'تغییر ترازو'
    case LineHistoryTypeEnum.activate:
      return 'فعال کردن'
    case LineHistoryTypeEnum.deactivate:
      return 'غیرفعال کردن'
    case LineHistoryTypeEnum.sorting_product:
      return 'مرتب سازی محصول'
    default:
      return '-'
  }
}

export const displayLineHistoryDescription = (
  lineHistoryType: LineHistoryTypeEnum,
  value?: string
): string => {
  switch (lineHistoryType) {
    case LineHistoryTypeEnum.create:
      return 'جایگاه ایجاد شد'
    case LineHistoryTypeEnum.change_employee:
      return `${value || '_'} مسئول جایگاه شد`
    case LineHistoryTypeEnum.change_scale:
      return `ترازو شماره ${toPersianDigit(value! || '')} انتخاب شد`
    case LineHistoryTypeEnum.activate:
      return 'جایگاه فعال شد'
    case LineHistoryTypeEnum.deactivate:
      return 'جایگاه غیرفعال شد'
    case LineHistoryTypeEnum.sorting_product:
      return 'محصول جایگاه مرتب سازی شد'
    default:
      return '-'
  }
}
