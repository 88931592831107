import * as Yup from 'yup'
import {texts} from './texts'
import useText from 'hooks/useText'

const useSchema = () => {
  const {TX} = useText()
  return Yup.object().shape({
    category: Yup.string().trim().required(TX(texts.faq_form_category_required)),
  })
}

export {useSchema}
