import {District, IDistrict} from './District'
import {ITag, Tag} from './Tag'

export interface ITagUse {
  id?: string
  createdAt?: string
  updatedAt?: string
  tagId?: string
  districtId?: string
  tag?: ITag
  district?: IDistrict
}

export class TagUse {
  public props: ITagUse = {}

  constructor(data?: ITagUse) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getTagId(): string {
    return this.props.tagId || ''
  }

  getDistrictId(): string {
    return this.props.districtId || ''
  }

  getTag(): Tag {
    return new Tag(this.props.tag || {})
  }

  getDistrict(): District {
    return new District(this.props.district || {})
  }
}
