import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  supplies_breadcrumbs: 'pages.protected.breadcrumbsItems.supplies',
  new_variety:
    'pages.protected.production-line,supplies.products.components.create-variety-modal.btn-title',
  variety_name_form:
    'pages.protected.production-line,supplies.products.components.create-variety-modal.form-variety-name',
  variery_name_required:
    'pages.protected.production-line,supplies.products.components.create-variety-modal.form-variety-name-required-error',
  variery_cancel:
    'pages.protected.production-line,supplies.products.components.create-variety-modal.cancel',
  create_new_variety:
    'pages.protected.production-line,supplies.products.components.create-variety-modal.submit',
  creation_success: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.success-creation',
  name_required: "pages.protected.production-line,supplies.products.components.create-variety-modal.name_required"
}
