import {useMutation, useQuery} from 'react-query'
import {useEffect, useLayoutEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import queryString from 'query-string'

//hooks
import useText from 'hooks/useText'
import useProtectedLayout from 'hooks/useProtectedLayout'
import useToolbarRefetch from 'hooks/useToolbarRefetch'

//services
import services, {ServerError} from 'services'

//constants
import {TRANSACTIONS_LIST_ROUTE} from 'constants/routes'

//utils
import {removeEmptyValues} from 'utils/basic/object'

//models
import {Dto, Meta} from 'models'
import {Transaction} from 'models/Transaction'

//locals
import {texts} from './texts'
import {useSnackbar} from 'notistack'
import {TransactionStatusEnum} from 'enums'

interface IQuery {
  page?: number
  limit?: number
  search?: string

  'filters[status]'?: TransactionStatusEnum
}

const useData = () => {
  const {setConfig} = useProtectedLayout()
  const {TX} = useText()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const {isRefetchOn, setIsRefetchOn} = useToolbarRefetch()
  const {enqueueSnackbar} = useSnackbar()

  const convertSearchParamsToQuery = (searchParams: URLSearchParams): IQuery => {
    return removeEmptyValues({
      page: Number(searchParams.get('page')) || 1,
      limit: Number(searchParams.get('limit')) || 10,
      search: searchParams.get('search') || '',

      'filters[status]':
        (searchParams.get('filters[status]') as TransactionStatusEnum) || undefined,
    })
  }

  const [query, setQuery] = useState<IQuery>(convertSearchParamsToQuery(searchParams))
  const [isUploadDocumentModalOpen, setIsUploadDocumentModalOpen] = useState(false)
  const [selectedTransaction, setSelectedTransaction] = useState(new Transaction())

  /* -------------------------------------------------------------------------- */
  /*                             Services                                       */
  /* -------------------------------------------------------------------------- */

  const handleGetTransactionsListService = async () =>
    await services.transactions.getTransactions({...removeEmptyValues(query)})

  const handleAcceptTransaction = async (transactionId: string) =>
    await services.transactions.acceptTransaction(transactionId)

  const handleRejectTransaction = async (transactionId: string) =>
    await services.transactions.rejectTransaction(transactionId)

  /* -------------------------------------------------------------------------- */
  /*                             Queries                                       */
  /* -------------------------------------------------------------------------- */

  const {isLoading, data, refetch} = useQuery(
    ['get-transactions-list', searchParams],
    handleGetTransactionsListService,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  const {isLoading: loadingAccept, mutate: accept} = useMutation(
    ['accept-transaction', searchParams],
    handleAcceptTransaction,
    {
      onSuccess() {
        enqueueSnackbar(TX(texts.accept_transaction_success), {variant: 'success'})
        refetch()
      },
      onError(error) {
        const {message} = error as ServerError
        enqueueSnackbar(message, {variant: 'error'})
      },
    }
  )

  const {isLoading: loadingReject, mutate: reject} = useMutation(
    ['reject-transaction', searchParams],
    handleRejectTransaction,
    {
      onSuccess() {
        enqueueSnackbar(TX(texts.rejcet_transaction_success), {variant: 'success'})
        refetch()
      },
      onError(error) {
        const {message} = error as ServerError
        enqueueSnackbar(message, {variant: 'error'})
      },
    }
  )

  /* -------------------------------------------------------------------------- */
  /*                             LifeCycles                                       */
  /* -------------------------------------------------------------------------- */

  useLayoutEffect(() => {
    setConfig({
      pageName: [TX(texts.transactions)],
      pageDescription: [],
      toolbarType: 'transactions',
      breadcrumbsItems: [
        {
          title: TX(texts.transactions),
          path: TRANSACTIONS_LIST_ROUTE,
          isActive: false,
          isSeparator: false,
        },
      ],
    })
  }, [setConfig, TX])

  useEffect(() => {
    const query = convertSearchParamsToQuery(searchParams)
    setQuery(query)
    refetch()
  }, [refetch, searchParams])

  useEffect(() => {
    if (isRefetchOn) {
      refetch()
      setIsRefetchOn(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetchOn])

  /* -------------------------------------------------------------------------- */
  /*                             Handlers                                       */
  /* -------------------------------------------------------------------------- */

  const handleChangeUrl = (newQuery: IQuery) => {
    navigate(
      queryString.stringifyUrl({
        url: TRANSACTIONS_LIST_ROUTE,
        query: {page: 1, limit: 10, ...newQuery},
      })
    )
  }

  const onSubmit = () => {
    const {page, limit, ...restQuery} = query
    handleChangeUrl(removeEmptyValues(restQuery))
  }

  const onClean = () => {
    setQuery({
      page: 1,
      limit: 10,
      'filters[status]': undefined,
    })
    handleChangeUrl(
      removeEmptyValues({
        page: 1,
        limit: 10,
        'filters[status]': undefined,
      })
    )
  }

  /* -------------------------------------------------------------------------- */
  /*                             Data                                       */
  /* -------------------------------------------------------------------------- */

  const dto = new Dto(data)
  const transactions = dto.getItems
    ? dto.getItems().map((transaction) => new Transaction(transaction))
    : []
  const meta = dto.getMeta ? dto.getMeta() : new Meta()

  return {
    TX,
    isLoading,
    transactions,
    meta,
    setQuery,
    handleChangeUrl,
    query,
    isUploadDocumentModalOpen,
    setIsUploadDocumentModalOpen,
    selectedTransaction,
    setSelectedTransaction,
    onClean,
    onSubmit,
    loading: loadingReject || loadingAccept,
    accept,
    reject,
  }
}

export default useData
