import {IsArray, IsNotEmpty, IsNumber, IsOptional, IsPositive, IsString} from 'class-validator'

export class CreateDeliveryChainRequestDto {
  @IsString({message: 'تاریخ الزامی است'})
  date: string

  @IsString({message: 'محله الزامی است'})
  regionId: string

  @IsString({message: 'برنامه ارسال الزامی است'})
  deliveryPlanId: string

  @IsString({message: 'پیک الزامی است'})
  courierId: string

  @IsNumber()
  @IsNotEmpty({message: 'ظرفیت الزامی است و باید مثبت باشد'})
  @IsPositive()
  capacity: number

  @IsArray()
  @IsOptional()
  deliveryIds?: string[]

  constructor() {
    this.date = ''
    this.regionId = ''
    this.deliveryPlanId = ''
    this.courierId = ''
    this.capacity = 0
    this.deliveryIds = []
  }
}
