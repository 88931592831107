import {Customer, ICustomer} from './Customer'
import {ITransaction, Transaction} from './Transaction'

export interface IWallet {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date | null
  balance?: number
  customerId?: string
  customer?: ICustomer
  transactions?: ITransaction[]
}

export class Wallet {
  public props: IWallet = {}

  constructor(data?: IWallet) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date {
    return this.props.createdAt || new Date()
  }

  getUpdatedAt(): Date {
    return this.props.updatedAt || new Date()
  }

  getDeletedAt(): Date | null {
    return this.props.deletedAt ? new Date(this.props.deletedAt) : null
  }

  getBalance(): number {
    return this.props.balance || 0
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getTransactions(): Transaction[] {
    return (this.props.transactions || []).map((transaction) => new Transaction(transaction))
  }
}
