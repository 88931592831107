import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  supplies_breadcrumbs: 'pages.protected.breadcrumbsItems.supplies',
  new_variety:
    'pages.protected.production-line,supplies.products.components.create-variety-modal.btn-title',
  variety_name_form:
    'pages.protected.production-line,supplies.products.components.create-variety-modal.form-variety-name',
  variery_name_required:
    'pages.protected.production-line,supplies.products.components.create-variety-modal.form-variety-name-required-error',
  variery_cancel:
    'pages.protected.production-line,supplies.products.components.create-variety-modal.cancel',
  create_new_variety:
    'pages.protected.production-line,supplies.products.components.create-variety-modal.submit',
  creation_success: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.success-creation',
  new_type: "pages.protected.production-line.supplies.product-types.components.new_type",
  name_type: "pages.protected.production-line.supplies.product-types.components.name_type",
  fa_name_type: "pages.protected.production-line.supplies.product-types.components.fa_name_type",
  active: "pages.protected.production-line.supplies.product-types.components.active",
  deactive: "pages.protected.production-line.supplies.product-types.components.deactive",
  submit: "pages.protected.production-line.supplies.product-types.components.submit",
  name_required: "pages.protected.production-line.supplies.product-types.components.name_required",
  fa_name_required: "pages.protected.production-line.supplies.product-types.components.fa_name_required",
  create_product_type_success: "pages.protected.production-line.supplies.product-types.components.create_product_type_success",
  update_product_type_success: "pages.protected.production-line.supplies.product-types.components.update_product_type_success"
}
