import {IProduct, Product, IQuoteProductVendor, QuoteProductVendor, IQuote, Quote} from 'models'
import {IQuoteProductBox, QuoteProductBox} from './QuoteProductBox'

export interface IQuoteProduct {
  id?: string
  createdAt?: string
  updatedAt?: string
  totalWeight?: number
  recordedTotalWeight?: number
  isUseOfStock?: boolean
  quoteId?: string
  productId?: string
  alternativeProductId?: string
  quote?: IQuote
  product?: IProduct
  alternativeProduct?: IProduct
  quoteProductVendors?: IQuoteProductVendor[]
  quoteProductBoxes?: IQuoteProductBox[]
}

export class QuoteProduct {
  public props: IQuoteProduct = {}

  constructor(data?: IQuoteProduct) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getTotalWeight(): number {
    return this.props.totalWeight || 0
  }

  getRecordedTotalWeight(): number {
    return this.props.recordedTotalWeight || 0
  }

  getIsUseOfStock(): boolean {
    return this.props.isUseOfStock || false
  }

  getQuoteId(): string {
    return this.props.quoteId || ''
  }

  getQuote(): Quote {
    return new Quote(this.props.quote || {})
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getAlternativeProductId(): string {
    return this.props.alternativeProductId || ''
  }

  getProduct(): Product {
    return new Product(this.props.product || {})
  }

  getAlternativeProduct(): Product {
    return new Product(this.props.alternativeProduct || {})
  }

  getQuoteProductVendors(): QuoteProductVendor[] {
    return (this.props.quoteProductVendors || []).map(
      (quoteProductVendor) => new QuoteProductVendor(quoteProductVendor)
    )
  }

  getQuoteProductBoxes(): QuoteProductBox[] {
    return (this.props.quoteProductBoxes || []).map(
      (quoteProductBox) => new QuoteProductBox(quoteProductBox)
    )
  }
}
