import {useEffect, useRef} from 'react'

export const useHorizontalScroll = () => {
  const elementRef = useRef<HTMLDivElement>(null)
  const elem = elementRef.current
  const onWheel = (e: WheelEvent) => {
    if (e.deltaY === 0) return
    e.preventDefault()
    elem?.scrollTo({
      left: elem.scrollLeft - e.deltaY,
      behavior: 'smooth',
    })
  }
  elem?.addEventListener('wheel', onWheel)

  useEffect(() => {
    if (elem) elem?.addEventListener('wheel', onWheel)
    return () => elem?.removeEventListener('wheel', onWheel)
  }, [elem])

  return elementRef
}
