import {IsBoolean, IsNotEmpty, IsString} from 'class-validator'

export class CreateProductTypeRequestDto {
  @IsString({message: 'نام نوع الزامی است'})
  @IsNotEmpty({message: 'نام نوع الزامی است'})
  name: string

  @IsString({message: 'نام نوع به فارسی الزامی است'})
  @IsNotEmpty({message: 'نام نوع به فارسی الزامی است'})
  faName: string

  @IsBoolean()
  isActive: boolean

  constructor() {
    this.name = ''
    this.faName = ''
    this.isActive = true
  }
}
