import {Admin, IAdmin} from './Admin'
import {Customer, ICustomer} from './Customer'
import {IPhotoUse, PhotoUse} from './PhotoUse'

export enum PhotoTypeEnum {
  WebP = 'WebP',
  SVG = 'SVG',
  PNG = 'PNG',
  JPEG = 'JPEG',
  GIF = 'GIF',
  AVIF = 'AVIF',
  APNG = 'APNG',
}
export interface IPhoto {
  id?: string
  createdAt?: string
  updatedAt?: string
  url?: string
  createdDate?: string
  type?: PhotoTypeEnum
  filename?: string
  destination?: string
  mimetype?: string
  path?: string
  size?: number
  customerId?: string
  adminId?: string
  admin?: IAdmin
  customer?: ICustomer
  photoUses?: IPhotoUse[]
}

export class Photo {
  public props: IPhoto = {}

  constructor(data?: IPhoto) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getUrl(): string {
    return this.props.url || ''
  }

  getCreatedDate(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getFilename(): string {
    return this.props.filename || ''
  }

  getType(): PhotoTypeEnum | undefined {
    return this.props.type
  }

  getDestination(): string {
    return this.props.destination || ''
  }

  getPath(): string {
    return this.props.path || ''
  }

  getMimetype(): string {
    return this.props.mimetype || ''
  }

  getSize(): number {
    return this.props.size || 0
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || [])
      .map((photoUse) => new PhotoUse(photoUse))
      .sort((a, b) => a.getOrderNumber() - b.getOrderNumber())
  }
}
