import {KTSVG} from 'utils/template/helpers'
import { texts } from './texts'
import useText from 'hooks/useText'

const ToolbarBuyer = () => {
  const {TX} = useText()
  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div
        className='btn btn-sm main-gradient-background fw-bold d-flex align-items-center px-4 gap-1'
        // onClick={() => setShowCreateSellerModal(true)}
      >
        <KTSVG
          path='/media/icons/duotune/art/add-circle-sarsabad.svg'
          className='svg-icon-1 ms-2 me-0 text-white'
        />
        <div className='text-white fw-bold'>{TX(texts.new_buy)}</div>
      </div>
      {/* <CreateSellerModal
        show={showCreateSellerModal}
        handleClose={() => setShowCreateSellerModal(false)}
      /> */}
    </div>
  )
}

export {ToolbarBuyer}
