import {FC} from 'react'

type SelectProps = {
  className?: string
  labelClassName?: string
  label?: string
  placeholder?: string
  options?: string[]
  formatOption?: any
  value?: string
  onChange?: (name: string) => void
  helperText?: string
  disabled?: boolean
}
const Select: FC<SelectProps> = ({
  className = '',
  value = '',
  labelClassName = '',
  label = '',
  placeholder = '',
  options = [],
  formatOption,
  onChange = (name) => null,
  helperText = '',
  disabled = false,
}) => {
  return (
    <div className='fv-row w-100'>
      <label
        className={['d-flex align-items-center fs-5 fw-semibold mb-2', labelClassName].join(' ')}
      >
        <span>{label}</span>
      </label>
      <select
        className={[
          'form-select fw-semibold w-100',
          className,
          disabled ? 'text-gray-500' : '',
        ].join(' ')}
        style={{backgroundColor: '#F5F8FA'}}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      >
        <option disabled selected={value === ''} value=''></option>
        {options?.map((option, index) => (
          <option selected={option === value} value={option} key={`${option}-${index}`}>
            {formatOption ? formatOption(option) : option}
          </option>
        ))}
      </select>
      <span className='fw-normal fs-8 text-gray-500'>{helperText}</span>
    </div>
  )
}

export {Select}
