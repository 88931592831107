import {Customer, ICustomer} from './Customer'
import {Address, IAddress} from './Address'
import {IRegion, Region} from './Region'
import {DeliveryPlan, IDeliveryPlan} from './DeliveryPlan'
import {Admin, IAdmin} from './Admin'
import {ITransaction, Transaction} from './Transaction'
import {Delivery, IDelivery} from './Delivery'
import {ISubscriptionBox, SubscriptionBox} from './SubscriptionBox'

export enum SubscriptionStatusEnum {
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
  expired = 'expired',
  delivered = 'delivered',
  replace = 'replace',
  refund = 'refund',
  cancel = 'cancel',
  failed = 'failed',
}

export interface ISubscription {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  subscriptionNumber?: string
  totalPrice?: number
  weeklyCount?: number
  status?: SubscriptionStatusEnum
  description?: string
  regionId?: string
  addressId?: string
  deliveryPlanId?: string
  customerId?: string
  adminId?: string
  customer?: ICustomer
  address?: IAddress
  region?: IRegion
  deliveryPlan?: IDeliveryPlan
  admin?: IAdmin
  transactions?: ITransaction[]
  deliveries?: IDelivery[]
  subscriptionBoxes?: ISubscriptionBox[]
}

export class Subscription {
  public props: ISubscription = {}

  constructor(data?: ISubscription) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date {
    return this.props.createdAt || new Date()
  }

  getUpdatedAt(): Date {
    return this.props.updatedAt || new Date()
  }

  getTotalPrice(): number {
    return this.props.totalPrice || 0
  }

  getWeeklyCount(): number {
    return this.props.weeklyCount || 0
  }

  getSubscriptionNumber(): string {
    return this.props.subscriptionNumber || ''
  }

  getStatus(): SubscriptionStatusEnum {
    return this.props.status as SubscriptionStatusEnum
  }

  getDescription(): string | null {
    return this.props.description || ''
  }

  getRegionId(): string {
    return this.props.regionId || ''
  }

  getAddressId(): string {
    return this.props.addressId || ''
  }

  getDeliveryPlanId(): string {
    return this.props.deliveryPlanId || ''
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getAddress(): Address {
    return new Address(this.props.address || {})
  }

  getRegion(): Region {
    return new Region(this.props.region || {})
  }

  getDeliveryPlan(): DeliveryPlan {
    return new DeliveryPlan(this.props.deliveryPlan || {})
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getTransactions(): Transaction[] {
    return (this.props.transactions || []).map(
      (transaction: ITransaction) => new Transaction(transaction)
    )
  }

  getDeliveries(): Delivery[] {
    return (this.props.deliveries || []).map((delivery: IDelivery) => new Delivery(delivery))
  }

  getSubscriptionBoxes(): SubscriptionBox[] {
    return (this.props.subscriptionBoxes || []).map(
      (subscriptionBox: ISubscriptionBox) => new SubscriptionBox(subscriptionBox)
    )
  }

  /* -------------------------------------------------------------------------- */
  /*                             Customs                                       */
  /* -------------------------------------------------------------------------- */
  getStatusDisplay(): string {
    switch (this.props.status) {
      case SubscriptionStatusEnum.pending:
        return 'در انتظار پرداخت'
      case SubscriptionStatusEnum.accepted:
        return 'تایید پرداخت'
      case SubscriptionStatusEnum.expired:
        return 'منقضی شده'
      case SubscriptionStatusEnum.rejected:
        return 'برگشت داده شده'
      case SubscriptionStatusEnum.delivered:
        return 'تحویل داده شده'
      case SubscriptionStatusEnum.replace:
        return 'جایگزین شده'
      case SubscriptionStatusEnum.refund:
        return 'برگشت داده شده'
      case SubscriptionStatusEnum.cancel:
        return 'لغو شده'
      case SubscriptionStatusEnum.failed:
        return 'تراکنش ناموفق'
      default:
        return ''
    }
  }
}

export const displaySubscriptionStatusEnum = (
  sortingProductStatus: SubscriptionStatusEnum
): string => {
  switch (sortingProductStatus) {
    case SubscriptionStatusEnum.pending:
      return 'در انتظار پرداخت'
    case SubscriptionStatusEnum.accepted:
      return 'تایید پرداخت'
    case SubscriptionStatusEnum.expired:
      return 'منقضی شده'
    case SubscriptionStatusEnum.rejected:
      return 'برگشت داده شده'
    case SubscriptionStatusEnum.delivered:
      return 'تحویل داده شده'
    case SubscriptionStatusEnum.replace:
      return 'جایگزین شده'
    case SubscriptionStatusEnum.refund:
      return 'برگشت داده شده'
    case SubscriptionStatusEnum.cancel:
      return 'لغو شده'
    case SubscriptionStatusEnum.failed:
      return 'تراکنش ناموفق'
    default:
      return ''
  }
}
