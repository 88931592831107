import {
  Admin,
  IAdmin,
  IQuoteProduct,
  IVariety,
  IVendor,
  QuoteProduct,
  Variety,
  Vendor,
} from 'models'

export interface IQuoteProductVendor {
  id?: string
  createdAt?: string
  updatedAt?: string
  weight?: number
  unitPrice?: number
  adminId?: string
  varietyId?: string
  vendorId?: string
  quoteProductId?: string
  quoteProduct?: IQuoteProduct
  vendor?: IVendor
  variety?: IVariety
  admin?: IAdmin
}

export class QuoteProductVendor {
  public props: IQuoteProductVendor = {}

  constructor(data?: IQuoteProductVendor) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getWeight(): number {
    return this.props.weight || 0
  }

  getUnitPrice(): number {
    return this.props.unitPrice || 0
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getVarietyId(): string {
    return this.props.varietyId || ''
  }

  getVendorId(): string {
    return this.props.vendorId || ''
  }

  getQuoteProductId(): string {
    return this.props.quoteProductId || ''
  }

  getQuoteProduct(): QuoteProduct {
    return new QuoteProduct(this.props.quoteProduct || {})
  }

  getVariety(): Variety {
    return new Variety(this.props.variety || {})
  }

  getVendor(): Vendor {
    return new Vendor(this.props.vendor || {})
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }
}
