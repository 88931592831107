import {IsNumber, IsString} from 'class-validator'

export class GetDailyTargetBoxResponseDto {
  @IsString()
  boxId: string

  @IsString()
  boxName: string

  @IsNumber()
  totalWeight: number

  @IsNumber()
  weight: number

  constructor() {
    this.boxId = ''
    this.boxName = ''
    this.totalWeight = 0
    this.weight = 0
  }
}
