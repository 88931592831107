import queryString, {StringifiableRecord} from 'query-string'
import apiConfig from 'services/config'
//models
import {IAddress, ICustomer, IDto, ISubscription, IWallet} from 'models'
// dtos
import {
  AddBalanceToCustomerWalletRequestDto,
  CreateCustomerAddressRequestDto,
  CreateCustomerRequestDto,
  UpdateCustomerAddressRequestDto,
  UpdateCustomerRequestDto,
} from 'dtos'
//locals
import ClientApi from './clientApi'

const {rootAddress} = apiConfig

const prefix = '/dashboard/customers'

const axios = new ClientApi()

export const getCustomers = async (query: StringifiableRecord): Promise<IDto<ICustomer>> =>
  await axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix, query}))

export const getCustomerAddresses = async (
  customerId: string,
  query: StringifiableRecord
): Promise<IAddress[]> =>
  await axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix + `/${customerId}/addresses`, query})
  )

export const createCustomerAddress = async (
  customerId: string,
  body: CreateCustomerAddressRequestDto
) => await axios.http.post(rootAddress + prefix + `/${customerId}/addresses`, body)

export const getCustomerAddress = async (
  customerId: string,
  addressId: string
): Promise<IAddress> =>
  await axios.http.get(rootAddress + prefix + `/${customerId}/addresses/${addressId}`)

export const updateCustomerAddress = async (
  customerId: string,
  addressId: string,
  body: UpdateCustomerAddressRequestDto
): Promise<IAddress> =>
  await axios.http.put(rootAddress + prefix + `/${customerId}/addresses/${addressId}`, body)

export const getCustomerDetails = async (customerId: string): Promise<ICustomer> =>
  await axios.http.get(rootAddress + prefix + `/${customerId}`)

export const updateCustomerActiveAddress = async (customerId: string, addressId: string) =>
  await axios.http.patch(rootAddress + prefix + `/${customerId}/addresses/${addressId}/activated`)

export const createCustomer = async (body: CreateCustomerRequestDto) =>
  await axios.http.post(rootAddress + prefix, body)

export const updateCustomer = async (customerId: string, body: UpdateCustomerRequestDto) =>
  await axios.http.put(rootAddress + prefix + `/${customerId}`, body)

export const deleteCustomer = async (customerId: string) =>
  await axios.http.delete(rootAddress + prefix + `/${customerId}`)

export const getCustomerSubscriptions = async (
  customerId: string,
  query: StringifiableRecord
): Promise<IDto<ISubscription>> =>
  await axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix + `/${customerId}/subscriptions`, query})
  )

export const getCustomerSubscriptionDetails = async (
  customerId: string,
  subscriptionId: string,
  query?: StringifiableRecord
): Promise<ISubscription> =>
  await axios.http.get(
    queryString.stringifyUrl({
      url: rootAddress + prefix + `/${customerId}/subscriptions/${subscriptionId}`,
      query,
    })
  )

export const getCustomerWallet = async (customerId: string): Promise<IWallet> =>
  await axios.http.get(rootAddress + prefix + `/${customerId}/wallet`)

export const addCustomerWallet = async (
  customerId: string,
  body: AddBalanceToCustomerWalletRequestDto
) => await axios.http.patch(rootAddress + prefix + `/${customerId}/wallet/add`, body)

export const zeroCustomerWallet = async (customerId: string) =>
  await axios.http.patch(rootAddress + prefix + `/${customerId}/wallet/zero`)
