import {FormikProvider} from 'formik'

// components
import {Modal} from 'components/elements/Modal'
import {Textfield} from 'components/elements/Textfield'
import {Button} from 'components/elements/Button'

// local
import {useData} from './useData'
import {texts} from './texts'
import {Textarea} from 'components/elements/Textarea'

type Props = {
  show: boolean
  handleClose: () => void
}

const CreateFAQModal = ({show, handleClose}: Props) => {
  const {form, loading, TX} = useData(show, handleClose)

  return (
    <Modal
      id='create-product-modal'
      show={show}
      handleClose={handleClose}
      title={TX(texts.faq_modal_btn)}
      children={
        <form onSubmit={form.handleSubmit} encType='multipart/form-data'>
          <FormikProvider value={form}>
            <div className='stepper stepper-pills stepper-column' id='kt_modal_create_app_stepper'>
              <div className='flex-row-fluid py-lg-5 px-lg-15'>
                <div className='d-flex flex-row justify-content-start align-items-start gap-5'>
                  <Textfield
                    {...form.getFieldProps('question')}
                    label={`${TX(texts.faq_form_question)} *`}
                    type='text'
                    name='question'
                    autoComplete='off'
                    error={!!form.errors.question}
                    touched={form.touched.question}
                    errorText={form.errors.question}
                    onChange={form.handleChange}
                    disabled={loading}
                  />
                </div>
                <div className='d-flex flex-row justify-content-start align-items-start gap-5 mt-5'>
                  <Textarea
                    {...form.getFieldProps('answer')}
                    name='answer'
                    label={`${TX(texts.faq_form_answer)} *`}
                    className='form-control form-control-lg form-control-solid h-25'
                    value={form.values.answer}
                    autoComplete='off'
                    onChange={(e) => form.setFieldValue('answer', e.target.value, true)}
                    disabled={loading}
                  />
                </div>
                <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
                  <Button
                    text={TX(texts.create_faq_cancel)}
                    className='main-red-background btn btn-lg background-inherit justify-content-center'
                    onClick={handleClose}
                    type='button'
                  />

                  <Button
                    text={TX(texts.create_faq_submit)}
                    isLoading={loading}
                    className='btn btn-lg btn-primary main-gradient-background'
                    type='submit'
                    disabled={form.isSubmitting || !(form.isValid && form.dirty)}
                  />
                </div>
              </div>
            </div>
          </FormikProvider>
        </form>
      }
    />
  )
}

export {CreateFAQModal}
