import {Expose, Type} from 'class-transformer'
import {
  IsBoolean,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  ValidateIf,
  ValidateNested,
} from 'class-validator'

class VendorQuoteForProductElement {
  @IsString({message: 'محصول الزامی است'})
  productId: string

  @IsString()
  @IsOptional()
  @ValidateIf((o) => o.varietyId)
  varietyId?: string

  @IsNumber()
  @IsPositive({message: 'قیمت باید یک عدد مثبت باشد'})
  unitPrice: number

  @IsNumber()
  @IsPositive({message: 'وزن باید یک عدد مثبت باشد'})
  weight: number

  @IsString({message: 'تامین کننده الزامی است'})
  vendorId: string

  constructor() {
    this.productId = ''
    this.varietyId = ''
    this.unitPrice = 0
    this.weight = 0
    this.vendorId = ''
  }
}
export class CreateVendorQuoteForProductBasedOnDailyTargetRequestDto {
  @IsString()
  @IsOptional()
  @ValidateIf((p) => p.quoteId)
  quoteId?: string

  @IsString({message: 'محصول روز الزامی است'})
  dailyTargetId: string

  @IsBoolean({message: 'از موجودی انبار استفاده شود'})
  isUseOfStock: boolean

  @ValidateNested({each: true})
  @Expose()
  @Type(() => VendorQuoteForProductElement)
  product: VendorQuoteForProductElement

  constructor() {
    this.quoteId = ''
    this.dailyTargetId = ''
    this.isUseOfStock = false
    this.product = new VendorQuoteForProductElement()
  }
}
