export const removeEmptyValues = <T>(target: T): T => {
  for (const key in target) {
    if (!target[key]) {
      delete target[key]
    }
  }
  return target
}

export const removeEmptyValuesStringArray = (array: string[]): string[] => {
  let filtered = array.filter((el) => {
    return el ? el : null
  })

  return filtered
}

export const removeFieldsStartWith = <T>(target: T, fieldName: string): T => {
  for (const key in target) {
    if (key.startsWith(fieldName)) {
      delete target[key]
    }
  }
  return target
}

export const countNotEmptyFields = <T>(target: T, fields: (keyof T)[]): number => {
  let count = 0
  fields.forEach((field) => {
    if (Array.isArray(target[field])) {
      let arrayFiled: any[] = target[field] as any
      if (arrayFiled.length > 0) {
        count++
      }
    } else if (!!target[field]) {
      count++
    }
  })

  return count
}
