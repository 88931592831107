import {IMeta, Meta} from './Meta'
export interface IDto<T> {
  items?: T[]
  meta?: IMeta
}

export class Dto<T> {
  public props: IDto<T> = {}

  constructor(data?: IDto<T>) {
    if (data) {
      this.props = data
    }
  }

  getItems(): T[] {
    return (this.props && this.props.items) || []
  }

  getMeta(): Meta {
    return new Meta((this.props && this.props.meta) || {})
  }
}
