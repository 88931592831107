import {
  Address,
  Delivery,
  DeliveryChainCustomer,
  IAddress,
  IDelivery,
  IDeliveryChainCustomer,
  IPhotoUse,
  ISubscription,
  IUser,
  IWalletTransaction,
  Photo,
  PhotoUse,
  Subscription,
  User,
  WalletTransaction,
} from 'models'
import {ITicket, Ticket} from './Ticket'
import {CustomerStatusEnum} from 'enums'

export interface ICustomer {
  id?: string
  createdAt?: string
  updatedAt?: string
  firstName?: string
  lastName?: string
  walletBalance?: number
  status?: CustomerStatusEnum
  passwordHash?: string
  email?: string
  mobileNumber?: string
  secondMobileNumber?: string
  birthDate?: Date
  description?: string
  user?: IUser
  phone?: string
  addresses?: IAddress[]
  photoUses?: IPhotoUse[]
  deliveries?: IDelivery[]
  tickets?: ITicket[]
  walletTransactions?: IWalletTransaction[]
  subscriptions?: ISubscription[]
  deliveryChainCustomers?: IDeliveryChainCustomer[]
}

export class Customer {
  public props: ICustomer = {}

  constructor(data?: ICustomer) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date {
    return this.props.createdAt ? new Date(this.props.createdAt) : new Date()
  }

  getBirthDate(): Date | null {
    return this.props.birthDate ? new Date(this.props.birthDate) : null
  }

  getEmail(): string {
    return this.props.email || ''
  }

  getDescription(): string {
    return this.props.description || ''
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getStatus(): CustomerStatusEnum {
    return this.props.status || CustomerStatusEnum.deactivated
  }

  getFirstName(): string {
    return this.props.firstName || ''
  }

  getMobileNumber(): string {
    return this.props.mobileNumber || ''
  }

  getSecondMobileNumber(): string {
    return this.props.secondMobileNumber || ''
  }

  getLastName(): string {
    return this.props.lastName || ''
  }

  getUser(): User {
    return new User(this.props.user || {})
  }

  getAddresses(): Address[] {
    return this.props.addresses?.map((item) => new Address(item)) || []
  }

  getPhone = (): string => {
    return this.props?.phone || this.getUser().getMobileNumber() || ''
  }

  getFullName = (): string => {
    return this.getFirstName() ? `${this.getFirstName()} ${this.getLastName()}` : ''
  }

  getDisplayStatus(): string {
    return displayCustomerStatusEnum(this.getStatus())
  }

  getActiveAddress(): Address {
    return (
      this.getAddresses().filter((item) => item.getIsActive() && !item.getIsDeleted())?.[0] ||
      new Address()
    )
  }

  getWalletBalance(): number {
    return this.props.walletBalance || 0
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || [])
      .map((photoUse) => new PhotoUse(photoUse))
      .sort((a, b) => a.getOrderNumber() - b.getOrderNumber())
  }

  getMainImage(): Photo {
    return this.getPhotoUses().length > 0 ? this.getPhotoUses()[0].getPhoto() : new Photo()
  }

  getDeliveries(): Delivery[] {
    return this.props.deliveries?.map((item) => new Delivery(item)) || []
  }

  getTickets(): Ticket[] {
    return this.props.tickets?.map((item) => new Ticket(item)) || []
  }

  getWalletTransactions(): WalletTransaction[] {
    return this.props.walletTransactions?.map((item) => new WalletTransaction(item)) || []
  }

  getSubscriptions(): Subscription[] {
    return this.props.subscriptions?.map((item) => new Subscription(item)) || []
  }

  getDeliveryChainCustomers(): DeliveryChainCustomer[] {
    return this.props.deliveryChainCustomers?.map((item) => new DeliveryChainCustomer(item)) || []
  }
}

export const displayCustomerStatusEnum = (adminStatusEnum: CustomerStatusEnum): string => {
  switch (adminStatusEnum) {
    case CustomerStatusEnum.activated:
      return 'فعال'
    case CustomerStatusEnum.deactivated:
      return 'غیر فعال'
    case CustomerStatusEnum.on_waiting_list:
      return 'لیست انتظار'
    case CustomerStatusEnum.out_of_range:
      return 'خارج از محدوده'
    case CustomerStatusEnum.full_capacity:
      return 'عدم ظرفیت کافی'
    default:
      return '-'
  }
}
