import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {classValidatorResolver} from '@hookform/resolvers/class-validator'
import {useMutation} from 'react-query'
import {useSnackbar} from 'notistack'
// services
import services, {ServerError} from 'services'
// enums
import {ProductStatusEnum, UnitTypeEnum} from 'enums'
// utils
import {removeEmptyValues} from 'utils/basic/object'
// dtos
import {CreateProductRequestDto} from 'dtos'
// hooks
import useText from 'hooks/useText'
import useToolbarRefetch from 'hooks/useToolbarRefetch'
import useParameters from 'hooks/useParameters'
// locals
import {texts} from './texts'

const useData = (show: boolean, handleClose: () => void) => {
  const {TX} = useText()
  const {enqueueSnackbar} = useSnackbar()
  const {setIsRefetchOn} = useToolbarRefetch()
  const [photos, setPhotos] = useState<{photoId: string; isFull: boolean}[]>(
    Array(1).fill({photoId: '', isFull: false})
  )
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const {productTypes, productUnits} = useParameters()

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue: setFormValue,
    formState: {isSubmitting, errors, isValid},
  } = useForm<CreateProductRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(CreateProductRequestDto),
    defaultValues: {
      name: '',
      productTypeId: '',
      productUnitId: '',
      weightInKilogram: 0,
      status: ProductStatusEnum.activated,
      description: '',
      percentagePrice: 0,
      customPrice: 0,
      useCustomPrice: false,
    },
  })

  /* ------------------------------------------------------------ */
  /*                           Services                           */
  /* ------------------------------------------------------------ */

  const handleCreateNewProduct = async (body: CreateProductRequestDto) =>
    await services.products.createNewProduct(body)

  /* ------------------------------------------------------------ */
  /*                         Mutations                            */
  /* ------------------------------------------------------------ */

  const {isLoading, mutate} = useMutation(handleCreateNewProduct, {
    onSuccess: async (data) => {
      handleClose()
      setIsRefetchOn(true)
      enqueueSnackbar(TX(texts.creation_success), {
        variant: 'success',
      })
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* ------------------------------------------------------------ */
  /*                          Handlers                            */
  /* ------------------------------------------------------------ */

  const onSubmit = async () => {
    setIsSubmitted(true)
  }

  const onSubmitFinish = async () => {
    setIsSubmitted(false)
    let tempWeightInKilogram = 0
    if (
      productUnits
        .filter((unit) => unit.getName() === UnitTypeEnum.kilogram)
        .some((unit) => unit.getId() === watch().productUnitId)
    ) {
      tempWeightInKilogram = 1
    } else if (
      productUnits
        .filter((unit) => unit.getName() === UnitTypeEnum.gram)
        .some((unit) => unit.getId() === watch().productUnitId)
    ) {
      tempWeightInKilogram = 0.001
    } else {
      tempWeightInKilogram = watch().weightInKilogram!
    }
    await mutate({
      name: watch().name,
      productTypeId: watch().productTypeId,
      productUnitId: watch().productUnitId,
      weightInKilogram: tempWeightInKilogram,
      status: watch().status,
      description: watch().description,
      percentagePrice: watch().percentagePrice,
      photoId: photos[0].photoId,
      customPrice: watch().useCustomPrice ? watch().customPrice : undefined,
      useCustomPrice: watch().useCustomPrice,
    })
  }

  /* ------------------------------------------------------------ */
  /*                         LifeCycles                           */
  /* ------------------------------------------------------------ */

  useEffect(() => {
    if (!show) {
      setPhotos(Array(1).fill({photoId: '', isFull: false}))
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return {
    onSubmit: handleSubmit(onSubmit),
    control,
    watch,
    setFormValue,
    isSubmitting,
    errors,
    isValid,
    loading: isLoading,
    photos,
    setPhotos,
    TX,
    isSubmitted,
    setIsSubmitted,
    onSubmitFinish,
    productTypes,
    productUnits,
  }
}

export {useData}
