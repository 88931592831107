import {VendorStatusEnum} from 'enums'
import {IAddress, Address} from './Address'
import {IAdmin, Admin} from './Admin'
import {IPhotoUse, PhotoUse} from './PhotoUse'

export interface IVendor {
  id?: string
  createdAt?: string
  updatedAt?: string
  name?: string
  firstName?: string
  lastName?: string
  status?: VendorStatusEnum
  mobileNumber?: string
  purchaseCount?: number
  adminId?: string
  admin?: IAdmin
  addresses?: IAddress[]
  photoUses?: IPhotoUse[]
}

export class Vendor {
  public props: IVendor = {}

  constructor(data?: IVendor) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getName(): string {
    return this.props.name || ''
  }

  getFirstName(): string {
    return this.props.firstName || ''
  }

  getLastName(): string {
    return this.props.lastName || ''
  }

  getFullName = (): string => {
    return this.getFirstName() ? `${this.getFirstName()} ${this.getLastName()}` : ''
  }

  getMobileNumber(): string {
    return this.props.mobileNumber || ''
  }

  getStatus(): VendorStatusEnum {
    return this.props.status || VendorStatusEnum.inactive
  }

  getPurchaseCount(): number {
    return this.props.purchaseCount || 0
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getAddresses(): Address[] {
    return this.props.addresses?.map((address) => new Address(address)) || []
  }

  getPhotoUses(): PhotoUse[] {
    return this.props.photoUses?.map((photoUse) => new PhotoUse(photoUse)) || []
  }

  // custom
  getFirstAddress(): Address {
    return new Address((this.props.addresses && this.props.addresses[0]) || {})
  }

  getDisplayStatus(): string {
    return displayVendorStatusEnum(this.getStatus())
  }
}

export const displayVendorStatusEnum = (vendorStatusEnum?: VendorStatusEnum): string => {
  switch (vendorStatusEnum) {
    case VendorStatusEnum.active:
      return 'فعال'
    case VendorStatusEnum.inactive:
      return 'غیرفعال'
    default:
      return ''
  }
}
