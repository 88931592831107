import {ContextEnum, WeighingSequenceStatusEnum} from 'enums'
import {Admin, IAdmin} from './Admin'
import {IOrderLine, OrderLine} from './OrderLine'
import {IScale, Scale} from './Scale'
import {IWeighing, Weighing} from './Weighing'

export interface IWeighingSequence {
  id?: string
  createdAt?: string
  updatedAt?: string
  context?: ContextEnum
  status?: WeighingSequenceStatusEnum
  emptyBoxWeight?: number
  adminId?: string
  orderLineId?: string
  scaleId?: string
  admin?: IAdmin
  orderLine?: IOrderLine
  scale?: IScale
  weighings?: IWeighing[]
}

export class WeighingSequence {
  public props: IWeighingSequence = {}

  constructor(data?: IWeighingSequence) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getContext(): ContextEnum {
    return this.props.context ? this.props.context : ContextEnum.sorting
  }

  getStatus(): WeighingSequenceStatusEnum {
    return this.props.status ? this.props.status : WeighingSequenceStatusEnum.pending
  }

  getEmptyBoxWeight(): number {
    return this.props.emptyBoxWeight || 0
  }

  getOrderLineId(): string {
    return this.props.orderLineId || ''
  }

  getScaleId(): string {
    return this.props.scaleId || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getOrderLine(): OrderLine {
    return new OrderLine(this.props.orderLine || {})
  }

  getScale(): Scale {
    return new Scale(this.props.scale || {})
  }

  getWeighings(): Weighing[] {
    return (this.props.weighings || []).map((weighing: IWeighing) => new Weighing(weighing))
  }

  //custom

  getDisplayContext(): string {
    return displayContext(this.getContext())
  }
}

export const displayContext = (context: ContextEnum): string => {
  switch (context) {
    case ContextEnum.inventory_arrived:
      return 'تحویل گرفته شده'
    case ContextEnum.inventory_remained:
      return 'ته انباری'
    case ContextEnum.inventory_waste:
      return 'دور ریز'
    case ContextEnum.sorting:
      return 'مرتب شده'
    case ContextEnum.packing:
      return 'بسته‌بندی شده'
    default:
      return '-'
  }
}
