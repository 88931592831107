import {IsBoolean, IsOptional, IsString} from 'class-validator'

export class UpdateInformationConflictRequestDto {
  @IsString()
  @IsOptional()
  description?: string

  @IsBoolean()
  @IsOptional()
  resolved?: boolean
}
