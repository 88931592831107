import {Admin, IAdmin} from './Admin'
import {ILine, Line} from './Line'
import {IProduct, Product} from './Product'
import {ISortingProductBox, SortingProductBox} from './SortingProductBox'

export enum SortingProductStatusEnum {
  start = 'start',
  stop = 'stop',
  pause = 'pause',
  resume = 'resume',
  disable = 'disable',
  done = 'done',
  finish = 'finish',
}

export enum SortingProductStatusQueryEnum {
  start = 'start',
  pause = 'pause',
  finish = 'finish',
}

export interface ISortingProduct {
  id?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string | null
  date?: string
  status?: SortingProductStatusEnum
  sortingLineId?: string
  productId?: string
  product?: IProduct
  adminId?: string
  admin?: IAdmin
  sortingLine?: ILine
  sortingProductBoxes?: ISortingProductBox[]
  totalQuantity?: number
  unitQuantity?: number
  expectedQuantity?: number
  remainedSorting?: number
  sortedQuantity?: number
  startTime?: string
  endTime?: string
}

export class SortingProduct {
  public props: ISortingProduct = {}

  constructor(data?: ISortingProduct) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDeletedAt(): Date | null {
    return this.props.deletedAt ? new Date(this.props.deletedAt) : null
  }

  getStartTime(): Date | undefined {
    return this.props.startTime ? new Date(this.props.startTime) : undefined
  }

  getEndTime(): Date | undefined {
    return this.props.endTime ? new Date(this.props.endTime) : undefined
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getDate(): Date | undefined {
    return this.props.date ? new Date(this.props.date) : undefined
  }

  getStatus(): SortingProductStatusEnum | string {
    return this.props.status ? this.props.status : ''
  }

  getSortingLineId(): string {
    return this.props.sortingLineId || ''
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getProduct(): Product {
    return new Product(this.props.product || {})
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getSortingLine(): Line {
    return new Line(this.props.sortingLine || {})
  }

  getSortingProductBoxes(): SortingProductBox[] {
    return (this.props.sortingProductBoxes || []).map(
      (sortingProductBox: ISortingProductBox) => new SortingProductBox(sortingProductBox)
    )
  }

  getTotalQuantity(): number {
    return this.props.totalQuantity || 0
  }

  getUnitQuantity(): number {
    return this.props.unitQuantity || 0
  }

  getExpectedQuantity(): number {
    return this.props.expectedQuantity || 0
  }

  getRemainedSorting(): number {
    return this.props.remainedSorting || 0
  }

  getSortedQuantity(): number {
    return this.props.sortedQuantity || 0
  }

  // custom

  getDisplaySortingProductStatus(): string {
    return displaySortingProductStatus(this.getStatus())
  }
}

export const displaySortingProductStatus = (
  sortingProductStatus: SortingProductStatusEnum | string
): string => {
  switch (sortingProductStatus) {
    case SortingProductStatusEnum.start:
      return 'آغاز'
    case SortingProductStatusEnum.pause:
      return 'توقف'
    case SortingProductStatusEnum.finish:
      return 'پایان'
    default:
      return '-'
  }
}
