import {MeasurementUnit, OrderLineStateEnum} from 'enums'
import {IOrder, Order} from './Order'
import {IProduct, Product} from './Product'
import {IQuoteProductVendor, QuoteProductVendor} from './QuoteProductVendor'
import {IScale, Scale} from './Scale'
import {IVariety, Variety} from './Variety'
import {IVendor, Vendor} from './Vendor'
import {IWeighingSequence, WeighingSequence} from './WeighingSequence'

export interface IOrderLine {
  id?: string
  createdAt?: string
  updatedAt?: string
  unitPrice?: number
  totalPrice?: number
  orderWeight?: number
  receivedWeight?: number
  arrivedWeight?: number
  wasteWeight?: number
  evaporationWeight?: number
  evaporationPercentage?: number
  wasteWeightPercentage?: number
  remainderWeight?: number
  orderId?: string
  quoteProductVendorId?: string
  scaleId?: string
  productId?: string
  varietyId?: string
  vendorId?: string
  variety?: IVariety
  scale?: IScale
  vendor?: IVendor
  product?: IProduct
  quoteProductVendor?: IQuoteProductVendor
  order?: IOrder
  weighingSequences?: IWeighingSequence[]
  totalEmptyBoxWeight?: number
  state?: OrderLineStateEnum
  sortedWeight?: number
}

export class OrderLine {
  public props: IOrderLine = {}

  constructor(data?: IOrderLine) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getUnitPrice(): number {
    return this.props.unitPrice || 0
  }

  getTotalPrice(): number {
    return this.props.totalPrice || 0
  }

  getOrderWeight(): number {
    return this.props.orderWeight || 0
  }

  getReceivedWeight(): number {
    return this.props.receivedWeight || 0
  }

  getArrivedWeight(): number {
    return this.props.arrivedWeight || 0
  }

  getWasteWeight(): number {
    return this.props.wasteWeight || 0
  }

  getWasteWeightPercentage(): number {
    return this.props.wasteWeightPercentage || 0
  }

  getEvaporationWeight(): number {
    return this.props.evaporationWeight || 0
  }

  getEvaporationPercentage(): number {
    return this.props.evaporationPercentage || 0
  }

  getTotalEmptyBoxWeight(): number {
    return this.props.totalEmptyBoxWeight || 0
  }

  getRemainderWeight(): number {
    return this.props.remainderWeight || 0
  }

  getSortedWeight(): number {
    return this.props.sortedWeight || 0
  }

  getOrderId(): string {
    return this.props.orderId || ''
  }

  getQuoteProductVendorId(): string {
    return this.props.quoteProductVendorId || ''
  }

  getState(): OrderLineStateEnum | undefined {
    return this.props.state
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getVarietyId(): string {
    return this.props.varietyId || ''
  }

  getVendorId(): string {
    return this.props.vendorId || ''
  }

  getVariety(): Variety {
    return new Variety(this.props.variety || {})
  }

  getVendor(): Vendor {
    return new Vendor(this.props.vendor || {})
  }

  getProduct(): Product {
    return new Product(this.props.product || {})
  }

  getQuoteProductVendor(): QuoteProductVendor {
    return new QuoteProductVendor(this.props.quoteProductVendor || {})
  }

  getOrder(): Order {
    return new Order(this.props.order || {})
  }

  getWeighingSequences(): WeighingSequence[] {
    return (this.props.weighingSequences || []).map(
      (weighingSequence: IWeighingSequence) => new WeighingSequence(weighingSequence)
    )
  }

  getScaleId(): string {
    return this.props.scaleId || ''
  }

  getScale(): Scale {
    return new Scale(this.props.scale || {})
  }

  // custom
  getDisplayState(): string {
    return displayOrderLineStateEnum(this.getState())
  }
}

export const displayMeasurementUnit = (measurementUnit?: MeasurementUnit): string => {
  switch (measurementUnit) {
    case MeasurementUnit.KILOGRAM:
      return 'کیلوگرم'
    case MeasurementUnit.PERCENT:
      return 'درصد'
    default:
      return '-'
  }
}

export const displayOrderLineStateEnum = (orderLineStateEnum?: OrderLineStateEnum): string => {
  switch (orderLineStateEnum) {
    case OrderLineStateEnum.in_packaging:
      return 'در بسته‌بندی'
    case OrderLineStateEnum.in_sorting:
      return 'در سواسازی'
    case OrderLineStateEnum.in_weighing:
      return 'در وزن‌کشی'
    case OrderLineStateEnum.packaged:
      return 'بسته‌بندی شده'
    case OrderLineStateEnum.purchased:
      return 'خریداری شده'
    case OrderLineStateEnum.sorted:
      return 'سواسازی شده'
    case OrderLineStateEnum.weighed:
      return 'وزن‌کشی شده'
    default:
      return '-'
  }
}
