//components
import {Checkbox} from 'components/elements/Checkbox'
import {Typography} from 'components/elements/Typography'

//models
import {WeeklyPlan} from 'models'
//locals
import useData from './useData'
import {WeeklyPlanDayOfWeekEnum, WeeklyPlanShiftEnum} from 'enums'

export interface IValue {
  day: WeeklyPlanDayOfWeekEnum
  shift: WeeklyPlanShiftEnum
  id: string
}
export interface IDayPicker {
  options: IValue[]
  value: IValue[]
  errorText?: string
  onChange?: (data: IValue[]) => void
}
const DayPicker = ({onChange, errorText, options, value}: IDayPicker) => {
  const data = useData({onChange, value})

  const renderWeekday = (item: IValue) => (
    <div key={item.id} className='border border-gray-300 rounded p-1 '>
      <Checkbox
        label={`${WeeklyPlan.getDayOfWeekDisplay(item.day)} - ${WeeklyPlan.getShiftDisplay(
          item.shift
        )}`}
        value={data.selectedOptions.map((option) => option.id).includes(item.id)}
        onChange={(e) => {
          data.setSelectedOptions((prev) => {
            const newSelectedOptions = e.target.checked
              ? [...prev, item]
              : prev.filter((option) => option.id !== item.id)

            onChange && onChange(newSelectedOptions)
            return newSelectedOptions
          })
        }}
      />
    </div>
  )
  const renderCards = () => <>{options.map((item) => renderWeekday(item))}</>

  return (
    <>
      <div className='border border-gray-300 p-5 rounded d-flex flex-row gap-5 flex-wrap align-items-start'>
        {renderCards()}
      </div>
      <Typography type='span' className='fs-7 mt-5' color='rgb(231, 76, 60)'>
        {errorText}
      </Typography>
    </>
  )
}

export default DayPicker
