import {Expose, Transform} from 'class-transformer'
import {IsEnum, IsOptional, IsString} from 'class-validator'
import {PaginateRequestDto} from './paginate.request.dto'
import {SortTypeEnum, SortingProductStatusEnum} from 'models'

export enum GetSortingReportRequestDtoSortEnum {
  sortingProductCreatedAt = 'sortingProduct.createdAt',
}

export class GetSortingReportRequestDtoFilterDto extends PaginateRequestDto {
  @IsOptional({each: true})
  @IsString({each: true})
  @Transform(({value}) => (value && typeof value === 'string' ? [value] : value || undefined))
  @Expose()
  'filters[productIds]'?: string[]

  @IsOptional({each: true})
  @IsString({each: true})
  @Transform(({value}) => (value && typeof value === 'string' ? [value] : value || undefined))
  @Expose()
  'filters[sortingLineIds]'?: string[]

  @IsString()
  @IsOptional()
  @Expose()
  'filters[date]'?: string

  @IsEnum(SortingProductStatusEnum)
  @IsOptional()
  @Expose()
  'filters[status]'?: SortingProductStatusEnum

  @IsEnum(SortTypeEnum)
  @IsOptional()
  @Expose()
  sortType?: SortTypeEnum

  @IsEnum(GetSortingReportRequestDtoSortEnum)
  @IsOptional()
  @Expose()
  sort?: GetSortingReportRequestDtoSortEnum

  @IsOptional()
  @IsString()
  search?: string
}
