import {Box, IBox, IQuoteProduct, QuoteProduct} from 'models'

export interface IQuoteProductBox {
  id?: string
  createdAt?: string
  updatedAt?: string
  weight?: number
  quoteProductId?: string
  boxId?: string
  box?: IBox
  quoteProduct?: IQuoteProduct
}

export class QuoteProductBox {
  public props: IQuoteProductBox = {}

  constructor(data?: IQuoteProductBox) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getWeight(): number {
    return this.props.weight || 0
  }

  getQuoteProductId(): string {
    return this.props.quoteProductId || ''
  }

  getBoxId(): string {
    return this.props.boxId || ''
  }

  getBox(): Box {
    return new Box(this.props.box || {})
  }

  getQuoteProduct(): QuoteProduct {
    return new QuoteProduct(this.props.quoteProduct || {})
  }
}
