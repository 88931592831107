import {Expose} from 'class-transformer'
import {
  IsArray,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator'
import {DeliveryStateEnum} from 'enums'

export class UpdateDeliveryBoxDto {
  @IsString()
  @IsOptional()
  newBoxId?: string

  @IsString()
  @IsOptional()
  currentBoxId?: string

  @IsNumber({}, {message: 'تعداد الزامی است'})
  @IsNotEmpty({message: 'تعداد الزامی است'})
  quantity: number

  constructor() {
    this.newBoxId = ''
    this.currentBoxId = ''
    this.quantity = 0
  }
}
export class UpdateDeliveryRequestDto {
  @IsOptional()
  @IsArray()
  @ValidateNested({each: true})
  @Expose()
  boxes?: UpdateDeliveryBoxDto[]

  @IsOptional()
  @IsString()
  addressId?: string

  @IsOptional()
  @IsString()
  customerNotes?: string

  @IsOptional()
  @IsEnum(DeliveryStateEnum)
  state?: DeliveryStateEnum

  constructor() {
    this.boxes = []
    this.addressId = ''
    this.customerNotes = ''
    this.state = DeliveryStateEnum.pending
  }
}
