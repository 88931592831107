import {FC, useState} from 'react'
import {Outlet} from 'react-router-dom'

// local
import Context from './context'

const ForgotPasswordLayout: FC = () => {
  const [mobileNumber, setMobileNumber] = useState<string>('')

  return (
    <Context.Provider
      value={{
        mobileNumber,
        setMobileNumber,
      }}
    >
      <Outlet />
    </Context.Provider>
  )
}

export default ForgotPasswordLayout
