import {useState} from 'react'
import {useSnackbar} from 'notistack'
import {useMutation, useQuery} from 'react-query'
import {useForm} from 'react-hook-form'
import {classValidatorResolver} from '@hookform/resolvers/class-validator'
//services
import services, {ServerError} from 'services'
//models
import {WeeklyPlan} from 'models'
// dtos
import {CreateCourierRequestDto} from 'dtos'
//hooks
import useText from 'hooks/useText'
import useToolbarRefetch from 'hooks/useToolbarRefetch'
//locals
import {texts} from './texts'
import {Props} from '.'

interface IUseData {
  handleClose: Props['handleClose']
}
const useData = ({handleClose}: IUseData) => {
  const {TX} = useText()
  const {enqueueSnackbar} = useSnackbar()
  const {setIsRefetchOn} = useToolbarRefetch()
  const [photos, setPhotos] = useState<{photoId: string; isFull: boolean}[]>(
    Array(1).fill({photoId: '', isFull: false})
  )
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue: setFormValue,
    formState: {errors, isValid, isDirty},
  } = useForm<CreateCourierRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(CreateCourierRequestDto),
    defaultValues: {
      firstName: '',
      lastName: '',
      mobileNumber: '',
      birthDate: new Date(),
      vehicle: {
        name: '',
        model: '',
        plateNumber: '',
      },
      weeklyPlanIds: [],
      email: '',
    },
  })

  /* ------------------------------------------------------------ */
  /*                           Services                           */
  /* ------------------------------------------------------------ */

  const handleCreateCourierService = async () => await services.couriers.createCourier({...watch()})

  const handleGetWeeklyPlansService = async () => await services.couriers.getWeeklyPlans()

  /* ------------------------------------------------------------ */
  /*                           Queries                            */
  /* ------------------------------------------------------------ */

  const {data} = useQuery(['get-weekly-plans-edit-info-page'], handleGetWeeklyPlansService, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  /* ------------------------------------------------------------ */
  /*                         Mutations                            */
  /* ------------------------------------------------------------ */

  const {isLoading, mutate} = useMutation(handleCreateCourierService, {
    onSuccess: () => {
      handleClose()
      setIsRefetchOn(true)
      reset()
      enqueueSnackbar(TX(texts.courier_added_successfully), {
        variant: 'success',
      })
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* ------------------------------------------------------------ */
  /*                          Handlers                            */
  /* ------------------------------------------------------------ */

  const onSubmit = async () => {
    setIsSubmitted(true)
  }

  const onSubmitFinish = async () => {
    setIsSubmitted(false)
    setFormValue('photoId', photos[0].photoId)
    await mutate()
  }

  const handleChangeBirthDate = (value: Date | null) => {
    if (!value) return

    setFormValue('birthDate', value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  /* ------------------------------------------------------------ */
  /*                            Data                              */
  /* ------------------------------------------------------------ */

  const weeklyPlans = data?.map((weeklyPlan) => new WeeklyPlan(weeklyPlan)) || []

  return {
    isLoading,
    onSubmitFinish,
    onSubmit: handleSubmit(onSubmit),
    control,
    watch,
    reset,
    setFormValue,
    errors,
    isValid,
    isDirty,
    weeklyPlans,
    isSubmitted,
    setIsSubmitted,
    photos,
    setPhotos,
    handleChangeBirthDate,
  }
}

export default useData
