import {City, ICity} from './City'
import {IRegionDistrict, RegionDistrict} from './RegionDistrict'
import {ITagUse, TagUse} from './TagUse'

export interface IDistrict {
  id?: string
  createdAt?: string
  updatedAt?: string
  label?: string
  cityId?: string
  city?: ICity
  tagUses?: ITagUse[]
  regionDistricts?: IRegionDistrict[]
}

export class District {
  public props: IDistrict = {}

  constructor(data?: IDistrict) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getLabel(): string {
    return this.props.label || ''
  }

  getCityId(): string {
    return this.props.cityId || ''
  }

  getCity(): City {
    return new City(this.props.city || {})
  }

  getTagUses(): TagUse[] {
    return (this.props.tagUses || []).map((tagUse: ITagUse) => new TagUse(tagUse))
  }

  getRegionDistricts(): RegionDistrict[] {
    return (this.props.regionDistricts || []).map(
      (regionDistrict: IRegionDistrict) => new RegionDistrict(regionDistrict)
    )
  }
}
