import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    addressCardWrapper: {
      textAlign: 'center',
      minHeight: '250px',
    },
    checkboxStatusAddressWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1.5),
      width: '100%',
    },
  }
})
