import {FormikProvider} from 'formik'

// components
import {Modal} from 'components/elements/Modal'
import {Textfield} from 'components/elements/Textfield'
import {Button} from 'components/elements/Button'

// local
import {useData} from './useData'
import {texts} from './texts'

type Props = {
  show: boolean
  handleClose: () => void
}

const CreateFAQCategoryModal = ({show, handleClose}: Props) => {
  const {form, loading, TX} = useData(show, handleClose)

  return (
    <Modal
      id='create-product-modal'
      show={show}
      handleClose={handleClose}
      title={TX(texts.faq_category_modal_btn)}
      children={
        <form onSubmit={form.handleSubmit} encType='multipart/form-data'>
          <FormikProvider value={form}>
            <div className='stepper stepper-pills stepper-column' id='kt_modal_create_app_stepper'>
              <div className='flex-row-fluid py-lg-5 px-lg-15'>
                <Textfield
                  {...form.getFieldProps('category')}
                  label={`${TX(texts.faq_form_category)} *`}
                  type='text'
                  name='category'
                  autoComplete='off'
                  error={!!form.errors.category}
                  touched={form.touched.category}
                  errorText={form.errors.category}
                  onChange={form.handleChange}
                  disabled={loading}
                />
                <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
                  <Button
                    text={TX(texts.create_faq_cancel)}
                    className='main-red-background btn btn-lg background-inherit justify-content-center'
                    onClick={handleClose}
                    type='button'
                  />

                  <Button
                    text={TX(texts.create_faq_category_submit)}
                    isLoading={loading}
                    className='btn btn-lg btn-primary main-gradient-background'
                    type='submit'
                    disabled={form.isSubmitting || !(form.isValid && form.dirty)}
                  />
                </div>
              </div>
            </div>
          </FormikProvider>
        </form>
      }
    />
  )
}

export {CreateFAQCategoryModal}
