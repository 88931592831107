import {DeliveryBox, DeliveryChain, DeliveryChainCustomer, Line} from 'models'
import {Dispatch, SetStateAction, createContext, useContext} from 'react'

interface IPackagingContext {
  line: Line | undefined
  setLine: Dispatch<SetStateAction<Line | undefined>>
  date: Date
  setDate: Dispatch<SetStateAction<Date>>
  deliveryChain: DeliveryChain | undefined
  setDeliveryChian: Dispatch<SetStateAction<DeliveryChain | undefined>>
  customer: DeliveryChainCustomer | undefined
  setCustomer: Dispatch<SetStateAction<DeliveryChainCustomer | undefined>>
  box: DeliveryBox | undefined
  setBox: Dispatch<SetStateAction<DeliveryBox | undefined>>
}

const packagingContext = createContext<IPackagingContext>({
  line: undefined,
  setLine: () => {},
  date: new Date(),
  setDate: () => {},
  deliveryChain: undefined,
  setDeliveryChian: () => {},
  customer: undefined,
  setCustomer: () => {},
  box: undefined,
  setBox: () => {},
})

export const usePackagingContext = () => useContext(packagingContext)
export default packagingContext
