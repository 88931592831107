import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  product_image_title: 'pages.protected.productionline.products.components.product-image-title',
  supplies_breadcrumbs: 'pages.protected.breadcrumbsItems.supplies',
  product_modal_btn:
    'pages.protected.production-line,supplies.products.components.create-product-modal.btn-title_scale',
  scale_id:
    'pages.protected.production-line,supplies.products.components.create-product-modal.scale_id',
  create_product_cancel:
    'pages.protected.production-line,supplies.products.components.create-product-modal.cancel',
  create_product_submit: 'components.elements.table-toolbar.submit',
  creation_success:
    'pages.protected.production-line,supplies.products.components.create-product-modal.success-creation_scale',
  must_be_number: "pages.protected.warehouse.scales.components.must_be_number",
  scale_id_required: "pages.protected.warehouse.scales.components.scale_id_required",
}
