import moment from 'jalali-moment'
import {toPersianDigit} from './digit'

export const DisplayJalaliDate = (date?: Date) => {
  if (!date) return ''
  const m = moment(date)
  return toPersianDigit(m.locale('fa').format('jDD MMMM YYYY'))
}

export const DisplayFromNow = (date?: Date) => {
  if (!date) return ''
  const m = moment(date)
  return toPersianDigit(m.locale('fa').fromNow())
}

export const getDateWithoutTime = (date: Date) => {
  return new Date(date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate())
}

export const getDateStringForQuery = (date: Date) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
    date.getDate()
  ).padStart(2, '0')}`
}

export const displayTime = (date?: Date) => {
  if (!date) return ''
  return toPersianDigit(date.toLocaleString('fa', {hour: 'numeric', minute: 'numeric'}))
}

export const toPersianMonthDay = (data?: Date) => {
  if (!data) return ''

  return toPersianDigit(data.toLocaleString('fa', {month: 'short', day: 'numeric'}))
}

export const isSameDates = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}
