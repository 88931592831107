import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      padding: theme.spacing(0.5, 0),
      display: 'flex',
      gap: 10,
      overflowX: 'scroll',
    },
  }
})
