import {IsBoolean, IsEnum, IsOptional, IsString} from 'class-validator'
import {AdminRolesEnum} from 'enums'

export class CreateResponseToTicketRequestDto {
  @IsString({message: 'پاسخ الزامی است'})
  body: string

  @IsOptional()
  @IsBoolean()
  isFinal?: boolean = false

  @IsBoolean()
  @IsOptional()
  isReference?: boolean = false

  @IsEnum(AdminRolesEnum, {each: true})
  @IsOptional({each: true})
  assigned?: AdminRolesEnum[]

  @IsOptional({each: true})
  @IsString({each: true})
  photoIds?: string[]

  constructor() {
    this.body = ''
    this.isFinal = false
    this.isReference = false
    this.assigned = []
    this.photoIds = []
  }
}
