export interface ISortingProductBoxRecord {
  id?: string
  createdAt?: string
  updatedAt?: string
  sortingProductBoxId?: string
}

export class SortingProductBoxRecord {
  public props: ISortingProductBoxRecord = {}

  constructor(data?: ISortingProductBoxRecord) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getSortingProductBoxId(): string | undefined {
    return this.props.sortingProductBoxId
  }
}
