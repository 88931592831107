import {FC, useState} from 'react'
import {useQuery} from 'react-query'

// models
import {ProductType} from 'models/ProductType'
import {ProductUnit} from 'models/ProductUnit'

// services
import services, {ServerError} from 'services'

// local
import Context from './context'
import {useSnackbar} from 'notistack'
import useUser from 'hooks/useUser'

const ParametersProvider: FC<{children: React.ReactNode}> = ({children}) => {
  const {enqueueSnackbar} = useSnackbar()

  const {isLogin} = useUser()

  const [productTypes, setProductTypes] = useState<ProductType[]>([])
  const [productUnits, setProductUnits] = useState<ProductUnit[]>([])

  const getProductTypes = async () => await services.products.getAllProductTypes()
  useQuery(['product-types'], getProductTypes, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: isLogin,
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
    onSuccess: (data) => {
      setProductTypes(data.map((item) => new ProductType(item)))
    },
  })

  const getProductUnits = async () => await services.products.getProductUnits()
  useQuery(['product-units'], getProductUnits, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: isLogin,
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
    onSuccess: (data) => {
      setProductUnits(data.map((item) => new ProductUnit(item)))
    },
  })

  return (
    <Context.Provider
      value={{
        productTypes,
        setProductTypes,
        productUnits,
        setProductUnits,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default ParametersProvider
