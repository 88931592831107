import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  product_image_title: 'pages.protected.productionline.products.components.product-image-title',
  supplies_breadcrumbs: 'pages.protected.breadcrumbsItems.supplies',
  product_modal_btn:
    'pages.protected.production-line,supplies.products.components.create-product-modal.btn-title_scale',
  scale_id:
    'pages.protected.production-line,supplies.products.components.create-product-modal.scale_id',
  create_product_cancel:
    'pages.protected.production-line,supplies.products.components.create-product-modal.cancel',
  create_product_submit: 'components.elements.table-toolbar.submit',
  creation_success:
    'pages.protected.production-line,supplies.products.components.create-product-modal.success-creation_scale',
  back: 'components.modals.TicketModal.back',
  referral: 'components.modals.TicketModal.referral',
  response: 'components.modals.TicketModal.response',
  description: 'components.modals.TicketModal.description',
  final_response: 'components.modals.TicketModal.final_response',
  send: 'components.modals.TicketModal.send',
  refer_to: 'components.modals.TicketModal.refer_to',
  refer_text: 'components.modals.TicketModal.refer_text',
  common: 'components.modals.TicketModal.common',
  category: 'components.modals.TicketModal.category',
  request_number: 'components.modals.TicketModal.request_number',
  write_ticket: 'components.modals.TicketModal.write_ticket',
  create_ticket_success: 'components.modals.TicketModal.create_ticket_success',
  create_response_ticket_success: 'components.modals.TicketModal.create_response_ticket_success',
}
