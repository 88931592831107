import {IsString} from 'class-validator'

export class GetSentVerificationCodeResponseDto {
  @IsString()
  mobileNumber: string

  constructor() {
    this.mobileNumber = ''
  }
}
