import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  product_image_title: 'pages.protected.productionline.products.components.product-image-title',
  supplies_breadcrumbs: 'pages.protected.breadcrumbsItems.supplies',
  product_modal_btn:
    'pages.protected.production-line,supplies.products.components.create-product-modal.btn-title',
  product_name_form:
    'pages.protected.production-line,supplies.products.components.create-product-modal.form-variety-name',
  product_type_form:
    'pages.protected.production-line,supplies.products.components.create-product-modal.form-variety-type',
  create_product_cancel:
    'pages.protected.production-line,supplies.products.components.create-product-modal.cancel',
  create_product_submit:
    'pages.protected.production-line,supplies.products.components.create-product-modal.submit',
  product_form_name_required:
    'pages.protected.production-line,supplies.products.components.create-product-modal.form-variety-name-required-error',
  product_from_type_required:
    'pages.protected.production-line,supplies.products.components.create-product-modal.form-variety-type-required-error',
  creation_success:
    'pages.protected.production-line,supplies.products.components.create-product-modal.success-creation',
  unit: 'pages.protected.production-line,supplies.products.components.create-product-modal.unit',
  type: 'pages.protected.production-line,supplies.products.components.create-product-modal.type',
  price: 'pages.protected.production-line,supplies.products.components.create-product-modal.price',
  percentage:
    'pages.protected.production-line,supplies.products.components.create-product-modal.percentage',
  weight:
    'pages.protected.production-line,supplies.products.components.create-product-modal.weight',
  kilogram:
    'pages.protected.production-line,supplies.products.components.create-product-modal.kilogram',
  description:
    'pages.protected.production-line,supplies.products.components.create-product-modal.description',
  name_required:
    'pages.protected.production-line,supplies.products.components.create-product-modal.name_required',
  percentage_price_not_min:
    'pages.protected.production-line,supplies.products.components.create-product-modal.percentage_price_not_min',
  percentage_price_required:
    'pages.protected.production-line,supplies.products.components.create-product-modal.percentage_price_required',
  percentage_margin:
    'pages.protected.production-line,supplies.products.components.create-product-modal.percentage_margin',
  use_custom_price:
    'pages.protected.production-line,supplies.products.components.create-product-modal.use_custom_price',
  custom_price:
    'pages.protected.production-line,supplies.products.components.create-product-modal.custom_price',
}
