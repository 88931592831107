import {IsEnum, IsOptional, IsString} from 'class-validator'
import {TicketCategoryEnum, TicketStatusEnum} from 'enums'

export class UpdateTicketRequestDto {
  @IsOptional()
  @IsString()
  description?: string

  @IsEnum(TicketCategoryEnum)
  @IsOptional()
  category?: TicketCategoryEnum

  @IsOptional()
  @IsString()
  customerId?: string

  @IsOptional()
  @IsString()
  deliveryId?: string

  @IsOptional()
  @IsEnum(TicketStatusEnum)
  status?: TicketStatusEnum

  constructor() {
    this.description = ''
    this.category = '' as TicketCategoryEnum
    this.customerId = ''
    this.deliveryId = ''
    this.status = '' as TicketStatusEnum
  }
}
