import {IsEnum, IsOptional, IsString, ValidateIf} from 'class-validator'
import {SortingProductStatusEnum} from 'models'

export class UpdateSortingProductRequestDto {
  @IsEnum(SortingProductStatusEnum)
  @IsOptional()
  status?: SortingProductStatusEnum

  @IsOptional()
  @IsString()
  @ValidateIf((o) => o.sortingLineId)
  sortingLineId?: string

  constructor() {
    this.status = '' as SortingProductStatusEnum
    this.sortingLineId = ''
  }
}
