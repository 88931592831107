import {Expose, Type} from 'class-transformer'
import {
  IsArray,
  IsDate,
  IsNumberString,
  IsString,
  Length,
  ValidateNested,
  IsOptional,
} from 'class-validator'

class IVehicleRequestDto {
  @IsString({message: 'نام خودرو الزامی است'})
  name: string

  @IsString({message: 'مدل خودرو الزامی است'})
  model: string

  @IsOptional()
  @IsString()
  plateNumber?: string

  constructor() {
    this.name = ''
    this.model = ''
    this.plateNumber = ''
  }
}

export class CreateCourierRequestDto {
  @IsString({message: 'نام الزامی است'})
  firstName: string

  @IsString({message: 'نام خانوادگی الزامی است'})
  lastName: string

  @Length(11, 11, {message: 'شماره موبایل باید ۱۱ رقم باشد'})
  @IsNumberString()
  mobileNumber: string

  @IsString({message: 'ایمیل نامعتبر است'})
  @IsOptional()
  email: string

  @IsOptional()
  @IsDate()
  birthDate?: Date

  @IsOptional()
  @Type(() => IVehicleRequestDto)
  @Expose()
  @ValidateNested()
  vehicle?: IVehicleRequestDto

  @IsString()
  @IsOptional()
  photoId?: string

  @IsOptional({each: true})
  @IsArray()
  weeklyPlanIds?: string[]

  constructor() {
    this.firstName = ''
    this.lastName = ''
    this.mobileNumber = ''
    this.email = ''
    this.birthDate = new Date()
    this.photoId = ''
    this.weeklyPlanIds = []
  }
}
