import apiConfig from 'services/config'
import ClientApi from './clientApi'

const axios = new ClientApi()

const {rootAddress} = apiConfig

const prefix = '/download'

export const getFileCSV = (body: {filename: string; downloadedName: string}) =>
  axios.http
    .get(rootAddress + prefix + '/admin/csv/' + body.filename, {
      headers: {'Content-Type': 'multipart/form-data'},
      responseType: 'blob',
    })
    // @ts-ignore
    .then((response: BlobPart) => {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      // link.download = body.downloadedName
      link.setAttribute('download', body.downloadedName + '.csv')

      document.body.appendChild(link)
      link.click()
      link.parentNode!.removeChild(link)
    })
