import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  create_subscriberModal_new_customer:
    'pages.protected.subscribers.list.components.create_subscriberModal_new_customer',
  birth_day: 'pages.protected.subscribers.list.components.birth_day',
  description: 'pages.protected.subscribers.list.components.description',
  back: 'pages.protected.subscribers.list.components.back',
  submit: 'pages.protected.subscribers.list.components.submit',
  password_is_require: 'pages.protected.subscribers.list.components.password_is_require',
  mobile_number_is_require: 'pages.protected.subscribers.list.components.mobile_number_is_require',
  first_name_is_require: 'pages.protected.subscribers.list.components.first_name_is_require',
  last_name_is_require: 'pages.protected.subscribers.list.components.last_name_is_require',
  out_of_service_range: 'pages.protected.subscribers.list.components.out_of_service_range',
  require: 'pages.protected.subscribers.list.components.require',
  optional: 'pages.protected.subscribers.list.components.optional',
  phone_must_be_11_character:
    'pages.protected.subscribers.list.components.phone_must_be_11_character',
  phone_is_not_valid: 'pages.protected.subscribers.list.components.phone_is_not_valid',
  email_is_not_valid: 'pages.protected.subscribers.list.components.email_is_not_valid',
  not_valid: "pages.protected.subscribers.list.components.not_valid",
  customer_added_successfully:
    'pages.protected.subscribers.list.components.customer_added_successfully',
  firstName: 'pages.protected.subscribers.list.firstName',
  lastName: 'pages.protected.subscribers.list.lastName',
  password: 'pages.protected.subscribers.list.password',
  mobile_number: 'pages.protected.subscribers.list.mobile_number',
  second_mobile_number: 'pages.protected.subscribers.list.second_mobile_number',
  username: 'pages.protected.subscribers.list.username',
  email: 'pages.protected.subscribers.list.email',
  postcode: 'pages.protected.subscribers.list.postcode',
  username_space_error: 'pages.protected.subscribers.list.username_space_error',

  address_label: 'pages.protected.subscribers.list.address_label',
  city: 'pages.protected.subscribers.list.city',
  province: 'pages.protected.subscribers.list.province',
  district: 'pages.protected.subscribers.list.district',
  street: 'pages.protected.subscribers.list.street',
  alley: 'pages.protected.subscribers.list.alley',
  house_number: 'pages.protected.subscribers.list.house_number',
  house_unit: 'pages.protected.subscribers.list.house_unit',
  area: 'pages.protected.subscribers.list.area',
  address_is_require: "pages.protected.subscribers.list.components.address_is_require",
  address_title: "pages.protected.subscribers.list.address_title",
  address_description: "pages.protected.subscribers.list.address_description",
  delivery_plan: "pages.protected.subscribers.list.delivery_plan",
  count_capacity: "pages.protected.subscribers.list.count_capacity",
  create_subscriber_title: "pages.protected.subscribers.list.create_subscriber_title",
  edit_subscriber_title: "pages.protected.subscribers.list.edit_subscriber_title",
  status_title: "pages.protected.subscribers.list.status_title",
  address_info: "pages.protected.subscribers.list.address_info",
  address_is_required: "pages.protected.subscribers.list.components.address_is_required"
}
