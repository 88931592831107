import {ICoordinates} from 'services/regions'

export const useData = (
  coordinates: ICoordinates[][],
  onChangeList: React.Dispatch<React.SetStateAction<ICoordinates[][]>>
) => {
  const _onEdited = (e: any) => {
    e.layers.eachLayer((layer: any) => {
      onChangeList(
        layer
          .getLatLngs()[0]
          .map((latlng: {lat: string; lng: string}) => ({lat: latlng.lat, long: latlng.lng}))
      )
    })
  }

  const _onCreated = (e: any) => {
    let layer = e.layer

    onChangeList((prevState) => [
      ...prevState,
      layer
        .getLatLngs()[0]
        .map((latlng: {lat: string; lng: string}) => ({lat: latlng.lat, long: latlng.lng})),
    ])
  }

  const _onDeleted = (e: any) => {
    e.layers.eachLayer((layer: any) => {
      onChangeList([])
    })
  }

  return {
    _onCreated,
    _onDeleted,
    _onEdited,
  }
}
