import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  faq_modal_btn: 'pages.protected.system-preferences.faq.components.create-faq-modal.btn-title',
  faq_form_question: 'pages.protected.system-preferences.faq.main.table-column-faq-question',
  faq_form_answer: 'pages.protected.system-preferences.faq.main.table-column-faq-answer',
  create_faq_cancel: 'pages.protected.system-preferences.faq.components.create-faq-modal.cancel',
  create_faq_submit: 'pages.protected.system-preferences.faq.components.create-faq-modal.submit',
  faq_form_question_required:
    'pages.protected.system-preferences.faq.components.create-faq-modal.form-faq-question-required-error',
  faq_form_answer_required:
    'pages.protected.system-preferences.faq.components.create-faq-modal.form-faq-answer-required-error',
  creation_success:
    'pages.protected.system-preferences.faq.components.create-faq-modal.success-creation',
}
