import {createPortal} from 'react-dom'
import {Modal as BootModal} from 'react-bootstrap'
import {KTSVG} from 'utils/template/helpers'
import {ReactNode} from 'react'

type ModalProps = {
  id?: string
  classNameWrapper?: string
  className?: string
  title?: string
  show: boolean
  handleClose: () => void
  children: ReactNode
}

const modalsRoot = document.getElementById('root-modals') || document.body

const Modal = ({
  id = '',
  title = '',
  show,
  handleClose,
  children,
  classNameWrapper = '',
  className = '',
}: ModalProps) => {
  return createPortal(
    <BootModal
      id={id}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={'modal-dialog modal-dialog-centered mw-900px'}
      show={show}
      onHide={handleClose}
      backdrop={true}
      style={{zIndex: 9999999}}
      className={classNameWrapper}
    >
      <div className='modal-header d-flex flex-row-reverse justify-content-center'>
        <h2 className='flex-grow-1 text-center'>{title}</h2>
        <div className='btn btn-sm btn-icon' onClick={handleClose}>
          <KTSVG
            className='svg-icon-2x main-green'
            svgClassName='mh-100px h-35px'
            path='/media/icons/duotune/arrows/arr061-sarsabad.svg'
          />
        </div>
      </div>

      <div className={['modal-body py-lg-10 px-lg-10', className].join(' ')}>{children}</div>
    </BootModal>,
    modalsRoot
  )
}

export {Modal}
