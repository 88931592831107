import {Expose, Transform} from 'class-transformer'
import {IsEnum, IsOptional, IsString} from 'class-validator'
import {SortTypeEnum} from 'models'
import {PaginateRequestDto} from './paginate.request.dto'
import {GetPackingsReportRequestDtoSortEnum, PackingStatusEnum} from 'enums'

export class GetPackingsReportRequestDtoFiltersDto extends PaginateRequestDto {
  @IsOptional({each: true})
  @IsString({each: true})
  @Transform(({value}) => (value && typeof value === 'string' ? [value] : value || undefined))
  'filters[packingLineIds]'?: string[]

  @IsOptional({each: true})
  @IsString({each: true})
  @Transform(({value}) => (value && typeof value === 'string' ? [value] : value || undefined))
  'filters[scaleIds]'?: string[]

  @IsOptional({each: true})
  @IsString({each: true})
  @Transform(({value}) => (value && typeof value === 'string' ? [value] : value || undefined))
  'filters[employeeIds]'?: string[]

  @IsOptional({each: true})
  @IsString({each: true})
  @Transform(({value}) => (value && typeof value === 'string' ? [value] : value || undefined))
  'filters[adminIds]'?: string[]

  @IsOptional()
  @IsString()
  @Expose()
  'filters[deliveryChainSpecificDate]'?: string

  @IsOptional()
  @IsString()
  @Expose()
  'filters[deliveryChainFromDate]'?: string

  @IsOptional()
  @IsString()
  @Expose()
  'filters[deliveryChainUntilDate]'?: string

  @IsOptional()
  @IsString()
  @Expose()
  'filters[packingSpecificDate]'?: string

  @IsOptional()
  @IsString()
  @Expose()
  'filters[packingFromDate]'?: string

  @IsOptional()
  @IsString()
  @Expose()
  'filters[packingUntilDate]'?: string

  @IsOptional()
  @IsEnum(PackingStatusEnum)
  @Expose()
  'filters[packingStatus]'?: PackingStatusEnum

  @IsEnum(SortTypeEnum)
  @IsOptional()
  @Expose()
  sortType?: SortTypeEnum

  @IsEnum(GetPackingsReportRequestDtoSortEnum)
  @IsOptional()
  @Expose()
  sort?: GetPackingsReportRequestDtoSortEnum

  @IsOptional()
  @IsString()
  search?: string
}
