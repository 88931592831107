import {PackingStatusEnum} from 'enums'
import {Admin, IAdmin} from './Admin'
import {DeliveryChain, IDeliveryChain} from './DeliveryChain'
import {IPackingLine, PackingLine} from './PackingLine'

export interface IPacking {
  admin?: IAdmin
  adminId?: string
  createdAt?: string
  updatedAt?: string
  deliveryChian?: IDeliveryChain
  deliveryChainId?: string
  finishedCustomers?: number
  packingLine?: IPackingLine
  packingLineId?: string
  id?: string
  status?: PackingStatusEnum
}

export class Packing {
  public props: IPacking = {}

  constructor(data?: IPacking) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getStatus(): string {
    return this.props.status || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getDeliveryChian(): DeliveryChain {
    return new DeliveryChain(this.props.deliveryChian)
  }

  getDeliveryChainId(): string {
    return this.props.deliveryChainId || ''
  }

  getPackigLine(): PackingLine {
    return new PackingLine(this.props.packingLine)
  }

  getPackingLineId(): string {
    return this.props.packingLineId || ''
  }
}

export const displayPackingStatusEnum = (packingStatus: PackingStatusEnum) => {
  switch (packingStatus) {
    case PackingStatusEnum.start:
      return 'ناتمام'
    case PackingStatusEnum.pause:
      return 'متوقف شده'
    case PackingStatusEnum.finish:
      return 'تکمیل شده'
    default:
      return ''
  }
}
