import {Expose, Type} from 'class-transformer'
import {
  IsBoolean,
  IsDate,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  ValidateIf,
  ValidateNested,
} from 'class-validator'

class VendorQuoteForProductElement {
  @IsString({message: 'محصول الزامی است'})
  productId: string

  @IsNumber()
  @IsPositive({message: 'قیمت باید یک عدد مثبت باشد'})
  unitPrice: number

  @IsNumber()
  @IsPositive({message: 'وزن باید یک عدد مثبت باشد'})
  weight: number

  @IsString({message: 'تامین کننده الزامی است'})
  vendorId: string

  @IsString()
  @IsOptional()
  @ValidateIf((o) => o.varietyId)
  varietyId?: string

  constructor() {
    this.productId = ''
    this.unitPrice = 0
    this.weight = 0
    this.vendorId = ''
    this.varietyId = ''
  }
}
export class CreateVendorQuoteForProductManuallyRequestDto {
  @IsString()
  @IsOptional()
  @ValidateIf((p) => p.quoteId)
  quoteId?: string

  @IsBoolean({message: 'از موجودی انبار استفاده شود'})
  isUseOfStock: boolean

  @IsString({message: 'تاریخ الزامی است'})
  date: string

  @ValidateNested({each: true})
  @Expose()
  @Type(() => VendorQuoteForProductElement)
  product: VendorQuoteForProductElement

  constructor() {
    this.quoteId = ''
    this.isUseOfStock = false
    this.date = ''
    this.product = new VendorQuoteForProductElement()
  }
}
