import {useEffect, useLayoutEffect, useState} from 'react'
import queryString from 'query-string'
import {useSearchParams, useNavigate} from 'react-router-dom'
import {useQuery} from 'react-query'
import {useSnackbar} from 'notistack'
// components
import {ITableColumn} from 'components/elements/Table'
import {Typography} from 'components/elements/Typography'
// services
import services, {ServerError} from 'services'
// models
import {Meta, Dto} from 'models'
import {PackingReport} from 'models/PackingReport'
// utils
import {removeEmptyValues} from 'utils/basic/object'
import {toPersianDigit} from 'utils/template/helpers'
import {DisplayFromNow} from 'utils/basic/date'
// enums
import {GetPackingsReportRequestDtoSortEnum, PackingStatusEnum} from 'enums'
// dtos
import {GetPackingsReportRequestDtoFiltersDto} from 'dtos'
// hooks
import useProtectedLayout from 'hooks/useProtectedLayout'
import useText from 'hooks/useText'
import useToolbarRefetch from 'hooks/useToolbarRefetch'
// constants
import {PACKAGING_LINE_REPORT_ROUTE} from 'constants/routes'
// locals
import {texts} from './texts'
import useStyles from './useStyles'

export const useData = () => {
  const initialQuery: GetPackingsReportRequestDtoFiltersDto = {
    page: 1,
    limit: 10,
    search: '',
    sort: GetPackingsReportRequestDtoSortEnum.packingDate,
    'filters[packingLineIds]': [],
    'filters[scaleIds]': [],
    'filters[employeeIds]': [],
    'filters[adminIds]': [],
    'filters[deliveryChainSpecificDate]': '',
    'filters[deliveryChainFromDate]': '',
    'filters[deliveryChainUntilDate]': '',
    'filters[packingSpecificDate]': '',
    'filters[packingFromDate]': '',
    'filters[packingUntilDate]': '',
  }

  const convertSearchParamsToQuery = (
    searchParams: URLSearchParams
  ): GetPackingsReportRequestDtoFiltersDto => {
    return {
      page: Number(searchParams.get('page')) || 1,
      limit: Number(searchParams.get('limit')) || 10,

      search: searchParams.get('search') || '',
      sort: GetPackingsReportRequestDtoSortEnum.packingDate,

      'filters[packingStatus]':
        (searchParams.get('filters[packingStatus]') as PackingStatusEnum) || undefined,
      'filters[packingLineIds]': (searchParams.getAll('filters[packingLineIds]') as string[]) || [],
      'filters[scaleIds]': (searchParams.getAll('filters[scaleIds]') as string[]) || [],
      'filters[employeeIds]': (searchParams.getAll('filters[employeeIds]') as string[]) || [],
      'filters[adminIds]': (searchParams.getAll('filters[adminIds]') as string[]) || [],
      'filters[deliveryChainSpecificDate]':
        (searchParams.get('filters[deliveryChainSpecificDate]') as string) || undefined,
      'filters[deliveryChainFromDate]':
        (searchParams.get('filters[deliveryChainFromDate]') as string) || undefined,
      'filters[deliveryChainUntilDate]':
        (searchParams.get('filters[deliveryChainUntilDate]') as string) || undefined,
      'filters[packingSpecificDate]':
        (searchParams.get('filters[packingSpecificDate]') as string) || undefined,
      'filters[packingFromDate]':
        (searchParams.get('filters[packingFromDate]') as string) || undefined,
      'filters[packingUntilDate]':
        (searchParams.get('filters[packingUntilDate]') as string) || undefined,
    }
  }

  const {TX} = useText()
  const {isRefetchOn, setIsRefetchOn} = useToolbarRefetch()
  const {classes} = useStyles()
  const {setConfig} = useProtectedLayout()
  const [searchParams] = useSearchParams()
  const [query, setQuery] = useState<GetPackingsReportRequestDtoFiltersDto>(
    convertSearchParamsToQuery(searchParams)
  )
  const [openPackingLineId, setOpenPackingLineId] = useState<string>('')
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()

  // table and pagination
  const [page, setPage] = useState<number>(1)
  const [rows, setRows] = useState<PackingReport[]>([])
  const [meta, setMeta] = useState<Meta>(new Meta())

  /* ------------------------------------------------------------ */
  /*                           Services                           */
  /* ------------------------------------------------------------ */

  const getPackingLinesHandler = (query: GetPackingsReportRequestDtoFiltersDto) => async () =>
    await services.packing.getPackingReports({
      page,
      ...query,
    })

  /* ------------------------------------------------------------ */
  /*                           Queries                            */
  /* ------------------------------------------------------------ */

  const {isLoading, data, isFetching, refetch} = useQuery(
    ['get-packing-lines', searchParams],
    getPackingLinesHandler(removeEmptyValues(query)),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
      onSuccess: (data) => {
        const dto = new Dto(data)
        setRows(dto.getItems().map((item) => new PackingReport(item)))
        setMeta(dto.getMeta())
      },
    }
  )

  /* ------------------------------------------------------------ */
  /*                          Handlers                            */
  /* ------------------------------------------------------------ */

  const handleChangeUrl = async (newQuery: GetPackingsReportRequestDtoFiltersDto) => {
    const updatedQuery = removeEmptyValues(newQuery)
    await setQuery(updatedQuery)
    navigate(
      queryString.stringifyUrl({
        url: PACKAGING_LINE_REPORT_ROUTE,
        query: {...updatedQuery},
      }),
      {
        replace: true,
      }
    )
  }

  const onSubmit = () => {
    setQuery({...query, page: 1})
    handleChangeUrl({...query, page: 1})
  }

  const onClean = () => {
    setQuery(initialQuery)
    handleChangeUrl(removeEmptyValues(initialQuery))
  }

  const onFilter = () => {
    onSubmit()
    refetch()
  }

  /* ------------------------------------------------------------ */
  /*                            Data                              */
  /* ------------------------------------------------------------ */

  const columns: ITableColumn<PackingReport>[] = [
    {
      name: 'service_number',
      label: TX(texts.table_service_number),
      cell: (item) => (
        <Typography type='span'>
          {toPersianDigit(item.getDeliveryChain().getServiceNumber())}
        </Typography>
      ),
    },
    {
      name: 'time',
      label: TX(texts.table_time),
      cell: (item) => <Typography type='span'>{DisplayFromNow(item.getUpdatedAt())}</Typography>,
    },
    {
      name: 'admin',
      label: TX(texts.table_admin),
      cell: (item) => (
        <Typography
          type='span'
          className={classes.linkText}
          onClick={() => {
            setQuery((query) => ({...query, 'filters[adminIds]': [item.getAdmin().getId()]}))
          }}
        >
          {item.getAdmin().getFullName()}
        </Typography>
      ),
    },
    {
      name: 'status',
      label: TX(texts.table_status),
      cell: (item) => (
        <Typography type='span' className='fs-6'>
          {item.getStatus() === 'finish' ? TX(texts.finish_status) : TX(texts.no_finish_status)}
        </Typography>
      ),
    },
    {
      name: 'total_customers',
      label: TX(texts.table_total_customers),
      cell: (item) => (
        <Typography type='span' className='fs-7'>
          {toPersianDigit(item.getTotalCustomers())}
        </Typography>
      ),
    },
    {
      name: 'finished_customers',
      label: TX(texts.table_finished_customers),
      cell: (item) => (
        <Typography type='span' className='fs-7'>
          {toPersianDigit(item.getFinishedCustomers())}
        </Typography>
      ),
    },
    {
      name: 'table_line_name',
      label: TX(texts.table_line_name),
      cell: (item, index) => (
        <Typography
          type='span'
          className={classes.linkText}
          onClick={() => {
            setQuery((query) => ({
              ...query,
              'filters[packingLineId]': item.getPackingLine().getId(),
            }))
          }}
        >
          {item.getPackingLine().getName()}
        </Typography>
      ),
    },
    {
      name: 'scale.id',
      label: TX(texts.table_scale_id),
      cell: (item) => (
        <Typography
          type='span'
          className={classes.linkText}
          onClick={() => {
            setQuery((query) => ({
              ...query,
              'filters[scaleIds]': [item.getPackingLine().getScale().getId()],
            }))
          }}
        >
          {toPersianDigit(item.getPackingLine().getScale().getScaleId())}
        </Typography>
      ),
    },
    {
      name: 'employee_id',
      label: TX(texts.table_employee_id),
      cell: (item) => (
        <Typography
          type='span'
          className={classes.linkText}
          onClick={() => {
            setQuery((query) => ({
              ...query,
              'filters[employeeIds]': [item.getPackingLine().getEmployee().getId()],
            }))
          }}
        >
          {item.getPackingLine().getEmployee().getFullName()}
        </Typography>
      ),
    },
  ]

  /* ------------------------------------------------------------ */
  /*                         LifeCycles                           */
  /* ------------------------------------------------------------ */

  useLayoutEffect(() => {
    setConfig({
      pageName: [TX(texts.report)],
      pageDescription: [],
      toolbarType: 'none',
      breadcrumbsItems: [
        {
          title: TX(texts.packing),
          path: PACKAGING_LINE_REPORT_ROUTE,
          isActive: false,
          isSeparator: false,
        },
      ],
    })
  }, [setConfig, TX])

  useEffect(() => {
    if (isRefetchOn) {
      refetch()
      setIsRefetchOn(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetchOn])

  useEffect(() => {
    const query = convertSearchParamsToQuery(searchParams)
    setQuery(query)
    refetch()
  }, [searchParams, refetch])

  return {
    query,
    setQuery,
    columns,
    rows,
    setRows,
    loading: isFetching || isLoading,
    data,
    refetch,
    meta,
    setMeta,
    convertSearchParamsToQuery,
    handleChangeUrl,
    TX,
    setPage,
    onClean,
    onSubmit,
    openPackingLineId,
    setOpenPackingLineId,
    searchParams,
    onFilter,
  }
}
