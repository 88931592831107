import {IsEnum, IsNotEmpty, IsOptional, IsString} from 'class-validator'
import {FAQStatusEnum} from 'enums'

export class CreateFaqRequestDto {
  @IsString({message: 'سوال الزامی است'})
  @IsNotEmpty({message: 'سوال الزامی است'})
  question: string

  @IsString({message: 'جواب الزامی است'})
  @IsNotEmpty({message: 'جواب الزامی است'})
  answer: string

  @IsEnum(FAQStatusEnum)
  @IsOptional()
  status?: FAQStatusEnum

  @IsString({message: 'دسته بندی الزامی است'})
  @IsNotEmpty({message: 'دسته بندی الزامی است'})
  faqCategoryId: string

  constructor() {
    this.question = ''
    this.answer = ''
    this.faqCategoryId = ''
  }
}
