export enum CouponStatusEnum {
  activated = 'activated',
  deactivated = 'deactivated',
}

export enum CouponTypeEnum {
  wallet = 'wallet',
  discount = 'discount',
}

export enum GetCouponsRequestDtoSortEnum {
  createdAt = 'coupon.createdAt',
}

export enum GetCouponsUsagesRequestDtoSortEnum {
  createdAt = 'couponUsage.createdAt',
}

export enum GetCouponsCustomersRequestDtoSortEnum {
  createdAt = 'couponCustomer.createdAt',
}
