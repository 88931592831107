export enum GetProductsRequestDtoSortEnum {
  productType = 'product.type',
  productName = 'product.name',
  averageRefuse = 'product.averageRefuse',
  price = 'product.price',
  vendor = 'vendor.name',
}

export enum ProductStatusEnum {
  activated = 'activated',
  deactivated = 'deactivated',
}

export enum UnitTypeEnum {
  count = 'count',
  pack = 'pack',
  gram = 'gram',
  kilogram = 'kilogram',
  liter = 'liter',
  milliliter = 'milliliter',
}
