import {DailyTarget, IDailyTarget} from './DailyTarget'
import {IProduct, Product} from './Product'

export interface IDailyTargetProduct {
  id?: string
  createdAt?: string
  updatedAt?: string
  date?: string
  totalWeight?: number
  productId?: string
  dailyTargetId?: string
  product?: IProduct
  dailyTarget?: IDailyTarget
  averageWasteWeight?: number
  remainderWeight?: number
}

export class DailyTargetProduct {
  public props: IDailyTargetProduct = {}

  constructor(data?: IDailyTargetProduct) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDate(): Date | undefined {
    return this.props.date ? new Date(this.props.date) : undefined
  }

  getTotalWeight(): number {
    return this.props.totalWeight || 0
  }

  getDailyTargetId(): string {
    return this.props.dailyTargetId || ''
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getProduct(): Product {
    return new Product(this.props.product || {})
  }

  getDailyTarget(): DailyTarget {
    return new DailyTarget(this.props.dailyTarget || {})
  }

  getAverageWasteWeight(): number {
    return this.props.averageWasteWeight || 0
  }

  getRemainderWeight(): number {
    return this.props.remainderWeight || 0
  }
}
