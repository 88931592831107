import {IsArray, IsNotEmpty, IsNumber, IsString} from 'class-validator'

export class CreateRegionDeliveryPlanRequestDto {
  @IsString({message: 'وقت ارسال الزامی است'})
  deliveryPlanId: string

  @IsArray({message: 'پیک الزامی است'})
  courierIds: string[]

  @IsNumber()
  @IsNotEmpty({message: 'ظرفیت الزامی است'})
  courierCapacity: number

  constructor() {
    this.deliveryPlanId = ''
    this.courierIds = []
    this.courierCapacity = 0
  }
}
