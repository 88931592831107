import {IsBoolean, IsDate, IsEnum, IsInt, IsNumber, IsOptional, IsString} from 'class-validator'
import {BoxStatusEnum} from 'enums'

export class UpdateBoxRequestDto {
  @IsString()
  @IsOptional()
  name?: string

  @IsOptional()
  @IsString()
  photoId?: string

  @IsOptional()
  @IsString()
  description?: string

  @IsOptional()
  @IsInt({message: 'اولویت باید یک عدد صحیح باشد'})
  priority?: number

  @IsOptional()
  @IsEnum(BoxStatusEnum)
  status?: BoxStatusEnum

  @IsOptional()
  @IsDate()
  expiryDate?: Date

  @IsOptional()
  @IsBoolean()
  useCustomPrice?: boolean

  @IsOptional()
  @IsNumber()
  customPrice?: number

  constructor() {
    this.name = ''
    this.photoId = ''
    this.description = ''
    this.priority = 0
    this.status = '' as BoxStatusEnum
    this.expiryDate = new Date()
    this.customPrice = 0
    this.useCustomPrice = false
  }
}
