import {IsNotEmpty, IsNumber, IsOptional, IsString} from 'class-validator'

export class CreateTransactionToSubscriptionRequestDto {
  @IsOptional()
  @IsNumber()
  amount?: number

  @IsOptional()
  @IsString()
  cardNumber?: string

  @IsOptional()
  @IsString()
  trackingCode?: string

  @IsOptional()
  @IsString()
  photoId: string

  constructor() {
    this.amount = 0
    this.cardNumber = ''
    this.trackingCode = ''
    this.photoId = ''
  }
}
