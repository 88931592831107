import {Expose, Transform} from 'class-transformer'
import {IsEnum, IsOptional, IsPositive, IsString} from 'class-validator'
import {SortTypeEnum} from 'models'
import {PaginateRequestDto} from './paginate.request.dto'
import {
  DeliveryStateEnum,
  DeliveryStatusEnum,
  GetDeliveriesReportRequestDtoSortEnum,
  WeeklyPlanShiftEnum,
} from 'enums'

export class GetDeliveriesReportRequestDtoFiltersDto extends PaginateRequestDto {
  @IsOptional()
  @IsString()
  @Expose()
  'filters[specificDate]'?: string

  @IsOptional()
  @IsString()
  @Expose()
  'filters[fromDate]'?: string

  @IsOptional()
  @IsString()
  @Expose()
  'filters[untilDate]'?: string

  @IsOptional()
  @IsEnum(WeeklyPlanShiftEnum)
  @Expose()
  'filters[shift]'?: WeeklyPlanShiftEnum

  @IsOptional({each: true})
  @IsString({each: true})
  @Transform(({value}) => (value && typeof value === 'string' ? [value] : value || undefined))
  'filters[regionIds]'?: string[]

  @IsOptional({each: true})
  @IsString({each: true})
  @Transform(({value}) => (value && typeof value === 'string' ? [value] : value || undefined))
  'filters[courierIds]'?: string[]

  @IsOptional({each: true})
  @IsString({each: true})
  @Transform(({value}) => (value && typeof value === 'string' ? [value] : value || undefined))
  'filters[districtIds]'?: string[]

  @IsOptional({each: true})
  @IsString({each: true})
  @Transform(({value}) => (value && typeof value === 'string' ? [value] : value || undefined))
  'filters[customerIds]'?: string[]

  @IsOptional({each: true})
  @IsString({each: true})
  @Transform(({value}) => (value && typeof value === 'string' ? [value] : value || undefined))
  'filters[boxIds]'?: string[]

  @IsOptional()
  @IsEnum(DeliveryStateEnum)
  @Expose()
  'filters[deliveryState]'?: DeliveryStateEnum

  @IsOptional()
  @IsEnum(DeliveryStatusEnum)
  @Expose()
  'filters[deliveryStatus]'?: DeliveryStatusEnum

  @IsOptional()
  @IsString({message: 'شماره سرویس باید یک عدد مثبت باشد'})
  @IsPositive({message: 'شماره سرویس باید یک عدد مثبت باشد'})
  @Expose()
  'filters[serviceNumber]'?: string

  @IsEnum(SortTypeEnum)
  @IsOptional()
  @Expose()
  sortType?: SortTypeEnum

  @IsEnum(GetDeliveriesReportRequestDtoSortEnum)
  @IsOptional()
  @Expose()
  sort?: GetDeliveriesReportRequestDtoSortEnum

  @IsOptional()
  @IsString()
  search?: string
}
