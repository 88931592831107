import {FC} from 'react'
// Components
import {Typography} from 'components/elements/Typography'
// Utils
import {toPersianDigit} from 'utils/basic/digit'
// models
import {Line} from 'models'
// hooks
import useText from 'hooks/useText'
// Locals
import useStyles from './useStyles'
import {texts} from '../../texts'

type Props = {
  selected?: boolean
  line?: Line
}

const LineCard: FC<Props> = ({selected, line}) => {
  const {TX} = useText()
  const {classes} = useStyles()
  const renderLineDetails = () => {
    return (
      <>
        <Typography
          className='fw-light mt-2'
          type='h3'
          children={TX(texts.current_line, [toPersianDigit(line?.getLineNumber() || 0)])}
          color='#5C5C5C'
        />
        <Typography
          type='h5'
          className='fw-light mt-5'
          children={line?.getEmployee().getFullName()}
          color='#1A73E8'
        />
      </>
    )
  }

  return (
    <div className={[classes.wrapper, selected ? classes.selected : ''].join(' ')}>
      {renderLineDetails()}
    </div>
  )
}

export default LineCard
