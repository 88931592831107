import {DeliveryPlan, IDeliveryPlan} from './DeliveryPlan'
import {IRegion, Region} from './Region'
import {IRegionDeliveryPlanCourier, RegionDeliveryPlanCourier} from './RegionDeliveryPlanCourier'

export enum RegionDeliveryPlanStatusEnum {
  activate = 'activate',
  deactivate = 'deactivate',
}

export interface IRegionDeliveryPlan {
  id?: string
  createdAt?: string
  updatedAt?: string
  status?: RegionDeliveryPlanStatusEnum
  courierCapacity?: number
  deliveryPlanId?: string
  regionId?: string
  region?: IRegion
  deliveryPlan?: IDeliveryPlan
  regionDeliveryPlanCouriers?: IRegionDeliveryPlanCourier[]
}

export class RegionDeliveryPlan {
  public props: IRegionDeliveryPlan = {}

  constructor(data?: IRegionDeliveryPlan) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getStatus(): RegionDeliveryPlanStatusEnum {
    return this.props.status || RegionDeliveryPlanStatusEnum.deactivate
  }

  getRegionDeliveryPlanCouriers(): RegionDeliveryPlanCourier[] {
    return (this.props.regionDeliveryPlanCouriers || []).map(
      (regionDeliveryPlanCourier) => new RegionDeliveryPlanCourier(regionDeliveryPlanCourier)
    )
  }

  getCourierCapacity(): number {
    return this.props.courierCapacity || 0
  }

  getDeliveryPlanId(): string {
    return this.props.deliveryPlanId || ''
  }

  getRegionId(): string {
    return this.props.regionId || ''
  }

  getRegion(): Region {
    return new Region(this.props.region || {})
  }

  getDeliveryPlan(): DeliveryPlan {
    return new DeliveryPlan(this.props.deliveryPlan || {})
  }

  // custom
  getDisplayStatus(): string {
    return displayRegionDeliveryPlanStatusEnum(this.getStatus())
  }
}

export const displayRegionDeliveryPlanStatusEnum = (
  regionDeliveryPlanStatusEnum?: RegionDeliveryPlanStatusEnum
): string => {
  switch (regionDeliveryPlanStatusEnum) {
    case RegionDeliveryPlanStatusEnum.activate:
      return 'فعال'
    case RegionDeliveryPlanStatusEnum.deactivate:
      return 'غیر فعال'
    default:
      return ''
  }
}
