import * as faq from './faq'
import * as admins from './admins'
import * as auth from './auth'
import * as boxes from './boxes'
import * as couriers from './couriers'
import * as customers from './customers'
import * as dailyTarget from './daily-target'
import * as deliveryChains from './delivery-chains'
import * as employees from './employees'
import * as file from './file'
import * as inventory from './inventory'
import * as location from './location'
import * as packageTarget from './packaging-target'
import * as products from './products'
import * as quote from './quote'
import * as regions from './regions'
import * as scales from './scales'
import * as storage from './storage'
import * as subscriptions from './subscriptions'
import * as tickets from './tickets'
import * as transactions from './transactions'
import * as upload from './upload'
import * as varieties from './varieties'
import * as vendors from './vendors'
import * as packing from './packing'
import * as download from './download'
import * as sorting from './sorting'
import * as delivery from './delivery'
import * as coupon from './coupon'

export interface ServerError {
  statusCode: number
  message: string
}

const services = {
  sorting,
  faq,
  admins,
  auth,
  products,
  file,
  boxes,
  vendors,
  varieties,
  storage,
  location,
  upload,
  packageTarget,
  dailyTarget,
  quote,
  inventory,
  scales,
  employees,
  customers,
  regions,
  couriers,
  transactions,
  subscriptions,
  tickets,
  deliveryChains,
  packing,
  download,
  delivery,
  coupon,
}

export default services
