import {FC} from 'react'
import {Tooltip as MuiTooltip, TooltipProps} from '@mui/material'

// local
import {useStyles} from './style'

const Tooltip: FC<TooltipProps> = ({placement = 'top-end', children, ...props}) => {
  const {classes} = useStyles()

  return (
    <MuiTooltip
      slotProps={{
        arrow: {className: classes.arrowWrapper},
        tooltip: {className: classes.tooltipWrapper},
      }}
      arrow
      placement={placement}
      {...props}
    >
      {children}
    </MuiTooltip>
  )
}

export {Tooltip}
