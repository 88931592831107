import {lazy} from 'react'
import {Navigate, useRoutes} from 'react-router-dom'

import {
  // public
  LOGIN_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  FORGOT_PASSWORD_SUCCESS_ROUTE,
  FORGOT_PASSWORD_CONFIRM_ROUTE,
  CONFIRM_CODE_ROUTE,
  NOT_FOUND_ROUTE,

  // protected
  ADMIN_MANAGEMENT_ROUTE,
  DASHBOARD_ROUTE,
  SYSTEM_PREFERENCES_ROUTE,
  SUPPLIES_ROUTE,
  WEEKLY_PACKAGING_ROUTE,
  WEEKLY_PACKAGING_DETAILS_ROUTE,
  PURCHASE_ORDER_ROUTE,
  PURCHASE_ORDER_NEW_ORDER_ROUTE,
  PURCHASE_ORDER_DETAILS_ROUTE,
  PRODUCTS_ROUTE,
  PRODUCTS_DETAILS_ROUTE,
  VARIETY_LIST_ROUTE,
  VENDORS_ROUTE,
  VENDORS_DETAILS_ROUTE,
  WAREHOUSE_ROUTE,
  WAREHOUSE_WEIGHING_ROUTE,
  WAREHOUSE_PROCESSING_STATE_ROUTE,
  SCALES_ROUTE,
  REPORTS_ROUTE,
  WEEKLY_PACKAGING_DETAILS_PACKAGING_ROUTE,
  WEEKLY_PACKAGING_DETAILS_CUSTOMERS_ROUTE,
  WEEKLY_PACKAGING_DETAILS_DELIVERIES_ROUTE,
  WEEKLY_PACKAGING_DETAILS_DESCRIPTIONS_ROUTE,
  WEEKLY_PACKAGING_DETAILS_WEEKLY_REPORTS_ROUTE,
  WEEKLY_PACKAGING_DETAILS_PURCHASE_CHANGES_ROUTE,
  EDIT_BOXES_ROUTE,
  EDIT_BOXES_DETAILS_ROUTE,
  // FAQ_ROUTE,
  // FAQ_CATEGORY_DETAILS_ROUTE,
  // FAQ_DETAILS_ROUTE,
  ADMIN_MANAGEMENT_DETAILS_ROUTE,
  CHANGE_TEMPORARY_PASSWORD_ROUTE,
  SETTINGS_ROUTE,
  SORTING_ROUTE,
  EMPLOYEE_ROUTE,
  SORTING_PRODUCTS_ROUTE,
  SORTING_PRODUCT_DETAILS_ROUTE,
  SORTING_LINES_ROUTE,
  SORTING_LINE_DETAILS_ROUTE,
  SORTING_REPORTS_ROUTE,
  SUBSCRIBERS_LIST_ROUTE,
  SUBSCRIBERS_ROUTE,
  SUBSCRIBERS_LIST_ACTIVE_LIST_ROUTE,
  SUBSCRIBERS_LIST_DEACTIVE_LIST_ROUTE,
  SUBSCRIBERS_LIST_WAITING_LIST_ROUTE,
  SUBSCRIBERS_LIST_OUTSIDE_LIST_ROUTE,
  SUBSCRIBERS_ACTIVE_DETAILS_ROUTE,
  SUBSCRIBERS_DEACTIVE_DETAILS_ROUTE,
  SUBSCRIBERS_WAITING_DETAILS_ROUTE,
  SUBSCRIBERS_OUTSIDE_DETAILS_ROUTE,
  SUBSCRIBERS_OUTSIDE_DETAILS_PURCHASE_ROUTE,
  SUBSCRIBERS_ACTIVE_DETAILS_PURCHASE_ROUTE,
  SUBSCRIBERS_DEACTIVE_DETAILS_PURCHASE_ROUTE,
  SUBSCRIBERS_WAITING_DETAILS_PURCHASE_ROUTE,
  DELIVERY_ROUTE,
  DELIVERY_REGIONS_ROUTE,
  DELIVERY_REGIONS_DEFINE_ROUTE,
  DELIVERY_COURIERS_ROUTE,
  DELIVERY_COURIERS_LIST_ROUTE,
  DELIVERY_COURIERS_DETAILS_CURRENT_SERVICES_ROUTE,
  DELIVERY_COURIERS_DETAILS_ROUTE,
  DELIVERY_COURIERS_DETAILS_INFO_ROUTE,
  DELIVERY_COURIERS_DETAILS_PREVIOUS_SERVICES_ROUTE,
  DELIVERY_REPORTS_ROUTE,
  TRANSACTIONS_ROUTE,
  TRANSACTIONS_LIST_ROUTE,
  DELIVERY_CHAINS_ROUTE,
  SUPPORT_ROUTE,
  SUPPORT_TICKETS_ROUTE,
  SUPPORT_TICKETS_OPEN_TICKETS_ROUTE,
  SUPPORT_TICKETS_CLOSED_TICKETS_ROUTE,
  DELIVERY_CHAINS_DEFINE_ROUTE,
  SUBSCRIBERS_ACTIVE_DETAILS_TICKETS_ROUTE,
  SUBSCRIBERS_DEACTIVE_DETAILS_TICKETS_ROUTE,
  SUBSCRIBERS_ACTIVE_DETAILS_WALLET_ROUTE,
  SUBSCRIBERS_DEACTIVE_DETAILS_WALLET_ROUTE,
  SUBSCRIBERS_LIST_FULL_CAPACITY_LIST_ROUTE,
  SUBSCRIBERS_FULL_CAPACITY_DETAILS_ROUTE,
  SUBSCRIBERS_FULL_CAPACITY_DETAILS_PURCHASE_ROUTE,
  PACKAGING_ROUTE,
  START_PACKAGING_ROUTE,
  PACKAGING_LINES_ROUTE,
  PACKAGING_LINE_DETAILS_ROUTE,
  PACKAGING_LINE_REPORT_ROUTE,
  PRODUCTS_TYPES_LIST_ROUTE,
  SUBSCRIBERS_ACTIVE_DETAILS_PURCHASE_DETAILS_ROUTE,
  SUBSCRIBERS_DEACTIVE_DETAILS_PURCHASE_DETAILS_ROUTE,
  SUBSCRIBERS_WAITING_DETAILS_PURCHASE_DETAILS_ROUTE,
  SUBSCRIBERS_OUTSIDE_DETAILS_PURCHASE_DETAILS_ROUTE,
  SUBSCRIBERS_FULL_CAPACITY_DETAILS_PURCHASE_DETAILS_ROUTE,
  ORDER_HISTORY_ROUTE,
  ORDER_HISTORY_LIST_ROUTE,
  ORDER_HISTORY_DETAIL_ROUTE,
  ORDER_HISTORY_DETAIL_BOXES_ROUTE,
  ORDER_HISTORY_DETAIL_STATUS_ROUTE,
  ORDER_HISTORY_DETAIL_CUSTOMER_INFO_ROUTE,
  COUPON_ROUTE,
  COUPON_LIST_ROUTE,
  COUPON_USERS_USAGE_ROUTE,
  COUPON_CREATE_ROUTE,
  COUPON_DETAIL_ROUTE,
} from 'constants/routes'

import ProtectedRoute from './ProtectedRoute'
import PublicRoute from './PublicRoute'

import ProtectedLayout from 'pages/protected'
import PublicLayout from 'pages/public'

import {WeeklyReport} from 'pages/protected/ProductionLine/Supplies/WeeklyPackaging/components/WeeklyReport'
import TransactionsLayout from 'pages/protected/Support/Transactions'
import TransactionsList from 'pages/protected/Support/Transactions/list'
import StartPackaging from 'pages/protected/ProductionLine/Packaging/StartPackaging'
import PackingReports from 'pages/protected/ProductionLine/Packaging/Reports'
import CouponDetailsPage from 'pages/protected/Support/Coupon/details'

// public
const LoginPage = lazy(() => import('pages/public/Login'))
const ConfirmCodePage = lazy(() => import('pages/public/ConfirmCode'))
const ForgotPasswordLayout = lazy(() => import('pages/public/ForgotPassword'))
const ForgotPasswordPage = lazy(() => import('pages/public/ForgotPassword/main'))
const ForgotPasswordConfirmPage = lazy(() => import('pages/public/ForgotPassword/confirm'))
const ForgotPasswordSuccessPage = lazy(() => import('pages/public/ForgotPassword/success'))
const ChangeTemporaryPassword = lazy(() => import('pages/public/ChangeTemporaryPassword'))

const ErrorPage = lazy(() => import('pages/public/Error'))

// protected
const DashboardLayout = lazy(() => import('pages/protected/Dashboard'))
const SystemPreferencesLayout = lazy(() => import('pages/protected/SystemPreferences'))
// const FAQLayout = lazy(() => import('pages/protected/SystemPreferences/FAQ'))
// const FAQPage = lazy(() => import('pages/protected/SystemPreferences/FAQ/main'))
// const FAQDetailPage = lazy(() => import('pages/protected/SystemPreferences/FAQ/details'))
const AdminLayout = lazy(() => import('pages/protected/SystemPreferences/Admin'))
const SuppliesLayout = lazy(() => import('pages/protected/ProductionLine/Supplies'))
const AdminListPage = lazy(() => import('pages/protected/SystemPreferences/Admin/main'))
const AdminDetailsPage = lazy(() => import('pages/protected/SystemPreferences/Admin/details'))
const WeeklyArrangementLayout = lazy(
  () => import('pages/protected/ProductionLine/Supplies/WeeklyPackaging')
)
const WeeklyPackagingPage = lazy(
  () => import('pages/protected/ProductionLine/Supplies/WeeklyPackaging/main')
)
const WeeklyPackagingDetailsPage = lazy(
  () => import('pages/protected/ProductionLine/Supplies/WeeklyPackaging/details')
)
const CustomersPage = lazy(
  () => import('pages/protected/ProductionLine/Supplies/WeeklyPackaging/details/customers')
)
const DeliveriesPage = lazy(
  () => import('pages/protected/ProductionLine/Supplies/WeeklyPackaging/details/deliveries')
)
const DescriptionPage = lazy(
  () => import('pages/protected/ProductionLine/Supplies/WeeklyPackaging/details/description')
)
const PackagingPage = lazy(
  () => import('pages/protected/ProductionLine/Supplies/WeeklyPackaging/details/packaging')
)
const BoxesEditPage = lazy(() => import('pages/protected/ProductionLine/Supplies/Boxes'))
const PurchaseChangePage = lazy(
  () => import('pages/protected/ProductionLine/Supplies/WeeklyPackaging/details/purchase-change')
)
const PurchaseOrderLayout = lazy(
  () => import('pages/protected/ProductionLine/Supplies/PurchaseOrder')
)
const PurchaseOrderPage = lazy(
  () => import('pages/protected/ProductionLine/Supplies/PurchaseOrder/main')
)
const PurchaseOrderNewOrderPage = lazy(
  () => import('pages/protected/ProductionLine/Supplies/PurchaseOrder/order')
)
const PurchaseOrderDetailsPage = lazy(
  () => import('pages/protected/ProductionLine/Supplies/PurchaseOrder/details')
)
const ProductsLayout = lazy(() => import('pages/protected/ProductionLine/Supplies/Products'))
const ProductsPage = lazy(() => import('pages/protected/ProductionLine/Supplies/Products/main'))
const VarietiesPage = lazy(() => import('pages/protected/ProductionLine/Supplies/Varieties'))
const ProductsDetailsPage = lazy(
  () => import('pages/protected/ProductionLine/Supplies/Products/details')
)
const ProductTypesPage = lazy(() => import('pages/protected/ProductionLine/Supplies/ProductTypes'))
const VendorsLayout = lazy(() => import('pages/protected/ProductionLine/Supplies/Vendors'))
const VendorsPage = lazy(() => import('pages/protected/ProductionLine/Supplies/Vendors/main'))
const VendorsDetailsPage = lazy(
  () => import('pages/protected/ProductionLine/Supplies/Vendors/details')
)
const WarehouseLayout = lazy(() => import('pages/protected/ProductionLine/Warehouse'))
const WarehouseWeighingPage = lazy(
  () => import('pages/protected/ProductionLine/Warehouse/Weighing')
)
const WarehouseProcessStatusPage = lazy(
  () => import('pages/protected/ProductionLine/Warehouse/ProcessStatus')
)
const ScalesPage = lazy(() => import('pages/protected/ProductionLine/Warehouse/Scales/main'))
const ReportsPage = lazy(() => import('pages/protected/ProductionLine/Warehouse/Reports'))

const SortingLayout = lazy(() => import('pages/protected/ProductionLine/Sorting'))
const SortingProductsPages = lazy(() => import('pages/protected/ProductionLine/Sorting/Products'))
const SortingLinesPages = lazy(() => import('pages/protected/ProductionLine/Sorting/Lines/main'))
const SortingLineDetailsPages = lazy(
  () => import('pages/protected/ProductionLine/Sorting/Lines/Details')
)
const SortingReportsPage = lazy(() => import('pages/protected/ProductionLine/Sorting/Reports'))

const PackagingLayout = lazy(() => import('pages/protected/ProductionLine/Packaging'))
const PackagingLinesPages = lazy(() => import('pages/protected/ProductionLine/Packaging/Lines'))
const PackagingLineDetailsPages = lazy(
  () => import('pages/protected/ProductionLine/Packaging/Lines/Details')
)

const EmployeeLayout = lazy(() => import('pages/protected/SystemPreferences/Employees'))
const EmployeeList = lazy(() => import('pages/protected/SystemPreferences/Employees/main'))

const SettingsPage = lazy(() => import('pages/protected/Settings'))

const SubscribersLayout = lazy(() => import('pages/protected/Support/Subscribers'))
const SubscribersList = lazy(() => import('pages/protected/Support/Subscribers/list'))
const SubscribersListActiveList = lazy(
  () => import('pages/protected/Support/Subscribers/list/activeList')
)
const SubscribersListDeactiveList = lazy(
  () => import('pages/protected/Support/Subscribers/list/deactiveList')
)
const SubscribersListWaitingList = lazy(
  () => import('pages/protected/Support/Subscribers/list/waitingList')
)
const SubscribersListOutsideList = lazy(
  () => import('pages/protected/Support/Subscribers/list/outSideList')
)
const SubscribersListFullCapacityList = lazy(
  () => import('pages/protected/Support/Subscribers/list/fullCapacityList')
)
const SubscribersDetailsLayout = lazy(() => import('pages/protected/Support/Subscribers/details'))
const SubscribersDetailsPage = lazy(
  () => import('pages/protected/Support/Subscribers/details/purchase/main')
)
const SubscribersDetailsTicketsPage = lazy(
  () => import('pages/protected/Support/Subscribers/details/purchase/main/tickets')
)
const SubscribersDetailsWalletPage = lazy(
  () => import('pages/protected/Support/Subscribers/details/purchase/main/wallet')
)
const SubscribersDetailsPurchasePage = lazy(
  () => import('pages/protected/Support/Subscribers/details/purchase/details')
)
const SubscribersDetailsPurchaseDetailsPage = lazy(
  () => import('pages/protected/Support/Subscribers/details/purchase/details/details')
)
const OrderHistoryLayout = lazy(() => import('pages/protected/Support/OrderHistory'))
const OrderHistoryListsPage = lazy(() => import('pages/protected/Support/OrderHistory/list'))
const OrderHistoryDetailPage = lazy(() => import('pages/protected/Support/OrderHistory/details'))
const OrderHistoryDetailBoxesPage = lazy(
  () => import('pages/protected/Support/OrderHistory/details/boxes')
)
const OrderHistoryDetailStatusPage = lazy(
  () => import('pages/protected/Support/OrderHistory/details/status')
)
const OrderHistoryDetailCustomerInfoPage = lazy(
  () => import('pages/protected/Support/OrderHistory/details/customerInfo')
)

const DeliveryLayout = lazy(() => import('pages/protected/Delivery'))
const DeliveryRegionsPage = lazy(() => import('pages/protected/Delivery/Regions'))
const DeliveryChainsPage = lazy(() => import('pages/protected/Delivery/Chains'))
const DeliveryChainsDefinePage = lazy(() => import('pages/protected/Delivery/Chains/define'))
const DeliveryRegionDefinePage = lazy(() => import('pages/protected/Delivery/Regions/define'))
const DeliveryCouriersLayout = lazy(() => import('pages/protected/Delivery/Couriers'))
const DeliveryCouriersList = lazy(() => import('pages/protected/Delivery/Couriers/list'))
const DeliveryCouriersDetailsLayout = lazy(
  () => import('pages/protected/Delivery/Couriers/details')
)
const DeliveryCouriersDetailsInfo = lazy(
  () => import('pages/protected/Delivery/Couriers/details/info')
)
const DeliveryCouriersDetailsCurrentServices = lazy(
  () => import('pages/protected/Delivery/Couriers/details/currentServices')
)
const DeliveryCouriersDetailsPreviousServices = lazy(
  () => import('pages/protected/Delivery/Couriers/details/previousServices')
)
const DeliveryReportsList = lazy(() => import('pages/protected/Delivery/Reports'))

const SupportLayout = lazy(() => import('pages/protected/Support'))
const SupportTicketsLayout = lazy(() => import('pages/protected/Support/Tickets'))
const SupportTicketsOpenTickets = lazy(() => import('pages/protected/Support/Tickets/openTickets'))
const SupportTicketsClosedTickets = lazy(
  () => import('pages/protected/Support/Tickets/closedTickets')
)

const CouponLayout = lazy(() => import('pages/protected/Support/Coupon'))
const CouponListsPage = lazy(() => import('pages/protected/Support/Coupon/list'))
const CouponUsersUsagePage = lazy(() => import('pages/protected/Support/Coupon/usersUsage'))
const CreateCouponPage = lazy(() => import('pages/protected/Support/Coupon/create'))

export const Routes = () =>
  useRoutes([
    {
      path: '/',
      element: <ProtectedRoute />,
      children: [
        {
          path: '/',
          element: <ProtectedLayout />,
          children: [
            {
              path: DASHBOARD_ROUTE,
              element: <DashboardLayout />,
            },
            {
              path: SUPPLIES_ROUTE,
              element: <SuppliesLayout />,
              children: [
                {
                  path: WEEKLY_PACKAGING_ROUTE,
                  element: <WeeklyArrangementLayout />,
                  children: [
                    {
                      path: WEEKLY_PACKAGING_ROUTE,
                      element: <WeeklyPackagingPage />,
                    },
                    {
                      path: WEEKLY_PACKAGING_DETAILS_ROUTE,
                      element: <WeeklyPackagingDetailsPage />,
                      children: [
                        {
                          path: WEEKLY_PACKAGING_DETAILS_CUSTOMERS_ROUTE,
                          element: <CustomersPage />,
                        },
                        {
                          path: WEEKLY_PACKAGING_DETAILS_DELIVERIES_ROUTE,
                          element: <DeliveriesPage />,
                        },
                        {
                          path: WEEKLY_PACKAGING_DETAILS_WEEKLY_REPORTS_ROUTE,
                          element: <WeeklyReport />,
                        },
                        {
                          path: WEEKLY_PACKAGING_DETAILS_DESCRIPTIONS_ROUTE,
                          element: <DescriptionPage />,
                        },
                        {
                          path: WEEKLY_PACKAGING_DETAILS_PACKAGING_ROUTE,
                          element: <PackagingPage />,
                        },
                        {
                          path: WEEKLY_PACKAGING_DETAILS_PURCHASE_CHANGES_ROUTE,
                          element: <PurchaseChangePage />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: EDIT_BOXES_ROUTE,
                  element: <BoxesEditPage />,
                },
                {
                  path: EDIT_BOXES_DETAILS_ROUTE,
                  element: <BoxesEditPage />,
                },
                {
                  path: PURCHASE_ORDER_ROUTE,
                  element: <PurchaseOrderLayout />,
                  children: [
                    {
                      path: PURCHASE_ORDER_ROUTE,
                      element: <PurchaseOrderPage />,
                    },
                    {
                      path: PURCHASE_ORDER_NEW_ORDER_ROUTE,
                      element: <PurchaseOrderNewOrderPage />,
                    },
                    {
                      path: PURCHASE_ORDER_DETAILS_ROUTE,
                      element: <PurchaseOrderDetailsPage />,
                    },
                  ],
                },
                {
                  path: PRODUCTS_ROUTE,
                  element: <ProductsLayout />,
                  children: [
                    {
                      path: PRODUCTS_ROUTE,
                      element: <ProductsPage />,
                    },
                    {
                      path: PRODUCTS_TYPES_LIST_ROUTE,
                      element: <ProductTypesPage />,
                    },
                    {
                      path: PRODUCTS_DETAILS_ROUTE,
                      element: <ProductsDetailsPage />,
                    },
                  ],
                },
                {
                  path: VARIETY_LIST_ROUTE,
                  element: <VarietiesPage />,
                },
                {
                  path: VENDORS_ROUTE,
                  element: <VendorsLayout />,
                  children: [
                    {
                      path: VENDORS_ROUTE,
                      element: <VendorsPage />,
                    },
                    {
                      path: VENDORS_DETAILS_ROUTE,
                      element: <VendorsDetailsPage />,
                    },
                  ],
                },
              ],
            },
            {
              path: WAREHOUSE_ROUTE,
              element: <WarehouseLayout />,
              children: [
                {
                  path: WAREHOUSE_WEIGHING_ROUTE,
                  element: <WarehouseWeighingPage />,
                },
                {
                  path: WAREHOUSE_PROCESSING_STATE_ROUTE,
                  element: <WarehouseProcessStatusPage />,
                },
                {
                  path: SCALES_ROUTE,
                  element: <ScalesPage />,
                },
                {
                  path: REPORTS_ROUTE,
                  element: <ReportsPage />,
                },
                {
                  path: WAREHOUSE_ROUTE,
                  element: <Navigate to={WAREHOUSE_WEIGHING_ROUTE} />,
                },
              ],
            },
            {
              path: SORTING_ROUTE,
              element: <SortingLayout />,
              children: [
                {
                  path: SORTING_PRODUCTS_ROUTE,
                  element: <SortingProductsPages />,
                },
                {
                  path: SORTING_PRODUCT_DETAILS_ROUTE,
                  element: <SortingProductsPages />,
                },
                {
                  path: SORTING_LINES_ROUTE,
                  element: <SortingLinesPages />,
                },
                {
                  path: SORTING_LINE_DETAILS_ROUTE,
                  element: <SortingLineDetailsPages />,
                },
                {
                  path: SORTING_REPORTS_ROUTE,
                  element: <SortingReportsPage />,
                },
                {
                  path: SORTING_ROUTE,
                  element: <Navigate to={SORTING_PRODUCTS_ROUTE} />,
                },
              ],
            },
            {
              path: PACKAGING_ROUTE,
              element: <PackagingLayout />,
              children: [
                {
                  path: START_PACKAGING_ROUTE,
                  element: <StartPackaging />,
                },
                {
                  path: PACKAGING_LINES_ROUTE,
                  element: <PackagingLinesPages />,
                },
                {
                  path: PACKAGING_LINE_DETAILS_ROUTE,
                  element: <PackagingLineDetailsPages />,
                },
                {
                  path: PACKAGING_LINE_REPORT_ROUTE,
                  element: <PackingReports />,
                },
              ],
            },
            {
              path: SYSTEM_PREFERENCES_ROUTE,
              element: <SystemPreferencesLayout />,
              children: [
                // {
                //   path: FAQ_ROUTE,
                //   element: <FAQLayout />,
                //   children: [
                //     {
                //       path: FAQ_ROUTE,
                //       element: <FAQPage />,
                //     },
                //     {
                //       path: FAQ_CATEGORY_DETAILS_ROUTE,
                //       element: <FAQPage />,
                //     },
                //     {
                //       path: FAQ_DETAILS_ROUTE,
                //       element: <FAQDetailPage />,
                //     },
                //   ],
                // },
                {
                  path: ADMIN_MANAGEMENT_ROUTE,
                  element: <AdminLayout />,
                  children: [
                    {
                      path: ADMIN_MANAGEMENT_ROUTE,
                      element: <AdminListPage />,
                    },
                    {
                      path: ADMIN_MANAGEMENT_DETAILS_ROUTE,
                      element: <AdminDetailsPage />,
                    },
                  ],
                },
                {
                  path: EMPLOYEE_ROUTE,
                  element: <EmployeeLayout />,
                  children: [
                    {
                      path: EMPLOYEE_ROUTE,
                      element: <EmployeeList />,
                    },
                  ],
                },
                {
                  path: SYSTEM_PREFERENCES_ROUTE,
                  element: <Navigate to={ADMIN_MANAGEMENT_ROUTE} />,
                },
              ],
            },
            {
              path: SETTINGS_ROUTE,
              element: <SettingsPage />,
            },
            {
              path: SUBSCRIBERS_ROUTE,
              element: <SubscribersLayout />,
              children: [
                {
                  path: SUBSCRIBERS_LIST_ROUTE,
                  element: <SubscribersList />,
                  children: [
                    {
                      path: SUBSCRIBERS_LIST_ACTIVE_LIST_ROUTE,
                      element: <SubscribersListActiveList />,
                    },
                    {
                      path: SUBSCRIBERS_LIST_DEACTIVE_LIST_ROUTE,
                      element: <SubscribersListDeactiveList />,
                    },
                    {
                      path: SUBSCRIBERS_LIST_WAITING_LIST_ROUTE,
                      element: <SubscribersListWaitingList />,
                    },
                    {
                      path: SUBSCRIBERS_LIST_OUTSIDE_LIST_ROUTE,
                      element: <SubscribersListOutsideList />,
                    },
                    {
                      path: SUBSCRIBERS_LIST_FULL_CAPACITY_LIST_ROUTE,
                      element: <SubscribersListFullCapacityList />,
                    },
                    {
                      path: SUBSCRIBERS_LIST_ROUTE,
                      element: <Navigate replace to={SUBSCRIBERS_LIST_ACTIVE_LIST_ROUTE} />,
                    },
                  ],
                },
                {
                  path: SUBSCRIBERS_ACTIVE_DETAILS_ROUTE,
                  element: <SubscribersDetailsLayout />,
                  children: [
                    {
                      path: SUBSCRIBERS_ACTIVE_DETAILS_ROUTE,
                      element: <SubscribersDetailsPage />,
                    },
                    {
                      path: SUBSCRIBERS_ACTIVE_DETAILS_TICKETS_ROUTE,
                      element: <SubscribersDetailsTicketsPage />,
                    },
                    {
                      path: SUBSCRIBERS_ACTIVE_DETAILS_WALLET_ROUTE,
                      element: <SubscribersDetailsWalletPage />,
                    },
                  ],
                },
                {
                  path: SUBSCRIBERS_DEACTIVE_DETAILS_ROUTE,
                  element: <SubscribersDetailsLayout />,
                  children: [
                    {
                      path: SUBSCRIBERS_DEACTIVE_DETAILS_ROUTE,
                      element: <SubscribersDetailsPage />,
                    },
                    {
                      path: SUBSCRIBERS_DEACTIVE_DETAILS_TICKETS_ROUTE,
                      element: <SubscribersDetailsTicketsPage />,
                    },
                    {
                      path: SUBSCRIBERS_DEACTIVE_DETAILS_WALLET_ROUTE,
                      element: <SubscribersDetailsWalletPage />,
                    },
                  ],
                },
                {
                  path: SUBSCRIBERS_WAITING_DETAILS_ROUTE,
                  element: <SubscribersDetailsPage />,
                },
                {
                  path: SUBSCRIBERS_OUTSIDE_DETAILS_ROUTE,
                  element: <SubscribersDetailsPage />,
                },
                {
                  path: SUBSCRIBERS_FULL_CAPACITY_DETAILS_ROUTE,
                  element: <SubscribersDetailsPage />,
                },
                {
                  path: SUBSCRIBERS_ACTIVE_DETAILS_PURCHASE_ROUTE,
                  element: <SubscribersDetailsPurchasePage />,
                },
                {
                  path: SUBSCRIBERS_DEACTIVE_DETAILS_PURCHASE_ROUTE,
                  element: <SubscribersDetailsPurchasePage />,
                },
                {
                  path: SUBSCRIBERS_WAITING_DETAILS_PURCHASE_ROUTE,
                  element: <SubscribersDetailsPurchasePage />,
                },
                {
                  path: SUBSCRIBERS_OUTSIDE_DETAILS_PURCHASE_ROUTE,
                  element: <SubscribersDetailsPurchasePage />,
                },
                {
                  path: SUBSCRIBERS_FULL_CAPACITY_DETAILS_PURCHASE_ROUTE,
                  element: <SubscribersDetailsPurchasePage />,
                },
                {
                  path: SUBSCRIBERS_ACTIVE_DETAILS_PURCHASE_DETAILS_ROUTE,
                  element: <SubscribersDetailsPurchaseDetailsPage />,
                },
                {
                  path: SUBSCRIBERS_DEACTIVE_DETAILS_PURCHASE_DETAILS_ROUTE,
                  element: <SubscribersDetailsPurchaseDetailsPage />,
                },
                {
                  path: SUBSCRIBERS_WAITING_DETAILS_PURCHASE_DETAILS_ROUTE,
                  element: <SubscribersDetailsPurchaseDetailsPage />,
                },
                {
                  path: SUBSCRIBERS_OUTSIDE_DETAILS_PURCHASE_DETAILS_ROUTE,
                  element: <SubscribersDetailsPurchaseDetailsPage />,
                },
                {
                  path: SUBSCRIBERS_FULL_CAPACITY_DETAILS_PURCHASE_DETAILS_ROUTE,
                  element: <SubscribersDetailsPurchaseDetailsPage />,
                },
                {
                  path: SUBSCRIBERS_ROUTE,
                  element: <Navigate replace to={SUBSCRIBERS_LIST_ACTIVE_LIST_ROUTE} />,
                },
              ],
            },
            {
              path: SUPPORT_ROUTE,
              element: <SupportLayout />,
              children: [
                {
                  path: SUPPORT_TICKETS_ROUTE,
                  element: <SupportTicketsLayout />,
                  children: [
                    {
                      path: SUPPORT_TICKETS_OPEN_TICKETS_ROUTE,
                      element: <SupportTicketsOpenTickets />,
                    },
                    {
                      path: SUPPORT_TICKETS_CLOSED_TICKETS_ROUTE,
                      element: <SupportTicketsClosedTickets />,
                    },
                    {
                      path: SUPPORT_TICKETS_ROUTE,
                      element: <Navigate to={SUPPORT_TICKETS_OPEN_TICKETS_ROUTE} replace />,
                    },
                  ],
                },
                {
                  path: SUPPORT_ROUTE,
                  element: <Navigate to={SUPPORT_TICKETS_ROUTE} replace />,
                },
              ],
            },
            {
              path: TRANSACTIONS_ROUTE,
              element: <TransactionsLayout />,
              children: [
                {
                  path: TRANSACTIONS_LIST_ROUTE,
                  element: <TransactionsList />,
                },
                {
                  path: TRANSACTIONS_ROUTE,
                  element: <Navigate to={TRANSACTIONS_LIST_ROUTE} />,
                },
              ],
            },
            {
              path: ORDER_HISTORY_ROUTE,
              element: <OrderHistoryLayout />,
              children: [
                {
                  path: ORDER_HISTORY_LIST_ROUTE,
                  element: <OrderHistoryListsPage />,
                },
                {
                  path: ORDER_HISTORY_DETAIL_ROUTE,
                  element: <OrderHistoryDetailPage />,
                  children: [
                    {
                      path: ORDER_HISTORY_DETAIL_BOXES_ROUTE,
                      element: <OrderHistoryDetailBoxesPage />,
                    },
                    {
                      path: ORDER_HISTORY_DETAIL_STATUS_ROUTE,
                      element: <OrderHistoryDetailStatusPage />,
                    },
                    {
                      path: ORDER_HISTORY_DETAIL_CUSTOMER_INFO_ROUTE,
                      element: <OrderHistoryDetailCustomerInfoPage />,
                    },
                    {
                      path: ORDER_HISTORY_DETAIL_ROUTE,
                      element: <Navigate to={ORDER_HISTORY_DETAIL_BOXES_ROUTE} />,
                    },
                  ],
                },
                {
                  path: ORDER_HISTORY_ROUTE,
                  element: <Navigate to={ORDER_HISTORY_LIST_ROUTE} />,
                },
              ],
            },
            {
              path: COUPON_ROUTE,
              element: <CouponLayout />,
              children: [
                {
                  path: COUPON_LIST_ROUTE,
                  element: <CouponListsPage />,
                },
                {
                  path: COUPON_USERS_USAGE_ROUTE,
                  element: <CouponUsersUsagePage />,
                },
                {
                  path: COUPON_CREATE_ROUTE,
                  element: <CreateCouponPage />,
                },
                {
                  path: COUPON_DETAIL_ROUTE,
                  element: <CouponDetailsPage />,
                },
                {
                  path: COUPON_ROUTE,
                  element: <Navigate to={COUPON_LIST_ROUTE} />,
                },
              ],
            },
            {
              path: DELIVERY_ROUTE,
              element: <DeliveryLayout />,
              children: [
                {
                  path: DELIVERY_REPORTS_ROUTE,
                  element: <DeliveryReportsList />,
                },
                {
                  path: DELIVERY_REGIONS_ROUTE,
                  element: <DeliveryRegionsPage />,
                },
                {
                  path: DELIVERY_CHAINS_ROUTE,
                  element: <DeliveryChainsPage />,
                },
                {
                  path: DELIVERY_CHAINS_DEFINE_ROUTE,
                  element: <DeliveryChainsDefinePage />,
                },
                {
                  path: DELIVERY_REGIONS_DEFINE_ROUTE,
                  element: <DeliveryRegionDefinePage />,
                },
                {
                  path: DELIVERY_COURIERS_ROUTE,
                  element: <DeliveryCouriersLayout />,
                  children: [
                    {
                      element: <DeliveryCouriersList />,
                      path: DELIVERY_COURIERS_LIST_ROUTE,
                    },
                    {
                      element: <DeliveryCouriersDetailsLayout />,
                      path: DELIVERY_COURIERS_DETAILS_ROUTE,
                      children: [
                        {
                          path: DELIVERY_COURIERS_DETAILS_INFO_ROUTE,
                          element: <DeliveryCouriersDetailsInfo />,
                        },
                        {
                          path: DELIVERY_COURIERS_DETAILS_CURRENT_SERVICES_ROUTE,
                          element: <DeliveryCouriersDetailsCurrentServices />,
                        },
                        {
                          path: DELIVERY_COURIERS_DETAILS_PREVIOUS_SERVICES_ROUTE,
                          element: <DeliveryCouriersDetailsPreviousServices />,
                        },
                        {
                          path: DELIVERY_COURIERS_DETAILS_ROUTE,
                          element: <Navigate to={DELIVERY_COURIERS_DETAILS_INFO_ROUTE} replace />,
                        },
                      ],
                    },
                    {
                      path: DELIVERY_COURIERS_ROUTE,
                      element: <Navigate to={DELIVERY_COURIERS_LIST_ROUTE} replace />,
                    },
                  ],
                },
                {
                  path: DELIVERY_ROUTE,
                  element: <Navigate replace to={DELIVERY_REGIONS_ROUTE} />,
                },
              ],
            },

            {
              path: '/',
              element: <Navigate to={DASHBOARD_ROUTE} />,
            },
          ],
        },
      ],
    },
    {
      path: '/',
      element: <PublicRoute />,
      children: [
        {
          path: '/',
          element: <PublicLayout />,
          children: [
            {
              path: LOGIN_ROUTE,
              element: <LoginPage />,
            },
            {
              path: FORGOT_PASSWORD_ROUTE,
              element: <ForgotPasswordLayout />,
              children: [
                {
                  path: FORGOT_PASSWORD_ROUTE,
                  element: <ForgotPasswordPage />,
                },
                {
                  path: FORGOT_PASSWORD_SUCCESS_ROUTE,
                  element: <ForgotPasswordSuccessPage />,
                },
                {
                  path: FORGOT_PASSWORD_CONFIRM_ROUTE,
                  element: <ForgotPasswordConfirmPage />,
                },
              ],
            },
            {
              path: CONFIRM_CODE_ROUTE,
              element: <ConfirmCodePage />,
            },
            {
              path: CHANGE_TEMPORARY_PASSWORD_ROUTE,
              element: <ChangeTemporaryPassword />,
            },
            {
              path: '/',
              element: <Navigate to={LOGIN_ROUTE} />,
            },
          ],
        },
      ],
    },
    {
      path: NOT_FOUND_ROUTE,
      element: <ErrorPage />,
    },
  ])
