import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  confirmAddress: 'pages.protected.purchase.new.order.confirm_address',
  welcomeToSarsabad: 'pages.protected.purchase.new.order.welcome_to_sarsabad',
  enterYourAddress: 'pages.protected.purchase.new.order.enter_your_address_to_view_basic_products',
  confirmLocation: 'pages.protected.purchase.new.order.confirm_location',
  editLocation: 'pages.protected.purchase.new.order.edit_location',
  address: 'pages.protected.purchase.new.order.address',
  infoFillAddress: 'pages.protected.purchase.new.order.info_fill_address',
  address_name: 'pages.protected.purchase.new.order.address_name',
  address_name_placeholder: 'pages.protected.purchase.new.order.address_name_placeholder',
  province: 'pages.protected.purchase.new.order.province',
  city: 'pages.protected.purchase.new.order.city',
  district: 'pages.protected.purchase.new.order.district',
  street: 'pages.protected.purchase.new.order.street',
  street_two: 'pages.protected.purchase.new.order.street_two',
  house_number: 'pages.protected.purchase.new.order.house_number',
  unit_number: 'pages.protected.purchase.new.order.unit_number',
  description: 'pages.protected.purchase.new.order.description',
  description_optional: 'pages.protected.purchase.new.order.description_optional',
  please_wait_for_us: 'pages.protected.purchase.new.order.please_wait_for_us',
  thanks_for_choosing_sarsabad: 'pages.protected.purchase.new.order.thanks_for_choosing_sarsabad',
  we_will_text_you: 'pages.protected.purchase.new.order.we_will_text_you',
  understood: 'pages.protected.purchase.new.order.understood',
  search: "components.elements.MapPin.search",
  not_have_shipping_in_this_area_yet: "components.elements.MapPin.not_have_shipping_in_this_area_yet!",
  city_is_require: "components.elements.MapPin.city_is_require",
  is_not_valid: "components.elements.MapPin.is_not_valid",
  name_is_require: "components.elements.MapPin.name_is_require",
  province_is_require: "components.elements.MapPin.province_is_require",
  streetOne_is_require: "components.elements.MapPin.streetOne_is_require",
}
