import {IDelivery, IBox, IPackingTarget, Delivery, Box, PackingTarget} from 'models'
import {DeliveryBoxPacking, IDeliveryBoxPacking} from './DeliveryBoxPacking'

export interface IDeliveryBox {
  id?: string
  createdAt?: string
  updatedAt?: string
  quantity?: number
  price?: number
  deliveryId?: string
  boxId?: string
  packingTargetId?: string
  packingDeliveryBoxId?: string
  packingDeliveryBox?: IDeliveryBoxPacking
  delivery?: IDelivery
  isExtra?: boolean
  box?: IBox
  packingTarget?: IPackingTarget
}

export class DeliveryBox {
  public props: IDeliveryBox = {}

  constructor(data?: IDeliveryBox) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getQuantity(): number {
    return this.props.quantity || 0
  }

  getDeliveryId(): string {
    return this.props.deliveryId || ''
  }

  getBoxId(): string {
    return this.props.boxId || ''
  }

  getPrice(): number {
    return this.props.price || 0
  }

  getPackagingTargetId(): string {
    return this.props.packingTargetId || ''
  }

  getDelivery(): Delivery {
    return new Delivery(this.props.delivery || {})
  }

  getBox(): Box {
    return new Box(this.props.box || {})
  }

  getPackingTarget(): PackingTarget {
    return new PackingTarget(this.props.packingTarget || {})
  }

  getPackingDeliveryBoxId(): string {
    return this.props.packingDeliveryBoxId || ''
  }

  getPackingDeliveryBox(): DeliveryBoxPacking {
    return new DeliveryBoxPacking(this.props.packingDeliveryBox)
  }

  getIsExtra(): boolean {
    return !!this.props.isExtra
  }
}
