import {Controller} from 'react-hook-form'
// components
import {Textfield} from 'components/elements/Textfield'
import {Modal} from 'components/elements/Modal'
import {Button} from 'components/elements/Button'
import {AutoComplete} from 'components/elements/AutoComplete'
// models
import {City, Province} from 'models'
// local
import {useData} from './useData'
import {texts} from './texts'

type Props = {
  show: boolean
  handleClose: () => void
}

const CreateVendorModal = ({show, handleClose}: Props) => {
  const {
    onSubmit,
    control,
    watch,
    setValue,
    isSubmitting,
    errors,
    isValid,
    isLoadingProvince,
    isLoadingCities,
    cities,
    provinces,
    TX,
    isLoadingMutate,
  } = useData(show, handleClose)

  return (
    <Modal
      id='create-seller-modal'
      show={show}
      handleClose={handleClose}
      title={TX(texts.create_seller_title)}
    >
      <div className='d-flex flex-column flex-xl-row flex-row-fluid'>
        <div className='flex-row-fluid py-lg-5 px-lg-15'>
          <form onSubmit={onSubmit} encType='multipart/form-data'>
            <div className='w-100'>
              <div className='d-flex flex-row justify-content-center gap-5 w-100 mb-5'>
                <Controller
                  name='firstName'
                  control={control}
                  render={({field}) => (
                    <Textfield
                      {...field}
                      label={TX(texts.first_name)}
                      type='text'
                      error={!!errors.firstName}
                      touched={!!errors.firstName?.message}
                      errorText={errors.firstName?.message}
                    />
                  )}
                />
                <Controller
                  name='lastName'
                  control={control}
                  render={({field}) => (
                    <Textfield
                      {...field}
                      label={TX(texts.last_name)}
                      type='text'
                      error={!!errors.lastName}
                      touched={!!errors.lastName?.message}
                      errorText={errors.lastName?.message}
                    />
                  )}
                />
                <Controller
                  name='mobileNumber'
                  control={control}
                  render={({field}) => (
                    <Textfield
                      {...field}
                      label={TX(texts.phone_number)}
                      type='phone-number'
                      name='mobileNumber'
                      error={!!errors.mobileNumber}
                      touched={!!errors.mobileNumber?.message}
                      errorText={errors.mobileNumber?.message}
                    />
                  )}
                />
              </div>
              <div className='d-flex flex-row justify-content-center gap-5 w-100 my-5 mb-5'>
                <Controller
                  name='provinceId'
                  control={control}
                  render={({field}) => (
                    <AutoComplete
                      {...field}
                      loading={isLoadingProvince}
                      options={provinces.map((option: Province) => ({
                        value: option.getId(),
                        label: option.getLabel(),
                      }))}
                      label={TX(texts.province)}
                      value={watch().provinceId}
                      onChange={(option: {value: string; label: string} | any) =>
                        setValue('provinceId', option ? option.value : '')
                      }
                    />
                  )}
                />
                <Controller
                  name='cityId'
                  control={control}
                  render={({field}) => (
                    <AutoComplete
                      {...field}
                      loading={isLoadingCities}
                      disabled={!watch().provinceId}
                      options={cities.map((option: City) => ({
                        value: option.getId(),
                        label: option.getLabel(),
                      }))}
                      label={TX(texts.city)}
                      value={watch().cityId}
                      onChange={(option: {value: string; label: string} | any) =>
                        setValue('cityId', option ? option.value : '')
                      }
                    />
                  )}
                />
              </div>
              <div className='d-flex flex-row justify-content-center gap-5 w-100 my-5 mb-10'>
                <Controller
                  name='address'
                  control={control}
                  render={({field}) => (
                    <Textfield
                      {...field}
                      label={TX(texts.address)}
                      type='text'
                      error={!!errors.address}
                      touched={!!errors.address?.message}
                      errorText={errors.address?.message}
                    />
                  )}
                />
              </div>
            </div>
            <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
              <Button
                type='button'
                className='main-red-background btn btn-lg background-inherit justify-content-center'
                onClick={handleClose}
              >
                {TX(texts.cancel)}
              </Button>

              <Button
                type='submit'
                isLoading={isLoadingMutate}
                disabled={isSubmitting || !isValid}
                className='btn btn-lg justify-content-center btn-primary main-gradient-background w-25'
                text={TX(texts.submit)}
              ></Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default CreateVendorModal
