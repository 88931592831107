import 'reflect-metadata'
import {createRoot} from 'react-dom/client'
import {Chart, registerables} from 'chart.js'

import './styles/css/pallet.css'
import './styles/css/backgrounds.css'
import './styles/css/style.rtl.css'

import {App} from 'App'

Chart.register(...registerables)

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(<App />)
}
