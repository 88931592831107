import {FC} from 'react'

// components
import {Typography} from 'components/elements/Typography'

// models
import {Address} from 'models'

// utils
import {KTSVG} from 'utils/template/helpers'

//hooks
import useText from 'hooks/useText'

//locals
import {texts} from './texts'
import useData from './useData'
import {Checkbox} from 'components/elements/Checkbox'
import {useStyles} from './styles'
import {Box} from '@mui/material'

export type AddressCardProps = {
  active?: boolean
  activedAddress?: string
  onEditClick?: () => void
  onSuccess: () => void
  address: Address
  customerId: string
}

const AddressCard: FC<AddressCardProps> = ({
  active = false,
  activedAddress = '',
  onEditClick = () => null,
  address = new Address(),
  onSuccess = () => undefined,
  customerId,
}) => {
  const {TX} = useText()
  const data = useData({address, activedAddress, onSuccess, customerId})
  const {classes} = useStyles()

  const renderDetails = () => (
    <Box>
      <Typography className='fw-normal mt-2' type='h3' color='#00461C'>
        {address.getName()}
      </Typography>
      <Typography type='h5' className='fw-normal mt-5' color='#00461C'>
        {address.getDistrict().getLabel()}
      </Typography>
      <Typography type='h5' className='fw-light' color='#029C45'>
        {address.getFullAddress()}
      </Typography>
    </Box>
  )

  const renderCheckboxActiveAddress = () => {
    return (
      <Box className={classes.checkboxStatusAddressWrapper}>
        <Checkbox
          label={active ? TX(texts.active_address_label) : ''}
          disabled={data.isLoading}
          value={active}
          onClick={async () => await data.mutateAsync()}
        />
      </Box>
    )
  }

  return (
    <div
      className={[
        classes.addressCardWrapper,
        'card d-flex flex-column align-items-center justify-center w-175px min-w-175px p-3 border-radius-12px',
      ].join(' ')}
      style={{
        backgroundColor: active ? '#bbf0a4' : '#f7deaf',
      }}
    >
      {renderCheckboxActiveAddress()}
      {renderDetails()}
      <div
        onClick={(e) => {
          e.stopPropagation()
          onEditClick()
        }}
        className='d-flex flex-row justify-between mt-auto cursor-pointer'
      >
        <KTSVG
          className='svg-icon-1 main-green'
          svgClassName='mh-100px h-35px'
          path='/media/icons/duotune/art/replace-circle-sarsabad.svg'
        />
        <Typography className='fw-normal' type='h5' color='#1A73E8'>
          {TX(texts.edit)}
        </Typography>
      </div>
    </div>
  )
}

export {AddressCard}
