export enum SubscriptionStatusEnum {
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
  expired = 'expired',
  delivered = 'delivered',
  replace = 'replace',
  refund = 'refund',
  cancel = 'cancel',
  failed = 'failed',
}

export enum GetSubscriptionsRequestDtoSortEnum {
  createdAt = 'subscription.createdAt',
  totalPrice = 'subscription.totalPrice',
}
