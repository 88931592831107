import {useState} from 'react'
import {useQuery} from 'react-query'
import {useSnackbar} from 'notistack'
// services
import services, {ServerError} from 'services'
// models
import {ContentBox, SortingProductBox} from 'models'
// utils
import {removeEmptyValues} from 'utils/basic/object'

const useData = (content: ContentBox) => {
  const {enqueueSnackbar} = useSnackbar()
  const [checked, setChecked] = useState<boolean>(
    content.getPackingDeliveryBoxProducts()[0]?.getStatus() === 'finish'
  )
  const [selectedProductId, setSelectedProductId] = useState<string | undefined>()
  const [sortingProducts, setSortingProducts] = useState<SortingProductBox[]>([])

  /* ------------------------------------------------------------ */
  /*                           Services                           */
  /* ------------------------------------------------------------ */

  const getSortingProductQueryFunction = async () =>
    await services.packing.getSortingProductBoxes(
      removeEmptyValues({
        getAll: true,
        'filters[productId]': content.getProduct().getId(),
        'filters[unitQuantity]': !!content.getProductInstanceId()
          ? content.getProductInstance().getQuantity()
          : content.getQuantity(),
      })
    )

  /* ------------------------------------------------------------ */
  /*                           Queries                            */
  /* ------------------------------------------------------------ */

  const {isLoading, isFetching} = useQuery(
    ['get-sorting-products', content.getProduct().getId()],
    getSortingProductQueryFunction,
    {
      refetchOnWindowFocus: false,

      onError: (error: ServerError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      },

      onSuccess: (data) => {
        setSortingProducts(
          data.map((sortingProductBox) => new SortingProductBox(sortingProductBox))
        )
      },
    }
  )

  return {
    checked,
    setChecked,
    loading: isLoading || isFetching,
    sortingProducts,
    selectedProductId,
    setSelectedProductId,
    enqueueSnackbar,
  }
}

export default useData
