import {useLayoutEffect} from 'react'
import {generatePath, useNavigate, useParams} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {useMutation, useQuery, useQueryClient} from 'react-query'
//constants
import {COUPON_DETAIL_ROUTE, COUPON_LIST_ROUTE} from 'constants/routes'
// services
import services, {ServerError} from 'services'
//hooks
import useText from 'hooks/useText'
import useProtectedLayout from 'hooks/useProtectedLayout'
// dtos
import {UpdateCouponRequestDto} from 'dtos/update-coupon-request.dto'
// models
import {Coupon} from 'models'
// locals
import {texts} from './texts'

export const useData = () => {
  const {setConfig} = useProtectedLayout()
  const {enqueueSnackbar} = useSnackbar()
  const {TX} = useText()
  const navigate = useNavigate()
  const {couponId = ''} = useParams()
  const queryClient = useQueryClient()

  /* ------------------------------------------------------------ */
  /*                           Services                           */
  /* ------------------------------------------------------------ */

  const handleUpdateCoupon = async (body: UpdateCouponRequestDto) =>
    await services.coupon.updateCoupon(couponId, body)

  const handleGetCouponDetails = async () => await services.coupon.getCouponDetails(couponId)

  /* ------------------------------------------------------------ */
  /*                           Queries                            */
  /* ------------------------------------------------------------ */

  const {data, isLoading, refetch} = useQuery(['couponDetails', couponId], handleGetCouponDetails, {
    enabled: !!couponId,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* ------------------------------------------------------------ */
  /*                         Mutations                            */
  /* ------------------------------------------------------------ */

  const {isLoading: isLoadingUpdate, mutateAsync} = useMutation(handleUpdateCoupon, {
    onSuccess: async (data) => {
      enqueueSnackbar(TX(texts.success_creation_coupon_edit), {
        variant: 'success',
      })
      navigate(COUPON_LIST_ROUTE)
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* ------------------------------------------------------------ */
  /*                          Handlers                            */
  /* ------------------------------------------------------------ */

  const handleSubmit = async (value: UpdateCouponRequestDto) => {
    await mutateAsync(value)
    await refetch()
    await queryClient.refetchQueries('get-coupon-list')
  }

  /* ------------------------------------------------------------ */
  /*                         LifeCycles                           */
  /* ------------------------------------------------------------ */

  useLayoutEffect(() => {
    setConfig({
      pageName: [TX(texts.coupons_title)],
      pageDescription: [],
      toolbarType: 'none',
      breadcrumbsItems: [
        {
          title: TX(texts.coupons_title),
          path: COUPON_LIST_ROUTE,
          isActive: false,
          isSeparator: false,
        },
        {
          title: TX(texts.title_edit_coupon),
          path: COUPON_LIST_ROUTE,
          isActive: false,
          isSeparator: true,
        },
        {
          title: TX(texts.title_edit_coupon),
          path: generatePath(COUPON_DETAIL_ROUTE, {couponId}),
          isActive: true,
          isSeparator: false,
        },
      ],
    })
  }, [setConfig, TX])

  return {handleSubmit, isLoading, coupon: new Coupon(data), isLoadingUpdate}
}
