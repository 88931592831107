import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {},
    cropperContainer: {
      position: 'relative',
      width: 400,
      height: 316,
    },
    cropperSlider: {
      height: '46px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '70%',
      margin: '0 auto',
    },
    cropperActions: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    viewerContainer: {
      paddingBottom: '50px',
      width: 400,
    },
    viewerHeader: {},
    viewerImage: {
      margin: '0 auto',
    },
    viewerActions: {
      display: 'flex',
      gap: theme.spacing(3),
      width: '100%',
    },
    buttonContainer: {
      width: '72px',
    },
    button: {
      width: '100%',
      height: '84px',
      borderRadius: '10px',
    },
    circularButton: {
      borderRadius: '50%',
      overflow: 'hidden',
    },
    buttonImage: {
      backgroundColor: theme.palette.primary.main,
      width: '100%',
      height: '100%',
      borderRadius: '10px',
    },
    buttonShowImage: {
      backgroundColor: 'transparent',
      width: '100%',
      height: '100%',
      borderRadius: '10px',
    },
    title: {
      marginTop: theme.spacing(0.5),
      cursor: 'pointer',
    },
    disableButtonContainer: {
      opacity: 0.5,
    },
    disableTitle: {
      cursor: 'default',
    },
    masked: {
      position: 'fixed',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(70, 70, 70, 0.9)',
      zIndex: '9999',
      color: '#ffffff',
      top: 0,
      right: 0,
    },
  }
})
