import {Controller} from 'react-hook-form'
// components
import {Textfield} from 'components/elements/Textfield'
import {Button} from 'components/elements/Button'
import {JalaliDatePicker} from 'components/elements/JalaliCalendar'
import {Checkbox} from 'components/elements/Checkbox'
import {Textarea} from 'components/elements/Textarea'
// dtos
import {UpdateCouponRequestDto} from 'dtos/update-coupon-request.dto'
// enums
import {CouponStatusEnum} from 'enums'
// models
import {Coupon} from 'models'
// hooks
import useText from 'hooks/useText'
// locals
import {useData} from './useData'
import {texts} from './texts'

export interface ICouponData {
  coupon?: Coupon
}

interface IUpdateCoupon extends ICouponData {
  onSubmit: (value: UpdateCouponRequestDto) => Promise<void>
  isLoading?: boolean
  isLoadingUpdate?: boolean
}

export const UpdateCoupon: React.FC<IUpdateCoupon> = ({
  coupon = new Coupon(),
  onSubmit,
  isLoading = false,
  isLoadingUpdate = false,
}) => {
  const data = useData({coupon})
  const {TX} = useText()

  const renderFields = () => (
    <div className='row gx-9 gy-6'>
      <div className='col-3'>
        <Textfield
          label={TX(texts.coupon_code)}
          type='text'
          name='code'
          autoComplete='off'
          value={coupon.getCode()}
          disabled={true}
        />
      </div>

      <div className='col-3'>
        <Textfield
          label={TX(texts.price)}
          type='text'
          name='amountInTooman'
          autoComplete='off'
          value={coupon.getAmountInTooman().toString()}
          disabled={true}
        />
      </div>
      <div className='col-3'>
        <Textfield
          label={TX(texts.coupon_number)}
          type='text'
          name='usageLimit'
          autoComplete='off'
          value={coupon.getUsageLimit().toString()}
          disabled={true}
        />
      </div>
      <div className='col-3'>
        <Controller
          name='expiryDate'
          control={data.control}
          render={({field}) => (
            <JalaliDatePicker
              {...field}
              name='expiryDate'
              label={TX(texts.expiry_date)}
              error={!!data.errors.expiryDate}
              errorText={data.errors.expiryDate?.message as string}
              onChange={data.handleChangeExpiryDate}
              disabled={isLoading || isLoadingUpdate}
              minDate={new Date()}
            />
          )}
        />
      </div>
      <div className='col-12'>
        <Controller
          name='description'
          control={data.control}
          render={({field}) => (
            <Textarea
              {...field}
              name='description'
              label={TX(texts.description)}
              className='form-control form-control-lg form-control-solid'
              autoComplete='off'
              disabled={isLoading || isLoadingUpdate}
            />
          )}
        />
      </div>
      <div className='col-12'>
        <Controller
          name='status'
          control={data.control}
          render={({field}) => (
            <Checkbox
              {...field}
              label={TX(texts.activate_coupon)}
              name='status'
              disabled={isLoading || isLoadingUpdate}
              value={data.watch('status') === CouponStatusEnum.activated}
              onChange={data.handleSetStatusValue}
            />
          )}
        />
      </div>

      <div className='col-12'>
        <Checkbox
          label={TX(texts.select_new_users)}
          name='forRegistration'
          value={coupon.getForRegistration()}
          disabled
        />
      </div>
    </div>
  )

  const renderActions = () => (
    <div className='d-flex justify-content-end py-9 gap-6'>
      <Button
        style={{minWidth: '150px'}}
        text={TX(texts.edit)}
        isLoading={isLoading || isLoadingUpdate}
        className='btn btn-lg btn-primary main-gradient-background justify-content-center'
        type='submit'
        disabled={isLoading || isLoadingUpdate}
      />

      <Button
        style={{minWidth: '150px'}}
        text={TX(texts.cancel)}
        className='main-red-background btn btn-lg background-inherit justify-content-center'
        onClick={data.handleCancel}
        type='button'
      />
    </div>
  )

  return (
    <form onSubmit={data.handleSubmit(onSubmit)} className='px-9'>
      {renderFields()}
      {renderActions()}
    </form>
  )
}
