import {Expose, Type} from 'class-transformer'
import {IsArray, IsNumber, IsString, ValidateNested} from 'class-validator'

export class DeliveryPriorityInDeliveryChain {
  @IsString({message: 'زنجیره ارسال الزامی است'})
  deliveryId: string

  @IsNumber()
  priority: number

  constructor() {
    this.deliveryId = ''
    this.priority = 0
  }
}

export class UpdatePriorityDeliveriesDeliveryChainRequestDto {
  @IsArray()
  @ValidateNested()
  @Expose()
  @Type(() => DeliveryPriorityInDeliveryChain)
  deliveriesPriority: DeliveryPriorityInDeliveryChain[]

  constructor() {
    this.deliveriesPriority = []
  }
}
