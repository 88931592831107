import {Admin, User} from 'models'
import {createContext} from 'react'

export interface IUserContext {
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
  admin: Admin
  setAdmin: React.Dispatch<React.SetStateAction<Admin>>
  token: string
  setToken: React.Dispatch<React.SetStateAction<string>>
  isLogin: boolean
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  getUserInfo: () => Promise<void>
  logout: () => void
}

export default createContext<IUserContext>({
  user: new User(),
  setUser: () => {},
  admin: new Admin(),
  setAdmin: () => {},
  token: '',
  setToken: () => {},
  isLogin: false,
  setIsLogin: () => {},
  isLoading: false,
  getUserInfo: () => new Promise(() => {}),
  logout: () => {},
})
