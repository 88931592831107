import {createContext} from 'react'

export interface IToolbarRefetchContext {
  isRefetchOn: boolean
  setIsRefetchOn: React.Dispatch<React.SetStateAction<boolean>>
}

export default createContext<IToolbarRefetchContext>({
  isRefetchOn: false,
  setIsRefetchOn: () => {},
})
