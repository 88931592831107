import {IsBoolean, IsOptional, IsString} from 'class-validator'

export class UpdateProductTypeRequestDto {
  @IsString()
  @IsOptional()
  name?: string

  @IsOptional()
  @IsString()
  faName?: string

  @IsOptional()
  @IsBoolean()
  isActive?: boolean

  constructor() {
    this.name = ''
    this.faName = ''
    this.isActive = true
  }
}
