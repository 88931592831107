import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    borderRadius: '1rem',
    transition: 'all .2s',
    cursor: 'pointer',
    border: '.2rem solid transparent',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '.5rem 2rem',
    background: 'rgb(255, 243, 224)',
    zIndex: '10',
  },

  selected: {
    borderColor: '#029C45',
  },
}))

export default useStyles
