export enum WeighingSequenceStatusEnum {
  pending = 'pending',
  accepted = 'accepted',
}

export enum MeasurementUnit {
  PERCENT = 'percent',
  KILOGRAM = 'kilogram',
}

export enum OrderLineStateEnum {
  purchased = 'purchased',
  in_weighing = 'in_weighing',
  weighed = 'weighed',
  in_sorting = 'in_sorting',
  sorted = 'sorted',
  in_packaging = 'in_packaging',
  packaged = 'packaged',
}

export enum GetOrderLinesReportRequestDtoSortEnum {
  orderLineCreatedAt = 'orderLine.createdAt',
}

export enum BarcodeContextEnum {
  inventory_arrived = 'inventory_arrived',
  inventory_waste = 'inventory_waste',
  inventory_remained = 'inventory_remained',
  sorting = 'sorting',
  packaging = 'packaging',
}
