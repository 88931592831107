import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    gap: '1.2rem',
    padding: '1rem',
    // background: 'rgb(255, 243, 224)',
    border: '.1rem solid #7D7D7D66',
    borderRadius: '.5rem',
  },

  descriptionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '12rem',
    flexWrap: 'nowrap',
    gap: '.5rem',
  },

  autoComplete: {
    minWidth: '18rem',
  },
}))

export default useStyles
