import {BarcodeContextEnum} from 'enums'
import {IOrderLine, OrderLine} from './OrderLine'

export interface IBarcode {
  id?: string
  createdAt?: string
  updatedAt?: string
  barcode?: string
  context?: BarcodeContextEnum
  isActive?: boolean
  orderLineId?: string
  orderLine?: IOrderLine
}

export class Barcode {
  public props: IBarcode = {}

  constructor(data?: IBarcode) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getBarcode(): string {
    return this.props.barcode || ''
  }

  getContext(): BarcodeContextEnum {
    return this.props.context ? this.props.context : BarcodeContextEnum.sorting
  }

  getOrderLineId(): string {
    return this.props.orderLineId || ''
  }

  getOrderLine(): OrderLine {
    return new OrderLine(this.props.orderLine || {})
  }

  //custom

  getDisplayStatus(): string {
    return displayBarcodeContext(this.getContext())
  }
}

export const displayBarcodeContext = (scaleStatus: BarcodeContextEnum): string => {
  switch (scaleStatus) {
    case BarcodeContextEnum.inventory_arrived:
      return 'تحویل گرفته شده'
    case BarcodeContextEnum.inventory_remained:
      return 'ته انباری'
    case BarcodeContextEnum.inventory_waste:
      return 'دور ریز'
    case BarcodeContextEnum.packaging:
      return 'بسنه بندی شده'
    case BarcodeContextEnum.sorting:
      return 'سواسازی شده'
    default:
      return '-'
  }
}
