import {
  IsDate,
  IsEnum,
  IsNotEmpty,
  IsNumberString,
  IsOptional,
  IsPhoneNumber,
  IsString,
  Length,
} from 'class-validator'
import {CustomerStatusEnum} from 'enums'

export class UpdateCustomerRequestDto {
  @IsString({message: 'نام الزامی است'})
  @IsNotEmpty({message: 'نام الزامی است'})
  firstName: string

  @IsString({message: 'نام خانوادگی الزامی است'})
  @IsNotEmpty({message: 'نام خانوادگی الزامی است'})
  lastName: string

  @IsNumberString({}, {message: 'شماره همراه الزامی است'})
  @IsPhoneNumber('IR', {message: 'شماره همراه وارد شده صحیح نمی‌باشد.'})
  @Length(11, 11, {message: 'شماره همراه باید ۱۱ رقم باشد'})
  mobileNumber: string

  @IsString()
  @IsOptional()
  email?: string

  @IsDate()
  @IsOptional()
  birthDate?: Date

  @IsOptional()
  @IsString()
  secondMobileNumber?: string

  @IsOptional()
  @IsString()
  description?: string

  @IsOptional()
  @IsEnum(CustomerStatusEnum)
  status?: CustomerStatusEnum

  constructor() {
    this.firstName = ''
    this.lastName = ''
    this.mobileNumber = ''
    this.email = ''
    this.birthDate = new Date()
    this.secondMobileNumber = ''
    this.description = ''
    this.status = CustomerStatusEnum.activated
  }
}
