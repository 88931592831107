import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    deactiveProductsAlert: {
      "[role='alert']": {
        fontWeight: '700',
        color: '#DC3545 !important',
      },
    },
    calculatedPrice: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '10px',
    },
    useCustomPriceWrapper: {
      marginBottom: theme.spacing(2),
      '& .form-check': {
        margin: theme.spacing(2, 0, 1.5),
      },
    },
  }
})
