import {IAdmin, Admin, ICustomer, Customer, Courier, ICourier} from 'models'

export enum UserTypeEnum {
  admin = 'admin',
}

export interface IUser {
  id?: string
  createdAt?: string
  updatedAt?: string
  mobileNumber?: string
  username?: string
  isActive?: boolean
  userType?: UserTypeEnum
  customerId?: string
  courierId?: string
  adminId?: string
  admin?: IAdmin
  customer?: ICustomer
  courier?: ICourier
}

export class User {
  public props: IUser = {}

  constructor(data?: IUser) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getMobileNumber(): string {
    return this.props.mobileNumber || ''
  }

  getUsername(): string {
    return this.props.username || ''
  }

  getIsActive(): boolean {
    return this.props.isActive || false
  }

  getUserType(): UserTypeEnum {
    return this.props.userType || UserTypeEnum.admin
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getCourierId(): string {
    return this.props.courierId || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getCourier(): Courier {
    return new Courier(this.props.courier || {})
  }

  // custom
  getDisplayUserName(): string {
    return `@${this.getUsername()}`
  }

  getDisplayUserTypeEnum(): string {
    return displayUserTypeEnum(this.getUserType())
  }

  getDisplayIsActive(): string {
    return this.getIsActive() ? 'فعال' : 'غیر فعال'
  }
}

export const displayUserTypeEnum = (userTypeEnum?: UserTypeEnum) => {
  switch (userTypeEnum) {
    case UserTypeEnum.admin:
      return 'ادمین'
    default:
      return '-'
  }
}
