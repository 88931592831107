import {useSnackbar} from 'notistack'
import {useMutation} from 'react-query'

//services
import services, {ServerError} from 'services'

//hooks
import useText from 'hooks/useText'

//locals
import {AddressCardProps} from '.'
import {texts} from './texts'
import useToolbarRefetch from 'hooks/useToolbarRefetch'

interface IUseData {
  activedAddress: AddressCardProps['activedAddress']
  address: AddressCardProps['address']
  onSuccess: AddressCardProps['onSuccess']
  customerId: string
}
const useData = ({address, activedAddress, onSuccess, customerId}: IUseData) => {
  const {enqueueSnackbar} = useSnackbar()
  const {TX} = useText()
  const {setIsRefetchOn} = useToolbarRefetch()

  /* -------------------------------------------------------------------------- */
  /*                             Services                                     */
  /* -------------------------------------------------------------------------- */

  const handleAddressActivationService = async () =>
    await services.customers.updateCustomerActiveAddress(customerId, activedAddress!)

  /* -------------------------------------------------------------------------- */
  /*                             Mutations                                     */
  /* -------------------------------------------------------------------------- */

  const {isLoading, mutateAsync} = useMutation(
    ['update-active-address'],
    handleAddressActivationService,
    {
      onSuccess: () => {
        onSuccess()
        enqueueSnackbar(TX(texts.active_address_changed), {
          variant: 'success',
        })
        setIsRefetchOn(true)
      },
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
    }
  )
  return {isLoading, mutateAsync}
}

export default useData
