import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  product_image_title: 'pages.protected.productionline.products.components.product-image-title',
  supplies_breadcrumbs: 'pages.protected.breadcrumbsItems.supplies',
  product_modal_btn:
    'pages.protected.production-line,supplies.products.components.create-product-modal.btn-title',
  product_name_form:
    'pages.protected.production-line,supplies.products.components.create-product-modal.form-variety-name',
  product_type_form:
    'pages.protected.production-line,supplies.products.components.create-product-modal.form-variety-type',
  create_product_cancel:
    'pages.protected.production-line,supplies.products.components.create-product-modal.cancel',
  create_product_submit: 'pages.protected.system-preferences.employee.create_submit',
  product_form_name_required:
    'pages.protected.production-line,supplies.products.components.create-product-modal.form-variety-name-required-error',
  product_from_type_required:
    'pages.protected.production-line,supplies.products.components.create-product-modal.form-variety-type-required-error',
  creation_success: 'pages.protected.system-preferences.employee.creation_success',
  mobile_error: 'pages.public.login.mobile_error',
  new_employee: "pages.protected.system-preferences.employee.components.new_employee",
  edit_employee: "pages.protected.system-preferences.employee.components.edit_employee",
  first_name: "pages.protected.system-preferences.employee.components.first_name",
  last_name: "pages.protected.system-preferences.employee.components.last_name",
  mobile_number: "pages.protected.system-preferences.employee.components.mobile_number",
  mobile_is_required: "pages.protected.system-preferences.employee.components.mobile_is_required",
  first_name_is_required: "pages.protected.system-preferences.employee.components.first_name_is_required",
  last_name_is_required: "pages.protected.system-preferences.employee.components.last_name_is_required",
}
