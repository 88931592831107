import {Expose, Type} from 'class-transformer'
import {
  IsArray,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  IsUUID,
  ValidateIf,
  ValidateNested,
} from 'class-validator'

class BoxElement {
  @IsString({message: 'باکس الزامی است'})
  boxId: string

  @IsNumber()
  @IsPositive({message: 'وزن باید یک عدد مثبت باشد'})
  weight: number

  constructor() {
    this.boxId = ''
    this.weight = 0
  }
}

export class CreateReplacementOfProductInQuoteRequestDto {
  @IsString()
  @IsOptional()
  @ValidateIf((p) => p.quoteId)
  quoteId?: string

  @IsString({message: 'محصول روز الزامی است'})
  dailyTargetId: string

  @IsString({message: 'محصول الزامی است'})
  productId: string

  @IsString({message: 'محصول جایگزین الزامی است'})
  alternativeProductId: string

  @ValidateNested({each: true})
  @Expose()
  @Type(() => BoxElement)
  boxes: BoxElement[]

  constructor() {
    this.quoteId = ''
    this.dailyTargetId = ''
    this.productId = ''
    this.alternativeProductId = ''
    this.boxes = []
  }
}
