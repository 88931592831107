import {Expose, Type} from 'class-transformer'
import {IsArray, IsEmail, IsNotEmpty, IsOptional, IsString, ValidateNested} from 'class-validator'

class IVehicleRequestDto {
  @IsString({message: 'نام خودرو الزامی است'})
  name: string

  @IsString({message: 'مدل خودرو الزامی است'})
  model: string

  @IsOptional()
  @IsString()
  plateNumber?: string

  constructor() {
    this.name = ''
    this.model = ''
    this.plateNumber = ''
  }
}

export class UpdateCourierRequestDto {
  @IsString({message: 'نام الزامی است'})
  @IsNotEmpty({message: 'نام الزامی است'})
  firstName: string

  @IsString({message: 'نام خانوادگی الزامی است'})
  @IsNotEmpty({message: 'نام خانوادگی الزامی است'})
  lastName: string

  @IsOptional()
  @IsString()
  birthDate?: Date | string

  @IsOptional()
  @IsEmail()
  email?: string

  @Type(() => IVehicleRequestDto)
  @Expose()
  @ValidateNested()
  @IsOptional()
  vehicle?: IVehicleRequestDto

  @IsString({message: 'تصویر الزامی است'})
  photoId: string

  @IsOptional({each: true})
  @IsArray()
  weeklyPlanIds?: string[]

  constructor() {
    this.firstName = ''
    this.lastName = ''
    this.email = ''
    this.birthDate = new Date()
    this.photoId = ''
    this.weeklyPlanIds = []
  }
}
