import {IsDate, IsString} from 'class-validator'

export class StartSortingProductRequestDto {
  @IsString({message: 'جایگاه الزامی است'})
  sortingLineId: string

  @IsDate({message: 'تاریخ نامعتبر است'})
  date: Date

  constructor() {
    this.sortingLineId = ''
    this.date = new Date()
  }
}
