export enum GetQuotesRequestDtoSortEnum {
  quoteDate = 'quote.date',
  quoteUpdatedAt = 'quote.updatedAt',
  quoteNumber = 'quote.quoteNumber',
  quoteCreatedAt = 'quote.createdAt',
}

export enum QuoteStatusEnum {
  waiting_for_completion = 'waiting_for_completion',
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
}

export enum QuoteTypeEnum {
  daily_target = 'daily_target',
  manual = 'manual',
}
