import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  title_modal_line:
    'pages.protected.production-line.sorting.lines.components.create-line-modal.btn-title_line',
  line_name: 'pages.protected.production-line.sorting.lines.components.create-line-modal.line_name',
  line_number:
    'pages.protected.production-line.sorting.lines.components.create-line-modal.line_number',
  scale_id: 'pages.protected.production-line.sorting.lines.components.create-line-modal.scale_id',
  employee_id:
    'pages.protected.production-line.sorting.lines.components.create-line-modal.employee_id',
  cancel: 'pages.protected.production-line.sorting.lines.components.create-line-modal.cancel',
  submit: 'pages.protected.production-line.sorting.lines.components.create-line-modal.submit',
  submit_edit:
    'pages.protected.production-line.sorting.lines.components.create-line-modal.submit_edit',
  success_creation_line:
    'pages.protected.production-line.sorting.lines.components.create-line-modal.success-creation_line',
  line_name_required: "pages.protected.production-line.sorting.lines.components.create-line-modal.line_name_required",
  line_number_must_be_number: "pages.protected.production-line.sorting.lines.components.create-line-modal.line_number_must_be_number",
  line_number_required: "pages.protected.production-line.sorting.lines.components.create-line-modal.line_number_required",
  scale_id_required: "pages.protected.production-line.sorting.lines.components.create-line-modal.scale_id_required",
  employee_id_required: "pages.protected.production-line.sorting.lines.components.create-line-modal.employee_id_required"
}
