import {Suspense} from 'react'
import {LayoutProvider, LayoutSplashScreen} from 'providers/layout'
import ApiProvider from 'providers/api'
import TextProvider from 'providers/text'
import MessageProvider from 'providers/message'
import UserProvider from 'providers/user'
import ToolbarRefetchProvider from 'providers/toolbar-refetch'
import Router from 'routers'
import ParametersProvider from 'providers/parameters'
import {MasterInit} from 'components/template/layout/MasterInit'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <LayoutProvider>
        <ApiProvider>
          <TextProvider>
            <MessageProvider>
              <UserProvider>
                <ToolbarRefetchProvider>
                  <ParametersProvider>
                    <Router />
                    <MasterInit />
                  </ParametersProvider>
                </ToolbarRefetchProvider>
              </UserProvider>
            </MessageProvider>
          </TextProvider>
        </ApiProvider>
      </LayoutProvider>
    </Suspense>
  )
}

export {App}
