import {IsEnum, IsNotEmpty, IsNumber, IsPositive} from 'class-validator'
import {BarcodeContextEnum} from 'enums'

export class CreateOrderLineBarcodeRequestDto {
  @IsEnum(BarcodeContextEnum, {message: 'نوع الزامی است'})
  context: BarcodeContextEnum

  @IsNumber({}, {message: 'تعداد الزامی است'})
  @IsPositive({message: 'تعداد باید عدد مثبت باشد'})
  @IsNotEmpty({message: 'تعداد الزامی است'})
  count: number

  constructor() {
    this.context = BarcodeContextEnum.inventory_arrived
    this.count = 0
  }
}
