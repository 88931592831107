import apiConfig from 'services/config'
import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'
// models
import {
  ICourier,
  ICustomer,
  IDelivery,
  IDeliveryChain,
  IDeliveryPlan,
  IDto,
  IRegion,
} from '../models'
// dtos
import {
  AddDeliveryToDeliveryChainRequestDto,
  CreateDeliveryChainRequestDto,
  UpdatePriorityDeliveriesDeliveryChainRequestDto,
} from 'dtos'

const axios = new ClientApi()

const {rootAddress} = apiConfig

const prefix = '/dashboard/delivery-chains'

export const getCouriersList = (query: StringifiableRecord): Promise<IDto<ICourier>> =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/couriers', query},
      {arrayFormat: 'comma'}
    )
  )

export const getDeliveries = (query: StringifiableRecord): Promise<IDto<IDelivery>> =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/deliveries', query},
      {arrayFormat: 'comma'}
    )
  )

export const getDeliveryPlanList = (): Promise<IDeliveryPlan[]> =>
  axios.http.get(rootAddress + prefix + '/delivery-plans')

export const getDeliveryChainsList = (query: StringifiableRecord): Promise<IDto<IDeliveryChain>> =>
  axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix, query}, {arrayFormat: 'comma'})
  )

export const getDeliveryChainDetails = (deliveryChainId: string): Promise<IDeliveryChain> =>
  axios.http.get(rootAddress + prefix + '/' + deliveryChainId)

export const getDeliveryChainCustomerLists = (
  deliveryChainId: string,
  query: StringifiableRecord
): Promise<IDto<ICustomer>> =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/' + deliveryChainId + '/customers', query},
      {arrayFormat: 'comma'}
    )
  )

export const createDeliveryChain = (body: CreateDeliveryChainRequestDto): Promise<IDeliveryChain> =>
  axios.http.post(rootAddress + prefix, body)

export const getRegionList = (query: StringifiableRecord): Promise<IDto<IRegion>> =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/delivery-plans/regions', query},
      {arrayFormat: 'comma'}
    )
  )

export const addCustomerToDeliveryChain = (
  deliveryChainId: string,
  body: AddDeliveryToDeliveryChainRequestDto
): Promise<IDeliveryChain> =>
  axios.http.post(rootAddress + prefix + '/' + deliveryChainId + '/deliveries', body)

export const editPriorityCustomer = (
  deliveryChainId: string,
  body: UpdatePriorityDeliveriesDeliveryChainRequestDto
) => axios.http.put(rootAddress + prefix + '/' + deliveryChainId + '/deliveries-priority', body)

export const deleteCustomer = (deliveryChainId: string, customerId: string) =>
  axios.http.delete(rootAddress + prefix + '/' + deliveryChainId + '/deliveries/' + customerId)

export const deleteDeliveryChain = (deliveryChainId: string) =>
  axios.http.delete(rootAddress + prefix + '/' + deliveryChainId)

export const getCapacityRegion = (regionDeliveryPlanId: string) =>
  axios.http.delete(rootAddress + prefix + '/region/' + regionDeliveryPlanId)
