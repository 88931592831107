import queryString, {StringifiableRecord} from 'query-string'
import ClientApi from './clientApi'
import config from './config'
// models
import {IBox, IBoxAndWeight, ICustomer, IDelivery, IDto, IQuoteProduct} from 'models'
// dtos
import {CreateBoxRequestDto, UpdateBoxRequestDto} from 'dtos'

const {rootAddress} = config

const prefix = '/dashboard/boxes'

const axios = new ClientApi()

export const getBoxesList = (query: StringifiableRecord): Promise<IDto<IBox>> =>
  axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix, query}, {arrayFormat: 'comma'})
  )

export const createNewBox = (body: CreateBoxRequestDto): Promise<IBox> =>
  axios.http.post(rootAddress + prefix, body)

export const getBoxDetail = (boxId: string): Promise<IBox> =>
  axios.http.get(rootAddress + prefix + '/' + boxId)

export const editBox = (boxId: string, body: UpdateBoxRequestDto): Promise<IBox> =>
  axios.http.put(rootAddress + prefix + '/' + boxId, body)

export const getBoxDeliveries = (
  boxId: string,
  query: StringifiableRecord
): Promise<IDto<IDelivery>> =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/' + boxId + '/deliveries', query},
      {arrayFormat: 'comma'}
    )
  )

export const getBoxReplacements = (
  boxId: string,
  query: StringifiableRecord
): Promise<IDto<IQuoteProduct>> =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/' + boxId + '/replacements', query},
      {arrayFormat: 'comma'}
    )
  )

export const getBoxCustomers = (
  boxId: string,
  query: StringifiableRecord
): Promise<IDto<ICustomer>> =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/' + boxId + '/customers', query},
      {arrayFormat: 'comma'}
    )
  )

export const getPriceAndWeight = (boxId: string): Promise<IBoxAndWeight> =>
  axios.http.get(rootAddress + prefix + '/price-and-weight/' + boxId)
