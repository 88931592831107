import {IsOptional, IsString} from 'class-validator'

export class CreateVarietyRequestDto {
  @IsString({message: 'نام الزامی است'})
  name: string

  @IsString()
  @IsOptional()
  description?: string

  @IsOptional()
  @IsString()
  photoId?: string

  constructor() {
    this.name = ''
    this.description = ''
    this.photoId = ''
  }
}
