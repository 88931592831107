import {useState} from 'react'
import {KTSVG} from 'utils/template/helpers'
import {CreateEmployeesModal} from 'pages/protected/SystemPreferences/Employees/components/CreateEmployeesModal'
import useText from 'hooks/useText'
import { texts } from './texts'

const ToolbarEmployee = () => {
  const {TX} = useText()
  const [showCreateEmployeeModal, setShowCreateEmployeeModal] = useState<boolean>(false)

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div
        className='btn btn-sm main-gradient-background fw-bold d-flex align-items-center px-4 gap-1'
        onClick={() => setShowCreateEmployeeModal(true)}
      >
        <KTSVG
          path='/media/icons/duotune/art/add-circle-sarsabad.svg'
          className='svg-icon-1 ms-2 me-0 text-white'
        />
        <div className='text-white fw-bold'>{TX(texts.new_employee)}</div>
      </div>
      <CreateEmployeesModal
        show={showCreateEmployeeModal}
        handleClose={() => setShowCreateEmployeeModal(false)}
      />
    </div>
  )
}

export {ToolbarEmployee}
