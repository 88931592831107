import {FC} from 'react'
import {Box} from '@mui/material'
// Utils
import {KTSVG} from 'utils/template/helpers'
import {toPersianDigit} from 'utils/basic/digit'
import {isSameDates} from 'utils/basic/date'
// Local components
import LineCard from './components/Line'
import {Typography} from 'components/elements/Typography'
import {JalaliDatePicker} from 'components/elements/JalaliCalendar'
import DeliveryChainCard from './components/DeliveryChainCard'
import {Button} from 'components/elements/Button'
import CustomerCard from './components/Customer'
import BoxCard from './components/BoxCard'
import BoxContent from './components/BoxContent'
import Results from './components/Results'
// Locals
import useStyles from './useStyles'
import {useData} from './useData'
import {texts} from './texts'

const StartPackaging: FC = () => {
  const {
    TX,
    date,
    handleChangeDeliveryDate,

    loadingLines,
    setLine,
    lines,
    line,

    loadingDeliveryChians,
    setDeliveryChian,
    deliveryChains,
    deliveryChain,

    loadingCustomers,
    setCustomer,
    customers,
    customer,

    loadingCustomerBoxes,
    setBox,
    boxes,
    box,

    loadingBoxContents,
    boxContents,

    startDeliveeryChianPakcking,
    loadingStartDeliveryChainPacking,
    startCustomerDeliveryChianPacking,
    loadingStartCustomerDeliveryChainPacking,
    loadingStartBoxPacking,
    startBoxPacking,
    laodingFinishBoxPacking,
    finishBoxPacking,
    loadingFinishCustomerPacking,
    finishCustomerPacking,
    loadingFinishDeliveryChianPacking,
    finishDeliveryChainPacking,

    packingStateIds,

    packageCount,
    addExtarBox,
    loadingAddExtraBox,

    handleAddToCheckedContents,
    handleRemoveCheckedContents,

    downloadLabelsForCustomer,
    loadingDownlaodCustomerLabels,
    downloadLabelsForDeliveryChian,
    loadingDownloadDeliveryChianLabels,
  } = useData()

  const {classes} = useStyles()

  const renderLinesDate = () => {
    return (
      <Box className={[classes.boxContainer, classes.box].join(' ')}>
        <Box className={classes.cardsWrapper}>
          {!loadingLines &&
            lines.map((showingLine) => (
              <div onClick={() => setLine(showingLine)} key={showingLine.getId()}>
                <LineCard selected={showingLine.getId() === line?.getId()} line={showingLine} />
              </div>
            ))}
        </Box>
        <Box className={classes.datePickerWrapper}>
          <JalaliDatePicker
            name='deliveryDate'
            label={TX(texts.delivery_date)}
            value={date as unknown as (Date & (string | number | readonly string[])) | undefined}
            onChange={handleChangeDeliveryDate}
          />
        </Box>
      </Box>
    )
  }

  const renderDeliveryChainsPacking = () => {
    return (
      <Box className={classes.cardsWrapper}>
        {!loadingDeliveryChians &&
          !!line?.getId() &&
          (deliveryChains.length > 0 ? (
            deliveryChains.map((dChain) => (
              <div key={dChain.getId()} onClick={() => setDeliveryChian(dChain)}>
                <DeliveryChainCard
                  deliveryChian={dChain}
                  selected={deliveryChain?.getId() === dChain.getId()}
                />
              </div>
            ))
          ) : (
            <Typography type='span' className={classes.notExist}>
              {TX(texts.no_delivery_chains_data)}
            </Typography>
          ))}
      </Box>
    )
  }

  const renderActionsDeliveryChainsPacking = () => {
    return (
      <Box className={classes.buttonWrapper}>
        <Button
          className={classes.startButton}
          disabled={!deliveryChain || !!packingStateIds.packingId}
          onClick={() => startDeliveeryChianPakcking()}
          isLoading={loadingStartDeliveryChainPacking}
        >
          {TX(texts.chains_start_packing)}
        </Button>
        <Button
          className={classes.endButton}
          isLoading={loadingFinishDeliveryChianPacking}
          onClick={() => finishDeliveryChainPacking()}
          disabled={
            !deliveryChain ||
            !packingStateIds.packingId ||
            deliveryChain.getPacking().getStatus() === 'finish'
          }
        >
          {TX(texts.chains_finish_packing)}
        </Button>
        <Button
          className={classes.labelButton}
          isLoading={loadingDownloadDeliveryChianLabels}
          onClick={() => downloadLabelsForDeliveryChian()}
          disabled={!deliveryChain}
        >
          {TX(texts.chains_download_label)}
        </Button>
      </Box>
    )
  }

  const renderCustomersPacking = () => {
    return (
      <Box className={classes.cardsWrapper}>
        {!loadingCustomers &&
          !!deliveryChain &&
          (customers.length > 0 ? (
            customers.map((showingCustomer) => (
              <div key={showingCustomer.getId()} onClick={() => setCustomer(showingCustomer)}>
                <CustomerCard
                  customer={showingCustomer}
                  selected={showingCustomer.getId() === customer?.getId()}
                />
              </div>
            ))
          ) : (
            <Typography type='span' className={classes.notExist}>
              {TX(texts.no_customers_for_delivery_chains_data)}
            </Typography>
          ))}
      </Box>
    )
  }

  const renderActionsCustomersPacking = () => {
    return (
      <Box className={classes.buttonWrapper}>
        <Button
          className={classes.startButton}
          disabled={!customer || !!packingStateIds.packingDeliveryId}
          onClick={() => startCustomerDeliveryChianPacking()}
          isLoading={loadingStartCustomerDeliveryChainPacking}
        >
          {TX(texts.customer_start_packing)}
        </Button>
        <Button
          className={classes.endButton}
          isLoading={loadingFinishCustomerPacking}
          onClick={() => finishCustomerPacking()}
          disabled={
            !customer ||
            !packingStateIds.packingDeliveryId ||
            customer.getPackingDelivery().getStatus() === 'finish'
          }
        >
          {TX(texts.customer_finish_packing)}
        </Button>
        <Button
          className={classes.labelButton}
          isLoading={loadingDownlaodCustomerLabels}
          onClick={() => downloadLabelsForCustomer()}
          disabled={!customer}
        >
          {TX(texts.order_download_label)}
        </Button>
      </Box>
    )
  }

  const renderBoxesPacking = () => {
    return (
      <Box className={classes.cardsWrapper}>
        {!loadingCustomerBoxes &&
          !!customer &&
          (boxes.length > 0 ? (
            boxes.map((showingBox) => (
              <div key={showingBox.getId()} onClick={() => setBox(showingBox)}>
                <BoxCard box={showingBox} selected={showingBox.getId() === box?.getId()} />
              </div>
            ))
          ) : (
            <Typography type='span' className={classes.notExist}>
              {TX(texts.no_box_for_customer_data)}
            </Typography>
          ))}
      </Box>
    )
  }

  const renderActionsBoxesPacking = () => {
    return (
      <Box className={classes.buttonWrapper}>
        <Button
          className={classes.startButton}
          disabled={!box || !!box.getPackingDeliveryBoxId()}
          onClick={() => startBoxPacking()}
          isLoading={loadingStartBoxPacking}
        >
          {TX(texts.box_start_packing)}
        </Button>
        <Button
          className={classes.endButton}
          isLoading={laodingFinishBoxPacking}
          onClick={() => finishBoxPacking()}
          disabled={
            !packingStateIds.packingDeliveryBoxId ||
            box?.getPackingDeliveryBox().getStatus() === 'finish'
          }
        >
          {TX(texts.box_finish_packing)}
        </Button>
      </Box>
    )
  }

  const renderBoxContentsPacking = () => {
    return (
      <Box className={classes.contentWrapper}>
        {!loadingBoxContents &&
          !!box &&
          (boxContents.length > 0 ? (
            boxContents.map((boxContent) => (
              <BoxContent
                content={boxContent}
                onCheck={handleAddToCheckedContents}
                onRemove={handleRemoveCheckedContents}
                key={boxContent.getId()}
              />
            ))
          ) : (
            <Typography type='span' className={classes.notExist}>
              {TX(texts.no_product_for_box_data)}
            </Typography>
          ))}
      </Box>
    )
  }

  const renderFooterPacking = () => {
    return (
      <Box className={classes.contentFooter}>
        <Box className={classes.contentFooterPackages}>
          {!boxContents.every(
            (content) => content.getPackingDeliveryBoxProducts()[0].getStatus() === 'finish'
          ) && (
            <Button
              className={classes.startButton}
              isLoading={loadingAddExtraBox}
              onClick={() => addExtarBox()}
            >
              <KTSVG
                path='/media/icons/duotune/art/add.svg'
                className='svg-icon-1 me-0 p-0 text-white'
              />
            </Button>
          )}
          <Typography type='span' className={classes.text}>
            {TX(texts.package_count)}: {toPersianDigit(packageCount)}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box className={classes.wrapper}>
        {/* choose a line */}
        {renderLinesDate()}

        {/* choose a delivery chain for gettiing customers */}
        {line && (
          <Box
            className={[classes.box, classes.boxContainer, !line ? classes.diactive : ''].join(' ')}
          >
            <Results
              allItems={deliveryChains.length}
              inProgressItems={
                deliveryChains.filter(
                  (deliveryChain) =>
                    !!deliveryChain.getPackingId &&
                    deliveryChain.getPacking().getStatus() === 'start'
                ).length
              }
              packedItems={
                deliveryChains.filter(
                  (deliveryChain) =>
                    !!deliveryChain.getPackingId &&
                    deliveryChain.getPacking().getStatus() === 'finish'
                ).length
              }
              name={TX(texts.chains)}
            />
            {renderDeliveryChainsPacking()}
            {renderActionsDeliveryChainsPacking()}
          </Box>
        )}

        {/* choose a customer for start pack it's boxes*/}
        {deliveryChain && !!packingStateIds.packingId && (
          <Box
            className={[
              classes.box,
              classes.boxContainer,
              !deliveryChain ? classes.diactive : '',
            ].join(' ')}
          >
            <Results
              allItems={customers.length}
              inProgressItems={
                customers.filter(
                  (customer) =>
                    !!customer.getPackingDeliveryId &&
                    customer.getPackingDelivery().getStatus() === 'start'
                ).length
              }
              packedItems={
                customers.filter(
                  (customer) =>
                    !!customer.getPackingDeliveryId &&
                    customer.getPackingDelivery().getStatus() === 'finish'
                ).length
              }
              name={TX(texts.customers)}
            />
            {renderCustomersPacking()}
            {renderActionsCustomersPacking()}
          </Box>
        )}

        {/* choose a box for start packing for it*/}
        {customer && !!packingStateIds.packingDeliveryId && (
          <Box
            className={[classes.box, classes.boxContainer, !customer ? classes.diactive : ''].join(
              ' '
            )}
          >
            <Results
              allItems={boxes.length}
              inProgressItems={
                boxes.filter(
                  (box) =>
                    !!box.getPackingDeliveryBoxId &&
                    box.getPackingDeliveryBox().getStatus() === 'start'
                ).length
              }
              packedItems={
                boxes.filter(
                  (box) =>
                    !!box.getPackingDeliveryBoxId &&
                    box.getPackingDeliveryBox().getStatus() === 'finish'
                ).length
              }
              name={TX(texts.boxes)}
            />
            {renderBoxesPacking()}
            {renderActionsBoxesPacking()}
          </Box>
        )}

        {/* choose a content for packing */}
        {box && packingStateIds.packingDeliveryBoxId && (
          <Box
            className={[classes.box, classes.boxContainer, !box ? classes.diactive : ''].join(' ')}
          >
            {renderBoxContentsPacking()}
            {renderFooterPacking()}
          </Box>
        )}
      </Box>
    </>
  )
}

export default StartPackaging
