import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    marginRight: 'auto',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    top: '1rem',
    left: '1rem',
    borderRadius: '1rem',
    padding: '1rem',
    background: '#F5F8FA',
  },

  text: {
    color: '#5C5C5C !important',
  },
}))

export default useStyles
