import {Expose} from 'class-transformer'
import {IsDate, IsEnum, IsOptional, IsString} from 'class-validator'
import {PaginateRequestDto} from './paginate.request.dto'
import {WeeklyPlanShiftEnum} from 'enums'

export class GetDeliveriesRequestDtoFiltersDto extends PaginateRequestDto {
  @IsOptional()
  @Expose()
  @IsString()
  'filters[regionId]'?: string

  @IsOptional()
  @Expose()
  @IsEnum(WeeklyPlanShiftEnum)
  'filters[shift]'?: WeeklyPlanShiftEnum

  @IsOptional()
  @Expose()
  @IsDate()
  'filters[deliveryDate]'?: string

  @IsOptional()
  @IsString()
  search?: string
}
