import {generatePath, useNavigate} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {classValidatorResolver} from '@hookform/resolvers/class-validator'
import {useMutation} from 'react-query'
import {useSnackbar} from 'notistack'
// services
import services, {ServerError} from 'services'
// models
import {Box} from 'models'
// enums
import {BoxStatusEnum} from 'enums'
// constants
import {EDIT_BOXES_DETAILS_ROUTE} from 'constants/routes'
// dtos
import {CreateBoxRequestDto} from 'dtos'
// hooks
import useText from 'hooks/useText'
import useToolbarRefetch from 'hooks/useToolbarRefetch'
// locals
import {texts} from './texts'

const useData = (show: boolean, handleClose: () => void) => {
  const {TX} = useText()
  const [weight, setWeight] = useState(0)
  const [price, setPrice] = useState(0)
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()
  const {setIsRefetchOn} = useToolbarRefetch()

  const [photos, setPhotos] = useState<{photoId: string; isFull: boolean}[]>(
    Array(1).fill({photoId: '', isFull: false})
  )
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue: setFormValue,
    formState: {isSubmitting, errors, isValid},
  } = useForm<CreateBoxRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(CreateBoxRequestDto),
    defaultValues: {
      name: '',
      description: '',
      priority: 0,
      status: BoxStatusEnum.activated,
      expiryDate: new Date(),
    },
  })

  /* ------------------------------------------------------------ */
  /*                           Services                           */
  /* ------------------------------------------------------------ */

  const handleCreateNewBox = async () => await services.boxes.createNewBox({...watch()})

  /* ------------------------------------------------------------ */
  /*                         Mutations                            */
  /* ------------------------------------------------------------ */

  const {isLoading, mutate} = useMutation(handleCreateNewBox, {
    onSuccess: async (data) => {
      const currBox = new Box(data)
      handleClose()
      setIsRefetchOn(true)
      enqueueSnackbar(TX(texts.success_creation), {
        variant: 'success',
      })
      navigate(generatePath(EDIT_BOXES_DETAILS_ROUTE, {boxId: currBox.getId()}))
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* ------------------------------------------------------------ */
  /*                          Handlers                            */
  /* ------------------------------------------------------------ */

  const onSubmit = async () => {
    setIsSubmitted(true)
  }

  const onSubmitFinish = async () => {
    setIsSubmitted(false)
    setFormValue('photoId', photos[0].photoId)
    await mutate()
  }

  const handleChangeExpiryDate = (value: Date | null) => {
    if (!value) return

    setFormValue('expiryDate', value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  /* ------------------------------------------------------------ */
  /*                         LifeCycles                           */
  /* ------------------------------------------------------------ */

  useEffect(() => {
    if (!show) {
      setPhotos(Array(1).fill({photoId: '', isFull: false}))
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return {
    onSubmit: handleSubmit(onSubmit),
    control,
    watch,
    setFormValue,
    isSubmitting,
    errors,
    isValid,
    loading: isLoading,
    weight,
    setWeight,
    price,
    setPrice,
    onSubmitFinish,
    isSubmitted,
    photos,
    setPhotos,
    handleChangeExpiryDate,
  }
}

export {useData}
