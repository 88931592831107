export enum PackingStatusEnum {
  start = 'start',
  pause = 'pause',
  finish = 'finish',
}

export enum PackingDeliveryStatusEnum {
  start = 'start',
  pause = 'pause',
  finish = 'finish',
}

export enum PackingDeliveryBoxStatusEnum {
  start = 'start',
  pause = 'pause',
  finish = 'finish',
}

export enum PackingDeliveryBoxProductStatusEnum {
  start = 'start',
  pause = 'pause',
  finish = 'finish',
}

export enum GetPackingsReportRequestDtoSortEnum {
  packingDate = 'packing.createdAt',
  deliveryChainDate = 'deliveryChain.date',
}
