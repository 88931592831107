import {useEffect, useMemo, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useForm} from 'react-hook-form'
import {classValidatorResolver} from '@hookform/resolvers/class-validator'
import {useSnackbar} from 'notistack'
//services
import services, {ServerError} from 'services'
//models
import {
  Address,
  Box,
  BoxAndWeight,
  Customer,
  Dto,
  Product,
  Region,
  RegionDeliveryPlanStatusEnum,
} from 'models'
// enums
import {
  BoxStatusEnum,
  BoxTypeEnum,
  PackagingTargetStatusEnum,
  ProductStatusEnum,
  TransactionPaymentTypeEnum,
} from 'enums'
// dtos
import {CreateSubscriptionRequestDto} from 'dtos'
//hooks
import useToolbarRefetch from 'hooks/useToolbarRefetch'
import useText from 'hooks/useText'
//locals
import {Props} from '.'
import {texts} from './texts'

interface IUseData {
  handleClose: Props['handleClose']
  show: boolean
}

const useData = ({handleClose, show}: IUseData) => {
  const {setIsRefetchOn} = useToolbarRefetch()
  const {enqueueSnackbar} = useSnackbar()
  const {TX} = useText()

  const [allCustomers, setAllCustomers] = useState<Customer[]>([])
  const [customerSearch, setCustomerSearch] = useState<string>('')
  const [customerId, setCustomerId] = useState<string>('')
  const [paymentAmount, setPaymentAmount] = useState(0)
  const [paymentAmountBox, setPaymentAmountBox] = useState(0)
  const [triggerRefetchAddresses, setTriggerRefetchAddresses] = useState<string>('')
  const [isEditCustomerModalOpen, setIsEditCustomerModalOpen] = useState(false)
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState<Address>(new Address())

  const [products, setProducts] = useState<Product[]>([])
  const [boxes, setBoxes] = useState<Box[]>([])

  const [regionsQuery, setRegionsQuery] = useState({
    page: 1,
    limit: 10,
    search: '',
    'filters[districtId]': '',
  })

  const [regions, setRegions] = useState<Region[]>([])
  const [boxType, setBoxType] = useState<BoxTypeEnum>(BoxTypeEnum.STANDARD)
  const [productSearch, setProductSearch] = useState<string[]>([''])
  const [productList, setProductList] = useState<Product[]>([])
  const [boxSearch, setBoxSearch] = useState<string[]>([''])
  const [boxList, setBoxList] = useState<Box[]>([])

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue: setFormValue,
    formState: {errors, isValid, isSubmitting},
  } = useForm<CreateSubscriptionRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(CreateSubscriptionRequestDto),
    defaultValues: {
      standardBoxes: [
        {
          boxId: '',
          quantity: 0,
        },
      ],
      customBoxes: [
        {
          name: '',
          products: [
            {
              productId: '',
              productInstanceId: '',
              quantity: 0,
            },
          ],
        },
      ],
      regionId: '',
      deliveryPlanId: '',
      weeklyCount: 1,
      addressId: '',
      customerId: customerId,
      paymentType: TransactionPaymentTypeEnum.credit,
    },
  })

  /* -------------------------------------------------------------------------- */
  /*                             Services                                     */
  /* -------------------------------------------------------------------------- */

  const getCustomersService = async () =>
    await services.customers.getCustomers({getAll: true, limit: 1000})

  const getCustomerAddressesService = async () =>
    await services.customers.getCustomerAddresses(customerId, {getAll: true})

  const getBoxesService = async () => await services.boxes.getBoxesList({getAll: true, limit: 1000})

  const getRegionsService = async () => await services.regions.getRegions(regionsQuery)

  const createSubscriptionService = async () =>
    await services.subscriptions.createSubscription({
      standardBoxes: watch()
        .standardBoxes?.filter((item) => item.quantity !== 0)
        .map((item) => ({boxId: item.boxId, quantity: item.quantity})),
      customBoxes: watch().customBoxes?.map((item) => {
        return {
          name: item.name,
          products: item.products
            .filter((product) => product.quantity)
            .map((innerItem) => ({
              productId: innerItem.productId,
              productInstanceId: innerItem.productInstanceId,
              quantity: innerItem.quantity,
            })),
        }
      }),
      regionId: watch().regionId,
      deliveryPlanId: watch().deliveryPlanId,
      weeklyCount: watch().weeklyCount,
      addressId: watch().addressId,
      paymentType: TransactionPaymentTypeEnum.credit,
      customerId: watch().customerId,
    })

  const getProductService = async () =>
    await services.products.getProductsList({getAll: true, limit: 1000})

  /* -------------------------------------------------------------------------- */
  /*                             Queries                                     */
  /* -------------------------------------------------------------------------- */

  const {isLoading: isLoadingCustomers, isFetching: isFetchingCustomers} = useQuery(
    ['get-customers', customerSearch, show],
    getCustomersService,
    {
      refetchOnWindowFocus: false,
      enabled: !!show,
      onSuccess: (data) => {
        const dto = new Dto(data)
        const tempCustomers = dto.getItems ? dto.getItems().map((item) => new Customer(item)) : []
        setAllCustomers(tempCustomers)
      },
    }
  )

  const {
    data: customerAddresses,
    isLoading: customerAddressesIsLoading,
    refetch,
  } = useQuery(
    ['get-customer-addresses', customerId, show, isEditCustomerModalOpen, isEditAddressModalOpen],
    getCustomerAddressesService,
    {
      onSuccess: (data) => {
        const addresses = data.map((item) => new Address(item))
        const activeAddress = handleFindActiveAddress(addresses)?.getId()
        setFormValue('addressId', activeAddress)
        setRegionsQuery((prevState) => ({
          ...prevState,
          'filters[districtId]': handleFindActiveAddress(addresses)?.getDistrictId(),
        }))
      },
      refetchOnWindowFocus: false,
      enabled: !!customerId && !!show,
    }
  )

  const {isLoading: boxesIsLoading} = useQuery(['get-boxes-list', show], getBoxesService, {
    enabled: !!show,
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
    onSuccess: (data) => {
      const dto = new Dto(data)
      const currBoxes = dto.getItems().map((item) => new Box(item))
      setBoxes(currBoxes)
      setBoxList(currBoxes)
    },
  })

  const {isLoading: productsIsLoading} = useQuery(['get-product-list', show], getProductService, {
    enabled: !!show,
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
    onSuccess: (data) => {
      const dto = new Dto(data)
      const currProducts = dto.getItems().map((item) => new Product(item))
      setProductList(currProducts)
      setProducts(currProducts)
    },
  })

  const {isLoading: regionsIsLoading} = useQuery(
    ['get-regions-list', regionsQuery],
    getRegionsService,
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        const dto = new Dto(data)
        const tempRegions = dto.getItems().map((item) => new Region(item))
        setRegions(!!regionsQuery['filters[districtId]'] ? tempRegions : [])
      },
    }
  )

  /* -------------------------------------------------------------------------- */
  /*                             Mutations                                       */
  /* -------------------------------------------------------------------------- */

  const {isLoading: subscriptionIsLoading, mutateAsync} = useMutation(createSubscriptionService, {
    onSuccess: () => {
      handleClose()
      setIsRefetchOn(true)
      reset()
      enqueueSnackbar(TX(texts.success), {
        variant: 'success',
      })
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* -------------------------------------------------------------------------- */
  /*                                  Handlers                                  */
  /* -------------------------------------------------------------------------- */

  const handleFindActiveAddress = (addresses: Address[]) =>
    addresses.filter((item) => item.getIsActive())[0]

  const onSubmit = async () => {
    await mutateAsync()
  }

  const addRow = () => {
    setFormValue('standardBoxes', [...watch().standardBoxes!, {boxId: '', quantity: 0}])
    setBoxSearch([...boxSearch, ''])
    setBoxList(boxes)
  }

  /* -------------------------------------------------------------------------- */
  /*                             LifeCycle                                       */
  /* -------------------------------------------------------------------------- */

  const tempStandardBoxesQuantity = watch().standardBoxes?.filter((item) => item.quantity > 0)
  const tempCustomBoxesQuantity = watch().customBoxes?.filter((item) =>
    item.products.filter((product) => product.quantity > 0)
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    async function fetchData() {
      let totalAmount = 0

      try {
        const promises = watch()
          .standardBoxes?.filter((box) => box.quantity)
          .map(async (box) => {
            const data = await services.boxes.getPriceAndWeight(box.boxId!)
            const tempData = new BoxAndWeight(data)
            totalAmount += tempData.getPrice() * box.quantity
          })

        await Promise.all(promises!)

        const finalPaymentAmount = totalAmount
        setPaymentAmountBox(Math.ceil(finalPaymentAmount))
      } catch (error) {}
    }

    if (watch().standardBoxes!.length > 0 && !!watch().standardBoxes?.[0].boxId) {
      fetchData()
    }
    if (watch().standardBoxes?.length === 0 || !watch().standardBoxes?.[0].boxId) {
      setPaymentAmountBox(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempStandardBoxesQuantity])

  useEffect(() => {
    if (watch().customBoxes!.length > 0) {
      let totalAmount = 0
      watch().customBoxes?.forEach((innerProduct) => {
        if (innerProduct.products.length > 0) {
          innerProduct.products
            .filter((product) => !!product.quantity)
            .forEach((product) => {
              totalAmount +=
                products.filter((p) => p?.getId() === product?.productId)?.[0]?.getPrice() *
                (1 +
                  products
                    .filter((p) => p?.getId() === product?.productId)?.[0]
                    ?.getPercentagePrice() /
                    100) *
                products
                  .filter((p) => p?.getId() === product?.productId)?.[0]
                  ?.getProductInstances()
                  .filter(
                    (afterProductInstance) =>
                      afterProductInstance.getId() === product.productInstanceId
                  )?.[0]
                  ?.getQuantity() *
                product.quantity
            })
        }
      })
      setPaymentAmount(Math.ceil(totalAmount))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempCustomBoxesQuantity])

  useEffect(() => {
    if (show) {
      setRegionsQuery({
        page: 1,
        limit: 10,
        search: '',
        'filters[districtId]': '',
      })
      reset()
      setPaymentAmountBox(0)
      setPaymentAmount(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  useEffect(() => {
    if (!!customerId) refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditCustomerModalOpen, isEditAddressModalOpen, triggerRefetchAddresses])

  useEffect(() => {
    if (
      watch().standardBoxes?.length === 0 ||
      (!!watch().standardBoxes?.[watch().standardBoxes!.length - 1]?.boxId &&
        !!watch().standardBoxes?.[watch().standardBoxes!.length - 1]?.quantity)
    ) {
      addRow()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempStandardBoxesQuantity])

  useEffect(() => {
    watch().customBoxes?.forEach((item, index) => {
      if (
        item.products.length === 0 ||
        (!!item?.products?.[item.products.length - 1]?.productId &&
          !!item?.products?.[item.products.length - 1]?.quantity)
      ) {
        setFormValue(`customBoxes.${index}.products`, [
          ...item.products,
          {
            productId: '',
            productInstanceId: '',
            quantity: 0,
          },
        ])
        setProductSearch([...productSearch, ''])
        setProductList(products)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch().customBoxes?.at(-1)?.products?.at(-1)?.quantity])

  /* -------------------------------------------------------------------------- */
  /*                             Data                                       */
  /* -------------------------------------------------------------------------- */

  const renderActivePackingTarget = (box: Box) => {
    const tempActivePackingTarget = box
      .getPackagingTargets()
      .filter(
        (packingTarget) => packingTarget.getStatus() === PackagingTargetStatusEnum.are_selling
      )
    return (
      box.getStatus() === BoxStatusEnum.activated &&
      tempActivePackingTarget.length > 0 &&
      tempActivePackingTarget.filter(
        (packingTarget) =>
          packingTarget
            .getPackagingContentTargets()
            .filter((item) => item.getProduct().getStatus() === ProductStatusEnum.deactivated)
            .length >= 1
      ).length <= 0
    )
  }

  const addresses = customerAddresses?.length
    ? customerAddresses.map((item) => new Address(item))
    : []

  const activeAddress = handleFindActiveAddress(addresses)

  const selectedRegion = watch().regionId
    ? regions.find((item) => item?.getId() === watch().regionId)
    : new Region()

  const deliveryPlans =
    selectedRegion
      ?.getRegionDeliveryPlan()
      .filter(
        (regionDeliveryPlan) =>
          regionDeliveryPlan.getStatus() === RegionDeliveryPlanStatusEnum.activate
      )
      .map((item) => item.getDeliveryPlan()) || []

  const standardBoxesValue = useMemo(() => {
    return watch().standardBoxes
  }, [watch().standardBoxes])

  const customBoxesValue = useMemo(() => {
    return watch().customBoxes
  }, [watch().customBoxes])

  const weeklyCountValue = useMemo(() => {
    return watch().weeklyCount
  }, [watch().weeklyCount])

  const regionValue = useMemo(() => {
    return watch().regionId
  }, [watch().regionId])

  const deliveryPlanValue = useMemo(() => {
    return watch().deliveryPlanId
  }, [watch().deliveryPlanId])

  const customerValue = useMemo(() => {
    return watch().customerId
  }, [watch().customerId])

  return {
    renderActivePackingTarget,
    activeAddress,
    boxes,
    regions,
    addresses,
    deliveryPlans,
    isEditCustomerModalOpen,
    setIsEditCustomerModalOpen,
    allCustomers,
    isLoadingCustomers: isLoadingCustomers || isFetchingCustomers,
    customerId,
    setCustomerId,
    customerSearch,
    setCustomerSearch,
    regionsQuery,
    setRegionsQuery,
    customerAddressesIsLoading,
    boxesIsLoading,
    regionsIsLoading,
    productSearch,
    setProductSearch,
    productList,
    setProductList,
    boxSearch,
    setBoxSearch,
    boxList,
    setBoxList,
    onSubmit: handleSubmit(onSubmit),
    control,
    standardBoxesValue,
    customBoxesValue,
    weeklyCountValue,
    regionValue,
    deliveryPlanValue,
    customerValue,
    watch,
    reset,
    setFormValue,
    errors,
    disabledForm:
      isSubmitting || !isValid || !regionValue || !deliveryPlanValue || weeklyCountValue <= 0,
    subscriptionIsLoading: subscriptionIsLoading,
    paymentAmount,
    setPaymentAmount,
    paymentAmountBox,
    setPaymentAmountBox,
    triggerRefetchAddresses,
    setTriggerRefetchAddresses,
    isEditAddressModalOpen,
    setIsEditAddressModalOpen,
    selectedAddress,
    setSelectedAddress,
    addRow,
    boxType,
    setBoxType,
    productsIsLoading,
    products,
  }
}

export default useData
