import {Expose} from 'class-transformer'
import {IsEnum, IsOptional, IsString} from 'class-validator'
import {ScaleStateEnum, ScaleStatusEnum} from 'enums'
import {PaginateRequestDto} from './paginate.request.dto'

export class GetScalesRequestDtoFilterDto extends PaginateRequestDto {
  @IsEnum(ScaleStatusEnum)
  @IsOptional()
  @Expose()
  'filters[status]'?: ScaleStatusEnum

  @IsEnum(ScaleStateEnum)
  @IsOptional()
  @Expose()
  'filters[state]'?: ScaleStateEnum

  @IsOptional()
  @IsString()
  search?: string
}
