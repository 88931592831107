import {Controller} from 'react-hook-form'
// components
import {Modal} from 'components/elements/Modal'
import {Checkbox} from 'components/elements/Checkbox'
import {Button} from 'components/elements/Button'
import {Typography} from 'components/elements/Typography'
import {Textfield} from 'components/elements/Textfield'
// models
import {displayAdminRolesEnum} from 'models'
// local
import {useData} from './useData'
import {texts} from './texts'
import {useStyles} from '../style'

type Props = {
  show: boolean
  handleClose: () => void
}

const CreateAdminModal = ({show, handleClose}: Props) => {
  const {
    onSubmit,
    control,
    watch,
    setValue,
    isSubmitting,
    errors,
    isValid,
    loading,
    radioOptions,
    TX,
  } = useData(show, handleClose)
  const {classes} = useStyles()

  return (
    <Modal
      id='forgot-password-modal'
      show={show}
      handleClose={handleClose}
      title={TX(texts.new_admin)}
    >
      <div className='modal-body py-lg-10 px-lg-10'>
        <div
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <form onSubmit={onSubmit} encType='multipart/form-data'>
              <div className='current' data-kt-stepper-element='content'>
                <div className='w-100'>
                  <div className='text-center mb-11'>
                    <Typography type='h4' className='fw-normal' color='#001A0B'>
                      {TX(texts.add_mobile_number_hint)}
                    </Typography>
                  </div>

                  <div className='d-flex flex-row justify-content-center gap-5 w-100'>
                    <div className='fv-row mb-10 w-50'>
                      <Controller
                        name='firstName'
                        control={control}
                        render={({field}) => (
                          <Textfield
                            {...field}
                            label={TX(texts.name)}
                            type='text'
                            name='firstName'
                            autoComplete='one-time-code'
                            error={!!errors.firstName}
                            touched={!!errors.firstName?.message}
                            errorText={errors.firstName?.message}
                            disabled={loading}
                          />
                        )}
                      />
                    </div>

                    <div className='fv-row mb-10 w-50'>
                      <Controller
                        name='lastName'
                        control={control}
                        render={({field}) => (
                          <Textfield
                            {...field}
                            label={TX(texts.last_name)}
                            name='lastName'
                            autoComplete='one-time-code'
                            error={!!errors.lastName}
                            touched={!!errors.lastName?.message}
                            errorText={errors.lastName?.message}
                            disabled={loading}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className='d-flex flex-row justify-content-center gap-5 w-100'>
                    <div className='fv-row mb-10 w-50'>
                      <Controller
                        name='mobileNumber'
                        control={control}
                        render={({field}) => (
                          <Textfield
                            {...field}
                            label={TX(texts.mobile_number)}
                            type='text'
                            name='mobileNumber'
                            autoComplete='one-time-code'
                            error={!!errors.mobileNumber}
                            touched={!!errors.mobileNumber?.message}
                            errorText={errors.mobileNumber?.message}
                            disabled={loading}
                          />
                        )}
                      />
                      <div className={[classes.message, 'fv-plugins-message-container'].join(' ')}>
                        <div className='fv-help-block'>
                          <Typography type='span' className='fs-7 fw-light'>
                            {TX(texts.use_mobile_number_for_user_name)}
                          </Typography>
                        </div>
                      </div>
                    </div>

                    <div className='fv-row mb-10 w-50'>
                      <Controller
                        name='temporaryPassword'
                        control={control}
                        render={({field}) => (
                          <Textfield
                            {...field}
                            label={TX(texts.temporary_password)}
                            type='password'
                            name='temporaryPassword'
                            autoComplete='one-time-code'
                            error={!!errors.temporaryPassword}
                            touched={!!errors.temporaryPassword?.message}
                            errorText={errors.temporaryPassword?.message}
                            disabled={loading}
                          />
                        )}
                      />
                    </div>
                  </div>

                  {radioOptions.map((option) => (
                    <>
                      <div className='separator separator-dashed my-5'></div>
                      <div className='flex-row d-flex justify-content-between form-check form-check-custom form-check-solid mb-3'>
                        <label className='form-check-label fw-bold text-gray-600'>
                          {displayAdminRolesEnum(option)}
                        </label>
                        <Controller
                          name='roles'
                          control={control}
                          render={({field}) => (
                            <Checkbox
                              name={option}
                              value={!!watch().roles.filter((role) => role === option)?.[0]}
                              onChange={() => {
                                if (!!watch().roles.includes(option)) {
                                  const currRoles = watch().roles.filter((role) => role !== option)
                                  setValue('roles', currRoles, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                  })
                                } else {
                                  const currRoles = watch().roles
                                  currRoles.push(option)
                                  setValue('roles', Array.from(new Set([...watch().roles, option])))
                                }
                              }}
                            />
                          )}
                        />
                      </div>
                    </>
                  ))}
                </div>
              </div>

              <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
                <Button
                  type='button'
                  className='main-red-background btn btn-lg background-inherit justify-content-center'
                  onClick={handleClose}
                >
                  {TX(texts.create_product_cancel)}
                </Button>

                <Button
                  type='submit'
                  isLoading={loading}
                  className='btn btn-lg btn-primary main-gradient-background'
                  disabled={isSubmitting || !isValid || watch().roles.length === 0}
                >
                  {TX(texts.add_admin)}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {CreateAdminModal}
