import {Expose, Transform} from 'class-transformer'
import {IsArray, IsEnum, IsOptional, IsString} from 'class-validator'
import {GetOrderLinesReportRequestDtoSortEnum, MeasurementUnit, OrderLineStateEnum} from 'enums'
import {SortTypeEnum} from 'models'
import {PaginateRequestDto} from './paginate.request.dto'

export class GetOrderLinesReportRequestDtoFilterDto extends PaginateRequestDto {
  @IsOptional({each: true})
  @IsArray()
  @IsString({each: true})
  @Transform(({value}) => (value && typeof value === 'string' ? [value] : value || undefined))
  @Expose()
  'filters[productIds]'?: string[]

  @IsOptional({each: true})
  @IsArray()
  @IsString({each: true})
  @Transform(({value}) => (value && typeof value === 'string' ? [value] : value || undefined))
  @Expose()
  'filters[vendorIds]'?: string[]

  @IsOptional({each: true})
  @IsArray()
  @IsString({each: true})
  @Transform(({value}) => (value && typeof value === 'string' ? [value] : value || undefined))
  @Expose()
  'filters[varietyIds]'?: string[]

  @IsOptional()
  @IsString()
  @Expose()
  'filters[fromDate]'?: string

  @IsOptional()
  @IsString()
  @Expose()
  'filters[untilDate]'?: string

  @IsOptional()
  @IsEnum(MeasurementUnit)
  @Expose()
  'filters[evaporationUnit]'?: MeasurementUnit

  @IsOptional()
  @IsEnum(MeasurementUnit)
  @Expose()
  'filters[wastageUnit]'?: MeasurementUnit

  @IsOptional()
  @IsEnum(OrderLineStateEnum)
  @Expose()
  'filters[orderLineState]'?: OrderLineStateEnum

  @IsEnum(SortTypeEnum)
  @IsOptional()
  @Expose()
  sortType?: SortTypeEnum

  @IsEnum(GetOrderLinesReportRequestDtoSortEnum)
  @IsOptional()
  @Expose()
  sort?: GetOrderLinesReportRequestDtoSortEnum

  @IsOptional()
  @IsString()
  search?: string
}
