import {useEffect} from 'react'
import {ILayout, useLayout} from 'providers/layout'
import {ToolbarArrangement, ToolbarEmployee} from './toolbars'
import {ToolbarLine} from './toolbars/ToolbarLine'
import {ToolbarFAQ} from './toolbars/ToolbarFAQ'
import {ToolbarAdmin} from './toolbars/ToolbarAdmin'
import {ToolbarSeller} from './toolbars/ToolbarSeller'
import {ToolbarBuyer} from './toolbars/ToolbarBuyer'
import {ToolbarProduct} from './toolbars'
import {ToolbarNewBox} from './toolbars/ToolbarNewBox'
import {ToolbarPurchaseOrder} from './toolbars/ToolbarNewPurchaseOrder'
import {ToolbarNewVariety} from './toolbars/ToolbarNewVariety'
import {ToolbarScale} from './toolbars/ToolbarScale'
import {ToolbarSubscriber} from './toolbars/ToolbarSubscriber'
import {ToolbarRegion} from './toolbars/ToolbarRegion'
import {ToolbarCourier} from './toolbars/ToolbarCourier'
import ToolbarReports from './toolbars/ToolbarReports'
import {ToolbarTransactions} from './toolbars/ToolbarTransactions'
import {ToolbarCustomer} from './toolbars/ToolbarCustomer'
import {ToolbarTicket} from './toolbars/ToolbarTicket'
import {ToolbarNewPackingLine} from './toolbars/ToolbarNewPackingLIne'
import {ToolbarNewType} from './toolbars/ToolbarNewType'
import {ToolbarOrderHistory} from './toolbars/ToolbarOrderHistory'
import {ToolbarCoupon} from './toolbars/ToolbarCoupon'

const Toolbar = () => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
    document.body.setAttribute('data-kt-app-toolbar-enabled', 'true')
  }, [config])

  switch (config.app?.toolbar?.layout) {
    case 'line':
      return <ToolbarLine />
    case 'faq':
      return <ToolbarFAQ />
    case 'admin':
      return <ToolbarAdmin />
    case 'seller':
      return <ToolbarSeller />
    case 'product':
      return <ToolbarProduct />
    case 'scale':
      return <ToolbarScale />
    case 'buyer':
      return <ToolbarBuyer />
    case 'new-box':
      return <ToolbarNewBox />
    case 'new-packing-line':
      return <ToolbarNewPackingLine />
    case 'new-variety':
      return <ToolbarNewVariety />
    case 'new-type':
      return <ToolbarNewType />
    case 'purchase-order':
      return <ToolbarPurchaseOrder />
    case 'arrangement':
      return <ToolbarArrangement />
    case 'employee':
      return <ToolbarEmployee />
    case 'subscriber':
      return <ToolbarSubscriber />
    case 'region':
      return <ToolbarRegion />
    case 'courier':
      return <ToolbarCourier />
    case 'reports':
      return <ToolbarReports />
    case 'transactions':
      return <ToolbarTransactions />
    case 'customer':
      return <ToolbarCustomer />
    case 'ticket':
      return <ToolbarTicket />
    case 'order-history':
      return <ToolbarOrderHistory />
    case 'coupon':
      return <ToolbarCoupon />
    case 'none':
      return null
    default:
      return null
  }
}

const updateDOM = (config: ILayout) => {
  let appToolbarSwapAttributes: {[attrName: string]: string} = {}
  const appToolbarSwapEnabled = config.app?.toolbar?.swap?.enabled
  if (appToolbarSwapEnabled) {
    appToolbarSwapAttributes = config.app?.toolbar?.swap?.attributes as {[attrName: string]: string}
  }

  let appToolbarStickyAttributes: {[attrName: string]: string} = {}
  const appToolbarStickyEnabled = config.app?.toolbar?.sticky?.enabled
  if (appToolbarStickyEnabled) {
    appToolbarStickyAttributes = config.app?.toolbar?.sticky?.attributes as {
      [attrName: string]: string
    }

    let appToolbarMinimizeAttributes: {[attrName: string]: string} = {}
    const appToolbarMinimizeEnabled = config.app?.toolbar?.minimize?.enabled
    if (appToolbarMinimizeEnabled) {
      appToolbarMinimizeAttributes = config.app?.toolbar?.minimize?.attributes as {
        [attrName: string]: string
      }
    }

    if (config.app?.toolbar?.fixed?.desktop) {
      document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
    }

    if (config.app?.toolbar?.fixed?.mobile) {
      document.body.setAttribute('data-kt-app-toolbar-fixed-mobile', 'true')
    }

    setTimeout(() => {
      const toolbarElement = document.getElementById('kt_app_toolbar')
      // toolbar
      if (toolbarElement) {
        const toolbarAttributes = toolbarElement
          .getAttributeNames()
          .filter((t) => t.indexOf('data-') > -1)
        toolbarAttributes.forEach((attr) => toolbarElement.removeAttribute(attr))

        if (appToolbarSwapEnabled) {
          for (const key in appToolbarSwapAttributes) {
            if (appToolbarSwapAttributes.hasOwnProperty(key)) {
              toolbarElement.setAttribute(key, appToolbarSwapAttributes[key])
            }
          }
        }

        if (appToolbarStickyEnabled) {
          for (const key in appToolbarStickyAttributes) {
            if (appToolbarStickyAttributes.hasOwnProperty(key)) {
              toolbarElement.setAttribute(key, appToolbarStickyAttributes[key])
            }
          }
        }

        if (appToolbarMinimizeEnabled) {
          for (const key in appToolbarMinimizeAttributes) {
            if (appToolbarMinimizeAttributes.hasOwnProperty(key)) {
              toolbarElement.setAttribute(key, appToolbarMinimizeAttributes[key])
            }
          }
        }
      }
    }, 0)
  }
}

export {Toolbar}
