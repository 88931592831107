import {FC, useCallback, useEffect, useState} from 'react'
import {useMutation} from 'react-query'

// models
import {Admin, User} from 'models'

// services
import storage from 'services/storage'
import serviceConfig from 'services/config'
import service from 'services'

// local
import Context from './context'

const UserProvider: FC<{children: React.ReactNode}> = ({children}) => {
  const [user, setUser] = useState(new User())
  const [admin, setAdmin] = useState(new Admin())
  const [token, setToken] = useState(storage.get<string>(serviceConfig.tokenName) || '')
  const [isLogin, setIsLogin] = useState(!!token)

  const {isLoading: loadingUser, mutateAsync} = useMutation(
    'getCurrentUser',
    service.auth.currentUser,
    {
      onError: () => {
        setIsLogin(false)
      },
      onSuccess: (data) => {
        setUser(new User(data.user))
        setAdmin(new Admin(data.admin))
        setIsLogin(true)
      },
    }
  )

  const getUserInfo = useCallback(async () => {
    await mutateAsync()
  }, [mutateAsync])

  const logout = () => {
    setIsLogin(false)
    setToken('')
    setUser(new User())
    setAdmin(new Admin())
    storage.set<string>(serviceConfig.tokenName, '')
  }

  useEffect(() => {
    if (token) {
      mutateAsync()
    }
  }, [token, mutateAsync])

  return (
    <Context.Provider
      value={{
        user,
        setUser,
        admin,
        setAdmin,
        token,
        setToken,
        isLogin,
        setIsLogin,
        isLoading: loadingUser,
        getUserInfo,
        logout,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default UserProvider
