import queryString, {StringifiableRecord} from 'query-string'
import ClientApi from './clientApi'
import config from './config'
// models
import {IAdmin, IDto} from 'models'
// dtos
import {InviteNewAdminRequestDto, UpdateAdminRequestDto, UpdateAdminStatusRequestDto} from 'dtos'

const {rootAddress} = config

const prefix = '/dashboard/admins'

const axios = new ClientApi()

export const getAdmins = (query: StringifiableRecord): Promise<IDto<IAdmin>> =>
  axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix, query}))

export const getAdminDetail = (adminId: string): Promise<IAdmin> =>
  axios.http.get(rootAddress + prefix + '/' + adminId)

export const updateAdmin = (adminId: string, body: UpdateAdminRequestDto): Promise<IAdmin> =>
  axios.http.put(rootAddress + prefix + '/' + adminId, body)

export const inviteAdmin = (body: InviteNewAdminRequestDto) =>
  axios.http.post(rootAddress + prefix + '/invite', body)

export const updateStatus = (adminId: string, body: UpdateAdminStatusRequestDto) =>
  axios.http.patch(rootAddress + prefix + '/' + adminId + '/update-admin-status', body)
