export enum TicketStatusEnum {
  open = 'open',
  closed = 'closed',
}

export enum TicketCreatedByEnum {
  customer_management = 'customer_management',
  customer = 'customer',
}

export enum TicketResponseCreatedByEnum {
  admin = 'admin',
  customer = 'customer',
}

export enum TicketCategoryEnum {
  // payment = 'payment',
  // courier = 'courier',
  account = 'account',
  delivery = 'delivery',
  delivery_quality = 'delivery_quality',
  cancel_delivery = 'cancel_delivery',
  general = 'general',
}
