import {FC} from 'react'
import {Box} from '@mui/material'
// Utils
import {KTSVG, toAbsoluteUrl} from 'utils/template/helpers'
// models
import {DeliveryBox} from 'models'
// hooks
import useText from 'hooks/useText'
// Local components
import Image from 'components/elements/Image'
import {Typography} from 'components/elements/Typography'
// Locals
import useStyles from './useStyles'
import {BoxTypeEnum} from 'enums'
import {texts} from '../../texts'

type Props = {
  box?: DeliveryBox
  selected?: boolean
}

const BoxCard: FC<Props> = ({selected, box}) => {
  const {TX} = useText()
  const {classes} = useStyles()

  return (
    <Box className={[classes.wrapper, selected ? classes.selected : ''].join(' ')}>
      {box?.getBox().getMainImage().getId() ? (
        <Image
          width={320 / 2}
          height={110 / 2}
          photo={box?.getBox().getMainImage()}
          disableDialog
        />
      ) : (
        <Image
          width={320 / 2}
          height={110 / 2}
          src={toAbsoluteUrl('/media/png/custombox.png')}
          disableDialog
        />
      )}
      <Typography type='span' className={classes.text}>
        {!box?.getBox().getName() ? TX(texts.custom_box) : box?.getBox().getName()}
      </Typography>
      {box?.getPackingDeliveryBox().getStatus() === 'finish' && (
        <KTSVG path='/media/sarsabad/done_all.svg' className={classes.icon} />
      )}
    </Box>
  )
}

export default BoxCard
