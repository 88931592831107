import {FC} from 'react'
// components
import {Chip} from 'components/elements/Chip'
import Image from 'components/elements/Image'
import {Typography} from 'components/elements/Typography'
// utils
import {DisplayJalaliDate} from 'utils/basic/date'
import {toPersianDigit} from 'utils/basic/digit'
// models
import {Ticket} from 'models/Ticket'
import {Photo} from 'models'
import {TicketResponse} from 'models/TicketResponse'
// enums
import {TicketCreatedByEnum, TicketResponseCreatedByEnum} from 'enums'
// hooks
import useText from 'hooks/useText'
// local
import {texts} from './texts'

type MessageCardProps = {
  ticket: Ticket
}

const MessageCard: FC<MessageCardProps> = ({ticket}) => {
  const {TX} = useText()
  const renderTitleCard = () => {
    if (ticket.getCreatedBy() === TicketCreatedByEnum.customer) {
      return TX(texts.customer_name_ticket_created, [
        ticket.getCustomer().getFullName(),
        ticket.getDelivery().getPackagingTarget().getBox().getName(),
      ])
    } else {
      return TX(texts.admin_name_ticket_created, [
        ticket.getAdmin().getFullName(),
        ticket.getAdmin().getDisplayAccessLevel(),
      ])
    }
  }

  const renderTitleResponseCard = (innerResponse: TicketResponse) => {
    if (innerResponse.getIsReference()) {
      return TX(texts.ticket_response, [innerResponse.getDisplayAssigned()])
    } else if (innerResponse.getCreatedBy() === TicketResponseCreatedByEnum.customer) {
      return TX(texts.customer_name_ticket_created, [
        innerResponse.getCustomer().getFullName(),
        innerResponse.getTicket().getDelivery().getPackagingTarget().getBox().getName(),
      ])
    } else {
      return TX(texts.admin_name_ticket_created, [
        innerResponse.getAdmin().getFullName(),
        innerResponse.getAdmin().getDisplayAccessLevel(),
      ])
    }
  }

  const renderResponse = (response: TicketResponse) => {
    return (
      <>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 align-items-center'>
          {response.getCustomer().getUser().getId() && (
            <div className='me-5'>
              <div className='symbol symbol-fixed position-relative'>
                <Image
                  photo={new Photo()}
                  width={32}
                  height={32}
                  alt='card img'
                  style={{objectFit: 'cover'}}
                />
              </div>
            </div>
          )}
          <div className='flex-grow-1'>
            <Typography type='h4' className='flex-grow-1 text-gray-800 fs-2 fw-bolder mb-0'>
              {renderTitleResponseCard(response)}
            </Typography>
          </div>
          {toPersianDigit(response.getDate()?.getHours()!)}:
          {toPersianDigit(response.getDate()?.getMinutes()!)} -{' '}
          {DisplayJalaliDate(response.getDate())}
        </div>
        <div className='d-flex flex-row  mb-3 align-items-start justify-content-start'>
          <div
            className='d-flex flex-wrap flex-sm-nowrap align-items-start justify-content-start'
            style={{flex: 1}}
          >
            {response.getBody()}
          </div>
          {response.getMainImage().getId() && (
            <Image
              photo={response.getMainImage()}
              width={84}
              height={84}
              alt='card img'
              style={{objectFit: 'cover'}}
            />
          )}
        </div>
      </>
    )
  }

  const renderTicket = () => {
    return (
      <>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 align-items-center'>
          {ticket.getCustomer().getUser().getId() && (
            <div className='me-5'>
              <div className='symbol symbol-fixed position-relative'>
                <Image
                  photo={new Photo()}
                  width={32}
                  height={32}
                  alt='card img'
                  style={{objectFit: 'cover'}}
                />
              </div>
            </div>
          )}
          <div className='flex-grow-1'>
            <Typography type='h4' className='flex-grow-1 text-gray-800 fs-2 fw-bolder mb-0'>
              {renderTitleCard()}
            </Typography>
          </div>
          {!!ticket.getDisplayTicketCategory() && (
            <div className='w-auto'>
              <Chip
                text={ticket.getDisplayTicketCategory()}
                classNameColor={'badge-light-success'}
                className=''
              />
            </div>
          )}
          {toPersianDigit(ticket.getDate()?.getHours()!)}:
          {toPersianDigit(ticket.getDate()?.getMinutes()!)} - {DisplayJalaliDate(ticket.getDate())}
        </div>
        <div className='d-flex flex-row  mb-3 align-items-start justify-content-start'>
          <div
            className='d-flex flex-wrap flex-sm-nowrap align-items-start justify-content-start'
            style={{flex: 1}}
          >
            {ticket.getDescription()}
          </div>
          {ticket.getMainImage().getId() && (
            <Image
              photo={ticket.getMainImage()}
              width={84}
              height={84}
              alt='card img'
              style={{objectFit: 'cover'}}
            />
          )}
        </div>
      </>
    )
  }

  return (
    <>
      {renderTicket()}
      <div className='separator border-gray-200 mb-5' />
      {ticket
        .getTicketResponses()
        .sort((a, b) => a.getCreatedAt()?.getTime()! - b.getCreatedAt()?.getTime()!)
        .map((response: TicketResponse, index) => (
          <>
            {renderResponse(response)}
            {index !== ticket.getTicketResponses().length - 1 && (
              <div className='separator border-gray-200 mb-5' />
            )}
          </>
        ))}
    </>
  )
}

export {MessageCard}
