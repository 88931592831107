import {ISortingProductBoxRecord, SortingProductBoxRecord} from './SortingProductBoxRecord'

export interface IDeliveryBoxProductPacking {
  id?: string
  createdAt?: string
  deletedAt?: string
  updatedAt?: string
  status?: string
  packingContentTargetId?: string
  packingDeliveryBoxId?: string
  sortingProductBoxRecords?: ISortingProductBoxRecord[]
}

export class DeliveryBoxProductPacking {
  public props: IDeliveryBoxProductPacking = {}

  constructor(data?: IDeliveryBoxProductPacking) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getPackingContentTargetId(): string {
    return this.props.packingContentTargetId || ''
  }

  getPackingDeliveryBoxId(): string {
    return this.props.packingDeliveryBoxId || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getStatus(): string {
    return this.props.status || ''
  }
  getDeletedAt(): string {
    return this.props.deletedAt || ''
  }

  getSortingProductBoxRecords(): SortingProductBoxRecord[] {
    return (this.props.sortingProductBoxRecords || []).map(
      (sortingProduct) => new SortingProductBoxRecord(sortingProduct)
    )
  }
}
