import {Admin, DeliveryChain, IAdmin, IDeliveryChain, IPackingLine, PackingLine} from 'models'

export interface IPackingReport {
  id?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  status?: string
  totalCustomers?: number
  finishedCustomers?: number
  adminId?: string
  deliveryChainId?: string
  packingLineId?: string
  deliveryChain?: IDeliveryChain
  admin?: IAdmin
  packingLine?: IPackingLine
}

export class PackingReport {
  public props: IPackingReport = {}

  constructor(data?: IPackingReport) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDeletedAt(): Date | undefined {
    return this.props.deletedAt ? new Date(this.props.deletedAt) : undefined
  }

  getStatus(): string | undefined {
    return this.props.status
  }

  getTotalCustomers(): number {
    return this.props.totalCustomers || 0
  }

  getFinishedCustomers(): number {
    return this.props.finishedCustomers || 0
  }

  getAdminId(): string | undefined {
    return this.props.adminId
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin)
  }

  getDeliveryChainId(): string | undefined {
    return this.props.deliveryChainId
  }

  getDeliveryChain(): DeliveryChain {
    return new DeliveryChain(this.props.deliveryChain)
  }

  getPackingLineId(): string | undefined {
    return this.props.packingLineId
  }

  getPackingLine(): PackingLine {
    return new PackingLine(this.props.packingLine)
  }
}
