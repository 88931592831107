import {Controller} from 'react-hook-form'
//components
import {Modal} from 'components/elements/Modal'
import {Textfield} from 'components/elements/Textfield'
import {JalaliDatePicker} from 'components/elements/JalaliCalendar'
import {Button} from 'components/elements/Button'
import {Typography} from 'components/elements/Typography'
import DayPicker from 'components/pickers/DayPicker'
import UploadImagesChunk from 'components/elements/UploadImagesChunk'
//hooks
import useText from 'hooks/useText'
//locals
import {texts} from './texts'
import useData from './useData'

export type Props = {
  show: boolean
  handleClose: () => void
}

const CreateCourierModal = ({show, handleClose}: Props) => {
  const {TX} = useText()
  const data = useData({handleClose})

  const renderForm = () => {
    return (
      <form onSubmit={data.onSubmit}>
        <div className='container ' id='kt_modal_create_app_stepper'>
          <div>
            <div className='row mt-5'>
              <div className='col-sm d-flex justify-content-center mb-10 align-items-center flex-column'>
                <UploadImagesChunk
                  disabled={data.isLoading}
                  photos={data.photos}
                  setPhotos={data.setPhotos}
                  isSubmitted={data.isSubmitted}
                  onSubmitFinish={async () => await data.onSubmitFinish()}
                  aspect={1}
                  width='125px'
                  height='125px'
                />
                <Typography type='span' className='mt-2 text-gray-500 text-center'>
                  {TX(texts.click_to_upload)}
                </Typography>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-sm'>
                <Controller
                  name='firstName'
                  control={data.control}
                  render={({field}) => (
                    <Textfield
                      {...field}
                      label={TX(texts.firstName)}
                      name='firstName'
                      type='text'
                      error={!!data.errors.firstName}
                      touched={!!data.errors.firstName?.message}
                      errorText={data.errors.firstName?.message}
                      disabled={data.isLoading}
                    />
                  )}
                />
              </div>
              <div className='col-sm'>
                <Controller
                  name='lastName'
                  control={data.control}
                  render={({field}) => (
                    <Textfield
                      {...field}
                      label={TX(texts.lastName)}
                      name='lastName'
                      type='text'
                      error={!!data.errors.lastName}
                      touched={!!data.errors.lastName?.message}
                      errorText={data.errors.lastName?.message}
                      disabled={data.isLoading}
                    />
                  )}
                />
              </div>
            </div>
            <div className='row my-5'>
              <div className='col-sm'>
                <Controller
                  name='mobileNumber'
                  control={data.control}
                  render={({field}) => (
                    <Textfield
                      {...field}
                      label={TX(texts.phone)}
                      name='mobileNumber'
                      type='phone'
                      error={!!data.errors.mobileNumber}
                      touched={!!data.errors.mobileNumber?.message}
                      errorText={data.errors.mobileNumber?.message}
                      disabled={data.isLoading}
                    />
                  )}
                />
              </div>
              <div className='col-sm'>
                <Controller
                  name='email'
                  control={data.control}
                  render={({field}) => (
                    <Textfield
                      {...field}
                      label={TX(texts.email)}
                      type='text'
                      name='email'
                      error={!!data.errors.email}
                      touched={!!data.errors.email?.message}
                      errorText={data.errors.email?.message}
                      disabled={data.isLoading}
                    />
                  )}
                />
              </div>
            </div>
            <div className='row my-5'>
              <div className='col-sm'>
                <Controller
                  name='birthDate'
                  control={data.control}
                  render={({field}) => (
                    <JalaliDatePicker
                      {...field}
                      name='birthDate'
                      label={TX(texts.birth_date)}
                      value={
                        data.watch().birthDate as unknown as
                          | (Date & (string | number | readonly string[]))
                          | undefined
                      }
                      error={!!data.errors.birthDate}
                      onChange={data.handleChangeBirthDate}
                      disabled={data.isLoading}
                      errorText={data.errors.birthDate?.message}
                      maxDate={new Date()}
                    />
                  )}
                />
              </div>
              <div className='col-sm'>
                <Controller
                  name='vehicle.name'
                  control={data.control}
                  render={({field}) => (
                    <Textfield
                      {...field}
                      label={TX(texts.vehicle_name)}
                      type='text'
                      autoComplete='off'
                      error={!!data.errors.vehicle?.name}
                      touched={!!data.errors.vehicle?.name?.message}
                      errorText={data.errors.vehicle?.name?.message}
                      disabled={data.isLoading}
                      name='vehicle.name'
                    />
                  )}
                />
              </div>
            </div>
            <div className='row my-5'>
              <div className='col-sm-6'>
                <Controller
                  name='vehicle.model'
                  control={data.control}
                  render={({field}) => (
                    <Textfield
                      {...field}
                      label={TX(texts.vehicle_model)}
                      type='text'
                      autoComplete='off'
                      error={!!data.errors.vehicle?.model}
                      touched={!!data.errors.vehicle?.model?.message}
                      errorText={data.errors.vehicle?.model?.message}
                      disabled={data.isLoading}
                      name='vehicle.model'
                    />
                  )}
                />
              </div>
              <div className='col-sm-6'>
                <Controller
                  name='vehicle.plateNumber'
                  control={data.control}
                  render={({field}) => (
                    <Textfield
                      {...field}
                      label={TX(texts.plate)}
                      type='text'
                      autoComplete='off'
                      error={!!data.errors.vehicle?.plateNumber}
                      touched={!!data.errors.vehicle?.plateNumber?.message}
                      errorText={data.errors.vehicle?.plateNumber?.message}
                      disabled={data.isLoading}
                      name='vehicle.plateNumber'
                    />
                  )}
                />
              </div>
            </div>
            <div className='card p-10'>
              <div className='card-body container  '>
                <Typography type='h5'>{TX(texts.working_days)}</Typography>
                <DayPicker
                  options={data.weeklyPlans.map((item) => ({
                    day: item.getDayOfWeek(),
                    id: item.getId(),
                    shift: item.getShift(),
                  }))}
                  onChange={(values) => {
                    const ids = values.map((item) => item.id)
                    data.setFormValue('weeklyPlanIds', ids)
                  }}
                  value={[]}
                />
              </div>
            </div>

            {renderActions()}
          </div>
        </div>
      </form>
    )
  }

  const renderActions = () => {
    return (
      <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
        <Button
          text={TX(texts.cancel)}
          className='main-red-background btn btn-lg background-inherit justify-content-center'
          onClick={() => {
            handleClose()
            data.reset()
          }}
          type='button'
        />

        <Button
          text={TX(texts.submit)}
          className='btn btn-lg btn-primary main-gradient-background'
          isLoading={data.isLoading}
          disabled={!data.isValid || !data.isDirty}
        />
      </div>
    )
  }

  return (
    <Modal
      id='create-courier-modal'
      show={show}
      handleClose={() => {
        handleClose()
        data.reset()
      }}
      title={TX(texts.title)}
    >
      {renderForm()}
    </Modal>
  )
}

export default CreateCourierModal
