import {IsString} from 'class-validator'

export class RemoveWeightToSortingProductBoxRequestDto {
  @IsString({message: 'جایگاه سفارش الزامی است'})
  orderLineId: string

  constructor() {
    this.orderLineId = ''
  }
}
