import {FC, useState, useEffect} from 'react'
import {Outlet} from 'react-router-dom'

// hooks
import useUser from 'hooks/useUser'

// locals
import Context, {IConfig} from 'pages/protected/context'
import {getLayoutFromLocalStorage, PageDescription, PageTitle, useLayout} from 'providers/layout'

const ProtectedLayout: FC = () => {
  const {getUserInfo} = useUser()
  const {setToolbarType} = useLayout()
  const [config, setConfig] = useState<IConfig>({
    pageName: [],
    pageDescription: [],
    breadcrumbsItems: [],
    toolbarType: 'none',
  })

  useEffect(() => {
    getLayoutFromLocalStorage().app?.toolbar?.layout !== config.toolbarType &&
      setToolbarType(config.toolbarType)
  }, [setToolbarType, config])

  useEffect(() => {
    getUserInfo()
  }, [config, getUserInfo])

  return (
    <Context.Provider
      value={{
        config,
        setConfig,
      }}
    >
      <PageTitle breadcrumbs={config.breadcrumbsItems}>
        {config.pageName.map((name) => name)}
      </PageTitle>
      <PageDescription>{config.pageDescription.map((name) => name)}</PageDescription>
      <Outlet />
    </Context.Provider>
  )
}

export default ProtectedLayout
