import 'leaflet/dist/leaflet.css'
import {LatLngExpression, LatLngLiteral, LeafletEventHandlerFnMap} from 'leaflet'
import {MapContainer, Marker, TileLayer, useMap, useMapEvents} from 'react-leaflet'

// locals
import {useStyles} from './style'
import {FC, MutableRefObject} from 'react'
import DrawTools from './DrawTools'
import {ICoordinates} from 'services/regions'
import {Button} from '../Button'
import {iconPin} from './IconPin'
import useText from 'hooks/useText'
import {texts} from './texts'

interface ILeafletMap {
  polygonCoordinates?: ICoordinates[][]
  onChangeList?: React.Dispatch<React.SetStateAction<ICoordinates[][]>>
  coordinates?: LatLngExpression
  onChangePinHandler?: (marker: LatLngLiteral) => void
  onDragPinHandler?: LeafletEventHandlerFnMap
  isChange?: boolean
  isDraggable?: boolean
  scrollWheelZoomMap?: boolean
  mapLocationRef?: MutableRefObject<L.Marker | null>
  height?: string | number
  containerClassName?: string
  zoom?: number
  isSearched?: boolean
  isDrawable?: boolean
}

const ScrollWheelZoomPosition = ({
  scrollWheelZoomMap = false,
  center,
}: {
  scrollWheelZoomMap: boolean
  center: LatLngExpression
}) => {
  const map = useMap()
  if (scrollWheelZoomMap !== map.options.scrollWheelZoom) {
    map.options.scrollWheelZoom = scrollWheelZoomMap
    if (scrollWheelZoomMap) {
      map.scrollWheelZoom.enable()
    } else {
      map.scrollWheelZoom.disable()
    }
  }
  return null
}

const MarkerChange = ({
  onChangePinHandler,
}: {
  onChangePinHandler: (marker: LatLngLiteral) => void
}) => {
  const map = useMapEvents({
    click(e) {
      map.flyTo(e.latlng, map.getZoom())
      onChangePinHandler(e.latlng)
    },
    // drag: (e) => {
    //   map.flyTo(e.target.getCenter(), map.getZoom())
    //   onChangePinHandler(e.target.getCenter())
    // },
  })
  return null
}

const DefaultPosition = ({center}: {center: LatLngExpression}) => {
  const map = useMap()
  map.flyTo(center, 16)

  return null
}

const LeafletMap: FC<ILeafletMap> = ({
  onChangePinHandler,
  onDragPinHandler,
  coordinates,
  isChange = false,
  isDraggable = false,
  scrollWheelZoomMap = false,
  mapLocationRef,
  height,
  containerClassName = '',
  zoom = 11,
  isSearched = false,
  polygonCoordinates,
  onChangeList,
  isDrawable,
  ...props
}) => {
  const {TX} = useText()
  const {classes} = useStyles()
  const initialCenter: LatLngExpression = {
    lat: 35.7219,
    lng: 51.3347,
  }

  return (
    <div className={classes.root}>
      <MapContainer
        center={coordinates ? coordinates : initialCenter}
        zoomControl={false}
        zoom={zoom}
        className={containerClassName}
        style={{
          width: '100%',
          height: '400px',
        }}
      >
        {coordinates && (
          <ScrollWheelZoomPosition scrollWheelZoomMap={scrollWheelZoomMap} center={coordinates} />
        )}
        {isDrawable && <DrawTools onChangeList={onChangeList!} coordinates={polygonCoordinates!} />}
        <TileLayer
          url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
          maxZoom={20}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        />
        {!isDrawable && isChange && <MarkerChange onChangePinHandler={onChangePinHandler!} />}
        {!isDrawable && isSearched && <DefaultPosition center={coordinates!} />}
        {!isDrawable && (
          <Marker
            position={coordinates ? coordinates : initialCenter}
            icon={iconPin}
            draggable={isDraggable}
            ref={mapLocationRef}
            eventHandlers={onDragPinHandler}
          />
        )}
      </MapContainer>
      {isDrawable && polygonCoordinates!.length > 0 && (
        <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
          <Button
            text={TX(texts.remove_coordinates)}
            className='main-red-background btn btn-lg background-inherit justify-content-center'
            type='button'
            onClick={() => {
              onChangeList && onChangeList([])
            }}
          />
        </div>
      )}
    </div>
  )
}

export default LeafletMap
