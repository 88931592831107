import {IsNotEmpty, IsString} from 'class-validator'

export class UpdatePackingLineRequestDto {
  @IsString({message: 'ایدی ترازو الزامی است'})
  @IsNotEmpty({message: 'ایدی ترازو الزامی است'})
  scaleId: string

  @IsString({message: 'مسئول الزامی است'})
  @IsNotEmpty({message: 'مسئول الزامی است'})
  employeeId: string

  constructor() {
    this.scaleId = ''
    this.employeeId = ''
  }
}
