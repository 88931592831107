import {createContext} from 'react'

export interface IPublicRouteContext {
  mobileNumber: string
  setMobileNumber: React.Dispatch<React.SetStateAction<string>>
}

export default createContext<IPublicRouteContext>({
  mobileNumber: '',
  setMobileNumber: () => {},
})
