import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  coupons_title: 'pages.protected.coupon.list.coupons_title',
  coupon_create: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_coupon',
  coupon_code: 'pages.protected.coupon.create.coupon_code',
  price: 'pages.protected.coupon.create.price',
  coupon_number: 'pages.protected.coupon.create.coupon_number',
  expiry_date: 'pages.protected.coupon.create.expiry_date',
  description: 'pages.protected.coupon.create.description',
  edit: 'pages.protected.coupon.list.edit',
  cancel: 'pages.protected.coupon.create.cancel',
  activate_coupon: 'pages.protected.coupon.create.activate_coupon',
  select_new_users: 'pages.protected.coupon.create.select_new_users',
}
