import {FC} from 'react'
import {toAbsoluteUrl} from 'utils/template/helpers'

type ButtonProps = {
  className?: string
  isLoading?: boolean
  text?: string
  loadingText?: string
  iconSource?: string
  type?: 'button' | 'submit' | 'reset' | undefined
} & React.HTMLProps<HTMLButtonElement>

const Button: FC<ButtonProps> = ({
  className,
  isLoading = false,
  text,
  loadingText = 'لطفا منتظر بمانید...',
  type = undefined,
  iconSource,
  ...props
}) => {
  return (
    <button
      {...props}
      type={type}
      className={[
        'btn btn-primary d-flex flex-row justify-center align-items-center gap-2',
        className,
      ].join(' ')}
    >
      {iconSource && (
        <img className='align-middle ms-1' src={toAbsoluteUrl(iconSource)} alt='support' />
      )}
      {!isLoading && text}
      {!isLoading && props.children && props.children}
      {isLoading && (
        <span className='indicator-progress' style={{display: 'block'}}>
          {loadingText} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </button>
  )
}

export {Button}
