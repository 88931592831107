import {Address, IAddress} from './Address'

export interface IEmployee {
  id?: string
  createdAt?: string
  updatedAt?: string
  firstName?: string
  lastName?: string
  mobileNumber?: string
  addresses?: IAddress[]
}

export class Employee {
  public props: IEmployee = {}

  constructor(data?: IEmployee) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getMobileNumber(): string {
    return this.props.mobileNumber || ''
  }

  getFirstName(): string {
    return this.props.firstName || ''
  }

  getLastName(): string {
    return this.props.lastName || ''
  }

  getFullName(): string {
    return this.getFirstName() + ' ' + this.getLastName()
  }

  getAddresses(): Address[] {
    return (this.props.addresses || []).map((address: IAddress) => new Address(address))
  }
}
