import {useEffect, useState} from 'react'

// services
import services, {ServerError} from 'services'
// enums
import {FAQStatusEnum} from 'enums'
// hooks
import useText from 'hooks/useText'
import {useFormik} from 'formik'
import {useMutation} from 'react-query'
import {useSnackbar} from 'notistack'
import {useSchema} from './useSchema'
import useToolbarRefetch from 'hooks/useToolbarRefetch'

// locals
import {texts} from './texts'
import {removeEmptyValues} from 'utils/basic/object'

const useData = (show: boolean, handleClose: () => void) => {
  const {TX} = useText()
  const {enqueueSnackbar} = useSnackbar()
  const {setIsRefetchOn} = useToolbarRefetch()
  const schemas = useSchema()
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const initialValues = {
    category: '',
  }

  /* ================================= */
  /*        Create FAQ Category        */
  /* ================================= */

  const createFAQCategoryHandler = async (body: {name: string; status?: FAQStatusEnum}) =>
    await services.faq.createFAQCategory(body)

  const {isLoading: isLoadingFAQCategory, mutate: mutateFAQCategory} = useMutation(
    createFAQCategoryHandler,
    {
      onSuccess: async (data) => {
        handleClose()
        setIsRefetchOn(true)
        enqueueSnackbar(TX(texts.creation_success), {
          variant: 'success',
        })
      },
      onError: (error) => {
        const {message} = error as ServerError
        form.setSubmitting(false)
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
    }
  )

  const form = useFormik({
    initialValues,
    validationSchema: schemas,
    onSubmit: async () => {
      setIsSubmitted(!isSubmitted)
      await mutateFAQCategory(
        removeEmptyValues({
          name: form.values.category,
          status: FAQStatusEnum.activated,
        })
      )
    },
  })

  useEffect(() => {
    if (!show) {
      form.resetForm()
      form.setValues(initialValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return {
    form,
    loading: isLoadingFAQCategory,
    TX,
    isSubmitted,
    setIsSubmitted,
  }
}

export {useData}
