import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {classValidatorResolver} from '@hookform/resolvers/class-validator'
// enums
import {CouponStatusEnum} from 'enums'
// dtos
import {UpdateCouponRequestDto} from 'dtos/update-coupon-request.dto'
// locals
import {ICouponData} from '.'

export const useData = ({coupon}: ICouponData) => {
  const navigate = useNavigate()

  const {
    handleSubmit,
    control,
    watch,
    setValue: setFormValue,
    formState: {errors},
    reset,
  } = useForm<UpdateCouponRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(UpdateCouponRequestDto),
    defaultValues: {
      status: CouponStatusEnum.deactivated,
      expiryDate: new Date(),
    },
  })

  /* ------------------------------------------------------------ */
  /*                          Handlers                            */
  /* ------------------------------------------------------------ */

  const handleCancel = () => navigate(-1)

  const handleChangeExpiryDate = (value: Date | null) => {
    if (!value) return

    setFormValue('expiryDate', value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const handleSetStatusValue = () =>
    setFormValue(
      'status',
      watch('status') === CouponStatusEnum.activated
        ? CouponStatusEnum.deactivated
        : CouponStatusEnum.activated
    )

  /* ------------------------------------------------------------ */
  /*                          LifeCycle                           */
  /* ------------------------------------------------------------ */

  useEffect(() => {
    if (!coupon) return

    reset({
      description: coupon.getDescription(),
      expiryDate: coupon.getExpiryDate(),
      status: coupon.getStatus(),
    })
  }, [coupon?.getCode(), reset])

  return {
    handleSubmit,
    control,
    errors,
    watch,
    handleCancel,
    handleChangeExpiryDate,
    handleSetStatusValue,
  }
}
