import {IPackingTarget, PackingTarget} from './PackingTarget'
import {IProduct, Product} from './Product'

export interface IPackagingContentTarget {
  id?: string
  createdAt?: string
  updatedAt?: string
  quantity?: number
  weight?: number
  refPackagingTargetId?: string
  endDate?: string
  packagingTargetId?: string
  productId?: string
  alternativeProductId?: string
  refPackagingContentTarget?: IPackagingContentTarget
  refPackagingContentTargets?: IPackagingContentTarget[]
  packagingTarget?: IPackingTarget
  product?: IProduct
  alternativeProduct?: IProduct
}

export class PackagingContentTarget {
  public props: IPackagingContentTarget = {}

  constructor(data?: IPackagingContentTarget) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getQuantity(): number {
    return this.props.quantity || 0
  }

  getWeight(): number {
    return this.props.weight || 0
  }

  getRefPackagingTargetId(): string {
    return this.props.refPackagingTargetId || ''
  }

  getEndDate(): Date | undefined {
    return this.props.endDate ? new Date(this.props.endDate) : undefined
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getAlternativeProductId(): string {
    return this.props.alternativeProductId || ''
  }

  getRefPackagingContentTarget(): PackagingContentTarget {
    return new PackagingContentTarget(this.props.refPackagingContentTarget || {})
  }

  getRefPackagingContentTargets(): PackagingContentTarget[] {
    return (this.props.refPackagingContentTargets || []).map(
      (refPackagingContentTarget: IPackagingContentTarget) =>
        new PackagingContentTarget(refPackagingContentTarget)
    )
  }

  getPackagingTargetId(): string {
    return this.props.packagingTargetId || ''
  }

  getPackagingTarget(): PackingTarget {
    return new PackingTarget(this.props.packagingTarget || {})
  }

  getProduct(): Product {
    return new Product(this.props.product || {})
  }

  getAlternativeProduct(): Product {
    return new Product(this.props.alternativeProduct || {})
  }
}
