export interface IDeliveryBoxPacking {
  id?: string
  createdAt?: string
  deletedAt?: string
  updatedAt?: string
  status?: string
  deliveryBoxId?: string
  packingDeliveryId?: string
}

export class DeliveryBoxPacking {
  public props: IDeliveryBoxPacking = {}

  constructor(data?: IDeliveryBoxPacking) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getDeliveryBoxId(): string {
    return this.props.deliveryBoxId || ''
  }

  getPackingDeliveryId(): string {
    return this.props.packingDeliveryId || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getStatus(): string {
    return this.props.status || ''
  }
  getDeletedAt(): string {
    return this.props.deletedAt || ''
  }
}
