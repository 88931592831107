import {Controller} from 'react-hook-form'
// components
import {Modal} from 'components/elements/Modal'
import {Textfield} from 'components/elements/Textfield'
import {Button} from 'components/elements/Button'
import {AutoComplete} from 'components/elements/AutoComplete'
// models
import {Employee, Scale} from 'models'
// enums
import {ScaleStateEnum, ScaleStatusEnum} from 'enums'
// local
import {useData} from './useData'
import {texts} from './texts'

type Props = {
  show: boolean
  handleClose: () => void
}

const CreateLinesModal = ({show, handleClose}: Props) => {
  const {
    onSubmit,
    control,
    watch,
    setValue,
    isSubmitting,
    errors,
    isValid,
    dirtyFields,
    loading,
    scales,
    loadingScales,
    employees,
    loadingEmployees,
    TX,
  } = useData(show, handleClose)

  return (
    <Modal
      id='create-line-modal'
      show={show}
      handleClose={handleClose}
      title={TX(texts.title_modal_line)}
      children={
        <form onSubmit={onSubmit} encType='multipart/form-data'>
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <div className='fv-row d-flex mb-8 gap-5'>
              <Controller
                name='name'
                control={control}
                render={({field}) => (
                  <Textfield
                    {...field}
                    label={TX(texts.line_name)}
                    type='text'
                    name='name'
                    autoComplete='off'
                    error={!!errors.name}
                    touched={!!errors.name?.message}
                    errorText={errors.name?.message}
                    disabled={loading}
                  />
                )}
              />
              <Controller
                name='lineNumber'
                control={control}
                render={({field}) => (
                  <Textfield
                    {...field}
                    label={TX(texts.line_number)}
                    type='text'
                    name='lineNumber'
                    autoComplete='off'
                    error={!!errors.lineNumber}
                    touched={!!errors.lineNumber?.message}
                    errorText={errors.lineNumber?.message}
                    disabled={loading}
                  />
                )}
              />
            </div>
            <div className='fv-row d-flex mb-8 gap-5'>
              <Controller
                name='scaleId'
                control={control}
                render={({field}) => (
                  <AutoComplete
                    {...field}
                    className='mb-5'
                    loading={loadingScales}
                    disabled={loadingScales}
                    options={scales
                      .filter(
                        (item) =>
                          item.getStatus() === ScaleStatusEnum.free &&
                          item.getState() === ScaleStateEnum.online
                      )
                      .map((option: Scale) => ({
                        value: option.getId(),
                        label: option.getScaleId(),
                      }))}
                    label={TX(texts.scale_id)}
                    value={watch().scaleId}
                    onChange={(option: {value: string; label: string} | any) =>
                      setValue('scaleId', option ? option.value : '', {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }
                  />
                )}
              />
              <Controller
                name='employeeId'
                control={control}
                render={({field}) => (
                  <AutoComplete
                    {...field}
                    className='mb-5'
                    loading={loadingEmployees}
                    disabled={loadingEmployees}
                    options={employees.map((option: Employee) => ({
                      value: option.getId(),
                      label: option.getFullName(),
                    }))}
                    label={TX(texts.employee_id)}
                    value={watch().employeeId}
                    onChange={(option: {value: string; label: string} | any) =>
                      setValue('employeeId', option ? option.value : '', {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }
                  />
                )}
              />
            </div>
            <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
              <Button
                text={TX(texts.cancel)}
                className='main-red-background btn btn-lg background-inherit justify-content-center'
                onClick={handleClose}
                type='button'
              />

              <Button
                text={TX(texts.submit)}
                isLoading={loading}
                className='btn btn-lg btn-primary main-gradient-background'
                type='submit'
                disabled={isSubmitting || !(isValid && dirtyFields.name && dirtyFields.lineNumber)}
              />
            </div>
          </div>
        </form>
      }
    />
  )
}

export {CreateLinesModal}
