import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  warehouse_title_weighing: 'pages.protected.warehouse.weighing.page_title',
  vendor: 'pages.protected.production-line.supplies.products.detail.table-column-seller',
  variety: 'pages.protected.production-line.supplies.products.variety.page_name',
  creation_success:
    'pages.protected.production-line,supplies.products.components.create-product-modal.success_message',
  reports: 'pages.protected.production-line.packing.report.main.table.reports',
  table_subtitle: 'pages.protected.production-line.sorting.lines.main.table-subtitle_lines',
  table_line: 'pages.protected.production-line.packing.report.main.table.table_line',
  table_time: 'pages.protected.production-line.packing.report.main.table.table_time',
  table_line_name: 'pages.protected.production-line.packing.report.main.table.table_line_name',
  table_admin: 'pages.protected.production-line.packing.report.main.table.table_admin',
  table_report: 'pages.protected.production-line.packing.report.main.table.table_report',
  table_line_number: 'pages.protected.production-line.sorting.lines.main.table-line_number-title',
  table_status: 'pages.protected.production-line.packing.report.main.table.table_status',
  table_service_number:
    'pages.protected.production-line.packing.report.main.table.table_service_number',
  table_total_customers:
    'pages.protected.production-line.packing.report.main.table.table_total_customers',
  table_finished_customers:
    'pages.protected.production-line.packing.report.main.table.table_finished_customers',
  table_scale_id: 'pages.protected.production-line.sorting.lines.main.table-scale_id-title',
  table_employee_id: 'pages.protected.production-line.sorting.lines.main.table-employee_id-title',
  status_operation: 'pages.protected.production-line.sorting.lines.main.status_operation',
  edit: 'pages.protected.production-line.sorting.lines.main.edit',
  table_history: 'pages.protected.production-line.sorting.lines.main.table_history',
  packing: "pages.protected.breadcrumbsItems.packing",
  report: "pages.protected.breadcrumbsItems.report",
  finish_status: "pages.protected.production-line.packing.report.finish_status",
  no_finish_status: "pages.protected.production-line.packing.report.no_finish_status",
  filter_delivery_chains: "pages.protected.production-line.packing.report.filter_delivery_chains",
  from_date: "pages.protected.production-line.packing.report.from_date",
  until_date: "pages.protected.production-line.packing.report.until_date",
  filter_packing: "pages.protected.production-line.packing.report.filter_packing",
  filter_packing_status: "pages.protected.production-line.packing.report.filter_packing_status",
  submit: "pages.protected.production-line.packing.report.submit",
}
