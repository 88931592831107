import {IsNotEmpty, IsNumberString, IsString, Length} from 'class-validator'

export class CreateEmployeeRequestDto {
  @IsString({message: 'نام الزامی است'})
  @IsNotEmpty({message: 'نام الزامی است'})
  firstName: string

  @IsString({message: 'نام خانوادگی الزامی است'})
  @IsNotEmpty({message: 'نام خانوادگی الزامی است'})
  lastName: string

  @IsNumberString()
  @Length(11, 11, {message: 'شماره موبایل باید ۱۱ رقم باشد'})
  mobileNumber: string

  constructor() {
    this.firstName = ''
    this.lastName = ''
    this.mobileNumber = ''
  }
}
