import {Equals, IsDate, IsEnum, IsString, IsNotEmpty} from 'class-validator'
import {ScaleActionEnum} from 'enums'

export class WeighingBarcodeScaleRequestDto {
  @IsEnum(ScaleActionEnum)
  @Equals(ScaleActionEnum.weighing_barcode)
  action: ScaleActionEnum

  @IsDate()
  timestamp: Date

  @IsString({message: 'ترازو الزامی است'})
  @IsNotEmpty({message: 'ترازو الزامی است'})
  scale_id: string

  @IsString({message: 'وزن الزامی است'})
  @IsNotEmpty({message: 'وزن الزامی است'})
  weighing_id: string

  @IsString({message: 'بارکد الزامی است'})
  @IsNotEmpty({message: 'بارکد الزامی است'})
  barcode: string

  constructor() {
    this.action = ScaleActionEnum.weighing_barcode
    this.timestamp = new Date()
    this.scale_id = '1'
    this.weighing_id = ''
    this.barcode = ''
  }
}
