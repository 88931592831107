import {IsString, Length} from 'class-validator'

export class AdminVerifyVerificationCodeRequestDto {
  @IsString({message: 'شماره موبایل الزامی است'})
  @Length(11, 11, {message: 'شماره موبایل باید ۱۱ رقم باشد'})
  mobileNumber: string

  @IsString({message: 'کد الزامی است'})
  @Length(4, 4, {message: 'کد باید ۴ رقم باشد'})
  code: string

  constructor() {
    this.mobileNumber = ''
    this.code = ''
  }
}
