import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from 'utils/template/helpers'
import {useLayout} from 'providers/layout'
import {MutableRefObject, useEffect, useRef} from 'react'
import {ToggleComponent} from 'components/template/basic'

type Props = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>
}

const SidebarLogo = ({sidebarRef}: Props) => {
  const sidebarToggleRef = useRef<HTMLDivElement>(null)

  const {config} = useLayout()
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default

  useEffect(() => {
    setTimeout(() => {
      const sidebarToggleInstance = ToggleComponent.getInstance(
        sidebarToggleRef.current!
      ) as ToggleComponent | null

      if (sidebarToggleInstance === null) {
        return
      }

      sidebarToggleInstance.on('kt.toggle.change', function () {
        sidebarRef.current!.classList.add('animating')
        setTimeout(function () {
          sidebarRef.current!.classList.remove('animating')
        }, 300)
      })
    }, 600)
  }, [sidebarToggleRef, sidebarRef])

  return (
    <div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo'>
      <Link to='/dashboard'>
        {config.layoutType === 'dark-sidebar' ? (
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/default-dark-sarsabad.svg')}
            className='h-40px app-sidebar-logo-default'
          />
        ) : (
          <>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/default.svg')}
              className='h-25px app-sidebar-logo-default theme-light-show'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/default-dark.svg')}
              className='h-25px app-sidebar-logo-default theme-dark-show'
            />
          </>
        )}

        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/default-small-sarsabad.svg')}
          className='h-20px app-sidebar-logo-minimize'
        />
      </Link>

      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id='kt_app_sidebar_toggle'
          ref={sidebarToggleRef}
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-success body-bg h-30px w-30px position-absolute top-50 start-100 rotate',
            {active: appSidebarDefaultMinimizeDefault}
          )}
          style={{transform: 'translate(50%, -50%)'}}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr079-sarsabad.svg'
            className='svg-icon-2 rotate-180'
          />
        </div>
      )}
    </div>
  )
}

export {SidebarLogo}
