import {IPhoto} from 'models'
import ClientApi from './clientApi'
import apiConfig from 'services/config'

const {rootAddress} = apiConfig

const axios = new ClientApi()

const prefix = '/files/admin'

export const uploadPhotoAdmin = (imageFile: File): Promise<IPhoto> => {
  const formData = new FormData()
  formData.append('file', imageFile)

  return axios.http.post(rootAddress + prefix + '/upload-photo', formData, {
    headers: {'content-type': 'multipart/form-data'},
  })
}
