import {useState} from 'react'
import {KTSVG} from 'utils/template/helpers'
import {CreateNewBoxModal} from 'pages/protected/ProductionLine/Supplies/Boxes/components/CreateNewBoxModal'

import useText from 'hooks/useText'

import {texts} from './texts'

const ToolbarNewBox = () => {
  const [showCreateBoxModal, setShowCreateBoxModal] = useState<boolean>(false)
  const {TX} = useText()

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div
        className='btn btn-sm main-gradient-background fw-bold d-flex align-items-center px-4'
        onClick={() => setShowCreateBoxModal(true)}
      >
        <KTSVG
          path='/media/icons/duotune/art/add-circle-sarsabad.svg'
          className='svg-icon-1 ms-2 me-0 text-white'
        />
        <div className='text-white fw-bold'>{TX(texts.new_box)}</div>
      </div>
      <CreateNewBoxModal
        show={showCreateBoxModal}
        handleClose={() => setShowCreateBoxModal(false)}
      />
    </div>
  )
}

export {ToolbarNewBox}
