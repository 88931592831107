export enum WeeklyPlanDayOfWeekEnum {
  saturday = 'saturday',
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
}

export enum WeeklyPlanShiftEnum {
  morning = 'morning',
  after_noon = 'after_noon',
}
