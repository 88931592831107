import {Expose} from 'class-transformer'
import {IsBoolean, IsEnum, IsOptional, IsString} from 'class-validator'
import {CouponStatusEnum, CouponTypeEnum, GetCouponsRequestDtoSortEnum} from 'enums'
import {SortTypeEnum} from 'models'
import {PaginateRequestDto} from './paginate.request.dto'

export class GetCouponsRequestDtoFiltersDto extends PaginateRequestDto {
  @IsBoolean()
  @IsOptional()
  @Expose()
  'filters[forSpecificUsers]'?: boolean

  @IsEnum(CouponStatusEnum)
  @IsOptional()
  @Expose()
  'filters[status]'?: CouponStatusEnum

  @IsEnum(CouponTypeEnum)
  @IsOptional()
  @Expose()
  'filters[type]'?: CouponTypeEnum

  @IsOptional()
  @Expose()
  @IsString()
  'filters[fromDate]'?: string

  @IsOptional()
  @Expose()
  @IsString()
  'filters[toDate]'?: string

  @IsEnum(SortTypeEnum)
  @IsOptional()
  @Expose()
  sortType?: SortTypeEnum

  @IsEnum(GetCouponsRequestDtoSortEnum)
  @IsOptional()
  @Expose()
  sort?: GetCouponsRequestDtoSortEnum

  @IsOptional()
  @IsString()
  search?: string
}
