import useText from 'hooks/useText'
import {SortTypeEnum} from 'models'
import {ReactNode} from 'react'
import {KTSVG} from 'utils/template/helpers'
import {Typography} from '../Typography'

// local
import {texts} from './texts'
import {useStyles} from './style'
import {Tooltip} from '../Tooltip'

export interface ITableColumn<C> {
  name: string
  label?: string | ReactNode
  size?: string
  sort?: boolean
  cell: (item: C, index: number) => ReactNode
  isTooltip?: boolean
  tooltipTitle?: (item: C, index: number) => string | ReactNode
}

export interface IColumnSortTable {
  columnName: string
  direction: SortTypeEnum | undefined
}

interface ITable<T extends {}> {
  rows: T[]
  columns: ITableColumn<T>[]
  loading?: boolean
  emptyText?: string | null
  columnSort?: IColumnSortTable
  onColumnSortClick?: (columnSortTable?: IColumnSortTable) => void
}

const Table = <T extends {}>({
  rows = [],
  columns = [],
  loading = false,
  emptyText = null,
  columnSort,
  onColumnSortClick = () => {},
}: ITable<T>) => {
  const {TX} = useText()
  const {classes} = useStyles()

  return (
    <>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          {loading ? (
            <div className='d-flex flex-row justify-content-center align-items-center w-100 h-450px'>
              <h5>{TX(texts.loading)}</h5>
            </div>
          ) : (
            <table
              className={[
                classes.tableWrapper,
                'table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4',
              ].join(' ')}
            >
              <thead>
                <tr className={[classes.tableRowHead, 'fw-bold text-muted'].join(' ')}>
                  {columns.map((column, index) => (
                    <th
                      className={column.size}
                      key={`${column}-${index}`}
                      style={
                        column.sort
                          ? columnSort &&
                            columnSort.columnName === column.name &&
                            columnSort.direction !== undefined
                            ? {color: 'black', textAlign: 'right', cursor: 'pointer'}
                            : {textAlign: 'right', cursor: 'pointer'}
                          : {textAlign: 'right'}
                      }
                      onClick={() => {
                        if (loading || !column.sort) return
                        if (
                          columnSort &&
                          columnSort.columnName === column.name &&
                          columnSort.direction === SortTypeEnum.ASC
                        )
                          return onColumnSortClick({
                            columnName: column.name,
                            direction: SortTypeEnum.DESC,
                          })
                        else if (
                          columnSort &&
                          columnSort.columnName === column.name &&
                          columnSort.direction === SortTypeEnum.DESC
                        )
                          return onColumnSortClick()
                        else
                          return onColumnSortClick({
                            columnName: column.name,
                            direction: SortTypeEnum.ASC,
                          })
                      }}
                    >
                      {columnSort &&
                        columnSort.columnName === column.name &&
                        columnSort.direction === SortTypeEnum.ASC && (
                          <KTSVG
                            className='svg-icon main-green'
                            svgClassName='mh-100px h-20px'
                            path='/media/icons/duotune/arrows/arr004.svg'
                          />
                        )}
                      {columnSort &&
                        columnSort.columnName === column.name &&
                        columnSort.direction === SortTypeEnum.DESC && (
                          <KTSVG
                            className='svg-icon main-green'
                            svgClassName='mh-100px h-20px'
                            path='/media/icons/duotune/arrows/arr003.svg'
                          />
                        )}
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              {rows.length > 0 && (
                <tbody>
                  {rows.map((row, rowIndex) => (
                    <tr className={classes.tableRowBody} key={`${row}-${rowIndex}`}>
                      {columns.map((column, columnIndex) =>
                        column.isTooltip ? (
                          <Tooltip
                            title={
                              column.tooltipTitle
                                ? column.tooltipTitle(row, rowIndex)
                                : column.cell(row, rowIndex)
                            }
                          >
                            <td key={`${column}-${columnIndex}`}>
                              <>{column.cell(row, rowIndex)}</>
                            </td>
                          </Tooltip>
                        ) : (
                          <td key={`${column}-${columnIndex}`}>{column.cell(row, rowIndex)}</td>
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          )}
        </div>
      </div>
      {rows.length === 0 && !loading && (
        <div className='d-flex flex-row justify-content-center align-items-center min-w-100 w-100 h-150px'>
          <Typography type='h5' className='w-100 text-center'>
            {emptyText ?? TX(texts.empty)}
          </Typography>
        </div>
      )}
    </>
  )
}

export {Table}
