import {Delivery, IDelivery} from './Delivery'
import {ISubscription, Subscription} from './Subscription'
import {Customer, ICustomer} from './Customer'
import {IWallet, Wallet} from './Wallet'
import {ITransaction, Transaction} from './Transaction'
import {IPhotoUse, PhotoUse} from './PhotoUse'
import {
  WalletTransactionCreatedByEnum,
  WalletTransactionReasonEnum,
  WalletTransactionTypeEnum,
} from 'enums'

export interface IWalletTransaction {
  id?: string
  createdAt?: string
  updatedAt?: string
  amount?: number
  refundDetails?: {}
  type?: WalletTransactionTypeEnum
  reason?: WalletTransactionReasonEnum
  createdBy?: WalletTransactionCreatedByEnum
  description?: string
  deliveryId?: string
  delivery?: IDelivery
  subscriptionId?: string
  subscription?: ISubscription
  walletId?: string
  wallet?: IWallet
  transactionId?: string
  transaction?: ITransaction
  customerId?: string
  customer?: ICustomer
  photoUses?: IPhotoUse[]
}

export class WalletTransaction {
  public props: IWalletTransaction = {}

  constructor(data?: IWalletTransaction) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getAmount(): number {
    return this.props.amount || 0
  }

  getRefundDetails(): {} {
    return this.props.refundDetails || {}
  }

  getType(): WalletTransactionTypeEnum {
    return this.props.type as WalletTransactionTypeEnum
  }

  getReason(): WalletTransactionReasonEnum {
    return this.props.reason as WalletTransactionReasonEnum
  }

  getCreatedBy(): WalletTransactionCreatedByEnum {
    return this.props.createdBy as WalletTransactionCreatedByEnum
  }

  getDescription(): string {
    return this.props.description || ''
  }

  getDeliveryId(): string {
    return this.props.deliveryId || ''
  }

  getDelivery(): Delivery {
    return new Delivery(this.props.delivery || {})
  }

  getSubscriptionId(): string {
    return this.props.subscriptionId || ''
  }

  getSubscription(): Subscription {
    return new Subscription(this.props.subscription || {})
  }

  getWalletId(): string {
    return this.props.walletId || ''
  }

  getWallet(): Wallet {
    return new Wallet(this.props.wallet || {})
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || [])
      .map((photoUse) => new PhotoUse(photoUse))
      .sort((a, b) => a.getOrderNumber() - b.getOrderNumber())
  }

  /* -------------------------------------------------------------------------- */
  /*                                   Custom                                   */
  /* -------------------------------------------------------------------------- */

  getDisplayType(): string {
    return displayWalletTransactionTypeEnum(this.getType())
  }

  getDisplayReason(): string {
    return displayWalletTransactionReasonEnum(this.getReason())
  }

  getDisplayCreatedBy(): string {
    return displayWalletTransactionCreatedByEnum(this.getCreatedBy())
  }
}

export const displayWalletTransactionTypeEnum = (
  walletTransactionTypeEnum: WalletTransactionTypeEnum
): string => {
  switch (walletTransactionTypeEnum) {
    case WalletTransactionTypeEnum.deposit:
      return 'سپرده'
    case WalletTransactionTypeEnum.withdrawal:
      return 'برداشت از اعتبار'
    default:
      return ''
  }
}

export const displayWalletTransactionReasonEnum = (
  walletTransactionReasonEnum: WalletTransactionReasonEnum
): string => {
  switch (walletTransactionReasonEnum) {
    case WalletTransactionReasonEnum.pay:
      return 'پرداخت'
    case WalletTransactionReasonEnum.back_to_card:
      return 'بازگشت به کارت'
    case WalletTransactionReasonEnum.admin_gift:
      return 'هدیه ادمین'
    case WalletTransactionReasonEnum.refund_delivery:
      return 'بازگشت ارسال'
    case WalletTransactionReasonEnum.refund_subscription:
      return 'بازگشت سفارش'
    default:
      return ''
  }
}

export const displayWalletTransactionCreatedByEnum = (
  walletTransactionCreatedByEnum: WalletTransactionCreatedByEnum
): string => {
  switch (walletTransactionCreatedByEnum) {
    case WalletTransactionCreatedByEnum.admin:
      return 'ادمین'
    case WalletTransactionCreatedByEnum.customer:
      return 'مشتری'
    default:
      return ''
  }
}
