import ClientApi from './clientApi'
import apiConfig from 'services/config'

const {rootAddress} = apiConfig

const axios = new ClientApi()

const prefix = '/upload'

export const chunkFile = async (
  xFileName: string,
  xFileSize: number,
  xStartChunk: number,
  chunk: string | ArrayBuffer | undefined
) =>
  axios.http.post(rootAddress + prefix + '/chunk-file', chunk, {
    headers: {
      'content-type': 'application/octet-stream',
      'x-file-name': xFileName,
      'x-file-size': String(xFileSize),
      'x-start-chunk': String(xStartChunk),
    },
  })

export const complete = (xFileNames: string[]): Promise<{photoIds: string[]}> => {
  return axios.http.post(
    rootAddress + prefix + '/complete',
    {},
    {
      headers: {
        'content-type': 'application/octet-stream',
        'x-file-names': JSON.stringify(xFileNames),
      },
    }
  )
}

export const remove = (xFileName: string) => {
  return axios.http.delete(rootAddress + prefix + '/remove', {
    headers: {'x-file-name': xFileName},
  })
}

export const getPhoto = async (photoId: string) =>
  axios.http
    .get(rootAddress + prefix + '/' + photoId, {
      headers: {'Content-Type': 'multipart/form-data'},
      responseType: 'blob',
    }) // @ts-ignore
    .then((response) => new Blob([response]))
    .then((blob) => URL.createObjectURL(blob))
