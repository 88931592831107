import ClientApi from './clientApi'
import config from './config'
import queryString, {StringifiableRecord} from 'query-string'
// models
import {
  IDto,
  IOrderLine,
  ILine,
  ISortingProduct,
  SortingProductBoxStatusEnum,
  ISortingProductBox,
} from 'models'
// dtos
import {
  AddWeightToSortingProductBoxRequestDto,
  CreateSortingLineRequestDto,
  RemoveWeightToSortingProductBoxRequestDto,
  StartSortingProductBoxRequestDto,
  StartSortingProductRequestDto,
  UpdateSortingLineRequestDto,
  UpdateSortingProductRequestDto,
} from 'dtos'

const {rootAddress} = config

const prefix = '/dashboard/sorting'

const axios = new ClientApi()

export const getSortingProductOrderLines = async (
  productId: string,
  query: StringifiableRecord
): Promise<IDto<IOrderLine>> =>
  await axios.http.get(
    queryString.stringifyUrl({
      url: rootAddress + prefix + '/sorting-products/' + productId + '/order-lines',
      query,
    })
  )

export const getSortingProductBarcodes = async (query: StringifiableRecord, fileName: string) =>
  await axios.http
    .get(
      queryString.stringifyUrl({
        url: rootAddress + prefix + '/sorting-products/barcodes',
        query,
      }),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      }
    )
    // @ts-ignore
    .then((response: BlobPart) => {
      const url = window.URL.createObjectURL(new Blob([response], {type: 'application/pdf'}))

      const link = document.createElement('a')
      link.href = url
      link.download = fileName
      // link.setAttribute('download', body.fileName + '.pdf')

      document.body.appendChild(link)

      link.click()
      link.parentNode!.removeChild(link)
    })

export const getSortingProductReports = async (
  query: StringifiableRecord
): Promise<IDto<ISortingProduct>> =>
  await axios.http.get(
    queryString.stringifyUrl({
      url: rootAddress + prefix + '/report',
      query,
    })
  )

export const getSortingProductDetails = async (
  productId: string,
  query: StringifiableRecord
): Promise<ISortingProduct> =>
  await axios.http.get(
    queryString.stringifyUrl({
      url: rootAddress + prefix + '/sorting-products/' + productId,
      query,
    })
  )

export const createBarcodeSortingProductBoxes = async (
  sortingProductId: string,
  sortingProductBoxId: string,
  fileName: string
) =>
  await axios.http
    .post(
      rootAddress +
        prefix +
        '/sorting-products/' +
        sortingProductId +
        '/sorting-product-boxes/' +
        sortingProductBoxId +
        '/barcode',
      {},
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      }
    )
    // @ts-ignore
    .then((response: BlobPart) => {
      const url = window.URL.createObjectURL(new Blob([response], {type: 'application/pdf'}))

      const link = document.createElement('a')
      link.href = url
      link.download = fileName
      // link.setAttribute('download', body.fileName + '.pdf')

      document.body.appendChild(link)

      link.click()
      link.parentNode!.removeChild(link)
    })

export const createStartSortingProductBoxes = async (
  sortingProductId: string,
  body: StartSortingProductBoxRequestDto
): Promise<ISortingProductBox> =>
  await axios.http.post(
    rootAddress + prefix + '/sorting-products/' + sortingProductId + '/sorting-product-box/start',
    body
  )

export const createStartSortingProducts = async (
  productId: string,
  body: StartSortingProductRequestDto
): Promise<ISortingProduct> =>
  await axios.http.post(rootAddress + prefix + '/sorting-products/' + productId + '/start', body)

export const updateStatusSortingProducts = async (
  sortingProductId: string,
  body: UpdateSortingProductRequestDto
): Promise<ISortingProduct> =>
  await axios.http.patch(
    rootAddress + prefix + '/sorting-products/' + sortingProductId + '/status',
    body
  )

export const updateStatusSortingProductBoxes = async (
  sortingProductId: string,
  sortingProductBoxId: string,
  body: {
    status: SortingProductBoxStatusEnum
  }
): Promise<ISortingProductBox> =>
  await axios.http.patch(
    rootAddress +
      prefix +
      '/sorting-products/' +
      sortingProductId +
      '/sorting-product-boxes/' +
      sortingProductBoxId +
      '/status',
    body
  )

export const createWeightSortingProductBoxes = async (
  sortingProductId: string,
  sortingProductBoxId: string,
  body: AddWeightToSortingProductBoxRequestDto
): Promise<ISortingProduct> =>
  await axios.http.post(
    rootAddress +
      prefix +
      '/sorting-products/' +
      sortingProductId +
      '/sorting-product-boxes/' +
      sortingProductBoxId +
      '/record-weight',
    body
  )

export const deleteWeightSortingProductBoxes = async (
  sortingProductId: string,
  sortingProductBoxId: string,
  body: RemoveWeightToSortingProductBoxRequestDto
): Promise<ISortingProduct> =>
  await axios.http.delete(
    rootAddress +
      prefix +
      '/sorting-products/' +
      sortingProductId +
      '/sorting-product-boxes/' +
      sortingProductBoxId +
      '/record-weight',
    {data: body}
  )

export const removeSortingProductBox = async (
  sortingProductId: string,
  sortingProductBoxId: string
): Promise<ISortingProduct> =>
  await axios.http.delete(
    rootAddress +
      prefix +
      '/sorting-products/' +
      sortingProductId +
      '/sorting-product-boxes/' +
      sortingProductBoxId +
      '/remove'
  )

export const getSortingProductBoxesDetails = async (
  sortingProductId: string,
  query: StringifiableRecord
): Promise<ISortingProductBox> =>
  await axios.http.get(
    queryString.stringifyUrl({
      url: rootAddress + prefix + '/sorting-products/' + sortingProductId + '/sorting-product-box',
      query,
    })
  )

export const getSortingProductBoxes = async (
  productId: string,
  query: StringifiableRecord
): Promise<ISortingProduct[]> =>
  await axios.http.get(
    queryString.stringifyUrl({
      url: rootAddress + prefix + '/sorting-products/' + productId + '/boxes',
      query,
    })
  )

export const getSortingProducts = async (query: StringifiableRecord): Promise<ISortingProduct[]> =>
  await axios.http.get(
    queryString.stringifyUrl({
      url: rootAddress + prefix + '/sorting-products',
      query,
    })
  )

export const getSortingLines = async (query: StringifiableRecord): Promise<IDto<ILine>> =>
  await axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix + '/lines', query}))

export const createSortingLines = async (body: CreateSortingLineRequestDto): Promise<ILine> =>
  await axios.http.post(rootAddress + prefix + '/lines', body)

export const getSortingLinesDetails = async (sortingLineId: string): Promise<ILine> =>
  await axios.http.get(rootAddress + prefix + '/lines/' + sortingLineId)

export const updateSortingLines = async (
  sortingLineId: string,
  body: UpdateSortingLineRequestDto
): Promise<ILine> => await axios.http.put(rootAddress + prefix + '/lines/' + sortingLineId, body)

export const getSortingLinesHistories = async (
  sortingLineId: string,
  query: StringifiableRecord
): Promise<IDto<ILine>> =>
  await axios.http.get(
    queryString.stringifyUrl({
      url: rootAddress + prefix + '/lines/' + sortingLineId + '/histories',
      query,
    })
  )

export const deactivateSortingLines = async (sortingLineId: string): Promise<ILine> =>
  await axios.http.patch(rootAddress + prefix + '/lines/' + sortingLineId + '/deactivate')

export const activateSortingLines = async (sortingLineId: string): Promise<ILine> =>
  await axios.http.patch(rootAddress + prefix + '/lines/' + sortingLineId + '/activate')
