import {useEffect, useId, useRef, useState} from 'react'
// hooks
import useText from 'hooks/useText'

// components
import {SearchComponent} from 'components/template/basic'

type useDataProps = {
  query: any
  onSubmit: () => void
}

export const useData = ({query, onSubmit}: useDataProps) => {
  const {TX} = useText()

  const [menuState, setMenuState] = useState<'main' | 'advanced'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const [search, setSearch] = useState<SearchComponent>()
  const [openSearch, setOpenSearch] = useState<boolean>()
  const id = useId()

  useEffect(() => {
    const tempSearch = SearchComponent.createInsance('#kt_header_search' + id.split(':')[1])
    setSearch(tempSearch)
  }, [id])

  useEffect(() => {
    const enterSubmitFilter = (e: KeyboardEvent) => {
      if ((query?.search?.length <= 0 || !query?.search) && menuState === 'main') return
      if (e.key === 'Enter') {
        onSubmit()
        setMenuState('main')
        search?.hide()
      }
    }
    element.current?.addEventListener('keydown', enterSubmitFilter)
    return () => element.current?.removeEventListener('keydown', enterSubmitFilter)
  }, [query, search, menuState])

  useEffect(() => {
    const focusSearch = () => {
      search?.inputElement.focus()
    }
    element.current?.addEventListener('click', focusSearch)
  }, [search])

  return {
    menuState,
    setMenuState,
    TX,
    search,
    wrapperElement,
    openSearch,
    setOpenSearch,
    element,
    id: id.split(':')[1],
  }
}
