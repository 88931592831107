import {ChangeEvent, FC, useState} from 'react'
import {toEnglishDigit, toPersianDigit} from 'utils/basic/digit'
import useStyles from './useStyles'
import {IconButton} from '@mui/material'
import {Visibility, VisibilityOff} from '@mui/icons-material'

type TextfieldProps = {
  className?: string
  labelClassName?: string
  label?: string
  type?: string
  name?: string
  placeholder?: string
  placeholderCenter?: boolean
  helperText?: string
  helperTextColor?: string
  value?: string
  error?: boolean
  errorText?: string
  setValue?: (T: any) => void
  params?: object
  touched?: boolean
  usePersianDigits?: boolean
  disabled?: boolean
} & React.HTMLProps<HTMLInputElement>

const Textfield: FC<TextfieldProps> = ({
  className = 'form-control-solid',
  labelClassName = '',
  label = '',
  type = 'text',
  name,
  placeholder = '',
  placeholderCenter = true,
  helperText = '',
  helperTextColor = '#001A0B',
  value = '',
  error = false,
  errorText = '',
  setValue = () => {},
  params = {},
  touched = false,
  usePersianDigits = true,
  disabled = false,
  ...props
}) => {
  const {classes} = useStyles({type})
  const [inputValue, setInputValue] = useState<string>(value)
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)

  const displayValue = usePersianDigits ? toPersianDigit(value) : value

  const {onChange, ...restProps} = props

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    setInputValue(inputValue)

    const englishValue = toEnglishDigit(inputValue)

    if (onChange) {
      e.target.value = englishValue
      onChange(e)
    }
  }

  const renderEndAdornment = () => {
    if (type !== 'password') return
    return (
      <IconButton
        disabled={disabled}
        disableRipple
        className={classes.visibilityIcon}
        onClick={() => setIsShowPassword((prev) => !prev)}
      >
        {isShowPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    )
  }

  const renderLabel = () => {
    if (!label) return
    return (
      <label
        className={[
          'd-flex align-items-center fs-4 fw-semibold mb-2',
          labelClassName,
          props.required ? 'required' : '',
        ].join(' ')}
      >
        <span>{label}</span>
      </label>
    )
  }

  const renderHelperText = () => {
    if (!helperText) return
    return (
      <div
        className='fv-plugins-message-container'
        style={{color: `${helperTextColor} !important`}}
      >
        <div className='fv-help-block'>
          <span>{helperText}</span>
        </div>
      </div>
    )
  }

  const renderInput = () => {
    return (
      <input
        {...restProps}
        type={isShowPassword || type === 'number' ? 'text' : type}
        className={[
          placeholderCenter ? classes.input : '',
          'form-control form-control-lg',
          className,
          `${touched && error ? 'is-invalid' : ''} ${touched && !error ? 'is-valid' : ''}`,
        ].join(' ')}
        name={name}
        placeholder={placeholder}
        value={
          disabled ? toPersianDigit(value || '') : !!value && !!displayValue ? displayValue : value
        }
        onChange={
          disabled
            ? (e) => {
                setValue(e.currentTarget.value)
              }
            : handleChange
        }
        onBlur={handleChange}
        disabled={disabled}
        {...params}
      />
    )
  }

  return (
    <div className='fv-row w-100'>
      {renderLabel()}
      {type === 'password' ? (
        <div className={[placeholderCenter ? classes.inputWrapper : '', className].join(' ')}>
          {renderInput()}
          {renderEndAdornment()}
        </div>
      ) : (
        renderInput()
      )}
      {renderHelperText()}
      {touched && error && (
        <div
          className={['fv-plugins-message-container', classes.errorText].join(' ')}
          style={{color: '#001A0B'}}
        >
          <div className='fv-help-block'>
            <span>{errorText}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export {Textfield}
