import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    tooltipWrapper: {
      fontSize: '11px',
      fontWeight: 400,
      fontFamily: 'Vazirmatn',
      backgroundColor: '#101828',
      borderRadius: '10px',
      padding: theme.spacing(1.2, 1.5),
      '& *': {
        color: `${theme.palette.common.white} !important`,
        background: 'transparent !important',
      },
    },
    arrowWrapper: {
      left: '50% !important',
      transform: 'translate(-50%, 0) !important',
      color: '#101828 !important',
    },
  }
})
