import {TicketModal} from 'components/modals/TicketModal'
import {SubmitTicketStepEnum} from 'components/modals/TicketModal/useData'
import {useState} from 'react'
import {KTSVG} from 'utils/template/helpers'
import { texts } from './texts'
import useText from 'hooks/useText'

const ToolbarTicket = () => {
  const {TX} = useText()
  const [showCreateTicketModal, setShowCreateTicketModal] = useState<boolean>(false)

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div
        className='btn btn-sm main-gradient-background fw-bold d-flex align-items-center px-4 gap-1'
        onClick={() => setShowCreateTicketModal(true)}
      >
        <KTSVG
          path='/media/icons/duotune/art/add-circle-sarsabad.svg'
          className='svg-icon-1 ms-2 me-0 text-white'
        />
        <div className='text-white fw-bold'>{TX(texts.new_ticket)}</div>
      </div>
      <TicketModal
        show={showCreateTicketModal}
        handleClose={() => setShowCreateTicketModal(false)}
        atStep={SubmitTicketStepEnum.onlyTicketing}
      />
    </div>
  )
}

export {ToolbarTicket}
