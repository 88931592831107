import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  transactions: 'pages.protected.breadcrumbsItems.transactions',
  name: 'pages.protected.support.transactions.list.name',
  price: 'pages.protected.support.transactions.list.price',
  creation_date: 'pages.protected.support.transactions.list.creation_date',
  creation_hour: 'pages.protected.support.transactions.list.creation_hour',
  mobile_number: 'pages.protected.support.transactions.list.mobile_number',
  status: 'pages.protected.support.transactions.list.status',
  show_receipt: 'pages.protected.support.transactions.list.show_receipt',
  edit: 'pages.protected.support.transactions.list.edit',
  determining_status: 'pages.protected.support.transactions.list.determining_status',
  accept: 'pages.protected.support.transactions.list.accept',
  reject: 'pages.protected.support.transactions.list.reject',
  accept_transaction_success:
    'pages.protected.support.transactions.list.accept_transaction_success',
  reject_transaction_success:
    'pages.protected.support.transactions.list.reject_transaction_success',
  upload_document_payment:
    'pages.protected.support.transactions.list.components.upload_document_payment',
  back: 'pages.protected.support.transactions.list.components.back',
  submit: 'pages.protected.support.transactions.list.components.submit',
  not_valid: 'pages.protected.support.transactions.list.components.not_valid',
  required: 'pages.protected.support.transactions.list.components.required',
  upload_document_success:
    'pages.protected.support.transactions.list.components.upload_document_success',
}
