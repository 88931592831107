import clsx from 'clsx'
import {HeaderUserMenu} from 'components/template/header-menus'
import {KTSVG} from 'utils/template/helpers'

const Navbar = () => {
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', 'ms-1 ms-lg-3')}>
        <div
          className={clsx(
            'cursor-pointer btn btn-icon w-20px btn-sm btn-active-color-primary symbol',
            'symbol-35px symbol-md-40px'
          )}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Navbar}
