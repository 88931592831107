import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  title: 'pages.protected.delivery.couriers.list.create_courier_modoal.title',
  name: 'pages.protected.delivery.couriers.list.create_courier_modoal.name',
  firstName: 'pages.protected.delivery.couriers.list.create_courier_modoal.firstName',
  lastName: 'pages.protected.delivery.couriers.list.create_courier_modoal.lastName',
  image: 'pages.protected.delivery.couriers.list.create_courier_modoal.image',
  phone: 'pages.protected.delivery.couriers.list.create_courier_modoal.phone',
  email: 'pages.protected.delivery.couriers.list.create_courier_modoal.email',
  birth_date: 'pages.protected.delivery.couriers.list.create_courier_modoal.birth_date',
  vehicle_type: 'pages.protected.delivery.couriers.list.create_courier_modoal.vehicle_type',
  vehicle_name: 'pages.protected.delivery.couriers.list.create_courier_modoal.vehicle_name',
  vehicle_model: 'pages.protected.delivery.couriers.list.create_courier_modoal.vehicle_model',
  plate: 'pages.protected.delivery.couriers.list.create_courier_modoal.plate',
  working_days: 'pages.protected.delivery.couriers.list.create_courier_modoal.working_days',
  submit: 'pages.protected.delivery.couriers.list.create_courier_modoal.submit',
  cancel: 'pages.protected.delivery.couriers.list.create_courier_modoal.cancel',
  require: 'pages.protected.delivery.couriers.list.create_courier_modoal.require',
  optional: 'pages.protected.delivery.couriers.list.create_courier_modoal.optional',
  invalid: 'pages.protected.delivery.couriers.list.create_courier_modoal.invalid',
  you_must_select_at_least_one_item:
    'pages.protected.delivery.couriers.list.create_courier_modoal.you_must_select_at_least_one_item',
  courier_added_successfully:
    'pages.protected.delivery.couriers.list.create_courier_modoal.courier_added_successfully',

  mobile_number_is_require: 'pages.protected.subscribers.list.components.mobile_number_is_require',
  first_name_is_require: 'pages.protected.subscribers.list.components.first_name_is_require',
  last_name_is_require: 'pages.protected.subscribers.list.components.last_name_is_require',
  phone_must_be_11_character:
    'pages.protected.subscribers.list.components.phone_must_be_11_character',
  phone_is_not_valid: 'pages.protected.subscribers.list.components.phone_is_not_valid',
  email_is_not_valid: 'pages.protected.subscribers.list.components.email_is_not_valid',
  new_customer: "pages.protected.delivery.chains.define.components.new_customer",
  customer: "pages.protected.delivery.chains.define.customer",
  create_customers_success: "pages.protected.delivery.chains.define.components.create_customers_success"
}
