import {useState} from 'react'

//hooks
import useText from 'hooks/useText'

//utils
import {KTSVG} from 'utils/template/helpers'

//locals
import CreateCourierModal from 'pages/protected/Delivery/Couriers/list/components/CreateCourierModal'
import {texts} from './texts'

const ToolbarCourier = () => {
  const [showCreateCouriersModal, setShowCreateCouriersModal] = useState<boolean>(false)
  const {TX} = useText()

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div
        className='btn btn-sm main-gradient-background fw-bold d-flex align-items-center px-4 gap-1'
        onClick={() => setShowCreateCouriersModal(true)}
      >
        <KTSVG
          path='/media/icons/duotune/art/add-circle-sarsabad.svg'
          className='svg-icon-1 ms-2 me-0 text-white'
        />
        <div className='text-white fw-bold'>{TX(texts.new_courier)}</div>
      </div>
      <CreateCourierModal
        show={showCreateCouriersModal}
        handleClose={() => {
          setShowCreateCouriersModal(false)
        }}
      />
    </div>
  )
}

export {ToolbarCourier}
