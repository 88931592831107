// components
import {UpdateCoupon} from '../components/UpdateCoupon'
import {Typography} from 'components/elements/Typography'
// hooks
import useText from 'hooks/useText'
// locals
import {useData} from './useData'
import {texts} from './texts'

const CouponDetailsPage = () => {
  const data = useData()
  const {TX} = useText()

  return (
    <div className='card card-xxl-stretch mb-5 mb-xl-8'>
      <div className='card-header border-0'>
        <Typography type='h3' className='card-title'>
          {TX(texts.title_edit_coupon)}
        </Typography>
      </div>
      <UpdateCoupon
        onSubmit={data.handleSubmit}
        isLoading={data.isLoading}
        coupon={data.coupon}
        isLoadingUpdate={data.isLoadingUpdate}
      />
    </div>
  )
}

export default CouponDetailsPage
