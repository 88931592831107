import {Expose} from 'class-transformer'
import {IsEnum, IsOptional, IsString} from 'class-validator'
import {GetQuotesRequestDtoSortEnum, QuoteStatusEnum, QuoteTypeEnum} from 'enums'
import {SortTypeEnum} from 'models'
import {PaginateRequestDto} from './paginate.request.dto'

export class GetQuotesRequestDtoFiltersDto extends PaginateRequestDto {
  @IsOptional()
  @Expose()
  @IsString()
  'filters[date]'?: string

  @IsEnum(QuoteTypeEnum)
  @IsOptional()
  @Expose()
  'filters[type]'?: QuoteTypeEnum

  @IsEnum(QuoteStatusEnum)
  @IsOptional()
  @Expose()
  'filters[status]'?: QuoteStatusEnum

  @IsEnum(SortTypeEnum)
  @IsOptional()
  @Expose()
  sortType?: SortTypeEnum

  @IsEnum(GetQuotesRequestDtoSortEnum)
  @IsOptional()
  @Expose()
  sort?: GetQuotesRequestDtoSortEnum

  @IsOptional()
  @IsString()
  search?: string
}
