import {WeeklyPlanDayOfWeekEnum} from 'enums'

export enum WeeklyPlanShiftEnum {
  morning = 'morning',
  after_noon = 'after_noon',
}

export interface IWeeklyPlan {
  id?: string
  createdAt?: string
  updatedAt?: string
  priority?: number
  dayOfWeek?: WeeklyPlanDayOfWeekEnum
  shift?: WeeklyPlanShiftEnum
}

export class WeeklyPlan {
  public props: IWeeklyPlan = {}

  constructor(data?: IWeeklyPlan) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getPriority(): number {
    return this.props.priority || 0
  }

  getDayOfWeek(): WeeklyPlanDayOfWeekEnum {
    return this.props.dayOfWeek as WeeklyPlanDayOfWeekEnum
  }

  getShift(): WeeklyPlanShiftEnum {
    return this.props.shift as WeeklyPlanShiftEnum
  }

  /* -------------------------------------------------------------------------- */
  /*                             Statics                                       */
  /* -------------------------------------------------------------------------- */

  static getShiftDisplay(shift: WeeklyPlanShiftEnum): string {
    switch (shift) {
      case WeeklyPlanShiftEnum.morning:
        return 'نوبت صبح'
      case WeeklyPlanShiftEnum.after_noon:
        return 'نوبت عصر'
    }
  }

  static getDayOfWeekDisplay(dayOfWeek: WeeklyPlanDayOfWeekEnum): string {
    switch (dayOfWeek) {
      case WeeklyPlanDayOfWeekEnum.monday:
        return 'دو شنبه'
      case WeeklyPlanDayOfWeekEnum.tuesday:
        return 'سه شنبه'
      case WeeklyPlanDayOfWeekEnum.wednesday:
        return 'چهار شنبه'
      case WeeklyPlanDayOfWeekEnum.thursday:
        return 'پنج شنبه'
      case WeeklyPlanDayOfWeekEnum.friday:
        return 'جمعه'
      case WeeklyPlanDayOfWeekEnum.saturday:
        return 'شنبه'
      case WeeklyPlanDayOfWeekEnum.sunday:
        return 'یک شنبه'
    }
  }
}
