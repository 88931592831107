import {IsNotEmpty, IsNumber, IsString} from 'class-validator'

export class CreateProductInstanceRequestDto {
  @IsString({message: 'واحد الزامی است'})
  productUnitId: string

  @IsNumber()
  @IsNotEmpty({message: 'مقدار الزامی است'})
  quantity: number

  constructor() {
    this.productUnitId = ''
    this.quantity = 0
  }
}
