import {Controller} from 'react-hook-form'
// components
import {Modal} from 'components/elements/Modal'
import {Textfield} from 'components/elements/Textfield'
import {Button} from 'components/elements/Button'
// local
import {useData} from './useData'
import {texts} from './texts'

type Props = {
  show: boolean
  handleClose: () => void
}

const InitializeScaleModal = ({show, handleClose}: Props) => {
  const {onSubmit, control, isSubmitting, errors, isValid, loading, TX} = useData(show, handleClose)

  return (
    <Modal
      id='create-product-modal'
      show={show}
      handleClose={handleClose}
      title={TX(texts.product_modal_btn)}
      children={
        <form onSubmit={onSubmit} encType='multipart/form-data'>
          <div className='stepper stepper-pills stepper-column' id='kt_modal_create_app_stepper'>
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <div className='fv-row d-flex mb-7 gap-3'>
                <Controller
                  name='scale_id'
                  control={control}
                  render={({field}) => (
                    <Textfield
                      {...field}
                      label={TX(texts.scale_id)}
                      type='text'
                      name='scale_id'
                      autoComplete='off'
                      error={!!errors.scale_id}
                      touched={!!errors.scale_id?.message}
                      errorText={errors.scale_id?.message}
                      disabled={loading}
                    />
                  )}
                />
              </div>
              <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
                <Button
                  text={TX(texts.create_product_cancel)}
                  className='main-red-background btn btn-lg background-inherit justify-content-center'
                  onClick={handleClose}
                  type='button'
                />

                <Button
                  text={TX(texts.create_product_submit)}
                  isLoading={loading}
                  className='btn btn-lg btn-primary main-gradient-background'
                  type='submit'
                  disabled={isSubmitting || !isValid}
                />
              </div>
            </div>
          </div>
        </form>
      }
    />
  )
}

export {InitializeScaleModal}
