import {FC, ReactNode} from 'react'

type ChipProps = {
  className?: string
  text?: string
  textColor?: string
  backgroundColor?: string
  classNameColor?: string
  onClick?: () => void
  children?: ReactNode
}

const Chip: FC<ChipProps> = ({
  className = '',
  text,
  textColor = 'black',
  backgroundColor = 'white',
  classNameColor = 'badge-light-warning',
  onClick = () => null,
  children,
}) => {
  return (
    <div className={['d-flex flex-column w-100 me-2', className].join(' ')} onClick={onClick}>
      <span
        className={['badge', classNameColor].join(' ')}
        style={{
          width: 'fit-content',
          backgroundColor: `${backgroundColor} !important`,
          color: `${textColor} !important`,
        }}
      >
        {children ? children : text}
      </span>
    </div>
  )
}

export {Chip}
