import {Controller} from 'react-hook-form'
import {ChangeEvent} from 'react'
// components
import {Modal} from 'components/elements/Modal'
import {Textfield} from 'components/elements/Textfield'
import {Button} from 'components/elements/Button'
import {AutoComplete} from 'components/elements/AutoComplete'
import {Typography} from 'components/elements/Typography'
import {Counter} from 'components/elements/Counter'
//models
import {Box, DeliveryPlan, Product, Region, ProductInstance, Customer} from 'models'
// enums
import {ProductStatusEnum} from 'enums'
//utils
import {toPersianDigit} from 'utils/basic/digit'
import {KTSVG} from 'utils/template/helpers'
//hooks
import useText from 'hooks/useText'
import {useHorizontalScroll} from 'hooks/useHorizontalScroll'
// local
import {texts} from './texts'
import useData from './useData'
import {CreateAddressModal} from 'pages/protected/Support/Subscribers/list/components/CreateAddressModal'
import {AddressCard} from 'pages/protected/Support/Subscribers/list/components/AddressCard'
import {EditAddressModal} from 'pages/protected/Support/Subscribers/list/components/EditAddressModal'

export type Props = {
  show: boolean
  handleClose: () => void
}

const NewPurchaseModal = ({show, handleClose}: Props) => {
  const data = useData({handleClose, show})
  const {TX} = useText()
  const scrollHorizontalRef = useHorizontalScroll()

  return (
    <Modal
      id='new-purchase_modal'
      show={show}
      handleClose={handleClose}
      title={TX(texts.new_purchase)}
      children={
        <>
          <form onSubmit={data.onSubmit}>
            <div className='container ' id='kt_modal_create_app_stepper'>
              <div>
                <div className='row mt-20'>
                  <AutoComplete
                    loading={data.isLoadingCustomers}
                    options={data.allCustomers.map((customer: Customer) => ({
                      value: customer.getId(),
                      label: customer.getFullName(),
                    }))}
                    label={TX(texts.customers)}
                    value={data.customerValue}
                    onChange={(options: {value: string; label: string}[] | any) => {
                      data.setCustomerId(options ? options.value : '')
                      data.setFormValue('customerId', options ? options.value : '', {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }}
                    inputValue={data.customerSearch}
                    onInputChange={async (newValue: string) => {
                      data.setCustomerSearch(newValue)
                    }}
                  />
                </div>

                <div className='fv-row w-100 d-flex flex-direction-row justify-content-start align-items-center py-5 gap-5'>
                  {TX(texts.row_selection_for_boxes_hint)}
                </div>

                <div className='d-flex flex-row gap-2 row my-5 w-100'>
                  <div className='d-flex flex-column gap-2' style={{width: '49%'}}>
                    {data.standardBoxesValue?.map(
                      (box: {boxId?: string; quantity: number}, index) => (
                        <div className='border border-gray-300 rounded px-5 py-2'>
                          <Typography
                            type='h6'
                            className='mb-2 d-flex flex-row justify-content-between'
                          >
                            {TX(texts.current_standard_box, [toPersianDigit(index + 1)])}
                            {box?.boxId! && box?.quantity ? (
                              <div
                                className='cursor-pointer'
                                onClick={() => {
                                  let tempBoxes: {boxId?: string; quantity: number}[] = []
                                  data.standardBoxesValue?.forEach((item) => {
                                    if (item?.boxId !== box?.boxId) {
                                      tempBoxes.push(item)
                                    }
                                  })
                                  data.setFormValue('standardBoxes', tempBoxes)
                                }}
                              >
                                <KTSVG
                                  className='svg-icon-2x main-green'
                                  svgClassName='mh-100px h-30px'
                                  path='/media/icons/duotune/art/trash-sarsabad.svg'
                                />
                              </div>
                            ) : null}
                          </Typography>
                          <AutoComplete
                            loading={data.boxesIsLoading}
                            options={data.boxList
                              ?.filter((box) => {
                                if (data.renderActivePackingTarget(box)) {
                                  return (
                                    data.standardBoxesValue?.filter(
                                      (item: {boxId?: string; quantity: number}, ind) =>
                                        item.boxId === box?.getId() && index !== ind
                                    ).length === 0
                                  )
                                }
                              })
                              ?.map((option: Box) => ({
                                value: option?.getId(),
                                label: option?.getName(),
                              }))}
                            value={box?.boxId}
                            label={TX(texts.select_product)}
                            onChange={(option: {value: string; label: string} | any) => {
                              data.setFormValue(
                                `standardBoxes.${index}.boxId`,
                                option ? option.value : '',
                                {shouldValidate: true, shouldDirty: true}
                              )
                            }}
                            disabled={!!box?.boxId}
                            inputValue={data.boxSearch[index] || ''}
                            onInputChange={async (newValue: string) => {
                              const newBoxSearch = data.boxSearch.map((item, i) => {
                                if (index === i) {
                                  item = newValue
                                  return item
                                }
                                return item
                              })
                              data.setBoxSearch(newBoxSearch)
                            }}
                          />
                          {!!box?.boxId && (
                            <div className='mt-5'>
                              <Controller
                                name={`standardBoxes.${index}.quantity`}
                                control={data.control}
                                render={({field}) => (
                                  <Counter
                                    {...field}
                                    name={`standardBoxes.${index}.quantity`}
                                    autoComplete='off'
                                    label={TX(texts.count)}
                                  />
                                )}
                              />
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                  <div className='d-flex flex-column gap-2' style={{width: '49%'}}>
                    {data.customBoxesValue?.map(
                      (
                        innerProduct: {
                          name?: string
                          products: {
                            productId?: string
                            productInstanceId?: string
                            quantity: number
                          }[]
                        },
                        innerIndex
                      ) => (
                        <div className='border border-gray-300 rounded px-5 py-2'>
                          <Typography
                            type='h6'
                            className='mb-2 d-flex flex-row justify-content-between'
                          >
                            {TX(texts.current_custom_box, [toPersianDigit(innerIndex + 1)])}
                            {data.customBoxesValue?.length !== 1 ? (
                              <div
                                className='cursor-pointer'
                                onClick={() => {
                                  let tempProducts: any = []
                                  data.customBoxesValue?.forEach((item, ii) => {
                                    if (innerIndex !== ii) tempProducts.push(item)
                                  })
                                  data.setFormValue(`customBoxes`, tempProducts)
                                }}
                              >
                                <KTSVG
                                  className='svg-icon-2x main-green'
                                  svgClassName='mh-100px h-30px'
                                  path='/media/icons/duotune/art/trash-sarsabad.svg'
                                />
                              </div>
                            ) : null}
                          </Typography>
                          <Controller
                            name={`customBoxes.${innerIndex}.name`}
                            control={data.control}
                            render={({field}) => (
                              <Textfield
                                {...field}
                                type='h6'
                                className='mb-2 d-flex flex-row justify-content-between'
                                label={TX(texts.custom_box_label)}
                                value={innerProduct.name}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  data.setFormValue(
                                    `customBoxes.${innerIndex}.name`,
                                    e.target.value!
                                  )
                                }
                              />
                            )}
                          />

                          {innerProduct.products.map((product, index) => {
                            return (
                              <div className='mt-2'>
                                <AutoComplete
                                  loading={data.productsIsLoading}
                                  options={data.productList
                                    ?.filter(
                                      (item) => item.getStatus() === ProductStatusEnum.activated
                                    )
                                    .map((option: Product) => ({
                                      value: option?.getId(),
                                      label: option?.getName(),
                                    }))}
                                  value={product?.productId}
                                  label={TX(texts.current_select_product, [
                                    toPersianDigit(index + 1),
                                  ])}
                                  onChange={(option: {value: string; label: string} | any) => {
                                    data.setFormValue(
                                      `customBoxes.${innerIndex}.products.${index}.productId`,
                                      option ? option.value : '',
                                      {shouldValidate: true, shouldDirty: true}
                                    )
                                  }}
                                  disabled={!!product?.quantity}
                                  inputValue={data.productSearch[index] || ''}
                                  onInputChange={async (newValue: string) => {
                                    const newProductSearch = data.productSearch.map((item, i) => {
                                      if (index === i) {
                                        item = newValue
                                        return item
                                      }
                                      return item
                                    })
                                    data.setProductSearch(newProductSearch)
                                  }}
                                />
                                {!!product?.productId && (
                                  <div className='mt-5'>
                                    <AutoComplete
                                      loading={data.productsIsLoading}
                                      options={data.productList
                                        .filter(
                                          (innerProduct) =>
                                            innerProduct?.getId() === product?.productId
                                        )?.[0]
                                        ?.getProductInstances()
                                        ?.filter(
                                          (innerProductInstance) =>
                                            data.customBoxesValue?.[innerIndex].products.filter(
                                              (
                                                item: {
                                                  productId?: string
                                                  productInstanceId?: string
                                                  quantity: number
                                                },
                                                ind
                                              ) =>
                                                item?.productInstanceId ===
                                                  innerProductInstance?.getId() && index !== ind
                                            ).length === 0
                                        )
                                        ?.map((option: ProductInstance) => ({
                                          value: option?.getId(),
                                          label: `${toPersianDigit(option?.getQuantity())} ${option
                                            ?.getProductUnit()
                                            .getFaName()}`,
                                        }))}
                                      value={product?.productInstanceId}
                                      label={TX(texts.select_instance)}
                                      onChange={(option: {value: string; label: string} | any) => {
                                        data.setFormValue(
                                          `customBoxes.${innerIndex}.products.${index}.productInstanceId`,
                                          option ? option.value : '',
                                          {shouldValidate: true, shouldDirty: true}
                                        )
                                      }}
                                      disabled={!!product?.quantity}
                                    />
                                  </div>
                                )}
                                {!!product?.productInstanceId && (
                                  <div className='mt-5'>
                                    <Controller
                                      name={`customBoxes.${innerIndex}.products.${index}.quantity`}
                                      control={data.control}
                                      render={({field}) => (
                                        <Counter
                                          {...field}
                                          name={`customBoxes.${innerIndex}.products.${index}.quantity`}
                                          autoComplete='off'
                                          label={TX(texts.count)}
                                        />
                                      )}
                                    />
                                  </div>
                                )}
                                {product?.quantity ? (
                                  <div
                                    className='cursor-pointer mt-5'
                                    onClick={() => {
                                      let tempProducts: {
                                        productId?: string
                                        productInstanceId?: string
                                        quantity: number
                                      }[] = []
                                      data.customBoxesValue?.[innerIndex].products.forEach(
                                        (item) => {
                                          if (
                                            item?.productInstanceId !== product?.productInstanceId
                                          )
                                            tempProducts.push(item)
                                        }
                                      )
                                      data.setFormValue(
                                        `customBoxes.${innerIndex}.products`,
                                        tempProducts
                                      )
                                    }}
                                  >
                                    <KTSVG
                                      className='svg-icon-2x main-green'
                                      svgClassName='mh-100px h-30px'
                                      path='/media/icons/duotune/art/trash-sarsabad.svg'
                                    />
                                  </div>
                                ) : null}
                              </div>
                            )
                          })}
                        </div>
                      )
                    )}
                    {data.customBoxesValue?.[data.customBoxesValue?.length - 1]?.products[0]
                      ?.quantity ? (
                      <div className='fv-row'>
                        <Button
                          text={TX(texts.add_other_custom_box)}
                          className='btn btn-lg btn-active-light-primary light-green-background background-inherit main-green mb-3 mt-3'
                          onClick={() => {
                            data.setFormValue('customBoxes', [
                              ...data.customBoxesValue!,
                              {
                                name: '',
                                products: [
                                  {
                                    productId: '',
                                    productInstanceId: '',
                                    quantity: 0,
                                  },
                                ],
                              },
                            ])
                            data.setProductList(data.products)
                            data.setProductSearch([])
                          }}
                          type='button'
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='w-50'>
                  <Controller
                    name='weeklyCount'
                    control={data.control}
                    render={({field}) => (
                      <Counter
                        {...field}
                        name='weeklyCount'
                        autoComplete='off'
                        label={TX(texts.weekly_count)}
                        error={!!data.errors.weeklyCount}
                        errorText={data.errors.weeklyCount?.message}
                      />
                    )}
                  />
                </div>
                {data.customBoxesValue![0].products.length > 1 || !!data.paymentAmountBox ? (
                  <>
                    <div className='my-15'>
                      <div className='row my-5 gap-10'>
                        <div className='col-sm'>
                          <Typography type='h3' className='text-gray-600'>
                            {TX(texts.amount_payable)}
                          </Typography>
                          <Typography type='span' className='fs-7'>
                            {TX(texts.payment_amount, [
                              toPersianDigit(
                                ((data.paymentAmount ? data.paymentAmount : 0) +
                                  (data.paymentAmountBox ? data.paymentAmountBox : 0)) *
                                  data.weeklyCountValue
                              ),
                            ])}
                          </Typography>
                        </div>
                        <div className='col-sm'>
                          <Typography type='h3' className='text-gray-600'>
                            {TX(texts.bank_number)}
                          </Typography>
                          <Typography type='span' className='fs-7'>
                            {toPersianDigit('6037 9977 2211 8712')}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className='row my-5'>
                      <div className='col-sm'>
                        <Controller
                          name='regionId'
                          control={data.control}
                          render={({field}) => (
                            <AutoComplete
                              {...field}
                              loading={data.regionsIsLoading}
                              options={data.regions.map((region: Region) => ({
                                value: region?.getId(),
                                label: region.getLabel(),
                              }))}
                              value={data.regionValue}
                              label={TX(texts.region)}
                              onChange={(option: {value: string; label: string} | any) => {
                                data.setFormValue('regionId', option ? option.value : '', {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                })
                                data.setFormValue('deliveryPlanId', '', {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                })
                              }}
                              inputValue={data.regionsQuery.search}
                              onInputChange={(newValue: string) =>
                                data.setRegionsQuery((prevQueries) => ({
                                  ...prevQueries,
                                  search: newValue,
                                }))
                              }
                            />
                          )}
                        />
                      </div>
                      <div className='col-sm'>
                        <Controller
                          name='deliveryPlanId'
                          control={data.control}
                          render={({field}) => (
                            <AutoComplete
                              {...field}
                              loading={data.regionsIsLoading}
                              options={data.deliveryPlans.map((option: DeliveryPlan) => ({
                                value: option?.getId(),
                                label: `${option.getDisplayDayOfWeek()} - ${option.getDisplayShift()}`,
                              }))}
                              disabled={!data.regionValue}
                              value={data.deliveryPlanValue}
                              label={TX(texts.delivery_plan)}
                              onChange={(option: {value: string; label: string} | any) => {
                                data.setFormValue('deliveryPlanId', option ? option.value : '', {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                })
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <hr className='opacity-10' />

                    <div className='card'>
                      <div
                        className='p-5 mb-xl-10 d-flex flex-row justify-start align-items-center gap-5 overflow-scroll'
                        ref={scrollHorizontalRef}
                      >
                        {data.addresses.map((address) => (
                          <AddressCard
                            onSuccess={() => data.setTriggerRefetchAddresses(address.getId())}
                            key={address.getId()}
                            address={address}
                            active={address.getIsActive()}
                            activedAddress={address.getId()}
                            onEditClick={() => {
                              data.setSelectedAddress(address)
                              data.setIsEditAddressModalOpen(true)
                            }}
                            customerId={data.customerId}
                          />
                        ))}
                      </div>
                    </div>

                    <Typography
                      onClick={() => data.setIsEditCustomerModalOpen(true)}
                      type='h6'
                      color='#1A73E8'
                      className='cursor-pointer my-10'
                    >
                      + {TX(texts.new_address)}
                    </Typography>

                    <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
                      <Button
                        text={TX(texts.back)}
                        className='light-green-background main-green btn btn-lg  justify-content-center'
                        onClick={handleClose}
                        type='button'
                      />

                      <Button
                        text={TX(texts.submit)}
                        className='btn btn-lg btn-primary main-gradient-background'
                        isLoading={data.subscriptionIsLoading}
                        disabled={data.disabledForm}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </form>
          <EditAddressModal
            addressId={data.selectedAddress.getId()}
            customerId={data.customerId}
            show={data.isEditAddressModalOpen}
            handleClose={() => data.setIsEditAddressModalOpen(false)}
            onSuccess={() => data.setTriggerRefetchAddresses(data.selectedAddress.getId())}
          />
          <CreateAddressModal
            customerId={data.customerId}
            show={data.isEditCustomerModalOpen}
            handleClose={() => data.setIsEditCustomerModalOpen(false)}
            onSuccess={() => data.setTriggerRefetchAddresses(data.selectedAddress.getId())}
          />
        </>
      }
    />
  )
}

export {NewPurchaseModal}
