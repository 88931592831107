import {IsPositive, Max} from 'class-validator'

export class PostponeDeliveryRequestDto {
  @IsPositive({message: 'تعداد تعویق هفته باید مثبت باشد'})
  @Max(4)
  postponeWeeks: number

  constructor() {
    this.postponeWeeks = 0
  }
}
