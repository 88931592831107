export interface IBoxAndWeight {
  id?: string
  createdAt?: string
  updatedAt?: string
  price?: number
  useCustomPrice?: boolean
  customPrice?: number
  packagingTargetPrice?: number
  weight?: number
}

export class BoxAndWeight {
  public props: IBoxAndWeight = {}

  constructor(data?: IBoxAndWeight) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getPrice(): number {
    return (this.getUseCustomPrice() ? this.getCustomPrice() : this.getPackagingTargetPrice()) || 0
  }

  getUseCustomPrice(): boolean {
    return this.props.useCustomPrice || false
  }

  getCustomPrice(): number {
    return this.props.customPrice || 0
  }

  getPackagingTargetPrice(): number {
    return this.props.packagingTargetPrice || 0
  }

  getWeight(): number {
    return this.props.weight || 0
  }
}
