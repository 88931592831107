import {useState} from 'react'

//hooks
import useText from 'hooks/useText'

//utils
import {KTSVG} from 'utils/template/helpers'

//locals
import {texts} from './texts'
import AddCustomerModal from 'pages/protected/Delivery/Chains/define/components/AddCustomerModal'

const ToolbarCustomer = () => {
  const [showAddCustomerModal, setShowAddCustomerModal] = useState<boolean>(false)
  const {TX} = useText()

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div
        className='btn btn-sm main-gradient-background fw-bold d-flex align-items-center px-4 gap-1'
        onClick={() => setShowAddCustomerModal(true)}
      >
        <KTSVG
          path='/media/icons/duotune/art/add-circle-sarsabad.svg'
          className='svg-icon-1 ms-2 me-0 text-white'
        />
        <div className='text-white fw-bold'>{TX(texts.new_customer)}</div>
      </div>
      <AddCustomerModal
        show={showAddCustomerModal}
        handleClose={() => {
          setShowAddCustomerModal(false)
        }}
      />
    </div>
  )
}

export {ToolbarCustomer}
