import {IsEnum, IsOptional, IsString, ValidateIf} from 'class-validator'
import {TicketCategoryEnum} from 'enums'

export class CreateTicketRequestDto {
  @IsString({message: 'توضیحات الزامی است'})
  description: string

  @IsEnum(TicketCategoryEnum)
  @IsOptional()
  category?: TicketCategoryEnum

  @IsString({message: 'مشترک الزامی است'})
  customerId: string

  @IsOptional()
  @IsString()
  @ValidateIf((o) => o.deliveryId)
  deliveryId?: string

  @IsOptional({each: true})
  @IsString({each: true})
  photoIds?: string[]

  constructor() {
    this.description = ''
    this.category = '' as TicketCategoryEnum
    this.customerId = ''
    this.deliveryId = ''
    this.photoIds = []
  }
}
