import {IsNotEmpty, IsNumberString, IsOptional, IsString, Length, ValidateIf} from 'class-validator'

export class CreateVendorRequestDto {
  @IsString({message: 'نام الزامی است'})
  @IsNotEmpty({message: 'نام الزامی است'})
  firstName: string

  @IsString({message: 'نام خانوادگی الزامی است'})
  @IsNotEmpty({message: 'نام خانوادگی الزامی است'})
  lastName: string

  @IsString({message: 'آدرس الزامی است'})
  @IsNotEmpty({message: 'آدرس الزامی است'})
  address: string

  @IsNumberString()
  @IsNotEmpty({message: 'شماره موبایل الزامی است'})
  @Length(11, 11, {message: 'شماره موبایل باید ۱۱ رقم باشد'})
  mobileNumber: string

  @IsOptional()
  @IsString()
  photoId?: string

  @IsOptional()
  @IsString()
  provinceId?: string

  @IsString()
  @IsOptional()
  @ValidateIf((obj) => obj.provinceId)
  cityId?: string

  constructor() {
    this.firstName = ''
    this.lastName = ''
    this.address = ''
    this.mobileNumber = ''
    this.photoId = ''
    this.provinceId = ''
    this.cityId = ''
  }
}
