import React from 'react'

//components
import {Modal} from 'components/elements/Modal'
import UploadImagesChunk from 'components/elements/UploadImagesChunk'
import {Button} from 'components/elements/Button'
import {Typography} from 'components/elements/Typography'

//locals
import useData from './useData'
import {texts} from '../../texts'

export type Props = {
  show: boolean
  handleClose: () => void
}

const ChangeStatusModal = ({show, handleClose}: Props) => {
  const data = useData({show, handleClose})

  return (
    <Modal
      id='upload-document-modal'
      show={show}
      handleClose={handleClose}
      title={data.TX(texts.upload_document_payment)}
      children={
        <>
          <form onSubmit={data.onSubmit}>
            <div className='container my-10 '>
              <div className='d-flex justify-content-center align-items-center flex-column'>
                <UploadImagesChunk
                  disabled={data.isLoading}
                  photos={data.photos}
                  setPhotos={data.setPhotos}
                  isSubmitted={data.isSubmitted}
                  onSubmitFinish={async () => await data.onSubmitFinish()}
                  aspect={1}
                  width='350px'
                  height='350px'
                />
                <Typography type='span' className='fs-7 mt-5' color='rgb(231, 76, 60)'>
                  {data.errors.photoId?.message}
                </Typography>
              </div>
              <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
                <Button
                  text={data.TX(texts.back)}
                  className='main-red-background btn btn-lg background-inherit justify-content-center'
                  onClick={handleClose}
                  type='button'
                />

                <Button
                  text={data.TX(texts.submit)}
                  type='submit'
                  className='btn btn-lg btn-primary main-gradient-background'
                  isLoading={data.isLoading}
                  disabled={data.photos.filter((photo) => photo.isFull).length === 0}
                />
              </div>
            </div>
          </form>
        </>
      }
    />
  )
}

export default ChangeStatusModal
