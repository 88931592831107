import {useState} from 'react'
import {KTSVG} from 'utils/template/helpers'
import {CreateFAQModal} from 'pages/protected/SystemPreferences/FAQ/components/CreateFAQModal'
import {CreateFAQCategoryModal} from 'pages/protected/SystemPreferences/FAQ/components/CreateFAQCategoryModal'
import useText from 'hooks/useText'
import { texts } from './texts'

const ToolbarFAQ = () => {
  const {TX} = useText()
  const [showCreateFAQCategoryModal, setShowCreateFAQCategoryModal] = useState<boolean>(false)
  const [showCreateFAQModal, setShowCreateFAQModal] = useState<boolean>(false)

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div
        className='btn btn-sm main-gradient-background fw-bold d-flex align-items-center px-4 gap-1'
        onClick={() => setShowCreateFAQCategoryModal(true)}
      >
        <KTSVG
          path='/media/icons/duotune/art/add-circle-sarsabad.svg'
          className='svg-icon-1 ms-2 me-0 text-white'
        />
        <div className='text-white fw-bold'>{TX(texts.new_category)}</div>
      </div>

      <div
        className='btn btn-sm main-gradient-background fw-bold d-flex align-items-center px-4 gap-1'
        onClick={() => setShowCreateFAQModal(true)}
      >
        <KTSVG
          path='/media/icons/duotune/art/add-circle-sarsabad.svg'
          className='svg-icon-1 ms-2 me-0 text-white'
        />
        <div className='text-white fw-bold'>{TX(texts.new_faq)}</div>
      </div>

      <CreateFAQCategoryModal
        show={showCreateFAQCategoryModal}
        handleClose={() => setShowCreateFAQCategoryModal(false)}
      />
      <CreateFAQModal show={showCreateFAQModal} handleClose={() => setShowCreateFAQModal(false)} />
    </div>
  )
}

export {ToolbarFAQ}
