import queryString, {StringifiableRecord} from 'query-string'
import ClientApi from './clientApi'
import config from './config'
// models
import {IDto, IEmployee} from 'models'
// dtos
import {CreateEmployeeRequestDto, UpdateEmployeeRequestDto} from 'dtos'

const {rootAddress} = config

const prefix = '/dashboard/employees'

const axios = new ClientApi()

export const getEmployees = (query: StringifiableRecord): Promise<IDto<IEmployee>> =>
  axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix, query}))

export const createEmployee = (body: CreateEmployeeRequestDto) =>
  axios.http.post(rootAddress + prefix, body)

export const getEmployeeDetails = (employeeId: string): Promise<IEmployee> =>
  axios.http.get(rootAddress + prefix + '/' + employeeId)

export const editEmployee = (employeeId: string, body: UpdateEmployeeRequestDto) =>
  axios.http.patch(rootAddress + prefix + '/' + employeeId, body)

export const deleteEmployee = (employeeId: string) =>
  axios.http.delete(rootAddress + prefix + '/' + employeeId)
