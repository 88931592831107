import {useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useSnackbar} from 'notistack'
import {useForm} from 'react-hook-form'
import {classValidatorResolver} from '@hookform/resolvers/class-validator'
// services
import services, {ServerError} from 'services'
// models
import {Dto, Employee, Scale} from 'models'
// dtos
import {CreateSortingLineRequestDto} from 'dtos'
// hooks
import useText from 'hooks/useText'
import useToolbarRefetch from 'hooks/useToolbarRefetch'
// locals
import {texts} from './texts'

const useData = (show: boolean, handleClose: () => void) => {
  const {TX} = useText()
  const {enqueueSnackbar} = useSnackbar()
  const {setIsRefetchOn} = useToolbarRefetch()
  const [scales, setScales] = useState<Scale[]>([])
  const [employees, setEmployees] = useState<Employee[]>([])

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: {isSubmitting, errors, isValid, dirtyFields},
  } = useForm<CreateSortingLineRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(CreateSortingLineRequestDto),
    defaultValues: {
      name: '',
      lineNumber: '',
      scaleId: '',
      employeeId: '',
    },
  })

  /* ------------------------------------------------------------ */
  /*                           Services                           */
  /* ------------------------------------------------------------ */

  const getScales = async () =>
    await services.scales.getScales({
      getAll: true,
    })

  const getEmployees = async () =>
    await services.employees.getEmployees({
      getAll: true,
    })

  const createSortingLinesHandler = async (body: CreateSortingLineRequestDto) =>
    await services.sorting.createSortingLines(body)

  /* ------------------------------------------------------------ */
  /*                           Queries                            */
  /* ------------------------------------------------------------ */

  const {isLoading: isLoadingScales, isFetching: isFetchingScales} = useQuery(
    ['scales'],
    getScales,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
      onSuccess: (data) => {
        const dto = new Dto(data)
        setScales(dto.getItems().map((item) => new Scale(item)))
      },
    }
  )

  const {isLoading: isLoadingEmployees, isFetching: isFetchingEmployees} = useQuery(
    ['employees'],
    getEmployees,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
      onSuccess: (data) => {
        const dto = new Dto(data)
        setEmployees(dto.getItems().map((item) => new Employee(item)))
      },
    }
  )

  /* ------------------------------------------------------------ */
  /*                         Mutations                            */
  /* ------------------------------------------------------------ */

  const {isLoading: isLoadingSortingLine, mutate: mutateSortingLine} = useMutation(
    createSortingLinesHandler,
    {
      onSuccess: async (data) => {
        handleClose()
        setIsRefetchOn(true)
        enqueueSnackbar(TX(texts.success_creation_line), {
          variant: 'success',
        })
      },
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
    }
  )

  /* ------------------------------------------------------------ */
  /*                          Handlers                            */
  /* ------------------------------------------------------------ */

  const onSubmit = async () => {
    await mutateSortingLine({
      name: watch().name.trim(),
      lineNumber: watch().lineNumber.trim(),
      scaleId: watch().scaleId,
      employeeId: watch().employeeId,
    })
  }

  /* ------------------------------------------------------------ */
  /*                         LifeCycles                           */
  /* ------------------------------------------------------------ */

  useEffect(() => {
    if (!show) {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return {
    onSubmit: handleSubmit(onSubmit),
    control,
    watch,
    setValue,
    isSubmitting,
    errors,
    isValid,
    dirtyFields,
    loading: isLoadingSortingLine,
    scales,
    loadingScales: isLoadingScales || isFetchingScales,
    employees,
    loadingEmployees: isLoadingEmployees || isFetchingEmployees,
    TX,
  }
}

export {useData}
