import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  packing: 'pages.protected.breadcrumbsItems.packing',
  delivery_date: 'pages.protected.production-line.packing.start-packaging.delivery_date',
  chains: 'pages.protected.production-line.packing.start-packaging.chains',
  no_delivery_chains_data:
    'pages.protected.production-line.packing.start-packaging.no_delivery_chains_data',
  chains_start_packing:
    'pages.protected.production-line.packing.start-packaging.chains_start_packing',
  chains_finish_packing:
    'pages.protected.production-line.packing.start-packaging.chains_finish_packing',
  chains_download_label:
    'pages.protected.production-line.packing.start-packaging.chains_download_label',
  customers: 'pages.protected.production-line.packing.start-packaging.customers',
  no_customers_for_delivery_chains_data:
    'pages.protected.production-line.packing.start-packaging.no_customers_for_delivery_chains_data',
  customer_start_packing:
    'pages.protected.production-line.packing.start-packaging.customer_start_packing',
  customer_finish_packing:
    'pages.protected.production-line.packing.start-packaging.customer_finish_packing',
  order_download_label:
    'pages.protected.production-line.packing.start-packaging.order_download_label',
  boxes: 'pages.protected.production-line.packing.start-packaging.boxes',
  no_box_for_customer_data:
    'pages.protected.production-line.packing.start-packaging.no_box_for_customer_data',
  box_start_packing: 'pages.protected.production-line.packing.start-packaging.box_start_packing',
  box_finish_packing: 'pages.protected.production-line.packing.start-packaging.box_finish_packing',
  no_product_for_box_data:
    'pages.protected.production-line.packing.start-packaging.no_product_for_box_data',
  package_count: 'pages.protected.production-line.packing.start-packaging.package_count',
  create_package_count_success:
    'pages.protected.production-line.packing.start-packaging.create_package_count_success',
  please_print_labels_again_message:
    'pages.protected.production-line.packing.start-packaging.please_print_labels_again_message',
  start_packing_box_message:
    'pages.protected.production-line.packing.start-packaging.start_packing_box_message',
  please_finish_all_customer_box_message:
    'pages.protected.production-line.packing.start-packaging.please_finish_all_customer_box_message',
  service_tag_service_number:
    'pages.protected.production-line.packing.start-packaging.service_tag_service_number',
  start_packing_chains_message:
    'pages.protected.production-line.packing.start-packaging.start_packing_chains_message',
  please_all_packing_boxes_message:
    'pages.protected.production-line.packing.start-packaging.please_all_packing_boxes_message',
  start_packing_customer_message:
    'pages.protected.production-line.packing.start-packaging.start_packing_customer_message',
  start_must_be_products_packing_message:
    'pages.protected.production-line.packing.start-packaging.start_must_be_products_packing_message',
  box_label: 'pages.protected.production-line.packing.start-packaging.box_label',
  please_select_vendor_message:
    'pages.protected.production-line.packing.start-packaging.components.please_select_vendor_message',
  unit: 'pages.protected.production-line.packing.start-packaging.components.unit',
  order_number: 'pages.protected.production-line.packing.start-packaging.components.order_number',
  service_number:
    'pages.protected.production-line.packing.start-packaging.components.service_number',
  capacity: 'pages.protected.production-line.packing.start-packaging.components.capacity',
  courier_name: 'pages.protected.production-line.packing.start-packaging.components.courier_name',
  shift: 'pages.protected.production-line.packing.start-packaging.components.shift',
  current_line: 'pages.protected.production-line.packing.start-packaging.components.current_line',
  results: 'pages.protected.production-line.packing.start-packaging.components.results',
  packing_up: 'pages.protected.production-line.packing.start-packaging.components.packing_up',
  packaged: 'pages.protected.production-line.packing.start-packaging.components.packaged',
  custom_box: 'pages.protected.production-line.packing.start-packaging.components.custom_box',
}
