import {IsEnum, IsNotEmpty} from 'class-validator'
import {AdminStatusEnum} from 'enums'

export class UpdateAdminStatusRequestDto {
  @IsEnum(AdminStatusEnum, {message: 'وضعیت ادمین الزامی است'})
  @IsNotEmpty({message: 'وضعیت ادمین الزامی است'})
  status: AdminStatusEnum

  constructor() {
    this.status = AdminStatusEnum.activated
  }
}
