import {ContextEnum} from 'enums'
import {Barcode, IBarcode} from './Barcode'
import {IOrderLine, OrderLine} from './OrderLine'
import {IScale, Scale} from './Scale'
import {IWeighingSequence, WeighingSequence} from './WeighingSequence'

export enum WeighingStatusEnum {
  accepted = 'accepted',
  rejected = 'rejected',
  pending = 'pending',
}

export interface IWeighing {
  id?: string
  createdAt?: string
  updatedAt?: string
  weight?: number
  context?: ContextEnum
  status?: WeighingStatusEnum
  barcodeId?: string
  orderLineId?: string
  scaleId?: string
  weighingSequenceId?: string
  barcode?: IBarcode
  orderLine?: IOrderLine
  scale?: IScale
  weighingSequence?: IWeighingSequence
}

export class Weighing {
  public props: IWeighing = {}

  constructor(data?: IWeighing) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getWeight(): number {
    return this.props.weight || 0
  }

  getContext(): ContextEnum | undefined {
    return this.props.context
  }

  getStatus(): WeighingStatusEnum {
    return this.props.status ? this.props.status : WeighingStatusEnum.pending
  }

  getOrderLineId(): string {
    return this.props.orderLineId || ''
  }

  getScaleId(): string {
    return this.props.scaleId || ''
  }

  getBarcodeId(): string {
    return this.props.barcodeId || ''
  }

  getWeighingSequenceId(): string {
    return this.props.weighingSequenceId || ''
  }

  getOrderLine(): OrderLine {
    return new OrderLine(this.props.orderLine || {})
  }

  getScale(): Scale {
    return new Scale(this.props.scale || {})
  }

  getWeighingSequence(): WeighingSequence {
    return new WeighingSequence(this.props.weighingSequence || {})
  }

  getBarcode(): Barcode {
    return new Barcode(this.props.barcode)
  }

  //custom

  getDisplayStatus(): string {
    return displayWeighingStatus(this.getStatus())
  }
}

export const displayWeighingStatus = (weighingStatusEnum: WeighingStatusEnum): string => {
  switch (weighingStatusEnum) {
    case WeighingStatusEnum.accepted:
      return 'پذیرفته شده'
    case WeighingStatusEnum.pending:
      return 'در دست بررسی'
    case WeighingStatusEnum.rejected:
      return 'رد شده'
    default:
      return '-'
  }
}
