import {IsString} from 'class-validator'

export class DeleteReceiptForOrderRequestDto {
  @IsString()
  photoId: string

  constructor() {
    this.photoId = ''
  }
}
