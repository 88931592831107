import {Delivery, IDelivery} from './Delivery'

export interface ICustomerPacking {
  id?: string
  createdAt?: string
  deletedAt?: string
  updatedAt?: string
  status?: string
  delivery?: IDelivery
  deliveryId?: string
  packingId?: string
  deliveryChainCustomerId?: string
}

export class CustomerPacking {
  public props: ICustomerPacking = {}

  constructor(data?: ICustomerPacking) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getDeliveryId(): string {
    return this.props.deliveryId || ''
  }
  getDelivery(): Delivery {
    return new Delivery(this.props.delivery || {})
  }
  getDeliveryChainCustomerId(): string {
    return this.props.deliveryChainCustomerId || ''
  }
  getPackingId(): string {
    return this.props.packingId || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getStatus(): string {
    return this.props.status || ''
  }
  getDeletedAt(): string {
    return this.props.deletedAt || ''
  }
}
