import {IProduct, Product} from './Product'
import {IProductUnit, ProductUnit} from './ProductUnit'

export interface IProductInstance {
  id?: string
  createdAt?: string
  updateAt?: string
  quantity?: number
  isActive?: boolean
  productId?: string
  productUnitId?: string
  product?: IProduct
  productUnit?: IProductUnit
}

export class ProductInstance {
  public props: IProductInstance = {}

  constructor(data?: IProductInstance) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updateAt ? new Date(this.props.updateAt) : undefined
  }

  getQuantity(): number {
    return this.props.quantity || 0
  }

  getIsActive(): boolean {
    return this.props.isActive || false
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getProductUnitId(): string {
    return this.props.productUnitId || ''
  }

  getProduct(): Product {
    return new Product(this.props.product || {})
  }

  getProductUnit(): ProductUnit {
    return new ProductUnit(this.props.productUnit || {})
  }
}
