import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
  },

  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    columnGap: '6rem',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  cardsWrapper: {
    width: '100%',
    display: 'flex',
    marginLeft: 'auto',
    marginBottom: '1rem',
    // marginTop: '4rem',
    gap: '2rem',
    flexWrap: 'nowrap',
    overflow: 'scroll',
  },

  contentWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  contentFooter: {
    marginRight: 'auto',
    display: 'flex',
    gap: '.5rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  contentFooterPackages: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '.5rem',
  },

  buttonWrapper: {
    display: 'flex',
    gap: '1rem',
    marginRight: 'auto',
    flexWrap: 'wrap',
  },

  startButton: {
    background: 'radial-gradient(424.47% 156.42% at -15.94% 108.5%, #0C923F 0%, #00461C 100%)',
  },

  labelButton: {},

  endButton: {
    background: '#E74C3C !important',
  },

  number: {
    fontSize: '1.5rem',
    color: '#5C5C5C',
    width: '100%',
    marginBottom: '2rem',
  },

  text: {
    color: '#5C5C5C !important',
    fontSize: '1.2rem',
  },

  date: {
    display: 'flex',
    flexDirection: 'column',
  },
  datePickerWrapper: {
    width: '30%',
    marginLeft: 'auto',
  },

  box: {
    background: '#fff',
    borderRadius: '.5rem',
    boxShadow: 'var(--kt-card-box-shadow)',
    padding: '1rem',
    width: '100%',
    transition: 'all .2s',
    position: 'relative',
  },

  diactive: {
    opacity: '.5',
  },

  notExist: {
    marginBottom: '2rem',
  },
}))

export default useStyles
