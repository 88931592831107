import apiConfig from 'services/config'
import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'
// models
import {
  IBox,
  ICourier,
  IDelivery,
  IDeliveryBox,
  IDeliveryChain,
  IDeliveryChainCustomer,
  IDto,
  IOrder,
  IOrderLine,
  IPacking,
  IProduct,
  IQuote,
  ISortingProduct,
} from 'models'
// dtos
import {PostponeDeliveryRequestDto, UpdateInformationConflictRequestDto} from 'dtos'

const axios = new ClientApi()

const {rootAddress} = apiConfig

const prefix = '/dashboard/delivery'

export interface IFullDetails {
  products?: IProduct[]
  delivery?: IDelivery
  deliveryBoxes?: IDeliveryBox[]
  deliveryChainCustomer?: IDeliveryChainCustomer
  deliveryChain?: IDeliveryChain
  courier?: ICourier
  packing?: IPacking
  packingDelivery?: IDelivery
  packingDeliveryBoxes?: IBox[]
  sortingProducts?: ISortingProduct[]
  orders?: IOrder[]
  quotes?: IQuote[]
  orderLines?: IOrderLine[]
}

export const getFullDetails = (deliveryId: string): Promise<IFullDetails> =>
  axios.http.get(rootAddress + prefix + '/' + deliveryId + '/full-details')

export const getDeliveriesReport = (query: StringifiableRecord): Promise<IDto<IDelivery>> =>
  axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix + '/report', query}))

export const postponeDelivery = (deliveryId: string, body: PostponeDeliveryRequestDto) =>
  axios.http.put(rootAddress + prefix + '/postpone-delivery/' + deliveryId, body)

export const getAllPostponeDelivery = (deliveryId: string, query: StringifiableRecord) =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/postpone-delivery/' + deliveryId, query},
      {arrayFormat: 'comma'}
    )
  )

export const getAllDeliveries = (query: StringifiableRecord) =>
  axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix, query}, {arrayFormat: 'comma'})
  )

export const getAllInformationConflicts = (informationConflictId: string) =>
  axios.http.get(rootAddress + prefix + '/information-conflict/' + informationConflictId)

export const updateInformationConflict = (
  informationConflictId: string,
  body: UpdateInformationConflictRequestDto
) => axios.http.put(rootAddress + prefix + '/information-conflict/' + informationConflictId, body)

export const getAllInformationConflictDetails = (query: StringifiableRecord) =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/information-conflict', query},
      {arrayFormat: 'comma'}
    )
  )
