import {FC} from 'react'
import {Box} from '@mui/material'
// Components
import {Typography} from 'components/elements/Typography'
// Utils
import {KTSVG} from 'utils/template/helpers'
import {toPersianDigit} from 'utils/basic/digit'
// models
import {DeliveryChain} from 'models'
// hooks
import useText from 'hooks/useText'
// Locals
import useStyles from './useStyles'
import {texts} from '../../texts'

type Props = {
  deliveryChian?: DeliveryChain
  selected?: boolean
}

const DeliveryChainCard: FC<Props> = ({deliveryChian, selected = false}) => {
  const {TX} = useText()
  const {classes} = useStyles()

  const renderDeliveryChainInfo = () => {
    return (
      <div className='d-flex flex-column'>
        <Typography className={classes.text} type='span'>
          {TX(texts.service_number)}: {toPersianDigit(deliveryChian?.getServiceNumber() || 0)}
        </Typography>
        <Typography className={classes.text} type='span'>
          {TX(texts.capacity)}: {deliveryChian?.getCapacity()}
        </Typography>
        <Typography className={classes.text} type='span'>
          {TX(texts.courier_name)}: {deliveryChian?.getCourier().getFullName()}
        </Typography>
        <Typography className={classes.text} type='span'>
          {TX(texts.shift)}:{' '}
          {deliveryChian?.getRegionDeliveryPlan().getDeliveryPlan().getDisplayDayOfWeek()}{' '}
          {deliveryChian?.getRegionDeliveryPlan().getDeliveryPlan().getDisplayShift()}
        </Typography>
      </div>
    )
  }

  return (
    <Box className={[classes.wrapper, selected ? classes.selected : ''].join(' ')}>
      {renderDeliveryChainInfo()}
      {deliveryChian?.getPacking().getStatus() === 'finish' && (
        <KTSVG path='/media/sarsabad/done_all.svg' svgClassName={classes.icon} />
      )}
    </Box>
  )
}

export default DeliveryChainCard
