import {IsEnum, IsNotEmpty, IsOptional, IsString} from 'class-validator'
import {FAQStatusEnum} from 'enums'

export class CreateFaqCategoryRequestDto {
  @IsString({message: 'دسته بندی الزامی است'})
  @IsNotEmpty({message: 'دسته بندی الزامی است'})
  name: string

  @IsEnum(FAQStatusEnum)
  @IsOptional()
  status?: FAQStatusEnum

  constructor() {
    this.name = ''
  }
}
