import apiConfig from 'services/config'
import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'
// models
import {IDto, IPackingTarget} from 'models'
// dtos
import {CreatePackagingTargetForBoxRequestDto, UpdatePackagingTargetOfBoxRequestDto} from 'dtos'

const axios = new ClientApi()

const {rootAddress} = apiConfig

const prefix = '/dashboard/packaging-target'

export const getPackageTargets = (query: StringifiableRecord): Promise<IDto<IPackingTarget>> =>
  axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix, query}, {arrayFormat: 'comma'})
  )

export const createPackageTarget = (
  body: CreatePackagingTargetForBoxRequestDto
): Promise<IPackingTarget> => axios.http.post(rootAddress + prefix, body)

export const getPackageTargetDetails = (packagingTargetId: string): Promise<IPackingTarget> =>
  axios.http.get(rootAddress + prefix + '/' + packagingTargetId)

export const editPackageTarget = (
  packagingTargetId: string,
  body: UpdatePackagingTargetOfBoxRequestDto
): Promise<IPackingTarget> => axios.http.put(rootAddress + prefix + '/' + packagingTargetId, body)

export const deletePackageTarget = (packagingTargetId: string) =>
  axios.http.delete(rootAddress + prefix + '/' + packagingTargetId)
