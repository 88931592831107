import {Transaction} from 'models/Transaction'
import {createContext} from 'react'

export default createContext<{
  selectedTransaction: Transaction
  setSelectedTransaction: React.Dispatch<React.SetStateAction<Transaction>>
}>({
  selectedTransaction: new Transaction(),
  setSelectedTransaction: () => undefined,
})
