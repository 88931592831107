import {Courier, ICourier} from './Courier'
import {IRegionDeliveryPlan, RegionDeliveryPlan} from './RegionDeliveryPlan'

export interface IRegionDeliveryPlanCourier {
  id?: string
  createdAt?: string
  updatedAt?: string
  isActive?: boolean
  regionDeliveryPlanId?: string
  courierId?: string
  courier?: ICourier
  regionDeliveryPlan?: IRegionDeliveryPlan
}

export class RegionDeliveryPlanCourier {
  public props: IRegionDeliveryPlanCourier = {}

  constructor(data?: IRegionDeliveryPlanCourier) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getIsActive(): boolean {
    return this.props.isActive || false
  }

  getRegionDeliveryPlanId(): string {
    return this.props.regionDeliveryPlanId || ''
  }

  getCourierId(): string {
    return this.props.courierId || ''
  }

  getCourier(): Courier {
    return new Courier(this.props.courier || {})
  }

  getRegionDeliveryPlan(): RegionDeliveryPlan {
    return new RegionDeliveryPlan(this.props.regionDeliveryPlan)
  }
}
