export enum GetVendorsRequestDtoSortEnum {
  vendorName = 'vendor.name',
  vendorFirstName = 'vendor.firstName',
  vendorLastName = 'vendor.lastName',
  vendorMobileNumber = 'vendor.mobileNumber',
  vendorPurchaseCount = 'vendor.purchaseCount',
  vendorCreatedAt = 'vendor.createdAt',
}

export enum VendorStatusEnum {
  active = 'active',
  inactive = 'inactive',
}
