import {Customer, ICustomer, IPhotoUse, ISubscription, PhotoUse, Subscription} from 'models'

export interface PaymentTXNDetails {
  cardNumber?: string
  expiryDate?: string
  status?: string
  amount?: number
  type?: string
  source?: string
  refId?: string
  method?: string
  gateway?: string
  cardInfo?: {}
}

export enum PaymentMethodTypeEnum {
  credit = 'credit',
  online = 'online',
  wallet = 'wallet',
}

export enum PaymentStatusEnum {
  pending = 'pending',
  cancel = 'cancel',
  completed = 'completed',
}

export interface IPayment {
  id?: string
  createdAt?: string
  updatedAt?: string
  date?: string
  amount?: number
  methodType?: PaymentMethodTypeEnum
  status?: PaymentStatusEnum
  txnDetails?: PaymentTXNDetails
  customerId?: string
  subscriptionId?: string
  customer?: ICustomer
  subscription?: ISubscription
  photoUses?: IPhotoUse[]
}

export class Payment {
  public props: IPayment = {}

  constructor(data?: IPayment) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDate(): Date | undefined {
    return this.props.date ? new Date(this.props.date) : undefined
  }

  getAmount(): number {
    return this.props.amount || 0
  }

  getMethodType(): PaymentMethodTypeEnum | undefined {
    return this.props.methodType
  }

  getStatus(): PaymentStatusEnum {
    return this.props.status || PaymentStatusEnum.cancel
  }

  getTnxDetails(): PaymentTXNDetails | undefined {
    return this.props.txnDetails
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getSubscriptionId(): string {
    return this.props.subscriptionId || ''
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getSubscription(): Subscription {
    return new Subscription(this.props.subscription || {})
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || [])
      .map((photoUse) => new PhotoUse(photoUse))
      .sort((a, b) => a.getOrderNumber() - b.getOrderNumber())
  }
}
