import {FC, ReactNode, useEffect} from 'react'

// utils
import {KTSVG} from 'utils/template/helpers'

// local
import Filter from './Filter'
import {Textfield} from '../Textfield'
import {texts} from './texts'
import {Button} from '../Button'
import {useStyle} from './useStyle'
import {useData} from './useData'

export interface ITableToolbar {
  filter?: ReactNode
  query?: any
  setQuery?: React.Dispatch<React.SetStateAction<any>>
  onSubmit?: () => void
  onClear?: () => void
  disabledSearch?: boolean
  position?: string
  filterIcon?: 'arrow' | 'filter'
}

const TableToolbar: FC<ITableToolbar> = ({
  filter,
  query = {},
  setQuery,
  onSubmit = () => {},
  onClear = () => {},
  disabledSearch = false,
  position = 'bottom-end',
  filterIcon = 'filter',
}) => {
  const styles = useStyle()

  const {id, menuState, setMenuState, TX, element, wrapperElement, search} = useData({
    query,
    onSubmit,
  })

  return (
    <>
      <div
        id={'kt_header_search' + id}
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement={position}
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id={'kt_header_search_toggle' + id}
          onClick={() => {
            search?.show()
          }}
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
          </div>
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            data-kt-search-element='wrapper'
            ref={wrapperElement}
          >
            <div className='w-100 position-relative mb-3 d-flex flex-column justify-content-center align-items-center'>
              <div className='d-flex flex-row w-100 justify-content-flex-start align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-1'
                />

                <Textfield
                  disabled={disabledSearch}
                  type='text'
                  className={[
                    disabledSearch
                      ? 'form-control ps-10 py-0 disabled'
                      : 'form-control form-control-flush ps-10 py-0',
                  ].join(' ')}
                  name='search'
                  placeholder={TX(texts.search)}
                  data-kt-search-element='input'
                  value={query?.search ? query?.search : ''}
                  onChange={(e: any) =>
                    setQuery && setQuery((query: any) => ({...query, search: e.target.value}))
                  }
                />

                {!!filter && (
                  <div className='position-absolute top-50 translate-middle-y' style={{left: '0'}}>
                    <div
                      data-kt-search-element='advanced-options-form-show'
                      className='btn btn-icon w-25px btn-sm btn-active-color-primary'
                      data-bs-toggle='tooltip'
                      onClick={() => {
                        setMenuState('advanced')
                      }}
                      title='Show more search options'
                    >
                      {filterIcon === 'filter' ? (
                        <KTSVG
                          path='/media/sarsabad/svg/fluent_filter.svg'
                          className='svg-icon-2'
                        />
                      ) : (
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr072.svg'
                          className='svg-icon-2'
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex w-100 justify-content-space-between align-items-center gap-10'>
              <Button
                className='w-50 btn btn-sm fw-bolder btn-primary d-flex justify-content-center align-items-center'
                data-kt-search-element='advanced-options-form-search'
                style={styles.button}
                onClick={() => {
                  onSubmit()
                  search?.hide()
                }}
                type='button'
                disabled={query?.search?.length <= 0 || !query?.search}
              >
                {TX(texts.submit)}
              </Button>
              <Button
                className='w-50 main-red-background btn btn-sm background-inherit justify-content-center'
                style={styles.button}
                onClick={() => {
                  onClear()
                  search?.hide()
                }}
                type='button'
              >
                {TX(texts.clear)}
              </Button>
            </div>
          </div>

          <Filter
            menuState={menuState}
            setMenuState={setMenuState}
            children={filter}
            onSubmit={() => {
              onSubmit()
              setMenuState('main')
              search?.hide()
            }}
            onClear={() => {
              onClear()
              search?.hide()
            }}
          />
        </div>
      </div>
    </>
  )
}

export {TableToolbar}
