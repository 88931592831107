import {Expose} from 'class-transformer'
import {IsEnum, IsOptional, IsString} from 'class-validator'
import {PaginateRequestDto} from './paginate.request.dto'
import {SortTypeEnum} from 'models'
import {GetProductsRequestDtoSortEnum, ProductStatusEnum} from 'enums'

export class GetProductsRequestDtoFiltersDto extends PaginateRequestDto {
  @IsOptional()
  @IsString()
  @Expose()
  'filters[type]'?: string

  @IsEnum(ProductStatusEnum)
  @IsOptional()
  'filters[status]'?: ProductStatusEnum

  @IsEnum(SortTypeEnum)
  @IsOptional()
  @Expose()
  sortType?: SortTypeEnum

  @IsEnum(GetProductsRequestDtoSortEnum)
  @IsOptional()
  @Expose()
  sort?: GetProductsRequestDtoSortEnum

  @IsOptional()
  @IsString()
  search?: string
}
