import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  header:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.components,createNewPackage.header',
  box_name:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.components,createNewPackage.box-name',
  sell_price:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.components,createNewPackage.sell-price',
  products_weight:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.components,createNewPackage.products-weight',
  expiration_date:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.components,createNewPackage.expiration-date',
  priority:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.components,createNewPackage.priority',
  description:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.components,createNewPackage.description',
  cancel:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.components,createNewPackage.cancel',
  submit:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.components,createNewPackage.submit',
  success_creation:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.components,createNewPackage.creation-success',
  product_form_name_required:
    'pages.protected.production-line,supplies.products.components.create-product-modal.form-variety-name-required-error',
  box_image:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.box_image',
  name_required:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.name_required',
  expiry_date_required:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.expiry_date_required',
  expiry_date_min:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.expiry_date_min',
  expiry_date_error:
    'pages.protected.production-line.supplies.weekly-arrangements.details.box-edit.expiry_date_error',
}
