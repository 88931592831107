import {useNavigate} from 'react-router-dom'

//utils
import {KTSVG} from 'utils/template/helpers'

// constants
import {DELIVERY_REGIONS_DEFINE_ROUTE} from 'constants/routes'

// hooks
import useText from 'hooks/useText'

//locals
import {texts} from './texts'

const ToolbarRegion = () => {
  const navigate = useNavigate()
  const {TX} = useText()

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div
        className='btn btn-sm main-gradient-background fw-bold d-flex align-items-center px-4 gap-1'
        onClick={() => navigate(DELIVERY_REGIONS_DEFINE_ROUTE)}
      >
        <KTSVG
          path='/media/icons/duotune/art/add-circle-sarsabad.svg'
          className='svg-icon-1 ms-2 me-0 text-white'
        />
        <div className='text-white fw-bold'>{TX(texts.new_region)}</div>
      </div>
    </div>
  )
}

export {ToolbarRegion}
