import {Type} from 'class-transformer'
import {IsOptional, IsPositive} from 'class-validator'

export class PaginateRequestDto {
  @IsOptional()
  @Type(() => Number)
  @IsPositive()
  page?: number

  @IsOptional()
  @Type(() => Number)
  @IsPositive()
  limit?: number

  constructor() {
    this.page = 1
    this.limit = 10
  }
}
