export enum ScaleStatusEnum {
  engaged = 'engaged',
  free = 'free',
}

export enum ScaleStateEnum {
  online = 'online',
  offline = 'offline',
}

export enum ContextEnum {
  inventory_arrived = 'inventory_arrived',
  inventory_waste = 'inventory_waste',
  inventory_remained = 'inventory_remained',
  sorting = 'sorting',
  packing = 'packing',
}

export enum ScaleActionEnum {
  initialize = 0,
  heartbeat = 1,
  weighing = 2,
  weighing_barcode = 3,
}
