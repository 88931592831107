import {IProvince, Province} from './Province'

export interface ICity {
  id?: string
  createdAt?: string
  updatedAt?: string
  label?: string
  provinceId?: string
  Province?: IProvince
  districtsCount?: number
}

export class City {
  public props: ICity = {}

  constructor(data?: ICity) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getDistrictsCount(): number {
    return this.props.districtsCount || 0
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getLabel(): string {
    return this.props.label || ''
  }

  getProvinceId(): string {
    return this.props.provinceId || ''
  }

  getProvince(): Province {
    return new Province(this.props.Province || {})
  }
}
