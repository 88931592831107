export const DASHBOARD_ROUTE = '/dashboard'

// PRODUCTION LINE
export const PRODUCTION_LINE_ROUTE = '/production-line'

// PRODUCTION LINE --- SUPPLIES
export const SUPPLIES_ROUTE = PRODUCTION_LINE_ROUTE + '/supplies'
export const WEEKLY_PACKAGING_ROUTE = SUPPLIES_ROUTE + '/weekly-packaging'
export const WEEKLY_PACKAGING_DETAILS_ROUTE = WEEKLY_PACKAGING_ROUTE + '/:boxId'
export const WEEKLY_PACKAGING_DETAILS_CUSTOMERS_ROUTE =
  WEEKLY_PACKAGING_DETAILS_ROUTE + '/customers'
export const WEEKLY_PACKAGING_DETAILS_DELIVERIES_ROUTE =
  WEEKLY_PACKAGING_DETAILS_ROUTE + '/deliveries'
export const WEEKLY_PACKAGING_DETAILS_WEEKLY_REPORTS_ROUTE =
  WEEKLY_PACKAGING_DETAILS_ROUTE + '/weekly-reports'
export const WEEKLY_PACKAGING_DETAILS_DESCRIPTIONS_ROUTE =
  WEEKLY_PACKAGING_DETAILS_ROUTE + '/description'
export const WEEKLY_PACKAGING_DETAILS_PACKAGING_ROUTE =
  WEEKLY_PACKAGING_DETAILS_ROUTE + '/packaging'
export const WEEKLY_PACKAGING_DETAILS_PURCHASE_CHANGES_ROUTE =
  WEEKLY_PACKAGING_DETAILS_ROUTE + '/purchase-changes'
export const PURCHASE_ORDER_ROUTE = SUPPLIES_ROUTE + '/purchase-order'
export const PURCHASE_ORDER_NEW_ORDER_ROUTE = PURCHASE_ORDER_ROUTE + '/order'
export const PURCHASE_ORDER_DETAILS_ROUTE = PURCHASE_ORDER_ROUTE + '/:orderId'
export const PRODUCTS_ROUTE = SUPPLIES_ROUTE + '/products'
export const PRODUCTS_DETAILS_ROUTE = PRODUCTS_ROUTE + '/:productId'
export const VARIETY_LIST_ROUTE = SUPPLIES_ROUTE + '/varieties'
export const PRODUCTS_TYPES_LIST_ROUTE = PRODUCTS_ROUTE + '/types'
export const VENDORS_ROUTE = SUPPLIES_ROUTE + '/vendors'
export const VENDORS_DETAILS_ROUTE = VENDORS_ROUTE + '/:vendorId'
export const EDIT_BOXES_ROUTE = SUPPLIES_ROUTE + '/edit-boxes'
export const EDIT_BOXES_DETAILS_ROUTE = EDIT_BOXES_ROUTE + '/:boxId'

// PRODUCTION LINE --- WAREHOUSE
export const WAREHOUSE_ROUTE = PRODUCTION_LINE_ROUTE + '/warehouse'
export const WAREHOUSE_WEIGHING_ROUTE = WAREHOUSE_ROUTE + '/weighing'
export const WAREHOUSE_PROCESSING_STATE_ROUTE = WAREHOUSE_ROUTE + '/process-status'
export const SCALES_ROUTE = WAREHOUSE_ROUTE + '/scales'
export const REPORTS_ROUTE = WAREHOUSE_ROUTE + '/reports'

// PRODUCTION LINE --- SORTING
export const SORTING_ROUTE = PRODUCTION_LINE_ROUTE + '/sorting'
export const SORTING_PRODUCTS_ROUTE = SORTING_ROUTE + '/products'
export const SORTING_PRODUCT_DETAILS_ROUTE = SORTING_PRODUCTS_ROUTE + '/:productId'
export const SORTING_LINES_ROUTE = SORTING_ROUTE + '/lines'
export const SORTING_LINE_DETAILS_ROUTE = SORTING_LINES_ROUTE + '/:lineId'
export const SORTING_REPORTS_ROUTE = SORTING_ROUTE + '/reports'

// PRODUCTION LINE --- PACKAGING
export const PACKAGING_ROUTE = PRODUCTION_LINE_ROUTE + '/packaging'
export const START_PACKAGING_ROUTE = PACKAGING_ROUTE + '/start-packaging'
export const PACKAGING_LINES_ROUTE = PACKAGING_ROUTE + '/lines'
export const PACKAGING_LINE_DETAILS_ROUTE = PACKAGING_LINES_ROUTE + '/:lineId'
export const PACKAGING_LINE_REPORT_ROUTE = PACKAGING_ROUTE + '/reports'

// SYSTEM_PREFERENCES
export const SYSTEM_PREFERENCES_ROUTE = '/system-preferences'

// SYSTEM_PREFERENCES --- FAQ_ROUTE
export const FAQ_ROUTE = SYSTEM_PREFERENCES_ROUTE + '/faq'
export const FAQ_CATEGORY_DETAILS_ROUTE = FAQ_ROUTE + '/:faqCategoryId'
export const FAQ_DETAILS_ROUTE = FAQ_ROUTE + '/detail/:faqId'

// SYSTEM_PREFERENCES --- ADMIN_MANAGEMENT
export const ADMIN_MANAGEMENT_ROUTE = SYSTEM_PREFERENCES_ROUTE + '/admin-management'
export const ADMIN_MANAGEMENT_DETAILS_ROUTE = ADMIN_MANAGEMENT_ROUTE + '/:adminId'

// SYSTEM_PREFERENCES --- EMPLOYEES
export const EMPLOYEE_ROUTE = SYSTEM_PREFERENCES_ROUTE + '/employee'
export const EMPLOYEE_DETAILS_ROUTE = EMPLOYEE_ROUTE + '/:employeeId'

// SETTINGS
export const SETTINGS_ROUTE = '/settings'

// SUPPORT
export const SUPPORT_ROUTE = '/support'

// SUPPORT --- TICKETS
export const SUPPORT_TICKETS_ROUTE = SUPPORT_ROUTE + '/tickets'
export const SUPPORT_TICKETS_OPEN_TICKETS_ROUTE = SUPPORT_TICKETS_ROUTE + '/open'
export const SUPPORT_TICKETS_CLOSED_TICKETS_ROUTE = SUPPORT_TICKETS_ROUTE + '/closed'

// SUPPORT --- SUBSCRIBERS
export const SUBSCRIBERS_ROUTE = SUPPORT_ROUTE + '/subscribers'
export const SUBSCRIBERS_LIST_ROUTE = SUBSCRIBERS_ROUTE + '/list'

export const SUBSCRIBERS_LIST_ACTIVE_LIST_ROUTE = SUBSCRIBERS_LIST_ROUTE + '/activated'
export const SUBSCRIBERS_ACTIVE_DETAILS_ROUTE = SUBSCRIBERS_LIST_ACTIVE_LIST_ROUTE + '/:customerId'
export const SUBSCRIBERS_ACTIVE_DETAILS_TICKETS_ROUTE =
  SUBSCRIBERS_ACTIVE_DETAILS_ROUTE + '/tickets'
export const SUBSCRIBERS_ACTIVE_DETAILS_WALLET_ROUTE = SUBSCRIBERS_ACTIVE_DETAILS_ROUTE + '/wallet'
export const SUBSCRIBERS_ACTIVE_DETAILS_PURCHASE_ROUTE =
  SUBSCRIBERS_ACTIVE_DETAILS_ROUTE + '/:purchaseId'
export const SUBSCRIBERS_ACTIVE_DETAILS_PURCHASE_DETAILS_ROUTE =
  SUBSCRIBERS_ACTIVE_DETAILS_PURCHASE_ROUTE + '/:deliveryId'

export const SUBSCRIBERS_LIST_DEACTIVE_LIST_ROUTE = SUBSCRIBERS_LIST_ROUTE + '/deactivated'
export const SUBSCRIBERS_DEACTIVE_DETAILS_ROUTE =
  SUBSCRIBERS_LIST_DEACTIVE_LIST_ROUTE + '/:customerId'
export const SUBSCRIBERS_DEACTIVE_DETAILS_TICKETS_ROUTE =
  SUBSCRIBERS_DEACTIVE_DETAILS_ROUTE + '/tickets'
export const SUBSCRIBERS_DEACTIVE_DETAILS_WALLET_ROUTE =
  SUBSCRIBERS_DEACTIVE_DETAILS_ROUTE + '/wallet'
export const SUBSCRIBERS_DEACTIVE_DETAILS_PURCHASE_ROUTE =
  SUBSCRIBERS_LIST_DEACTIVE_LIST_ROUTE + '/:purchaseId'
export const SUBSCRIBERS_DEACTIVE_DETAILS_PURCHASE_DETAILS_ROUTE =
  SUBSCRIBERS_ACTIVE_DETAILS_ROUTE + '/:deliveryId'

export const SUBSCRIBERS_LIST_WAITING_LIST_ROUTE = SUBSCRIBERS_LIST_ROUTE + '/on_waiting_list'
export const SUBSCRIBERS_WAITING_DETAILS_ROUTE =
  SUBSCRIBERS_LIST_WAITING_LIST_ROUTE + '/:customerId'
export const SUBSCRIBERS_WAITING_DETAILS_TICKETS_ROUTE =
  SUBSCRIBERS_WAITING_DETAILS_ROUTE + '/tickets'
export const SUBSCRIBERS_WAITING_DETAILS_WALLET_ROUTE =
  SUBSCRIBERS_WAITING_DETAILS_ROUTE + '/wallet'
export const SUBSCRIBERS_WAITING_DETAILS_PURCHASE_ROUTE =
  SUBSCRIBERS_WAITING_DETAILS_ROUTE + '/:purchaseId'
export const SUBSCRIBERS_WAITING_DETAILS_PURCHASE_DETAILS_ROUTE =
  SUBSCRIBERS_ACTIVE_DETAILS_ROUTE + '/:deliveryId'

export const SUBSCRIBERS_LIST_OUTSIDE_LIST_ROUTE = SUBSCRIBERS_LIST_ROUTE + '/out_of_range'
export const SUBSCRIBERS_OUTSIDE_DETAILS_ROUTE =
  SUBSCRIBERS_LIST_OUTSIDE_LIST_ROUTE + '/:customerId'
export const SUBSCRIBERS_OUTSIDE_DETAILS_TICKETS_ROUTE =
  SUBSCRIBERS_OUTSIDE_DETAILS_ROUTE + '/tickets'
export const SUBSCRIBERS_OUTSIDE_DETAILS_WALLET_ROUTE =
  SUBSCRIBERS_OUTSIDE_DETAILS_ROUTE + '/wallet'
export const SUBSCRIBERS_OUTSIDE_DETAILS_PURCHASE_ROUTE =
  SUBSCRIBERS_OUTSIDE_DETAILS_ROUTE + '/:purchaseId'
export const SUBSCRIBERS_OUTSIDE_DETAILS_PURCHASE_DETAILS_ROUTE =
  SUBSCRIBERS_ACTIVE_DETAILS_ROUTE + '/:deliveryId'

export const SUBSCRIBERS_LIST_FULL_CAPACITY_LIST_ROUTE = SUBSCRIBERS_LIST_ROUTE + '/full_capacity'
export const SUBSCRIBERS_FULL_CAPACITY_DETAILS_ROUTE =
  SUBSCRIBERS_LIST_FULL_CAPACITY_LIST_ROUTE + '/:customerId'
export const SUBSCRIBERS_FULL_CAPACITY_DETAILS_TICKETS_ROUTE =
  SUBSCRIBERS_FULL_CAPACITY_DETAILS_ROUTE + '/tickets'
export const SUBSCRIBERS_FULL_CAPACITY_DETAILS_WALLET_ROUTE =
  SUBSCRIBERS_FULL_CAPACITY_DETAILS_ROUTE + '/wallet'
export const SUBSCRIBERS_FULL_CAPACITY_DETAILS_PURCHASE_ROUTE =
  SUBSCRIBERS_FULL_CAPACITY_DETAILS_ROUTE + '/:purchaseId'
export const SUBSCRIBERS_FULL_CAPACITY_DETAILS_PURCHASE_DETAILS_ROUTE =
  SUBSCRIBERS_ACTIVE_DETAILS_ROUTE + '/:deliveryId'

// SUPPORT --- TRANSACTIONS
export const TRANSACTIONS_ROUTE = SUPPORT_ROUTE + '/transactions'
export const TRANSACTIONS_LIST_ROUTE = TRANSACTIONS_ROUTE + '/list'

// SUPPORT --- ORDER_HISTORY
export const ORDER_HISTORY_ROUTE = SUPPORT_ROUTE + '/order-history'
export const ORDER_HISTORY_LIST_ROUTE = ORDER_HISTORY_ROUTE + '/list'
export const ORDER_HISTORY_DETAIL_ROUTE = ORDER_HISTORY_ROUTE + '/:subscriptionId/:customerId'
export const ORDER_HISTORY_DETAIL_BOXES_ROUTE = ORDER_HISTORY_DETAIL_ROUTE + '/boxes'
export const ORDER_HISTORY_DETAIL_STATUS_ROUTE = ORDER_HISTORY_DETAIL_ROUTE + '/status'
export const ORDER_HISTORY_DETAIL_CUSTOMER_INFO_ROUTE =
  ORDER_HISTORY_DETAIL_ROUTE + '/customer-info'

// SUPPORT --- COUPON
export const COUPON_ROUTE = SUPPORT_ROUTE + '/coupon'
export const COUPON_LIST_ROUTE = COUPON_ROUTE + '/list'
export const COUPON_DETAIL_ROUTE = COUPON_ROUTE + '/:couponId'
export const COUPON_CREATE_ROUTE = COUPON_ROUTE + '/create'
export const COUPON_USERS_USAGE_ROUTE = COUPON_DETAIL_ROUTE + '/users'

// DELIVERY
export const DELIVERY_ROUTE = '/delivery'
export const DELIVERY_CHAINS_ROUTE = DELIVERY_ROUTE + '/chains'
export const DELIVERY_CHAINS_DEFINE_ROUTE = DELIVERY_CHAINS_ROUTE + '/define'
export const DELIVERY_REGIONS_ROUTE = DELIVERY_ROUTE + '/regions'
export const DELIVERY_REGIONS_DEFINE_ROUTE = DELIVERY_REGIONS_ROUTE + '/define'
export const DELIVERY_COURIERS_ROUTE = DELIVERY_ROUTE + '/couriers'
export const DELIVERY_COURIERS_LIST_ROUTE = DELIVERY_COURIERS_ROUTE + '/list'
export const DELIVERY_COURIERS_DETAILS_ROUTE = DELIVERY_COURIERS_LIST_ROUTE + '/:courierId'
export const DELIVERY_COURIERS_DETAILS_INFO_ROUTE = DELIVERY_COURIERS_DETAILS_ROUTE + '/info'
export const DELIVERY_COURIERS_DETAILS_CURRENT_SERVICES_ROUTE =
  DELIVERY_COURIERS_DETAILS_ROUTE + '/current-services'
export const DELIVERY_COURIERS_DETAILS_PREVIOUS_SERVICES_ROUTE =
  DELIVERY_COURIERS_DETAILS_ROUTE + '/previous-services'
export const DELIVERY_REPORTS_ROUTE = DELIVERY_ROUTE + '/reports'
