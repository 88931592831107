import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  production_line: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.production_line_section',
  costumers_orders:
    'components.layout.sidebar.sidebar-menu.SidebarMenuMain.costumer_orders_section',
  content_management:
    'components.layout.sidebar.sidebar-menu.SidebarMenuMain.content_management_section',
  system_config: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.system_config_section',

  dashboard: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.dashboard',
  supplies: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.supplies',
  warehouse: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.warehouse',
  distinguish: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.distinguish',
  packaging: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.packaging',
  startPackaging: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.start.packaging',
  delivery: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.delivery',
  reports: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.reports',
  messages: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.messages',
  costumers_list: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.costumers_list',
  account: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.account',
  users_management: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.users_management',
  faq: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.faq',
  admin_management: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.admin_management',
  employee_management: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.employee_management',
  first: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.content_management_first',
  second: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.content_management_second',
  third: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.content_management_third',
  weekly_packages: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.weekly_package_submenu',
  purchase_order: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.purchase_order_submenu',
  products: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.products_submenu',
  vendors: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.sellers_submenu',
  varieties: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.varieties_submenu',
  boxes: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.boxes_submenu',
  lines: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.lines_submenu',
  linesReports: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.lines_reports',
  warehouse_overall:
    'components.layout.sidebar.sidebar-menu.SidebarMenuMain.warehouse_first_submenu',
  warehouse_processing_status:
    'components.layout.sidebar.sidebar-menu.SidebarMenuMain.warehouse_second_submenu',
  scales: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.scales',
  customers: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.customers_submenu',
  delivery_submenu: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.delivery_submenu',
  regions: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.regions_submenu',
  chains: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.chains_submenu',
  customers_and_support:
    'components.layout.sidebar.sidebar-menu.SidebarMenuMain.customers_and_support_submenu',
  active: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.active_submenu',
  deactive: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.deactive_submenu',
  waiting_list: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.waiting_list__submenu',
  outside: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.outside_submenu',
  full_capacity: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.full_capacity_submenu',
  support: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.support_submenu',
  not_answered: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.not_answered_submenu',
  solved: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.solved_submenu',
  couriers: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.couriers',
  transactions: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.transactions',
  order_history: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.order_history',
  coupons: 'components.layout.sidebar.sidebar-menu.SidebarMenuMain.coupons',
}
