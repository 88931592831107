import {IsNotEmpty, IsNumber, IsPositive, IsString} from 'class-validator'

export class AddBalanceToCustomerWalletRequestDto {
  @IsNumber({}, {message: 'مقدار الزامی است'})
  @IsPositive({message: 'مقدار باید عدد مثبت باشد'})
  @IsNotEmpty({message: 'مقدار الزامی است'})
  amount: number

  @IsString({message: 'توضیحات الزامی است'})
  @IsNotEmpty({message: 'توضیحات الزامی است'})
  description: string

  @IsString({message: 'شماره سفارش الزامی است'})
  @IsNotEmpty({message: 'شماره سفارش الزامی است'})
  subscriptionId: string

  constructor() {
    this.amount = 0
    this.description = ''
    this.subscriptionId = ''
  }
}
