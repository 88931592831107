export enum ProductTypeEnum {
  fruit = 'fruit',
  vegetable = 'vegetable',
  crop = 'vegetable',
  fruit_juice = 'fruit_juice',
}

export interface IProductType {
  id?: string
  createdAt?: string
  updateAt?: string
  name?: string
  faName?: string
  isActive?: boolean
}

export class ProductType {
  public props: IProductType = {}

  constructor(data?: IProductType) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updateAt ? new Date(this.props.updateAt) : undefined
  }

  getName(): string {
    return this.props.name || ''
  }

  getFaName(): string {
    return this.props.faName || ''
  }

  getIsActive(): boolean {
    return this.props.isActive || false
  }
}
