import {Type} from 'class-transformer'
import {IsNumber, IsOptional, IsString, ValidateNested} from 'class-validator'

export class PackagingTargetForBoxProductElement {
  @IsOptional()
  @IsString()
  id?: string

  @IsNumber()
  @Type(() => Number)
  quantity: number

  constructor() {
    this.id = ''
    this.quantity = 0
  }
}
export class CreatePackagingTargetForBoxRequestDto {
  @IsString({message: 'باکس الزامی است'})
  boxId: string

  @Type(() => PackagingTargetForBoxProductElement)
  @ValidateNested({each: true})
  products: PackagingTargetForBoxProductElement[]

  constructor() {
    this.boxId = ''
    this.products = []
  }
}
