import {IsBoolean, IsNumber} from 'class-validator'

export class GetBoxPriceAndWeightResponseDto {
  @IsNumber()
  customPrice: number

  @IsNumber()
  packagingTargetPrice: number

  @IsNumber()
  price: number

  @IsBoolean()
  useCustomPrice: boolean

  @IsNumber()
  weight: number

  constructor() {
    this.customPrice = 0
    this.packagingTargetPrice = 0
    this.useCustomPrice = false
    this.price = 0
    this.weight = 0
  }
}
