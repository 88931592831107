import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  edit: 'pages.protected.subscribers.list.components.address_card.edit',
  active_address_changed:
    'pages.protected.subscribers.list.components.address_card.active_address_changed',
  active_address_label:
    'pages.protected.subscribers.list.components.address_card.active_address_label',
  deactive_address_label:
    'pages.protected.subscribers.list.components.address_card.deactive_address_label',
}
