import {IOrderLine, OrderLine} from './OrderLine'
import {ISortingProduct, SortingProduct} from './SortingProduct'

export enum SortingProductBoxStatusEnum {
  start = 'start',
  pause = 'pause',
  finish = 'finish',
  resume = 'resume',
  stop = 'stop',
  disable = 'disable',
  done = 'done',
}

export interface ISortingProductBox {
  id?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string | null
  status?: SortingProductBoxStatusEnum
  unitQuantity?: number
  expectedQuantity?: number | null
  recordedQuantity?: number | null
  orderLineId?: string
  orderLine?: IOrderLine
  barcode?: string
  barcodeId?: string
  weighingSequenceId?: string
  sortingProductId?: string
  sortingProduct: ISortingProduct
}

export class SortingProductBox {
  public props: ISortingProductBox = {
    sortingProduct: {},
  }

  constructor(data?: ISortingProductBox) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDeletedAt(): Date | null {
    return this.props.deletedAt ? new Date(this.props.deletedAt) : null
  }

  getStatus(): SortingProductBoxStatusEnum | string {
    return this.props.status ? this.props.status : ''
  }

  getUnitQuantity(): number {
    return this.props.unitQuantity || 0
  }

  getExpectedQuantity(): number | null {
    return this.props.expectedQuantity ? this.props.expectedQuantity : null
  }

  getRecordedQuantity(): number | null {
    return this.props.recordedQuantity ? this.props.recordedQuantity : null
  }

  getOrderLineId(): string {
    return this.props.orderLineId || ''
  }

  getOrderLine(): OrderLine {
    return new OrderLine(this.props.orderLine || {})
  }

  getBarcode(): string {
    return this.props.barcode || ''
  }

  getBarcodeId(): string {
    return this.props.barcodeId || ''
  }

  getWeighingSequenceId(): string {
    return this.props.weighingSequenceId || ''
  }

  getSortingProductId(): string {
    return this.props.sortingProductId || ''
  }

  getSortingProduct(): SortingProduct {
    return new SortingProduct(this.props.sortingProduct || {})
  }

  // custom

  getDisplaySortingProductBoxStatus(): string {
    return displaySortingProductBoxStatus(this.getStatus())
  }
}

export const displaySortingProductBoxStatus = (
  sortingProductBoxStatus: SortingProductBoxStatusEnum | string
): string => {
  switch (sortingProductBoxStatus) {
    case SortingProductBoxStatusEnum.start:
      return 'آغاز'
    case SortingProductBoxStatusEnum.pause:
      return 'توقف'
    case SortingProductBoxStatusEnum.finish:
      return 'پایان'
    default:
      return '-'
  }
}
