import {ProductType} from 'models/ProductType'
import {ProductUnit} from 'models/ProductUnit'
import {createContext} from 'react'

export interface IParametersContext {
  productTypes: ProductType[]
  setProductTypes: React.Dispatch<React.SetStateAction<ProductType[]>>
  productUnits: ProductUnit[]
  setProductUnits: React.Dispatch<React.SetStateAction<ProductUnit[]>>
}

export default createContext<IParametersContext>({
  productTypes: [],
  setProductTypes: () => {},
  productUnits: [],
  setProductUnits: () => {},
})
