// providers
import {PageLink, ToolbarType} from 'providers/layout'

import React, {createContext} from 'react'

export interface IConfig {
  pageName: string[]
  pageDescription: string[]
  breadcrumbsItems: PageLink[]
  toolbarType: ToolbarType
}
export interface IPublicRouteContext {
  config: IConfig
  setConfig: React.Dispatch<React.SetStateAction<IConfig>>
}

export default createContext<IPublicRouteContext>({
  config: {pageName: [], pageDescription: [], breadcrumbsItems: [], toolbarType: 'none'},
  setConfig: () => {},
})
