import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  new_box: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_box',
  new_product: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_product',
  new_scale: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_scale',
  new_seller: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_seller',
  refetch_scale: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.refetch_scale',
  refetch_scale_success:
    'components.template.layout.toolbar.toolbars.ToolbarNewBox.refetch_scale_success',
  new_purchase_order:
    'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_purchase-order',
  new_variety: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_variety',
  new_line: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_line',
  new_subscriber: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_subscriber',
  new_region: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_region',
  new_courier: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_courier',
  new_customer: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_customer',
  transactions: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_courier',
  new_admin: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_admin',
  new_buy: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_buy',
  new_employee: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_employee',
  new_category: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_category',
  new_faq: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_faq',
  new_type: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_type',
  new_ticket: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_ticket',
  new_purchase: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_purchase',
  new_coupon: 'components.template.layout.toolbar.toolbars.ToolbarNewBox.title_new_coupon',
}
