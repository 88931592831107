import {Delivery, IDelivery} from './Delivery'
import {Admin, IAdmin} from './Admin'
import {Customer, ICustomer} from './Customer'
import {IPhotoUse, PhotoUse} from './PhotoUse'
import {ITicketResponse, TicketResponse} from './TicketResponse'
import {Photo} from './Photo'
import {TicketCategoryEnum, TicketCreatedByEnum, TicketStatusEnum} from 'enums'

export interface ITicket {
  id?: string
  createdAt?: string
  updatedAt?: string
  description?: string
  number?: number
  date?: string
  status?: TicketStatusEnum
  createdBy?: TicketCreatedByEnum
  category?: TicketCategoryEnum
  customerId?: string
  deliveryId?: string
  adminId?: string
  customer?: ICustomer
  delivery?: IDelivery
  admin?: IAdmin
  photoUses?: IPhotoUse[]
  ticketResponses?: ITicketResponse[]
}

export class Ticket {
  public props: ITicket = {}

  constructor(data?: ITicket) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDescription(): string {
    return this.props.description || ''
  }

  getNumber(): number {
    return this.props.number || 0
  }

  getDate(): Date | undefined {
    return this.props.date ? new Date(this.props.date) : undefined
  }

  getStatus(): TicketStatusEnum {
    return this.props.status as TicketStatusEnum
  }

  getCategory(): TicketCategoryEnum {
    return this.props.category as TicketCategoryEnum
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getDeliveryId(): string {
    return this.props.deliveryId || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getDelivery(): Delivery {
    return new Delivery(this.props.delivery || {})
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || [])
      .map((photoUse) => new PhotoUse(photoUse))
      .sort((a, b) => a.getOrderNumber() - b.getOrderNumber())
  }

  getMainImage(): Photo {
    if (this.props.photoUses && this.props.photoUses.length > 0) {
      return new PhotoUse(this.props.photoUses[0]).getPhoto()
    }
    return new Photo()
  }

  getCreatedBy(): TicketCreatedByEnum {
    return this.props.createdBy as TicketCreatedByEnum
  }

  getTicketResponses(): TicketResponse[] {
    return (this.props.ticketResponses || []).map(
      (ticketResponse: ITicketResponse) => new TicketResponse(ticketResponse)
    )
  }

  // custom

  getDisplayTicketCategory(): string {
    return displayTicketCategoryEnum(this.getCategory())
  }
}

export const displayTicketCategoryEnum = (ticketCategoryEnum?: TicketCategoryEnum): string => {
  switch (ticketCategoryEnum) {
    case TicketCategoryEnum.account:
      return 'حساب کاربری'
    case TicketCategoryEnum.delivery:
      return 'ارسال و تحویل'
    case TicketCategoryEnum.delivery_quality:
      return 'کیفیت ارسال'
    case TicketCategoryEnum.cancel_delivery:
      return 'لغو سفارش'
    case TicketCategoryEnum.general:
      return 'سایر موارد'
    default:
      return ''
  }
}
