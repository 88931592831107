import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

interface ITheme {
  type: string
}

const useStyles = makeStyles<ITheme>()((theme: Theme, {type}) => ({
  inputWrapper: {
    display: 'flex',
    backgroundColor: 'var(--kt-input-solid-bg)',
    borderRadius: '0.625rem',
    paddingRight: `${theme.spacing(0)} !important`,
    paddingLeft:
      type === 'password' ? `${theme.spacing(1.8)} !important` : `${theme.spacing(0)} !important`,
  },
  input: {
    '&::placeholder': {
      textAlign: 'center',
    },
    borderRadius: '0.625rem',
  },
  errorText: {
    textAlign: 'right',
    color: 'red !important',
  },
  visibilityIcon: {
    padding: 0,
    marginRight: theme.spacing(1.7),
  },
}))

export default useStyles
