import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    tableWrapper: {
      borderCollapse: 'separate',
      borderSpacing: '0 !important',
      '& thead': {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'relative',
          right: 0,
          width: '100%',
          height: '10px',
          display: 'flex',
        },
      },
    },
    tableRowHead: {
      backgroundColor: theme.palette.grey[100],
      '& th': {
        whiteSpace: 'nowrap',
        borderTop: `2px solid ${theme.palette.grey[300]} !important`,
        borderBottom: `2px solid ${theme.palette.grey[300]} !important`,
      },
      '& th:first-child': {
        borderRight: `2px solid ${theme.palette.grey[300]} !important`,
        borderRadius: '0 8px 8px 0',
        paddingRight: `${theme.spacing(2)} !important`,
      },
      '& th:last-child': {
        borderLeft: `2px solid ${theme.palette.grey[300]} !important`,
        borderRadius: '8px 0 0 8px',
        paddingLeft: `${theme.spacing(2)} !important`,
      },
    },
    tableRowBody: {
      td: {
        textAlign: 'right',
      },
      // 'td *': {
      //   display: '-webkit-box',
      //   '-webkit-line-clamp': '1',
      //   '-webkit-box-orient': 'vertical',
      //   overflow: 'hidden',
      //   textOverflow: 'ellipsis',
      // },
      '& td:first-child': {
        paddingRight: `${theme.spacing(2)} !important`,
      },
      '& td:last-child': {
        paddingLeft: `${theme.spacing(2)} !important`,
      },
    },
  }
})
