import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  linkText: {
    color: '#1A73E8 !important',
    cursor: 'pointer',
  },

  filterButton: {
    margin: '1rem',
    marginRight: 'auto',
    background: 'radial-gradient(424.47% 156.42% at -15.94% 108.5%, #0C923F 0%, #00461C 100%)',
  },
}))

export default useStyles
