import apiConfig from 'services/config'
import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'
// models
import {IDto, IScale} from 'models'
// dtos
import {
  EngagedScaleRequestDto,
  HeartbeatScaleRequestDto,
  InitializedScaleRequestDto,
  WeighingBarcodeScaleRequestDto,
  WeighingScaleRequestDto,
} from 'dtos'

const axios = new ClientApi()

const {rootAddress} = apiConfig

const prefix = '/dashboard/scales'

export const getScales = (query: StringifiableRecord): Promise<IDto<IScale>> =>
  axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix, query}, {arrayFormat: 'comma'})
  )

export const engagedScale = (scaleId: string, body: EngagedScaleRequestDto): Promise<IScale> =>
  axios.http.patch(rootAddress + prefix + '/' + scaleId + '/engaged', body)

export const releaseScale = (scaleId: string): Promise<IScale> =>
  axios.http.patch(rootAddress + prefix + '/' + scaleId + '/release')

export const initializeScale = (body: InitializedScaleRequestDto): Promise<IScale> =>
  axios.http.post(rootAddress + prefix + '/initialized', body, {
    headers: {'X-API-KEY': `${process.env.REACT_APP_X_API_KEY}`},
  })

export const heartBeat = (body: HeartbeatScaleRequestDto): Promise<IScale> =>
  axios.http.post(rootAddress + prefix + '/heartbeat', body, {
    headers: {'X-API-KEY': `${process.env.REACT_APP_X_API_KEY}`},
  })

export const weighing = (body: WeighingScaleRequestDto): Promise<IScale> =>
  axios.http.post(rootAddress + prefix + '/weighing', body, {
    headers: {'X-API-KEY': `${process.env.REACT_APP_X_API_KEY}`},
  })

export const weighingBarcode = (body: WeighingBarcodeScaleRequestDto): Promise<IScale> =>
  axios.http.post(rootAddress + prefix + '/weighing-barcode', body, {
    headers: {'X-API-KEY': `${process.env.REACT_APP_X_API_KEY}`},
  })

export const heartbeatCheck = (): Promise<IScale> =>
  axios.http.post(
    rootAddress + prefix + '/heartbeat/check',
    {},
    {
      headers: {'X-API-KEY': `${process.env.REACT_APP_X_API_KEY}`},
    }
  )
