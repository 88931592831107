import {BoxCreatorTypeEnum, BoxStatusEnum, BoxTypeEnum} from 'enums'
import {Admin, IAdmin} from './Admin'
import {Customer, ICustomer} from './Customer'
import {IPackingTarget, PackingTarget} from './PackingTarget'
import {Photo} from './Photo'
import {IPhotoUse, PhotoUse} from './PhotoUse'

export interface IBox {
  id?: string
  createdAt?: string
  updatedAt?: string
  name?: string
  status?: BoxStatusEnum
  price?: number
  creatorType?: BoxCreatorTypeEnum
  type?: BoxTypeEnum
  priority?: number
  description?: string
  startDate?: string
  endDate?: string
  subscribersCount?: number
  customerId?: string
  customer?: ICustomer
  adminId?: string
  admin?: IAdmin
  photoUses?: IPhotoUse[]
  packagingTargets?: IPackingTarget[]
  useCustomPrice?: boolean
  customPrice?: number
}

export class Box {
  public props: IBox = {}

  constructor(data?: IBox) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getName(): string {
    return this.props.name || ''
  }

  getPrice(): number {
    return this.props.price || 0
  }

  getStatus(): BoxStatusEnum | undefined {
    return this.props.status
  }

  getCreatorType(): BoxCreatorTypeEnum | undefined {
    return this.props.creatorType
  }

  getType(): BoxTypeEnum | undefined {
    return this.props.type
  }

  getPriority(): number {
    return this.props.priority || 0
  }

  getDescription(): string {
    return this.props.description || ''
  }

  getStartDate(): Date | undefined {
    return this.props.startDate ? new Date(this.props.startDate) : undefined
  }

  getEndDate(): Date | undefined {
    return this.props.endDate ? new Date(this.props.endDate) : undefined
  }

  getSubscribersCount(): number {
    return this.props.subscribersCount || 0
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || [])
      .map((photoUse) => new PhotoUse(photoUse))
      .sort((a, b) => a.getOrderNumber() - b.getOrderNumber())
  }

  getPackagingTargets(): PackingTarget[] {
    return (this.props.packagingTargets || []).map(
      (packagingTarget) => new PackingTarget(packagingTarget)
    )
  }

  getMainImage(): Photo {
    return this.getPhotoUses().length > 0 ? this.getPhotoUses()[0].getPhoto() : new Photo()
  }

  // custom
  getDisplayStatus(): string {
    return displayBoxStatusEnum(this.getStatus())
  }

  getDisplayType(): string {
    return displayBoxTypeEnum(this.getType())
  }

  getUseCustomPrice(): boolean {
    return this.props.useCustomPrice || false
  }

  getCustomPrice(): number {
    return this.props.customPrice || 0
  }
}

export const displayBoxStatusEnum = (boxStatusEnum?: BoxStatusEnum): string => {
  switch (boxStatusEnum) {
    case BoxStatusEnum.activated:
      return 'فعال'
    case BoxStatusEnum.deactivated:
      return 'غیرفعال'
    default:
      return ''
  }
}

export const displayBoxTypeEnum = (boxTypeEnum?: BoxTypeEnum): string => {
  switch (boxTypeEnum) {
    case BoxTypeEnum.CUSTOM:
      return 'سفارشی'
    case BoxTypeEnum.STANDARD:
      return 'آماده'
    default:
      return ''
  }
}
