import {Type} from 'class-transformer'
import {
  ArrayNotEmpty,
  IsArray,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator'
import {TransactionPaymentTypeEnum} from 'enums'

class CreateCustomSubscriptionProduct {
  @IsOptional()
  @IsString()
  productId?: string

  @IsOptional()
  @IsString()
  productInstanceId?: string

  @IsNumber({}, {message: 'تعداد محصول الزامی است'})
  @IsNotEmpty({message: 'تعداد محصول الزامی است'})
  quantity: number

  constructor() {
    this.productId = ''
    this.productInstanceId = ''
    this.quantity = 0
  }
}

export class CreateCustomSubscriptionRequestDto {
  @ValidateNested({each: true})
  @Type(() => CreateCustomSubscriptionProduct)
  @IsArray({message: 'محصولی انتخاب نشده است'})
  @ArrayNotEmpty({message: 'محصولی انتخاب نشده است'})
  products: CreateCustomSubscriptionProduct[]

  @IsString({message: 'محدوده الزامی است'})
  @IsNotEmpty({message: 'محدوده الزامی است'})
  regionId: string

  @IsString({message: 'زمان ارسال الزامی است'})
  @IsNotEmpty({message: 'زمان ارسال الزامی است'})
  deliveryPlanId: string

  @IsNumber({}, {message: 'تعداد هفته الزامی است'})
  @IsNotEmpty({message: 'تعداد هفته الزامی است'})
  weeklyCount: number

  @IsString({message: 'آدرس الزامی است'})
  @IsNotEmpty({message: 'آدرس الزامی است'})
  addressId: string

  @IsString({message: 'مشترک الزامی است'})
  @IsNotEmpty({message: 'مشترک الزامی است'})
  customerId: string

  @IsEnum(TransactionPaymentTypeEnum)
  paymentType?: TransactionPaymentTypeEnum

  constructor() {
    this.products = []
    this.regionId = ''
    this.deliveryPlanId = ''
    this.weeklyCount = 0
    this.addressId = ''
    this.customerId = ''
    this.paymentType = TransactionPaymentTypeEnum.credit
  }
}
