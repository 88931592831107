import {ICoupon, Coupon} from './Coupon'
import {ICustomer, Customer} from './Customer'

export interface ICouponUsage {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date | null
  customerId?: string
  customer?: ICustomer
  couponId?: string
  coupon?: ICoupon
}

export class CouponUsage {
  public props: ICouponUsage = {}

  constructor(data?: ICouponUsage) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDeletedAt(): Date | null {
    return this.props.deletedAt ? new Date(this.props.deletedAt) : null
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getCouponId(): string {
    return this.props.couponId || ''
  }

  getCoupon(): Coupon {
    return new Coupon(this.props.coupon || {})
  }
}
