import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  new_purchase:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.new_purchase',
  back: 'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.back',
  submit:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.submit',
  phone:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.phone',
  lastname:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.lastname',
  select_product:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.select_product',
  discount_number:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.discount_number',
  recycle:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.recycle',
  payment_method:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.payment_method',
  purchase_description:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.purchase_description',
  address:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.address',
  province:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.province',
  city: 'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.city',
  region:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.region',
  district:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.district',
  street:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.street',
  alley:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.alley',
  house_number:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.house_number',
  unit_number:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.unit_number',
  address_label:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.address_label',
  new_address:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.new_address',
  require:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.require',
  posotive_value_is_acceptable:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.posotive_value_is_acceptable',
  delivery_plan:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.delivery_plan',
  success:
    'pages.protected.subscribers.details.details.purchases.components.new_purchase_modal.success',
  amount_payable: 'pages.protected.subscribers.details.purchase.components.amount_payable',
  payment_amount: 'pages.protected.subscribers.details.purchase.components.payment_amount',
  bank_number: 'pages.protected.subscribers.details.purchase.components.bank_number',
  row_selection_for_boxes_hint:
    'pages.protected.subscribers.details.purchase.components.row_selection_for_boxes_hint',
  current_standard_box:
    'pages.protected.subscribers.details.purchase.components.current_standard_box',
  count: 'pages.protected.subscribers.details.purchase.components.count',
  current_custom_box: 'pages.protected.subscribers.details.purchase.components.current_custom_box',
  current_select_product:
    'pages.protected.subscribers.details.purchase.components.current_select_product',
  select_instance: 'pages.protected.subscribers.details.purchase.components.select_instance',
  add_other_custom_box:
    'pages.protected.subscribers.details.purchase.components.add_other_custom_box',
  weekly_count: 'pages.protected.subscribers.details.purchase.components.weekly_count',
  custom_box_label: 'pages.protected.subscribers.details.purchase.components.custom_box_label',
  customers: 'pages.protected.order_history.components.new_purchase_modal.customers',
}
