import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  is_selling:
    'pages.protected.production-line.supplies.weekly-arrangements.components.package-card.is_selling',
  ended:
    'pages.protected.production-line.supplies.weekly-arrangements.components.package-card.ended',
  customers:
    'pages.protected.production-line.supplies.weekly-arrangements.components.package-card.customers',
  weekly_reports:
    'pages.protected.production-line.supplies.weekly-arrangements.components.package-card.weekly_reports',
  description:
    'pages.protected.production-line.supplies.weekly-arrangements.components.package-card.description',
  arrangements:
    'pages.protected.production-line.supplies.weekly-arrangements.components.package-card.arrangements',
  chart_title:
    'pages.protected.production-line.supplies.weekly-arrangements.components.weekly-report.barchart-title',
  this_week:
    'pages.protected.production-line.supplies.weekly-arrangements.components.weekly-report.this-week',
  next_week:
    'pages.protected.production-line.supplies.weekly-arrangements.components.weekly-report.next-week',
  arrangement_title:
    'pages.protected.production-line.supplies.weekly-arrangements.details.header.arranngement-title',
  weekly_report_title:
    'pages.protected.production-line.supplies.weekly-arrangements.details.header.weekly-report-title',
  customers_title:
    'pages.protected.production-line.supplies.weekly-arrangements.details.header.customers-title',
  descriptions_title:
    'pages.protected.production-line.supplies.weekly-arrangements.details.header.descriptions-title',
  small_package_cutomers:
    'pages.protected.production-line.supplies.weekly-arrangements.details.customerspage.small-package-customers',
  num_of_small_package_cutomers:
    'pages.protected.production-line.supplies.weekly-arrangements.details.customerspage.num-of-small-package-customers',
  purchase_changes_title:
    'pages.protected.production-line.supplies.weekly-arrangements.details.header.purchase-changes-title',
  table_title_costumer:
    'pages.protected.production-line.supplies.weekly-arrangements.details.customerspage.table-title-costumer',
  table_title_region:
    'pages.protected.production-line.supplies.weekly-arrangements.details.customerspage.table-title-region',
  table_title_delivary_date:
    'pages.protected.production-line.supplies.weekly-arrangements.details.customerspage.table-title-delivary-date',
  table_title_edit:
    'pages.protected.production-line.supplies.weekly-arrangements.details.customerspage.table-title-edit',
  changes_title:
    'pages.protected.production-line.supplies.weekly-arrangements.details.purchasechangepage.changes',
  num_of_changes:
    'pages.protected.production-line.supplies.weekly-arrangements.details.purchasechangepage.num-of-changes',
  table_title_products:
    'pages.protected.production-line.supplies.weekly-arrangements.details.purchasechangepage.table-title-products',
  table_title_kind:
    'pages.protected.production-line.supplies.weekly-arrangements.details.purchasechangepage.table-title-kind',
  table_title_product_race:
    'pages.protected.production-line.supplies.weekly-arrangements.details.purchasechangepage.table-title-product-race',
  table_title_weight:
    'pages.protected.production-line.supplies.weekly-arrangements.details.purchasechangepage.table-title-weight',
  table_title_purchase_price:
    'pages.protected.production-line.supplies.weekly-arrangements.details.purchasechangepage.table-title-purchase-prise',
  table_title_purchase_date:
    'pages.protected.production-line.supplies.weekly-arrangements.details.purchasechangepage.table-title-purchase-date',
  table_title_purchase_order:
    'pages.protected.production-line.supplies.weekly-arrangements.details.purchasechangepage.table-title-purchase-order',
  small_package_setting:
    'pages.protected.production-line.supplies.weekly-arrangements.details.header.small-box-setting',
  new_arrangement:
    'pages.protected.production-line.supplies.weekly-arrangements.details.header.new-arrangement',
  weekly_arrangement_title: 'pages.protected.supplies.weeklyarrangement.main.title',
  empty: 'components.elements.table.empty',
  supplies: 'pages.protected.breadcrumbsItems.supplies',
  products_deactive_hint:
    'pages.protected.production-line.supplies.weekly-packaging.components.products_deactive_hint',
  products_active_hint:
    'pages.protected.production-line.supplies.weekly-packaging.components.products_active_hint',
  loading: 'pages.protected.loading',
}
