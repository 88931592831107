import {Box, IBox} from './Box'
import {Customer, ICustomer} from './Customer'
import {DailyTarget, IDailyTarget} from './DailyTarget'
import {IPackagingContentTarget, PackagingContentTarget} from './PackagingContentTarget'
import {IPackingTarget, PackingTarget} from './PackingTarget'
import {IProduct, Product} from './Product'

export interface IDailyTargetAggregate {
  id?: string
  createdAt?: string
  updatedAt?: string
  weight?: number
  dailyTargetId?: string
  boxId?: string
  productId?: string
  packagingTargetId?: string
  packagingContentTargetId?: string
  customerId?: string
  box?: IBox
  packagingContentTarget?: IPackagingContentTarget
  packagingTarget?: IPackingTarget
  customer?: ICustomer
  product?: IProduct
  dailyTarget?: IDailyTarget
}

export class DailyTargetAggregate {
  public props: IDailyTargetAggregate = {}

  constructor(data?: IDailyTargetAggregate) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getWeight(): number {
    return this.props.weight || 0
  }

  getDailyTargetId(): string {
    return this.props.dailyTargetId || ''
  }

  getBoxId(): string {
    return this.props.boxId || ''
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getPackagingTargetId(): string {
    return this.props.packagingTargetId || ''
  }

  getPackagingContentTargetId(): string {
    return this.props.packagingContentTargetId || ''
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getBox(): Box {
    return new Box(this.props.box || {})
  }

  getPackagingContentTarget(): PackagingContentTarget {
    return new PackagingContentTarget(this.props.packagingContentTarget || {})
  }

  getPackagingTarget(): PackingTarget {
    return new PackingTarget(this.props.packagingTarget)
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer)
  }

  getProduct(): Product {
    return new Product(this.props.product || {})
  }

  getDailyTarget(): DailyTarget {
    return new DailyTarget(this.props.dailyTarget || {})
  }
}
