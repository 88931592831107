import {Equals, IsDate, IsEnum, IsNotEmpty, IsString} from 'class-validator'
import {ScaleActionEnum} from 'enums'

export class HeartbeatScaleRequestDto {
  @IsEnum(ScaleActionEnum)
  @Equals(ScaleActionEnum.heartbeat)
  action: ScaleActionEnum

  @IsDate()
  timestamp: Date

  @IsString({message: 'ترازو الزامی است'})
  @IsNotEmpty({message: 'ترازو الزامی است'})
  scale_id: string

  constructor() {
    this.action = ScaleActionEnum.heartbeat
    this.timestamp = new Date()
    this.scale_id = '1'
  }
}
