import {IsOptional, IsString} from 'class-validator'

export class GetLoginResponseDto {
  @IsString()
  @IsOptional()
  status?: string

  @IsString()
  @IsOptional()
  token?: string
}
