import {
  IQuote,
  DailyTargetProduct,
  DailyTargetAggregate,
  IDailyTargetAggregate,
  IDailyTargetProduct,
  Quote,
} from 'models'

export interface IDailyTarget {
  id?: string
  createdAt?: string
  updatedAt?: string
  date?: string
  dailyTargetAggregates?: IDailyTargetAggregate[]
  dailyTargetProducts?: IDailyTargetProduct[]
  quotes?: IQuote[]
}

export class DailyTarget {
  public props: IDailyTarget = {}

  constructor(data?: IDailyTarget) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDate(): Date | undefined {
    return this.props.date ? new Date(this.props.date) : undefined
  }

  getDailyTargetAggregates(): DailyTargetAggregate[] {
    return (this.props.dailyTargetAggregates || []).map(
      (dailyTargetAggregate) => new DailyTargetAggregate(dailyTargetAggregate)
    )
  }

  getDailyTargetProducts(): DailyTargetProduct[] {
    return (this.props.dailyTargetProducts || []).map(
      (dailyTargetProduct) => new DailyTargetProduct(dailyTargetProduct)
    )
  }

  getQuotes(): Quote[] {
    return (this.props.quotes || []).map((quote) => new Quote(quote))
  }
}
