import {Stack} from '@mui/material'
// components
import {Table} from 'components/elements/Table'
import {Pagination} from 'components/elements/Pagination'
import {TableHeader} from 'components/elements/TableHeader'
import {JalaliDatePicker} from 'components/elements/JalaliCalendar'
import {Typography} from 'components/elements/Typography'
import {Select} from 'components/elements/Select'
import {Button} from 'components/elements/Button'
// models
import {displayPackingStatusEnum} from 'models'
// enums
import {PackingStatusEnum} from 'enums'
// utils
import {toPersianDigit} from 'utils/basic/digit'
import {getDateStringForQuery} from 'utils/basic/date'
// locals
import {useData} from './useData'
import {texts} from './texts'
import useStyles from './useStyles'

const PackingReportPage = () => {
  const {
    query,
    setQuery,
    searchParams,
    loading,
    columns,
    rows,
    meta,
    handleChangeUrl,
    TX,
    onClean,
    onSubmit,
    onFilter,
  } = useData()
  const {classes} = useStyles()

  const renderFilters = () => {
    return (
      <div className='row gy-5 gx-xl-8 mb-10'>
        <div className='card card-xxl-stretch mb-5 mb-xl-8'>
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <div className='d-flex justify-content-space-around align-items-center gap-9 px-15 my-5'>
              <Typography type='span'>{TX(texts.filter_delivery_chains)} :</Typography>
              <Stack direction='row' gap={2}>
                <JalaliDatePicker
                  name='fromDateDeliveryChain'
                  label={TX(texts.from_date)}
                  value={
                    query['filters[deliveryChainFromDate]']
                      ? new Date(query['filters[deliveryChainFromDate]'])
                      : null
                  }
                  onChange={(value) => {
                    if (value instanceof Date && !isNaN(value as unknown as number)) {
                      setQuery((prevQuery) => ({
                        ...prevQuery,
                        'filters[deliveryChainFromDate]': getDateStringForQuery(value),
                      }))
                    } else {
                      setQuery((prevQuery) => ({
                        ...prevQuery,
                        'filters[deliveryChainFromDate]': '',
                      }))
                    }
                  }}
                  disabled={loading}
                />
                <JalaliDatePicker
                  name='untilDateDeliveryChain'
                  label={TX(texts.until_date)}
                  value={
                    query['filters[deliveryChainUntilDate]']
                      ? new Date(query['filters[deliveryChainUntilDate]'])
                      : null
                  }
                  onChange={(value) => {
                    if (value instanceof Date && !isNaN(value as unknown as number)) {
                      setQuery((prevQuery) => ({
                        ...prevQuery,
                        'filters[deliveryChainUntilDate]': getDateStringForQuery(value),
                      }))
                    } else {
                      setQuery((prevQuery) => ({
                        ...prevQuery,
                        'filters[deliveryChainUntilDate]': '',
                      }))
                    }
                  }}
                  disabled={loading}
                />
              </Stack>
            </div>
            <div className='d-flex flex-wrap justify-content-space-around align-items-center gap-9 px-15 my-5'>
              <Typography type='span'>{TX(texts.filter_packing)} :</Typography>
              <Stack direction='row' gap={2}>
                <JalaliDatePicker
                  name='fromDatePacking'
                  label={TX(texts.from_date)}
                  value={
                    query['filters[packingFromDate]']
                      ? new Date(query['filters[packingFromDate]'])
                      : null
                  }
                  onChange={(value) => {
                    if (value instanceof Date && !isNaN(value as unknown as number)) {
                      setQuery((prevQuery) => ({
                        ...prevQuery,
                        'filters[packingFromDate]': getDateStringForQuery(value),
                      }))
                    } else {
                      setQuery((prevQuery) => ({
                        ...prevQuery,
                        'filters[packingFromDate]': '',
                      }))
                    }
                  }}
                  disabled={loading}
                />
                <JalaliDatePicker
                  name='untilDatePacking'
                  label={TX(texts.until_date)}
                  value={
                    query['filters[packingUntilDate]']
                      ? new Date(query['filters[packingUntilDate]'])
                      : null
                  }
                  onChange={(value) => {
                    if (value instanceof Date && !isNaN(value as unknown as number)) {
                      setQuery((prevQuery) => ({
                        ...prevQuery,
                        'filters[packingUntilDate]': getDateStringForQuery(value),
                      }))
                    } else {
                      setQuery((prevQuery) => ({
                        ...prevQuery,
                        'filters[packingUntilDate]': '',
                      }))
                    }
                  }}
                  disabled={loading}
                />
              </Stack>
            </div>

            <div className='d-flex justify-content-space-around align-items-center gap-9 w-50 px-15 my-5'>
              <Select
                label={TX(texts.filter_packing_status)}
                options={Object.values(PackingStatusEnum)}
                formatOption={displayPackingStatusEnum}
                value={
                  query['filters[packingStatus]' || searchParams.get('filters[packingStatus]')]
                }
                onChange={(type) =>
                  setQuery((query) => ({
                    ...query,
                    'filters[packingStatus]': type as PackingStatusEnum,
                  }))
                }
              />
            </div>
            <Button onClick={onFilter} className={classes.filterButton}>
              {TX(texts.submit)}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const renderLinesList = () => {
    return (
      <>
        <div className='row gy-5 gx-xl-8'>
          <div className='col-12'>
            <div className='card card-xxl-stretch mb-5 mb-xl-8'>
              <TableHeader
                title={TX(texts.reports)}
                description={TX(texts.table_report, [toPersianDigit(meta.getTotalItems())])}
                query={query}
                setQuery={setQuery}
                onClear={onClean}
                onSubmit={onSubmit}
              />
              <Table rows={rows} loading={loading} columns={columns} />
              <Pagination
                disabled={loading}
                meta={meta}
                onPageChange={(page) => {
                  setQuery((query) => ({...query, page}))
                  handleChangeUrl({...query, page})
                }}
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {renderFilters()}
      {renderLinesList()}
    </>
  )
}

export default PackingReportPage
