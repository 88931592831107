import {IsEnum, IsOptional, IsString, NotEquals} from 'class-validator'
import {QuoteStatusEnum} from 'enums'

export class QuoteConfirmedByAdminRequestDto {
  @IsEnum(QuoteStatusEnum)
  @NotEquals(QuoteStatusEnum[QuoteStatusEnum.pending])
  @NotEquals(QuoteStatusEnum[QuoteStatusEnum.waiting_for_completion])
  status: QuoteStatusEnum

  @IsOptional()
  @IsString()
  comments?: string

  constructor() {
    this.status = QuoteStatusEnum.accepted
    this.comments = ''
  }
}
