import {IsString} from 'class-validator'

export class CreateReceiptForOrderRequestDto {
  @IsString({message: 'تصویر رسید را آپلود کنید'})
  photoId: string

  constructor() {
    this.photoId = ''
  }
}
