import {Admin, IAdmin} from './Admin'
import {Box, IBox} from './Box'
import {Courier, ICourier} from './Courier'
import {Customer, ICustomer} from './Customer'
import {IOrder, Order} from './Order'
import {IPhoto, Photo} from './Photo'
import {IProduct, Product} from './Product'
import {ITicket, Ticket} from './Ticket'
import {ITicketResponse, TicketResponse} from './TicketResponse'
import {ITransaction, Transaction} from './Transaction'
import {IVariety, Variety} from './Variety'
import {IVendor, Vendor} from './Vendor'
import {IWalletTransaction, WalletTransaction} from './WalletTransaction'

export enum PhotoUseStatusEnum {
  rejected = 'rejected',
  accepted = 'accepted',
  pending = 'pending',
}

export interface IPhotoUse {
  id?: string
  createdAt?: string
  updatedAt?: string
  status?: PhotoUseStatusEnum
  isAvatar?: true
  orderNumber?: number
  photoId?: string
  paymentOrderId?: string
  receiptOrderId?: string
  productId?: string
  boxId?: string
  vendorId?: string
  varietyId?: string
  ticketId?: string
  ticketResponseId?: string
  transactionId?: string
  courierId?: string
  photo?: IPhoto
  product?: IProduct
  customer?: ICustomer
  courier?: ICourier
  admin?: IAdmin
  variety?: IVariety
  vendor?: IVendor
  box?: IBox
  ticketResponse?: ITicketResponse
  walletTransaction?: IWalletTransaction
  paymentOrder?: IOrder
  receiptOrder?: IOrder
  ticket?: ITicket
  transaction?: ITransaction
}

export class PhotoUse {
  public props: IPhotoUse = {}

  constructor(data?: IPhotoUse) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getIsAvatar(): boolean {
    return !!this.props.isAvatar
  }

  getOrderNumber(): number {
    return this.props.orderNumber || 0
  }

  getPhotoId(): string {
    return this.props.photoId || ''
  }

  getPaymentOrderId(): string {
    return this.props.paymentOrderId || ''
  }

  getReceiptOrderId(): string {
    return this.props.receiptOrderId || ''
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getBoxId(): string {
    return this.props.boxId || ''
  }

  getVendorId(): string {
    return this.props.vendorId || ''
  }

  getVarietyId(): string {
    return this.props.varietyId || ''
  }

  getTicketId(): string {
    return this.props.ticketId || ''
  }

  getTicketResponseId(): string {
    return this.props.ticketResponseId || ''
  }

  getTransactionId(): string {
    return this.props.transactionId || ''
  }

  getCourierId(): string {
    return this.props.courierId || ''
  }

  getPhoto(): Photo {
    return new Photo(this.props.photo || {})
  }

  getProduct(): Product {
    return new Product(this.props.product || {})
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getCourier(): Courier {
    return new Courier(this.props.courier || {})
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getVariety(): Variety {
    return new Variety(this.props.variety || {})
  }

  getVendor(): Vendor {
    return new Vendor(this.props.vendor || {})
  }

  getBox(): Box {
    return new Box(this.props.box || {})
  }

  getTicketResponse(): TicketResponse {
    return new TicketResponse(this.props.ticketResponse || {})
  }

  getWalletTransaction(): WalletTransaction {
    return new WalletTransaction(this.props.walletTransaction || {})
  }

  getPaymentOrder(): Order {
    return new Order(this.props.paymentOrder || {})
  }

  getReceiptOrder(): Order {
    return new Order(this.props.receiptOrder || {})
  }

  getTicket(): Ticket {
    return new Ticket(this.props.ticket || {})
  }

  getTransaction(): Transaction {
    return new Transaction(this.props.transaction || {})
  }

  getStatus(): PhotoUseStatusEnum {
    return this.props.status || PhotoUseStatusEnum.rejected
  }
}

export const displayPhotoUseStatusEnum = (photoUseStatusEnum: PhotoUseStatusEnum): string => {
  switch (photoUseStatusEnum) {
    case PhotoUseStatusEnum.rejected:
      return 'رد شده'
    case PhotoUseStatusEnum.accepted:
      return 'تایید شده'
    case PhotoUseStatusEnum.pending:
      return 'در انتظار تایید'
    default:
      return ''
  }
}
