import {IDto, ITransaction} from 'models'

import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'
import apiConfig from 'services/config'

const {rootAddress} = apiConfig

const axios = new ClientApi()

const prefix = '/dashboard/transactions'

export const getTransactions = async (query: StringifiableRecord): Promise<IDto<ITransaction>> =>
  await axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix, query}))

export const getTransactionDetails = async (transactionId: string): Promise<ITransaction> =>
  await axios.http.get(rootAddress + prefix + '/' + transactionId)

export const acceptTransaction = async (transactionId: string) =>
  await axios.http.patch(rootAddress + prefix + '/' + transactionId + '/accept')

export const rejectTransaction = async (transactionId: string) =>
  await axios.http.patch(rootAddress + prefix + '/' + transactionId + '/reject')
