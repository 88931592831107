import {IsArray, IsEnum, IsOptional, IsString, Length} from 'class-validator'
import {AdminRolesEnum} from 'enums'

export class InviteNewAdminRequestDto {
  @IsString({message: 'شماره موبایل الزامی است'})
  @Length(11, 11, {message: 'شماره موبایل باید ۱۱ رقم باشد'})
  mobileNumber: string

  @IsString()
  @IsOptional()
  firstName?: string

  @IsString()
  @IsOptional()
  lastName?: string

  @IsString({message: 'رمز عبور موقت الزامی است'})
  temporaryPassword: string

  @IsArray({message: 'نقش الزامی است'})
  @IsEnum(AdminRolesEnum, {each: true})
  roles: AdminRolesEnum[]

  constructor() {
    this.firstName = ''
    this.lastName = ''
    this.mobileNumber = ''
    this.temporaryPassword = ''
    this.roles = [] as AdminRolesEnum[]
  }
}
