import {AdminRolesEnum, AdminStatusEnum} from 'enums'
import {IUser, User} from './User'

export interface IAdmin {
  id?: string
  createdAt?: string
  updatedAt?: string
  twoFactorAuthenticationHash?: string
  isEnableTwoFactorAuthentication?: boolean
  lastLoginDate?: string
  status?: AdminStatusEnum
  roles?: AdminRolesEnum[]
  firstName?: string
  lastName?: string
  user?: IUser
}

export class Admin {
  public props: IAdmin = {}

  constructor(data?: IAdmin) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getTwoFactorAuthenticationHash(): string {
    return this.props.twoFactorAuthenticationHash || ''
  }

  getIsEnableTwoFactorAuthentication(): boolean {
    return this.props.isEnableTwoFactorAuthentication || false
  }

  getLastLoginDate(): Date | undefined {
    return this.props.lastLoginDate ? new Date(this.props.lastLoginDate) : undefined
  }

  getStatus(): AdminStatusEnum {
    return this.props.status || AdminStatusEnum.deactivated
  }

  getRoles(): AdminRolesEnum[] {
    return this.props.roles || []
  }

  getFirstName(): string {
    return this.props.firstName || ''
  }

  getLastName(): string {
    return this.props.lastName || ''
  }

  getUser(): User {
    return new User(this.props.user || {})
  }

  getFullName = (): string => {
    return this.getFirstName() ? `${this.getFirstName()} ${this.getLastName()}` : ''
  }

  getDisplayStatus(): string {
    return displayAdminStatusEnum(this.getStatus())
  }

  getDisplayAccessLevel(): string {
    return this.getRoles()
      .map((role) => displayAdminRolesEnum(role))
      .join(', ')
  }
}

export const displayAdminStatusEnum = (adminStatusEnum: AdminStatusEnum): string => {
  switch (adminStatusEnum) {
    case AdminStatusEnum.activated:
      return 'فعال'
    case AdminStatusEnum.deactivated:
      return 'غیر فعال'
    case AdminStatusEnum.pending:
      return 'درحال انتظار'
    case AdminStatusEnum.invited:
      return 'دعوت شده'
    default:
      return '-'
  }
}

export const displayAdminRolesEnum = (adminAccessLevelEnum?: AdminRolesEnum): string => {
  switch (adminAccessLevelEnum) {
    case AdminRolesEnum.admin:
      return 'ادمین کل'
    case AdminRolesEnum.customer_management:
      return 'پشتیبانی و کاربران'
    case AdminRolesEnum.delivery:
      return 'مرسولات'
    case AdminRolesEnum.inventory:
      return 'انبار'
    case AdminRolesEnum.packaging:
      return 'دسته بندی'
    case AdminRolesEnum.procurement:
      return 'تدارکات'
    case AdminRolesEnum.sorting:
      return 'بسته‌بندی'
    default:
      return '-'
  }
}
