import {IsPhoneNumber, IsString, Length} from 'class-validator'

export class AdminSentVerificationCodeRequestDto {
  @IsString({message: 'شماره موبایل الزامی است'})
  @IsPhoneNumber('IR', {message: 'شماره موبایل وارد شده صحیح نمی‌باشد.'})
  @Length(11, 11, {message: 'شماره موبایل باید ۱۱ رقم باشد'})
  mobileNumber: string

  constructor() {
    this.mobileNumber = ''
  }
}
