import {
  Address,
  Customer,
  CustomerPacking,
  Delivery,
  DeliveryChain,
  IAddress,
  ICustomer,
  ICustomerPacking,
  IDelivery,
  IDeliveryChain,
} from 'models'

export interface IDeliveryChainCustomer {
  id?: string
  createdAt?: string
  updatedAt?: string
  priority?: number
  customerId?: string
  deliveryChainId?: string
  addressId?: string
  address?: IAddress
  customer?: ICustomer
  deliveryChain?: IDeliveryChain
  packingDelivery?: ICustomerPacking
  packingDeliveryId?: string
  deliveryId?: string
  delivery?: IDelivery
}

export class DeliveryChainCustomer {
  public props: IDeliveryChainCustomer = {}

  constructor(data?: IDeliveryChainCustomer) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getPackingDelivery(): CustomerPacking {
    return new CustomerPacking(this.props.packingDelivery)
  }

  getPackingDeliveryId(): string {
    return this.props.packingDeliveryId || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getAddressId(): string {
    return this.props.addressId || ''
  }

  getAddress(): Address {
    return new Address(this.props.address || {})
  }

  getDeliveryId(): string {
    return this.props.deliveryId || ''
  }

  getDelivery(): Delivery {
    return new Delivery(this.props.delivery || {})
  }

  getPriority(): number {
    return this.props.priority || -1
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getDeliveryChainId(): string {
    return this.props.customerId || ''
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getDeliveryChain(): DeliveryChain {
    return new DeliveryChain(this.props.deliveryChain || {})
  }
}
