import {Employee, IEmployee} from './Employee'
import {IScale, Scale} from './Scale'

export interface IPackingLine {
  adminId?: string
  id?: string
  createdAt?: string
  deletedAt?: string
  updatedAt?: string
  employeeId?: string
  lineNumber?: number
  name?: string
  scaleId?: string
  scale?: IScale
  employee?: IEmployee
  status?: string
}

export class PackingLine {
  public props: IPackingLine = {}

  constructor(data?: IPackingLine) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getStatus(): string {
    return this.props.status || ''
  }
  getScaleId(): string {
    return this.props.scaleId || ''
  }

  getScale(): Scale {
    return this.props.scale ? new Scale(this.props.scale) : new Scale()
  }

  getEmployee(): Employee {
    return this.props.employee ? new Employee(this.props.employee) : new Employee()
  }
  getDeletedAt(): string {
    return this.props.deletedAt || ''
  }

  getLineNumber(): number | undefined {
    return this.props.lineNumber
  }

  getName(): string {
    return this.props.name || ''
  }
}
