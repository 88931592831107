import {useState} from 'react'
import {useSnackbar} from 'notistack'
import {useMutation, useQuery} from 'react-query'
import {StringifiableRecord} from 'query-string'
import {useSearchParams} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {classValidatorResolver} from '@hookform/resolvers/class-validator'
//services
import services, {ServerError} from 'services'
//utils
import {removeEmptyValues} from 'utils/basic/object'
//models
import {Delivery, DeliveryChain, Dto} from 'models'
// enums
import {DeliveryPlanDayOfWeekEnum, DeliveryPlanShiftEnum} from 'enums'
// dtos
import {AddDeliveryToDeliveryChainRequestDto} from 'dtos'
//hooks
import useToolbarRefetch from 'hooks/useToolbarRefetch'
import useText from 'hooks/useText'
//locals
import {Props} from '.'
import {texts} from './texts'

interface IUseData {
  show: boolean
  handleClose: Props['handleClose']
  shift?: DeliveryPlanShiftEnum
}

interface IQuery {
  delivery_chain_id?: string
  region_id?: string
  delivery_plan_id?: string
  date?: string
  day_of_week?: DeliveryPlanDayOfWeekEnum
  shift?: DeliveryPlanShiftEnum
}

const useData = ({show, handleClose}: IUseData) => {
  const {TX} = useText()
  const {enqueueSnackbar} = useSnackbar()
  const {setIsRefetchOn} = useToolbarRefetch()
  const [deliveries, setDeliveries] = useState<Delivery[]>([])
  const [searchParams] = useSearchParams()
  const [deliveryChain, setDeliveryChain] = useState<DeliveryChain>(new DeliveryChain())
  const [deliverySearch, setDeliverySearch] = useState<string>('')

  const convertSearchParamsToQuery = (searchParams: URLSearchParams): IQuery => {
    return removeEmptyValues({
      delivery_chain_id: searchParams.get('delivery_chain_id') || '',
      region_id: searchParams.get('region_id') || '',
      delivery_plan_id: searchParams.get('delivery_plan_id') || '',
      date: searchParams.get('date') || '',
      day_of_week: (searchParams.get('day_of_week') as DeliveryPlanDayOfWeekEnum) || undefined,
      shift: (searchParams.get('shift') as DeliveryPlanShiftEnum) || undefined,
    })
  }

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: {isValid, isDirty},
  } = useForm<AddDeliveryToDeliveryChainRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(AddDeliveryToDeliveryChainRequestDto),
    defaultValues: {
      deliveryId: '',
    },
  })

  /* ------------------------------------------------------------ */
  /*                           Services                           */
  /* ------------------------------------------------------------ */

  const handleAddCustomerToDeliveryChain = async () =>
    await services.deliveryChains.addCustomerToDeliveryChain(
      convertSearchParamsToQuery(searchParams).delivery_chain_id || '',
      {...watch()}
    )

  const handleGetDeliveries = (query: StringifiableRecord) => async () =>
    await services.deliveryChains.getDeliveries({page: 1, limit: 10, ...query})

  const handleGetDeliveryChain = async () =>
    await services.deliveryChains.getDeliveryChainDetails(
      convertSearchParamsToQuery(searchParams).delivery_chain_id || ''
    )

  /* ------------------------------------------------------------ */
  /*                           Queries                            */
  /* ------------------------------------------------------------ */

  const {isLoading: deliveryChainLoading, isFetching: deliveryChainFetching} = useQuery(
    ['get-delivery-chain', convertSearchParamsToQuery(searchParams).delivery_chain_id],
    handleGetDeliveryChain,
    {
      refetchOnWindowFocus: false,
      enabled:
        !!convertSearchParamsToQuery(searchParams).region_id &&
        !!convertSearchParamsToQuery(searchParams).date &&
        show,
      onSuccess(data) {
        const tempDeliveryChain = new DeliveryChain(data)
        setDeliveryChain(tempDeliveryChain)
      },
    }
  )

  const {isLoading: customersLoading, isFetching: customersFetching} = useQuery(
    ['get-deliveries-delivery-chains', deliverySearch],
    handleGetDeliveries(
      removeEmptyValues({
        search: deliverySearch,
        'filters[regionId]': convertSearchParamsToQuery(searchParams).region_id,
        'filters[deliveryDate]': new Date(
          convertSearchParamsToQuery(searchParams).date || 0
        ).toString(),
        'filters[shift]': convertSearchParamsToQuery(searchParams).shift,
      })
    ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled:
        !!convertSearchParamsToQuery(searchParams).region_id &&
        !!convertSearchParamsToQuery(searchParams).date &&
        show,
      onSuccess(data) {
        const dto = new Dto(data)
        const tempDeliveries = dto.getItems()?.map((item) => new Delivery(item))
        setDeliveries(tempDeliveries)
      },
    }
  )

  /* ------------------------------------------------------------ */
  /*                          Mutations                           */
  /* ------------------------------------------------------------ */

  const {isLoading, mutate} = useMutation(handleAddCustomerToDeliveryChain, {
    onSuccess: () => {
      handleClose()
      setIsRefetchOn(true)
      reset()
      enqueueSnackbar(TX(texts.create_customers_success), {
        variant: 'success',
      })
    },
    onError: (error) => {
      const {message} = error as ServerError
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
  })

  /* ------------------------------------------------------------ */
  /*                          Handlers                            */
  /* ------------------------------------------------------------ */

  const onSubmit = async () => {
    await mutate()
  }

  return {
    isLoading,
    onSubmit: handleSubmit(onSubmit),
    control,
    watch,
    reset,
    setValue,
    isValid,
    isDirty,
    customersLoading:
      deliveryChainLoading || deliveryChainFetching || customersLoading || customersFetching,
    deliveries,
    deliveryChain,
    deliverySearch,
    setDeliverySearch,
  }
}

export default useData
