import apiConfig from 'services/config'
import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'
// models
import {IDto, IQuote, IQuoteProductVendor, IQuoteProduct, IProduct, IVariety} from '../models'
// dtos
import {
  CreateReceiptForOrderRequestDto,
  CreateReplacementOfProductInQuoteRequestDto,
  CreateVendorQuoteForProductBasedOnDailyTargetRequestDto,
  CreateVendorQuoteForProductManuallyRequestDto,
  DeleteReceiptForOrderRequestDto,
  QuoteConfirmedByAdminRequestDto,
  SubmitQuoteRequestDto,
} from 'dtos'

const axios = new ClientApi()

const {rootAddress} = apiConfig

const prefix = '/dashboard/quote'

export const getQuotes = (query: StringifiableRecord): Promise<IDto<IQuote>> =>
  axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix, query}, {arrayFormat: 'comma'})
  )

export const getQuoteDetails = (quoteId: string): Promise<IQuote> =>
  axios.http.get(rootAddress + prefix + '/' + quoteId)

export const submitQuote = (
  quoteId: string,
  body: QuoteConfirmedByAdminRequestDto
): Promise<IQuote> => axios.http.patch(rootAddress + prefix + '/' + quoteId + '/confirmed', body)

export const defineNewVendorDailyTarget = (
  body: CreateVendorQuoteForProductBasedOnDailyTargetRequestDto
): Promise<IQuoteProductVendor> =>
  axios.http.post(rootAddress + prefix + '/vendor/daily-target', body)

export const confirmQuoteDailyTarget = (
  quoteId: string,
  body: SubmitQuoteRequestDto
): Promise<IQuote> => axios.http.patch(rootAddress + prefix + '/' + quoteId + '/daily-target', body)

export const defineNewVendorManually = (
  body: CreateVendorQuoteForProductManuallyRequestDto
): Promise<IQuoteProductVendor> => axios.http.post(rootAddress + prefix + '/vendor/manual', body)

export const confirmQuoteManually = (
  quoteId: string,
  body: SubmitQuoteRequestDto
): Promise<IQuote> => axios.http.patch(rootAddress + prefix + '/' + quoteId + '/manual', body)

export const replaceProduct = (
  body: CreateReplacementOfProductInQuoteRequestDto
): Promise<IQuoteProduct> => axios.http.post(rootAddress + prefix + '/product', body)

export const removeReplacementProduct = (quoteProductId: string) =>
  axios.http.delete(rootAddress + prefix + '/product/' + quoteProductId)

export const removeVendor = (quoteProductVendorId: string): Promise<IQuoteProductVendor> =>
  axios.http.delete(rootAddress + prefix + '/vendor/' + quoteProductVendorId)

export const removeQuote = (quoteId: string): Promise<IQuote> =>
  axios.http.delete(rootAddress + prefix + '/' + quoteId)

export const getReplaceableProducts = (query: StringifiableRecord): Promise<IDto<IProduct>> =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/products/replaceable', query},
      {arrayFormat: 'comma'}
    )
  )

export const getProductVarieties = (
  productId: string,
  query: StringifiableRecord
): Promise<IDto<IVariety>> =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/products/' + productId + '/varieties', query},
      {arrayFormat: 'comma'}
    )
  )

export const uploadReceipt = (
  quoteId: string,
  body: CreateReceiptForOrderRequestDto
): Promise<IQuote> =>
  axios.http.post(rootAddress + prefix + '/' + quoteId + '/upload-receipt', body)

export const deleteReceipt = (
  quoteId: string,
  body: DeleteReceiptForOrderRequestDto
): Promise<IQuote> =>
  axios.http.delete(rootAddress + prefix + '/' + quoteId + '/remove-receipt/' + body.photoId)

export const uploadPayment = (
  quoteId: string,
  body: {
    photoId: string
  }
): Promise<IQuote> =>
  axios.http.post(rootAddress + prefix + '/' + quoteId + '/upload-payment', body)

export const deletePayment = (
  quoteId: string,
  body: {
    photoId: string
  }
): Promise<IQuote> =>
  axios.http.delete(rootAddress + prefix + '/' + quoteId + '/remove-payment/' + body.photoId)

export const generateCSV = (quoteId: string): Promise<string> =>
  axios.http.get(rootAddress + prefix + '/' + quoteId + '/generate-csv')
