import apiConfig from 'services/config'
import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'
// models
import {
  IBoxPacking,
  IContentBox,
  ICustomerPacking,
  IDeliveryBox,
  IDeliveryBoxProductPacking,
  IDeliveryChain,
  IDeliveryChainCustomer,
  IDto,
  ILine,
  IPacking,
  ISortingProductBox,
} from 'models'
import {IPackingReport} from 'models/PackingReport'
// dtos
import {CreatePackingLineRequestDto, UpdatePackingLineRequestDto} from 'dtos'

const {rootAddress} = apiConfig
const axios = new ClientApi()

const prefix = '/dashboard/packing'

export const getPackingLines = async (query: StringifiableRecord): Promise<IDto<ILine>> =>
  await axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix + '/lines', query}))

export const deactivatePackingLines = async (packingLineId: string): Promise<ILine> =>
  await axios.http.patch(rootAddress + prefix + '/lines/' + packingLineId + '/deactivate')

export const activatePackingLines = async (packingLineId: string): Promise<ILine> =>
  await axios.http.patch(rootAddress + prefix + '/lines/' + packingLineId + '/activate')

export const getPackingLinesDetails = async (packingLineId: string): Promise<ILine> =>
  await axios.http.get(rootAddress + prefix + '/lines/' + packingLineId)

export const createNewPackingLine = async (body: CreatePackingLineRequestDto): Promise<ILine> =>
  await axios.http.post(rootAddress + prefix + '/lines', body)

export const getPackingLineHistories = async (
  packingLineId: string,
  query: StringifiableRecord
): Promise<IDto<ILine>> =>
  await axios.http.get(
    queryString.stringifyUrl({
      url: rootAddress + prefix + '/lines/' + packingLineId + '/histories',
      query,
    })
  )

export const updatePackingLines = async (
  packingLineId: string,
  body: UpdatePackingLineRequestDto
): Promise<ILine> => await axios.http.put(rootAddress + prefix + '/lines/' + packingLineId, body)

export const getDeliveryChains = async (
  query: StringifiableRecord
): Promise<IDto<IDeliveryChain>> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/delivery-chain', query},
      {arrayFormat: 'comma'}
    )
  )

export const getCustomerForDeliveryChians = async (
  deliveryChainId: string,
  query: StringifiableRecord
): Promise<IDeliveryChainCustomer[]> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {
        url: rootAddress + prefix + '/delivery-chain/' + deliveryChainId + '/customers',
        query,
      },
      {
        arrayFormat: 'comma',
      }
    )
  )

export const getCustomerDeliveryBoxes = async (
  deliveryChainCustomerId: string,
  query: StringifiableRecord
): Promise<IDeliveryBox[]> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {
        url: rootAddress + prefix + '/box/' + deliveryChainCustomerId + '/delivery-boxes',
        query,
      },
      {
        arrayFormat: 'comma',
      }
    )
  )

export const getDeliveryBoxContents = async (
  deliveryBoxId: string,
  query: StringifiableRecord
): Promise<IContentBox[]> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {
        url: rootAddress + prefix + '/box/' + deliveryBoxId + '/contents',
        query,
      },
      {
        arrayFormat: 'comma',
      }
    )
  )

// ===================== Start packing services =====================

export const startDeliveryChainPacking = async (
  deliveryChainId: string,
  packingLineId: string
): Promise<IPacking> =>
  await axios.http.post(rootAddress + prefix + '/' + deliveryChainId + '/packing/start', {
    packingLineId,
  })

export const finishDeliveryChainPacking = async (packingId: string, packingLineId: string) =>
  await axios.http.patch(rootAddress + prefix + '/' + packingId + '/packing', {
    status: 'finish',
    packingLineId,
  })

export const startDeliveryChainCustomerPacking = async (
  packingId: string,
  deliveryChainCustomerId: string
): Promise<ICustomerPacking> =>
  await axios.http.post(
    rootAddress +
      prefix +
      '/' +
      packingId +
      '/packing-delivery/' +
      deliveryChainCustomerId +
      '/start'
  )
export const finishDeliveryChianCustomerPacking = async (packingDeliveryId: string) =>
  await axios.http.patch(rootAddress + prefix + '/' + packingDeliveryId + '/packing-delivery', {
    status: 'finish',
  })

export const startDeliveryBoxPacking = async (
  packingDeliveryId: string,
  delieryBoxId: string
): Promise<IBoxPacking> =>
  await axios.http.post(
    rootAddress +
      prefix +
      '/' +
      packingDeliveryId +
      '/packing-delivery-box/' +
      delieryBoxId +
      '/start'
  )

export const finishDelieryBoxPacking = async (packingDeliveryBoxId: string) =>
  await axios.http.patch(
    rootAddress + prefix + '/' + packingDeliveryBoxId + '/packing-delivery-box',
    {
      status: 'finish',
    }
  )

export const finishDeliveryBoxProductPacking = async (
  packingDeliveryBoxProductId: string,
  sortingProductBoxId?: string
): Promise<IDeliveryBoxProductPacking> =>
  await axios.http.patch(
    rootAddress + prefix + '/' + packingDeliveryBoxProductId + '/packing-delivery-box-product',
    {
      status: 'finish',
      sortingProductBoxId,
    }
  )

export const addExtraDeliveryBox = async (deliveryBoxId: string) =>
  await axios.http.post(rootAddress + prefix + '/' + deliveryBoxId + '/add-extra-delivery-box')

export const getPackingReports = async (
  query: StringifiableRecord
): Promise<IDto<IPackingReport>> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {
        url: rootAddress + prefix + '/report',
        query,
      },
      {
        arrayFormat: 'comma',
      }
    )
  )

export const downloadLabel = async (
  body: {
    deliveryChainId: string
    fileName: string
  },
  query: StringifiableRecord
) =>
  await axios.http
    .get(
      queryString.stringifyUrl({
        url: rootAddress + prefix + '/' + body.deliveryChainId + '/labels-pdf',
        query,
      }),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      }
    )
    // @ts-ignore
    .then((response: BlobPart) => {
      const url = window.URL.createObjectURL(new Blob([response], {type: 'application/pdf'}))

      const link = document.createElement('a')
      link.href = url
      link.download = body.fileName
      // link.setAttribute('download', body.fileName + '.pdf')

      document.body.appendChild(link)

      link.click()
      link.parentNode!.removeChild(link)
    })

export const getSortingProductBoxes = async (
  query: StringifiableRecord
): Promise<ISortingProductBox[]> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/sorting/sorting-product-box', query},
      {
        arrayFormat: 'comma',
      }
    )
  )
