export enum DeliveryPlanDayOfWeekEnum {
  saturday = 'saturday',
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
}

export enum DeliveryPlanShiftEnum {
  morning = 'morning',
  after_noon = 'after_noon',
}

export enum DeliveryPlanStatusEnum {
  activate = 'activate',
  deactivate = 'deactivate',
}
