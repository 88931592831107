import {IsEnum, IsOptional, IsString, MaxLength, MinLength} from 'class-validator'
import {AdminRolesEnum, AdminStatusEnum} from 'enums'
import {Match} from 'utils/decorators'

export class UpdateAdminRequestDto {
  @IsString({message: 'نام الزامی است'})
  firstName: string

  @IsString({message: 'نام خانوادگی الزامی است'})
  lastName: string

  @IsEnum(AdminRolesEnum, {each: true, message: 'نقش الزامی است'})
  roles: AdminRolesEnum[]

  @IsEnum(AdminStatusEnum)
  @IsOptional()
  status?: AdminStatusEnum

  @IsOptional()
  @IsString({message: 'رمزعبور الزامی است'})
  @MinLength(4, {message: 'رمزعبور نباید کمتر از ۴ حرف باشد'})
  @MaxLength(20, {message: 'رمزعبور نباید بیشتر از ۲۰ حرف باشد'})
  password?: string

  @IsOptional()
  @IsString({message: 'تکرار رمزعبور الزامی است'})
  @MinLength(4, {message: 'تکرار رمزعبور نباید کمتر از ۴ حرف باشد'})
  @MaxLength(20, {message: 'تکرار رمزعبور نباید بیشتر از ۲۰ حرف باشد'})
  @Match('password', {message: 'رمز عبور یکسان نیست'})
  confirmPassword?: string

  constructor() {
    this.firstName = ''
    this.lastName = ''
    this.roles = [] as AdminRolesEnum[]
    this.status = '' as AdminStatusEnum
  }
}
