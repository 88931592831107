import {FC, useEffect} from 'react'
import {Navigate, useLocation, useNavigate} from 'react-router-dom'

// hooks
import useUser from 'hooks/useUser'

// constants
import {
  DASHBOARD_ROUTE,
  DELIVERY_ROUTE,
  LOGIN_ROUTE,
  PACKAGING_ROUTE,
  SETTINGS_ROUTE,
  SORTING_ROUTE,
  SUPPLIES_ROUTE,
  SUPPORT_ROUTE,
  SYSTEM_PREFERENCES_ROUTE,
  WAREHOUSE_ROUTE,
} from 'constants/routes'

// components
import {MasterLayout} from 'components/template/layout/MasterLayout'
import {AdminRolesEnum} from 'enums'

interface IProtectedRouteProps {}

const ProtectedRoute: FC<IProtectedRouteProps> = () => {
  const {isLogin, admin} = useUser()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (isLogin && !admin.getRoles().includes(AdminRolesEnum.admin)) {
      if (
        (location.pathname.includes(SUPPORT_ROUTE || SUPPORT_ROUTE + '/') &&
          !admin.getRoles().includes(AdminRolesEnum.customer_management)) ||
        (location.pathname.includes(DELIVERY_ROUTE || DELIVERY_ROUTE + '/') &&
          !admin.getRoles().includes(AdminRolesEnum.delivery)) ||
        (location.pathname.includes(WAREHOUSE_ROUTE || WAREHOUSE_ROUTE + '/') &&
          !admin.getRoles().includes(AdminRolesEnum.inventory)) ||
        (location.pathname.includes(PACKAGING_ROUTE || PACKAGING_ROUTE + '/') &&
          !admin.getRoles().includes(AdminRolesEnum.packaging)) ||
        (location.pathname.includes(SUPPLIES_ROUTE || SUPPLIES_ROUTE + '/') &&
          !admin.getRoles().includes(AdminRolesEnum.procurement)) ||
        (location.pathname.includes(SORTING_ROUTE || SORTING_ROUTE + '/') &&
          !admin.getRoles().includes(AdminRolesEnum.sorting)) ||
        location.pathname.includes(SETTINGS_ROUTE || SETTINGS_ROUTE + '/')
      ) {
        admin.getId() && navigate(DASHBOARD_ROUTE)
      }
    } else if (isLogin && !admin.getRoles().includes(AdminRolesEnum.admin)) {
      if (location.pathname.includes(SYSTEM_PREFERENCES_ROUTE || SYSTEM_PREFERENCES_ROUTE + '/')) {
        admin.getId() && navigate(DASHBOARD_ROUTE)
      }
    }
  }, [isLogin, admin, location, navigate])

  return isLogin ? <MasterLayout /> : <Navigate replace to={LOGIN_ROUTE} />
}

export default ProtectedRoute
