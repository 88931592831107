import {Customer} from 'models'
import {createContext} from 'react'

export default createContext<{
  selectedCustomer: Customer
  setSelectedCustomer: React.Dispatch<React.SetStateAction<Customer>>
}>({
  selectedCustomer: new Customer(),
  setSelectedCustomer: () => undefined,
})
