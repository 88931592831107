export enum AdminStatusEnum {
  activated = 'activated',
  deactivated = 'deactivated',
  invited = 'invited',
  pending = 'pending',
}

export enum AdminRolesEnum {
  admin = 'admin',
  customer_management = 'customer_management',
  delivery = 'delivery',
  inventory = 'inventory',
  packaging = 'packaging',
  procurement = 'procurement',
  sorting = 'sorting',
}
