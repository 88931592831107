import {useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useForm} from 'react-hook-form'
import {useSnackbar} from 'notistack'
import {classValidatorResolver} from '@hookform/resolvers/class-validator'
// modals
import {Ticket} from 'models/Ticket'
import {Customer, Dto} from 'models'
// services
import services, {ServerError} from 'services'
// enums
import {TicketCategoryEnum, TicketStatusEnum} from 'enums'
// dtos
import {CreateResponseToTicketRequestDto, CreateTicketRequestDto} from 'dtos'
// utils
import {removeEmptyValues} from 'utils/basic/object'
// hooks
import useText from 'hooks/useText'
import useToolbarRefetch from 'hooks/useToolbarRefetch'
// local
import {texts} from './texts'

export enum SubmitTicketStepEnum {
  first = 'first',
  pend = 'pend',
  answering = 'answering',
  referring = 'referring',
  onlyTicketing = 'only_ticketing',
  viewOnly = 'view_only',
}

const useData = (
  show: boolean,
  handleClose: () => void,
  ticketId?: string,
  atStep?: SubmitTicketStepEnum,
  customerId?: string
) => {
  const {TX} = useText()
  const {enqueueSnackbar} = useSnackbar()
  const {setIsRefetchOn} = useToolbarRefetch()
  const [photos, setPhotos] = useState<{photoId: string; isFull: boolean}[]>(
    Array(1).fill({photoId: '', isFull: false})
  )
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [isSubmittedRefer, setIsSubmittedRefer] = useState<boolean>(false)
  const [ticket, setTicket] = useState(new Ticket())
  const [step, setStep] = useState<SubmitTicketStepEnum>(SubmitTicketStepEnum.first)
  const [customers, setCustomers] = useState<Customer[]>([])
  const [customerSearch, setCustomersSearch] = useState<string>('')

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: {isSubmitting, isValid, dirtyFields},
  } = useForm<CreateTicketRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(CreateTicketRequestDto),
    defaultValues: {
      customerId: '',
      description: '',
      category: '' as TicketCategoryEnum,
    },
  })

  const {
    handleSubmit: handleSubmitCreateResponseToTicket,
    control: controlCreateResponseToTicket,
    watch: watchCreateResponseToTicket,
    reset: resetCreateResponseToTicket,
    setValue: setValueCreateResponseToTicket,
    formState: {
      isSubmitting: isSubmittingCreateResponseToTicket,
      isValid: isValidCreateResponseToTicket,
      dirtyFields: dirtyFieldsCreateResponseToTicket,
    },
  } = useForm<CreateResponseToTicketRequestDto>({
    mode: 'onSubmit',
    resolver: classValidatorResolver(CreateResponseToTicketRequestDto),
    defaultValues: {
      body: '',
      isFinal: false,
      assigned: [],
    },
  })

  /* ------------------------------------------------------------ */
  /*                           Services                           */
  /* ------------------------------------------------------------ */

  const handleGetCustomers = () => async () =>
    await services.customers.getCustomers({
      page: 1,
      limit: 10,
      search: customerSearch,
    })

  const handleGetTicketDetails = () => async () =>
    await services.tickets.getTicketDetails(ticketId!)

  const handleCreateTicket = async (body: CreateTicketRequestDto) =>
    await services.tickets.createTicket(body)

  const handleCreateResponse = async (body: CreateResponseToTicketRequestDto) =>
    await services.tickets.createResponse(ticketId!, body)

  /* ------------------------------------------------------------ */
  /*                            Queries                           */
  /* ------------------------------------------------------------ */

  const {isLoading, isFetching} = useQuery(
    ['get-ticket-details', ticketId],
    handleGetTicketDetails(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!ticketId && show,
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
      onSuccess: (data) => {
        const tempTicket = new Ticket(data)
        setTicket(tempTicket)
        tempTicket.getTicketResponses().length > 0 && setStep(SubmitTicketStepEnum.pend)
        tempTicket.getStatus() === TicketStatusEnum.closed && setStep(SubmitTicketStepEnum.viewOnly)
      },
    }
  )

  const {isLoading: customersLoading, isFetching: customersFetching} = useQuery(
    ['get-customers', ticketId],
    handleGetCustomers(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !customerId && show,
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
      onSuccess: (data) => {
        const dto = new Dto(data)
        const tempCustomers = dto.getItems().map((item) => new Customer(item))
        setCustomers(tempCustomers)
      },
    }
  )

  /* ------------------------------------------------------------ */
  /*                           Mutations                          */
  /* ------------------------------------------------------------ */

  const {isLoading: createTicketLoading, mutate} = useMutation(
    ['create-ticket'],
    handleCreateTicket,
    {
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
      onSuccess: (data) => {
        enqueueSnackbar(TX(texts.create_ticket_success), {
          variant: 'success',
        })
        setIsRefetchOn(true)
        handleClose()
      },
    }
  )

  const {isLoading: createReferLoading, mutate: refer} = useMutation(
    ['create-response-ticket'],
    handleCreateResponse,
    {
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
      onSuccess: (data) => {
        enqueueSnackbar(TX(texts.create_response_ticket_success), {
          variant: 'success',
        })
        setIsRefetchOn(true)
        handleClose()
      },
    }
  )

  /* ------------------------------------------------------------ */
  /*                           Handlers                           */
  /* ------------------------------------------------------------ */

  const onSubmit = async () => {
    await setIsSubmitted(true)
  }

  const onSubmitRefer = async () => {
    await setIsSubmittedRefer(true)
  }

  const onSubmitFinish = async () => {
    setIsSubmitted(false)
    await mutate(
      removeEmptyValues({
        description: watch().description,
        category: watch().category as TicketCategoryEnum,
        customerId: customerId || watch().customerId,
        photoIds: !!photos[0].photoId ? [photos[0].photoId] : undefined,
      })
    )
  }

  const onSubmitFinishRefer = async () => {
    setIsSubmittedRefer(false)
    await refer(
      removeEmptyValues({
        ticketId: ticket.getId(),
        body: watchCreateResponseToTicket().body,
        isFinal: watchCreateResponseToTicket().isFinal,
        isReference: !!watchCreateResponseToTicket().assigned?.length,
        assigned:
          watchCreateResponseToTicket().assigned?.length! > 0
            ? watchCreateResponseToTicket().assigned
            : undefined,
        photoIds: !!photos[0].photoId ? [photos[0].photoId] : undefined,
      })
    )
  }

  /* ------------------------------------------------------------ */
  /*                          LifeCycle                           */
  /* ------------------------------------------------------------ */

  useEffect(() => {
    if (show && !!atStep) {
      setStep(atStep)
    } else if (show) {
      setStep(SubmitTicketStepEnum.first)
    }
  }, [atStep, show])

  useEffect(() => {
    if (show) {
      reset()
      resetCreateResponseToTicket()
      setPhotos([{photoId: '', isFull: false}])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return {
    TX,
    loading: isLoading || isFetching,
    ticket,
    step,
    setStep,
    SubmitTicketStepEnum,
    isSubmitted,
    photos,
    setPhotos,
    createTicketLoading,
    mutate,
    customersLoading: customersLoading || customersFetching,
    customers,
    createReferLoading,
    refer,
    isSubmittedRefer,
    setIsSubmittedRefer,
    onSubmitFinish,
    onSubmit: handleSubmit(onSubmit),
    control,
    watch,
    setValue,
    isSubmitting,
    isValid,
    dirtyFields,
    onSubmitFinishRefer,
    onSubmitRefer: handleSubmitCreateResponseToTicket(onSubmitRefer),
    controlCreateResponseToTicket,
    watchCreateResponseToTicket,
    setValueCreateResponseToTicket,
    isSubmittingCreateResponseToTicket,
    isValidCreateResponseToTicket,
    dirtyFieldsCreateResponseToTicket,
    customerSearch,
    setCustomersSearch,
  }
}

export {useData}
