import queryString, {StringifiableRecord} from 'query-string'
import apiConfig from 'services/config'
import ClientApi from './clientApi'
// models
import {IDelivery, IDto, ISubscription} from 'models'
// enums
import {DeliveryStatusEnum} from 'enums'
// dtos
import {
  CreateCustomSubscriptionRequestDto,
  CreateSubscriptionRequestDto,
  CreateTransactionToSubscriptionRequestDto,
  RefundSubscriptionRequestDto,
  UpdateDeliveryRequestDto,
} from 'dtos'

const {rootAddress} = apiConfig
const axios = new ClientApi()

const prefix = '/dashboard/subscriptions'

export const getDeliveries = async (query: StringifiableRecord): Promise<IDto<IDelivery>> =>
  await axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix + '/deliveries', query}))

export const getSubscriptions = async (query: StringifiableRecord): Promise<IDto<ISubscription>> =>
  await axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix, query}))

export const getAllSubscriptions = async (query: StringifiableRecord): Promise<ISubscription[]> =>
  await axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix, query}))

export const createSubscription = async (body: CreateSubscriptionRequestDto) =>
  await axios.http.post(rootAddress + prefix, body)

export const createCustomSubscription = async (body: CreateCustomSubscriptionRequestDto) =>
  await axios.http.post(rootAddress + prefix + '/custom', body)

export const createSubscriptionPayment = async (
  subscriptionId: string,
  body: CreateTransactionToSubscriptionRequestDto
) => await axios.http.post(rootAddress + prefix + `/${subscriptionId}/payment`, body)

export const updateDelivery = async (deliveryId: string, body: UpdateDeliveryRequestDto) =>
  await axios.http.put(rootAddress + prefix + `/${deliveryId}`, body)

export const updateDeliveryStatus = async (
  deliveryId: string,
  body: {
    status: DeliveryStatusEnum
  }
) => await axios.http.put(rootAddress + prefix + `/${deliveryId}`, body)

export const downloadInvoice = async (body: {deliveryId: string; fileName: string}) =>
  await axios.http
    .get(rootAddress + prefix + '/' + body.deliveryId + '/pdf', {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    })
    // @ts-ignore
    .then((response: BlobPart) => {
      const url = window.URL.createObjectURL(new Blob([response], {type: 'application/pdf'}))

      const link = document.createElement('a')
      link.href = url
      link.download = body.fileName
      // link.setAttribute('download', body.fileName + '.pdf')

      document.body.appendChild(link)

      link.click()
      link.parentNode!.removeChild(link)
    })

export const generateCSV = async (query: StringifiableRecord): Promise<string> =>
  await axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix + '/generate-csv', query})
  )

export const refundSubscription = async (
  subscriptionId: string,
  body: RefundSubscriptionRequestDto
) => await axios.http.post(rootAddress + prefix + '/' + subscriptionId + '/refund', body)
