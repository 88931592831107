import {Type} from 'class-transformer'
import {IsNumber, IsPositive, IsString} from 'class-validator'

export class CreateOrderLineManualWeighingRequestDto {
  @IsNumber()
  @Type(() => Number)
  @IsPositive({message: 'وزن باید یک عدد مثبت باشد'})
  weight: number

  @IsString({message: 'ترازو الزامی است'})
  scaleId: string

  constructor() {
    this.weight = 0
    this.scaleId = ''
  }
}
