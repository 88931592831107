import {DeliveryBoxProductPacking, IDeliveryBoxProductPacking} from './DeliveryBoxProductPacking'
import {IProduct, Product} from './Product'
import {IProductInstance} from './ProductInstance'

export interface IContentBox {
  id?: string
  createdAt?: string
  updateAt?: string
  deletedAt?: string
  weight?: number
  quantity?: number
  refPackagingTargetId?: string
  packagingTargetId?: string
  productId?: string
  productInstanceId?: string
  alternativeProductId?: string
  product?: IProduct
  productInstance?: IProductInstance
  packingTargetId?: string
  packingDeliveryBoxProducts?: IDeliveryBoxProductPacking[]
  refPackingTargetId?: string
}

export class ContentBox {
  public props: IContentBox = {}

  constructor(data?: IContentBox) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updateAt ? new Date(this.props.updateAt) : undefined
  }

  getDeletedAt(): Date | undefined {
    return this.props.deletedAt ? new Date(this.props.deletedAt) : undefined
  }

  getWeight(): number {
    return this.props.weight || 0
  }

  getQuantity(): number {
    return this.props.quantity || 0
  }

  getRefPackagingTargetId(): string | undefined {
    return this.props.refPackagingTargetId || undefined
  }

  getPackagingTargetId(): string | undefined {
    return this.props.packagingTargetId || undefined
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getProduct(): Product {
    return new Product(this.props.product || {})
  }

  getProductInstance(): ContentBox {
    return new ContentBox(this.props.productInstance)
  }

  getProductInstanceId(): string | undefined {
    return this.props.productInstanceId || undefined
  }

  getAlternativeProductId(): string | undefined {
    return this.props.alternativeProductId || undefined
  }

  getPackingDeliveryBoxProducts(): DeliveryBoxProductPacking[] {
    return (this.props.packingDeliveryBoxProducts || []).map(
      (product) => new DeliveryBoxProductPacking(product)
    )
  }

  getPackingTargetId(): string {
    return this.props.packagingTargetId || ''
  }

  getRefpackingTargetId(): string {
    return this.props.refPackingTargetId || ''
  }
}
