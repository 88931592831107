import {CouponStatusEnum, CouponTypeEnum} from 'enums'
import {Admin, IAdmin} from './Admin'

export interface ICoupon {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date | null
  amountInTooman?: number
  code?: string
  description?: string
  usageLimit?: number
  usageCount?: number
  forRegistration?: boolean
  forSpecificUsers?: boolean
  expiryDate?: Date
  status?: CouponStatusEnum
  type?: CouponTypeEnum
  adminId?: string
  admin?: IAdmin
}

export class Coupon {
  public props: ICoupon = {}

  constructor(data?: ICoupon) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDeletedAt(): Date | null {
    return this.props.deletedAt ? new Date(this.props.deletedAt) : null
  }

  getAmountInTooman(): number {
    return this.props.amountInTooman || 0
  }

  getCode(): string {
    return this.props.code || ''
  }

  getDescription(): string {
    return this.props.description || ''
  }

  getUsageLimit(): number {
    return this.props.usageLimit || 0
  }

  getUsageCount(): number {
    return this.props.usageCount || 0
  }

  getForRegistration(): boolean {
    return this.props.forRegistration || false
  }

  getForSpecificUsers(): boolean {
    return this.props.forSpecificUsers || false
  }

  getExpiryDate(): Date | undefined {
    return this.props.expiryDate ? new Date(this.props.expiryDate) : undefined
  }

  getStatus(): CouponStatusEnum {
    return this.props.status || CouponStatusEnum.activated
  }

  getType(): CouponTypeEnum {
    return this.props.type || CouponTypeEnum.wallet
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  /* --------------------------------- Custom --------------------------------- */

  getDisplayStatus(): string {
    return displayCouponStatusEnum(this.getStatus())
  }

  getDisplayType(): string {
    return displayCouponTypeEnum(this.getType())
  }

  getCouponCountRemaining(): number {
    return this.getUsageLimit() - this.getUsageCount()
  }
}

export const displayCouponStatusEnum = (couponStatusEnum?: CouponStatusEnum): string => {
  switch (couponStatusEnum) {
    case CouponStatusEnum.activated:
      return 'فعال'
    case CouponStatusEnum.deactivated:
      return 'غیرفعال'
    default:
      return ''
  }
}

export const displayCouponTypeEnum = (couponTypeEnum?: CouponTypeEnum): string => {
  switch (couponTypeEnum) {
    case CouponTypeEnum.wallet:
      return 'اعتباری'
    case CouponTypeEnum.discount:
      return 'تخفیفی'
    default:
      return ''
  }
}
