import {FC} from 'react'
import {SnackbarProvider} from 'notistack'
import Slide from '@mui/material/Slide'
import SuccessOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoOutlinedIcon from '@mui/icons-material/Info'

import './style.css'

interface IMessageProviderProps {
  children?: JSX.Element
}

const MessageProvider: FC<IMessageProviderProps> = ({children}) => {
  return (
    <SnackbarProvider
      style={{fontFamily: 'Vazirmatn', fontSize: '14px'}}
      iconVariant={{
        success: <SuccessOutlinedIcon />,
        info: <InfoOutlinedIcon />,
        error: <ErrorOutlineIcon />,
        warning: <ReportProblemOutlinedIcon />,
      }}
      maxSnack={5}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      TransitionComponent={Slide as React.ComponentType}
    >
      {children}
    </SnackbarProvider>
  )
}

export default MessageProvider
