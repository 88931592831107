import {IProduct, Product, IQuote, Quote, IBox, Box} from 'models'

export interface IQuoteBox {
  id?: string
  createdAt?: string
  updatedAt?: string
  weight?: number
  price?: number
  quoteId?: string
  boxId?: string
  productId?: string
  quote?: IQuote
  box?: IBox
  product?: IProduct
}

export class QuoteBox {
  public props: IQuoteBox = {}

  constructor(data?: IQuoteBox) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getWeight(): number {
    return this.props.weight || 0
  }

  getPrice(): number {
    return this.props.price || 0
  }

  getQuoteId(): string {
    return this.props.quoteId || ''
  }

  getBoxId(): string {
    return this.props.boxId || ''
  }

  getProductId(): string {
    return this.props.productId || ''
  }

  getQuote(): Quote {
    return new Quote(this.props.quote || {})
  }

  getBox(): Box {
    return new Box(this.props.box || {})
  }

  getProduct(): Product {
    return new Product(this.props.product || {})
  }
}
