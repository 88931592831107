export enum GetDeliveriesReportRequestDtoSortEnum {
  serviceNumber = 'deliveryChain.serviceNumber',
}

export enum DeliveryStatusEnum {
  processing = 'processing',
  success = 'success',
  cancel = 'cancel',
  customer_absence = 'customer_absence',
}

export enum DeliveryStateEnum {
  // processing
  pending = 'pending',
  ready = 'ready',
  out_for_delivery = 'out_for_delivery',
  // cancel
  cancelled = 'cancelled',
  // customer absence
  failed_delivery = 'failed_delivery',
  // success
  delivered = 'delivered',
}

export enum DeliveryTypeEnum {
  for_self = 'for_self',
  for_others = 'for_others',
}

export enum DeliveriesDateSortEnum {
  subscriptionCreatedAt = 'subscription.createdAt',
  deliveries_date = 'deliveries.date',
}
