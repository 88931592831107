import {Box} from '@mui/material'
import {FC} from 'react'
// Components
import {Typography} from 'components/elements/Typography'
// Utils
import {toPersianDigit} from 'utils/basic/digit'
// hooks
import useText from 'hooks/useText'
// Locals
import useStyles from './useStyles'
import {texts} from '../../texts'

type Props = {
  allItems?: number
  packedItems?: number
  inProgressItems?: number
  name?: string
}

const Results: FC<Props> = ({allItems = 0, packedItems = 0, inProgressItems = 0, name = ''}) => {
  const {TX} = useText()
  const {classes} = useStyles()
  return (
    <Box className={classes.wrapper}>
      <Typography type='span' className={classes.text}>
        {TX(texts.results, [name])}: {toPersianDigit(allItems)}
      </Typography>
      <Typography type='span' className={classes.text}>
        {TX(texts.packing_up)}: {toPersianDigit(inProgressItems)}
      </Typography>
      <Typography type='span' className={classes.text}>
        {TX(texts.packaged)}: {toPersianDigit(packedItems)}
      </Typography>
    </Box>
  )
}

export default Results
