import {IsArray, IsNotEmpty, IsNumber} from 'class-validator'

export class UpdateRegionDeliveryPlanRequestDto {
  @IsArray({message: 'پیک الزامی است'})
  courierIds: string[]

  @IsNumber()
  @IsNotEmpty({message: 'ظرفیت الزامی است'})
  courierCapacity: number

  constructor() {
    this.courierIds = []
    this.courierCapacity = 0
  }
}
