//models
import {
  IVehicle,
  Photo,
  IPhotoUse,
  PhotoUse,
  IUser,
  User,
  Vehicle,
  CourierWeeklyPlan,
  ICourierWeeklyPlan,
} from 'models'

export enum CourierStatusEnum {
  activated = 'activated',
  deactivated = 'deactivated',
}

export interface ICourier {
  firstName?: string
  lastName?: string
  birthDate?: Date
  serviceCount?: number
  isLoggedIn?: boolean
  status?: CourierStatusEnum
  // workingStartDate?: Date
  // workingEndDate?: Date
  id?: string
  user?: IUser
  photoUses?: IPhotoUse[]
  vehicles?: IVehicle[]
  courierWeeklyPlans?: ICourierWeeklyPlan[]
  createdAt?: Date
  updatedAt?: Date
  email?: string
}

export class Courier {
  public props: ICourier = {}

  constructor(data?: ICourier) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }
  getFirstName(): string {
    return this.props.firstName || ''
  }
  getLastName(): string {
    return this.props.lastName || ''
  }
  getEmail(): string {
    return this.props.email || ''
  }
  getBirthDate(): Date | null {
    return this.props.birthDate || null
  }
  getServiceCount(): number {
    return this.props.serviceCount || 0
  }
  getIsLoggedIn(): boolean {
    return this.props.isLoggedIn || false
  }
  getStatus(): CourierStatusEnum {
    return this.props.status as CourierStatusEnum
  }
  getCreatedAt(): Date {
    return this.props.createdAt || new Date()
  }
  getUpdatedAt(): Date {
    return this.props.updatedAt || new Date()
  }
  // getWorkingStartDate(): Date | null {
  //   return this.props.workingStartDate || null
  // }
  // getWorkingEndDate(): Date | null {
  //   return this.props.workingEndDate || null
  // }
  getCourierWeeklyPlans(): CourierWeeklyPlan[] {
    return this.props.courierWeeklyPlans?.map((item) => new CourierWeeklyPlan(item)) || []
  }

  getUser(): User {
    return new User(this.props.user || undefined)
  }
  getVehicles(): Vehicle[] {
    return this.props.vehicles?.map((vehicle) => new Vehicle(vehicle || undefined)) || []
  }

  getPhotoUses(): PhotoUse[] {
    return (this.props.photoUses || [])
      .map((photoUse) => new PhotoUse(photoUse))
      .sort((a, b) => a.getOrderNumber() - b.getOrderNumber())
  }

  getMainImage(): Photo {
    if (this.props.photoUses && this.props.photoUses.length > 0) {
      return new PhotoUse(this.props.photoUses[0]).getPhoto()
    }
    return new Photo()
  }

  /* -------------------------------------------------------------------------- */
  /*                             Customs                                       */
  /* -------------------------------------------------------------------------- */
  getFullName = (): string => {
    return this.getFirstName() ? `${this.getFirstName()} ${this.getLastName()}` : ''
  }
  getWeeklyPlanIds(): string[] {
    return this.getCourierWeeklyPlans().map((item) => item.getId())
  }
  getStatusDisplay(): string {
    return this.props.status === CourierStatusEnum.activated ? 'فعال' : 'غیرفعال'
  }
}
