import {Controller} from 'react-hook-form'
// components
import {Modal} from 'components/elements/Modal'
import {MessageCard} from 'components/cards/MessageCard'
import {Textarea} from 'components/elements/Textarea'
import {Button} from 'components/elements/Button'
import UploadImagesChunk from 'components/elements/UploadImagesChunk'
import {Checkbox} from 'components/elements/Checkbox'
import {AutoComplete} from 'components/elements/AutoComplete'
// models
import {Customer, displayAdminRolesEnum} from 'models'
import {displayTicketCategoryEnum} from 'models/Ticket'
// enums
import {AdminRolesEnum, TicketCategoryEnum} from 'enums'
// utils
import {toPersianDigit} from 'utils/basic/digit'
// local
import {SubmitTicketStepEnum, useData} from './useData'
import {texts} from './texts'

type Props = {
  show: boolean
  handleClose: () => void
  ticketId?: string
  atStep?: SubmitTicketStepEnum
  customerId?: string
}

const TicketModal = ({show, handleClose, ticketId, atStep, customerId}: Props) => {
  const data = useData(show, handleClose, ticketId, atStep, customerId)

  const renderBody = (step: SubmitTicketStepEnum) => {
    switch (step) {
      case SubmitTicketStepEnum.viewOnly:
        return (
          <div className='d-flex flex-lg-row-reverse flex-column-reverse justify-content-center pt-10 gap-5 gap-lg-15'>
            <Button
              type='button'
              className='main-red-background btn btn-lg background-inherit justify-content-center'
              onClick={handleClose}
            >
              {data.TX(texts.back)}
            </Button>
          </div>
        )

      case SubmitTicketStepEnum.first:
        return (
          <div className='d-flex flex-lg-row-reverse flex-column-reverse justify-content-center pt-10 gap-5 gap-lg-15'>
            <Button
              type='button'
              className='main-red-background btn btn-lg background-inherit justify-content-center'
              onClick={handleClose}
            >
              {data.TX(texts.back)}
            </Button>

            <Button
              className='btn btn-lg btn-active-light-primary light-green-background background-inherit main-green d-flex align-items-center justify-content-center'
              type='button'
              onClick={() => data.setStep(SubmitTicketStepEnum.referring)}
            >
              {data.TX(texts.referral)}
            </Button>

            <Button
              className='btn btn-lg btn-primary main-gradient-background justify-content-center'
              type='button'
              onClick={() => data.setStep(SubmitTicketStepEnum.answering)}
            >
              {data.TX(texts.response)}
            </Button>
          </div>
        )

      case SubmitTicketStepEnum.pend:
        return (
          <div className='d-flex flex-lg-row-reverse flex-column-reverse justify-content-center pt-10 gap-5 gap-lg-15'>
            <Button
              type='button'
              className='main-red-background btn btn-lg background-inherit justify-content-center'
              onClick={handleClose}
            >
              {data.TX(texts.back)}
            </Button>

            <Button
              className='btn btn-lg btn-active-light-primary light-green-background background-inherit main-green d-flex align-items-center justify-content-center'
              type='button'
              onClick={() => data.setStep(SubmitTicketStepEnum.referring)}
            >
              {data.TX(texts.referral)}
            </Button>

            <Button
              className='btn btn-lg btn-primary main-gradient-background justify-content-center'
              type='button'
              onClick={() => data.setStep(SubmitTicketStepEnum.answering)}
            >
              {data.TX(texts.response)}
            </Button>
          </div>
        )

      case SubmitTicketStepEnum.answering:
        return (
          <form className='w-100' onSubmit={data.onSubmitRefer} encType='multipart/form-data'>
            <div className='d-flex flex-row w-100 justify-content-between align-items-start gap-5'>
              <Controller
                name='body'
                control={data.controlCreateResponseToTicket}
                render={({field}) => (
                  <Textarea
                    {...field}
                    className='border rounded'
                    label={data.TX(texts.response)}
                    labelClassName='fw-normal fs-5'
                  />
                )}
              />
              <div className='w-25'>
                <UploadImagesChunk
                  disabled={data.loading}
                  photos={data.photos}
                  setPhotos={data.setPhotos}
                  isSubmitted={data.isSubmittedRefer}
                  onSubmitFinish={data.onSubmitFinishRefer}
                  aspect={1}
                  width='125px'
                  height='125px'
                />
              </div>
            </div>
            <div className='d-flex flex-row w-100 justify-content-start align-items-center'>
              <Controller
                name='isFinal'
                control={data.controlCreateResponseToTicket}
                render={({field}) => (
                  <Checkbox {...field} label={data.TX(texts.final_response)} name={'isFinal'} />
                )}
              />
            </div>
            <div className='d-flex flex-lg-row-reverse flex-column-reverse justify-content-center pt-10 gap-5 gap-lg-15'>
              <Button
                type='button'
                className='main-red-background btn btn-lg background-inherit justify-content-center'
                onClick={handleClose}
              >
                {data.TX(texts.back)}
              </Button>

              <Button
                className='btn btn-lg btn-primary main-gradient-background justify-content-center'
                type='submit'
                disabled={
                  data.isSubmittingCreateResponseToTicket ||
                  !(
                    data.isValidCreateResponseToTicket &&
                    data.dirtyFieldsCreateResponseToTicket.body
                  )
                }
              >
                {data.TX(texts.send)}
              </Button>
            </div>
          </form>
        )

      case SubmitTicketStepEnum.referring:
        return (
          <form className='w-100' onSubmit={data.onSubmitRefer} encType='multipart/form-data'>
            <Controller
              name='assigned'
              control={data.controlCreateResponseToTicket}
              render={({field}) => (
                <AutoComplete
                  {...field}
                  className='mb-5'
                  loading={data.customersLoading}
                  disabled={data.loading}
                  options={Object.entries(AdminRolesEnum).map(([key, value]) => ({
                    value: value,
                    label: displayAdminRolesEnum(value),
                  }))}
                  multiple
                  label={data.TX(texts.refer_to)}
                  value={data.watchCreateResponseToTicket().assigned}
                  onChange={(options: {value: string; label: string}[] | any) =>
                    data.setValueCreateResponseToTicket(
                      `assigned`,
                      options.map((option: {value: string; label: string}) => option.value)
                    )
                  }
                />
              )}
            />

            <div className='d-flex flex-row w-100 justify-content-between align-items-start gap-5'>
              <Controller
                name='body'
                control={data.controlCreateResponseToTicket}
                render={({field}) => (
                  <Textarea
                    {...field}
                    className='border rounded'
                    label={data.TX(texts.refer_text)}
                    labelClassName='fw-normal fs-5'
                  />
                )}
              />
              <div className='w-25'>
                <UploadImagesChunk
                  disabled={data.loading}
                  photos={data.photos}
                  setPhotos={data.setPhotos}
                  isSubmitted={data.isSubmittedRefer}
                  onSubmitFinish={data.onSubmitFinishRefer}
                  aspect={1}
                  width='125px'
                  height='125px'
                />
              </div>
            </div>
            <div className='d-flex flex-lg-row-reverse flex-column-reverse justify-content-center pt-10 gap-5 gap-lg-15'>
              <Button
                type='button'
                className='main-red-background btn btn-lg background-inherit justify-content-center'
                onClick={handleClose}
              >
                {data.TX(texts.back)}
              </Button>

              <Button
                className='btn btn-lg btn-primary main-gradient-background justify-content-center'
                type='submit'
                disabled={
                  data.isSubmittingCreateResponseToTicket ||
                  !(
                    data.isValidCreateResponseToTicket &&
                    data.dirtyFieldsCreateResponseToTicket.body
                  )
                }
              >
                {data.TX(texts.send)}
              </Button>
            </div>
          </form>
        )

      case SubmitTicketStepEnum.onlyTicketing:
        return (
          <form className='w-100' onSubmit={data.onSubmit} encType='multipart/form-data'>
            <div className='d-flex flex-row justify-content-between align-items-center gap-5 w-100'>
              {!customerId && (
                <Controller
                  name='customerId'
                  control={data.control}
                  render={({field}) => (
                    <AutoComplete
                      {...field}
                      className='mb-5'
                      loading={data.customersLoading}
                      disabled={data.loading}
                      options={data.customers.map((option: Customer) => ({
                        value: option.getId(),
                        label: option.getFullName(),
                      }))}
                      label={data.TX(texts.common)}
                      value={data.watch().customerId}
                      onChange={(option: {value: string; label: string} | any) =>
                        data.setValue(`customerId`, option ? option.value : '', {
                          shouldValidate: true,
                          shouldDirty: true,
                        })
                      }
                      inputValue={data.customerSearch}
                      onInputChange={(newValue: string) => data.setCustomersSearch(newValue)}
                    />
                  )}
                />
              )}
              <Controller
                name='category'
                control={data.control}
                render={({field}) => (
                  <AutoComplete
                    {...field}
                    className='mb-5'
                    loading={data.customersLoading}
                    disabled={data.loading}
                    options={Object.entries(TicketCategoryEnum).map(([key, value]) => ({
                      value: value,
                      label: displayTicketCategoryEnum(value),
                    }))}
                    label={data.TX(texts.category)}
                    value={data.watch().category}
                    onChange={(option: {value: string; label: string} | any) =>
                      data.setValue(`category`, option ? option.value : '')
                    }
                  />
                )}
              />
            </div>
            <div className='d-flex flex-row w-100 justify-content-between align-items-start gap-5'>
              <Controller
                name='description'
                control={data.control}
                render={({field}) => (
                  <Textarea
                    {...field}
                    className='border rounded'
                    label={data.TX(texts.description)}
                    labelClassName='fw-normal fs-5'
                    disabled={data.createTicketLoading}
                  />
                )}
              />
              <div className='w-25'>
                <UploadImagesChunk
                  disabled={data.loading}
                  photos={data.photos}
                  setPhotos={data.setPhotos}
                  isSubmitted={data.isSubmitted}
                  onSubmitFinish={data.onSubmitFinish}
                  aspect={1}
                  width='125px'
                  height='125px'
                />
              </div>
            </div>
            <div className='d-flex flex-lg-row-reverse flex-column-reverse justify-content-center pt-10 gap-5 gap-lg-15'>
              <Button
                type='button'
                className='main-red-background btn btn-lg background-inherit justify-content-center'
                onClick={handleClose}
              >
                {data.TX(texts.back)}
              </Button>

              <Button
                className='btn btn-lg btn-primary main-gradient-background justify-content-center'
                type='submit'
                disabled={
                  data.isSubmitting ||
                  !(data.isValid && data.dirtyFields.description) ||
                  data.createTicketLoading
                }
              >
                {data.TX(texts.send)}
              </Button>
            </div>
          </form>
        )

      default:
        return null
    }
  }

  return (
    <Modal
      id='create-ticket-modal'
      show={show}
      handleClose={handleClose}
      title={
        data.step !== SubmitTicketStepEnum.onlyTicketing
          ? data.TX(texts.request_number, [toPersianDigit(data.ticket.getNumber())])
          : data.TX(texts.write_ticket)
      }
      children={
        <>
          {!!data.ticket.getId() && <MessageCard ticket={data.ticket} />}
          {renderBody(data.step)}
        </>
      }
    />
  )
}

export {TicketModal}
