import {IProvince, ICity, IDistrict, IAddress} from 'models'
import ClientApi from './clientApi'
import config from './config'
import queryString, {StringifiableRecord} from 'query-string'

const {rootAddress} = config

export interface IAddressSearch {
  address?: string
  title?: string
  neighbourhood?: string
  location: {
    x: number
    y: number
  }
  region: string
}

const prefix = '/location'

const axios = new ClientApi()

export const getProvinces = (): Promise<IProvince[]> =>
  axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix + '/provinces'}))

export const getDistricts = (): Promise<IDistrict[]> =>
  axios.http.get(`${rootAddress}${prefix}/districts`)

export const getProvinceCities = (provinceId: string): Promise<ICity[]> =>
  axios.http.get(rootAddress + prefix + '/provinces/' + provinceId + '/cities')

export const getReverseGeoCoding = async (query: StringifiableRecord): Promise<IAddress> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/reverse-geocoding', query},
      {
        arrayFormat: 'comma',
      }
    )
  )

export const search = async (query: {
  term: string
}): Promise<{items: IAddressSearch[]; count: number}> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/search', query},
      {
        arrayFormat: 'comma',
      }
    )
  )
