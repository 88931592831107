import {Controller} from 'react-hook-form'
// components
import {Modal} from 'components/elements/Modal'
import {Button} from 'components/elements/Button'
import {Textfield} from 'components/elements/Textfield'
// local
import {useData} from './useData'
import {texts} from './texts'

type Props = {
  show: boolean
  handleClose: () => void
}

const CreateEmployeesModal = ({show, handleClose}: Props) => {
  const {onSubmit, control, isSubmitting, errors, isValid, loading, TX} = useData(show, handleClose)

  return (
    <Modal id='employee-modal' show={show} handleClose={handleClose} title={TX(texts.new_employee)}>
      <div className='modal-body py-lg-10 px-lg-10'>
        <div
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <form onSubmit={onSubmit} encType='multipart/form-data'>
              <div className='d-flex flex-row justify-content-center gap-5 w-100'>
                <div className='fv-row mb-10 w-50'>
                  <Controller
                    name='firstName'
                    control={control}
                    render={({field}) => (
                      <Textfield
                        {...field}
                        label={TX(texts.first_name)}
                        type='text'
                        name='firstName'
                        autoComplete='off'
                        error={!!errors.firstName}
                        touched={!!errors.firstName?.message}
                        errorText={errors.firstName?.message}
                        disabled={loading}
                      />
                    )}
                  />
                </div>
                <div className='fv-row mb-10 w-50'>
                  <Controller
                    name='lastName'
                    control={control}
                    render={({field}) => (
                      <Textfield
                        {...field}
                        label={TX(texts.last_name)}
                        type='text'
                        name='lastName'
                        autoComplete='off'
                        error={!!errors.lastName}
                        touched={!!errors.lastName?.message}
                        errorText={errors.lastName?.message}
                        disabled={loading}
                      />
                    )}
                  />
                </div>
              </div>
              <div className='d-flex flex-row justify-content-start gap-5 w-100'>
                <div className='fv-row mb-10 w-50'>
                  <Controller
                    name='mobileNumber'
                    control={control}
                    render={({field}) => (
                      <Textfield
                        {...field}
                        label={TX(texts.mobile_number)}
                        type='text'
                        name='mobileNumber'
                        autoComplete='off'
                        error={!!errors.mobileNumber}
                        touched={!!errors.mobileNumber?.message}
                        errorText={errors.mobileNumber?.message}
                        disabled={loading}
                      />
                    )}
                  />
                </div>
              </div>

              <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
                <Button
                  type='button'
                  className='main-red-background btn btn-lg background-inherit justify-content-center'
                  onClick={handleClose}
                >
                  {TX(texts.create_product_cancel)}
                </Button>

                <Button
                  type='submit'
                  isLoading={loading}
                  className='btn btn-lg btn-primary main-gradient-background'
                  disabled={isSubmitting || !isValid}
                >
                  {TX(texts.create_product_submit)}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {CreateEmployeesModal}
