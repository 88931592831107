import {IsNotEmpty, IsPhoneNumber, IsString, Length} from 'class-validator'

export class AdminLoginRequestDto {
  @IsString({message: 'شماره موبایل الزامی است'})
  @IsPhoneNumber('IR', {message: 'شماره موبایل وارد شده صحیح نمی‌باشد.'})
  @Length(11, 11, {message: 'شماره موبایل باید ۱۱ رقم باشد'})
  mobileNumber: string

  @IsString({message: 'رمز عبور الزامی است'})
  @IsNotEmpty({message: 'رمز عبور الزامی است'})
  password: string

  constructor() {
    this.mobileNumber = ''
    this.password = ''
  }
}
