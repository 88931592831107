import apiConfig from 'services/config'
import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'
// models
import {
  IDto,
  IOrderLine,
  IWeighingSequence,
  IScale,
  IWeighing,
  IBarcode,
  IProduct,
  IDtoWithTotal,
} from 'models'
// dtos
import {
  CreateOrderLineBarcodeRequestDto,
  CreateOrderLineManualWeighingRequestDto,
  CreateOrderLineWeighingSequenceRequestDto,
  SetOrderLineWeightEmptyBoxesRequestDto,
} from 'dtos'

const axios = new ClientApi()

const {rootAddress} = apiConfig

const prefix = '/dashboard/inventory'

export const getOrderLines = (query: StringifiableRecord): Promise<IDto<IOrderLine>> =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/order-lines', query},
      {arrayFormat: 'comma'}
    )
  )

export const getOrderLineDetails = (orderLineId: string): Promise<IOrderLine> =>
  axios.http.get(rootAddress + prefix + '/order-lines/' + orderLineId)

export const getOrderLineWeighingSequences = (
  orderLineId: string,
  query: StringifiableRecord
): Promise<IDto<IWeighingSequence>> =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/order-lines/' + orderLineId + '/weighing-sequences', query},
      {arrayFormat: 'comma'}
    )
  )

export const getOrderLineBarcodeList = (
  orderLineId: string,
  query: StringifiableRecord,
  fileName: string
) =>
  axios.http
    .get(
      queryString.stringifyUrl(
        {url: rootAddress + prefix + '/order-lines/' + orderLineId + '/barcode', query},
        {arrayFormat: 'comma'}
      ),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      }
    )
    // @ts-ignore
    .then((response: BlobPart) => {
      const url = window.URL.createObjectURL(new Blob([response], {type: 'application/pdf'}))

      const link = document.createElement('a')
      link.href = url
      link.download = fileName
      // link.setAttribute('download', body.fileName + '.pdf')

      document.body.appendChild(link)

      link.click()
      link.parentNode!.removeChild(link)
    })

export const createListBarcodeOrderLine = (
  orderLineId: string,
  body: CreateOrderLineBarcodeRequestDto
): Promise<IBarcode[]> =>
  axios.http.post(rootAddress + prefix + '/order-lines/' + orderLineId + '/barcode', body)

export const engagedScaleByOrderLine = (orderLineId: string, scaleId: string): Promise<IScale> =>
  axios.http.patch(
    rootAddress + prefix + '/order-lines/' + orderLineId + '/scales/' + scaleId + '/engaged'
  )

export const createWeighingSequence = (
  orderLineId: string,
  body: CreateOrderLineWeighingSequenceRequestDto
): Promise<IWeighingSequence> =>
  axios.http.post(rootAddress + prefix + '/order-lines/' + orderLineId + '/weighing-sequence', body)

export const removeWeighingOrderLine = (
  orderLineId: string,
  weighingId: string
): Promise<IWeighing> =>
  axios.http.delete(
    rootAddress + prefix + '/order-lines/' + orderLineId + '/weighing/' + weighingId
  )

export const removeWeighingSequenceOrderLine = (orderLineId: string, weighingSequenceId: string) =>
  axios.http.delete(
    rootAddress +
      prefix +
      '/order-lines/' +
      orderLineId +
      '/weighing-sequence/' +
      weighingSequenceId
  )

export const getOrderLineWeighings = (
  orderLineId: string,
  query: StringifiableRecord
): Promise<IDto<IWeighing>> =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/order-lines/' + orderLineId + '/weighings/pending', query},
      {arrayFormat: 'comma'}
    )
  )

export const getOrderLinesReport = (query: StringifiableRecord): Promise<IDtoWithTotal<IProduct>> =>
  axios.http.get(
    queryString.stringifyUrl({url: rootAddress + prefix + '/order-lines/report', query})
  )

export const setWeightManually = (
  orderLineId: string,
  body: CreateOrderLineManualWeighingRequestDto
) => axios.http.post(rootAddress + prefix + '/order-lines/' + orderLineId + '/weighing', body)

export const setTotalEmptyBoxWeight = (
  orderLineId: string,
  body: SetOrderLineWeightEmptyBoxesRequestDto
) =>
  axios.http.patch(
    rootAddress + prefix + '/order-lines/' + orderLineId + '/set-total-weight-empty-boxes',
    body
  )

export const weighingComplete = (orderLineId: string) =>
  axios.http.patch(rootAddress + prefix + '/order-lines/' + orderLineId + '/weighing/complete')
