import {IsDate, IsEnum, IsNotEmpty, IsOptional, IsString} from 'class-validator'
// enums
import {CouponStatusEnum} from 'enums'

export class UpdateCouponRequestDto {
  @IsString()
  @IsOptional()
  description?: string

  @IsDate()
  @IsNotEmpty({message: 'تاریخ انقضا الزامی است'})
  expiryDate: Date

  @IsEnum(CouponStatusEnum)
  @IsOptional()
  status?: CouponStatusEnum

  constructor() {
    this.description = ''
    this.expiryDate = new Date()
    this.status = CouponStatusEnum.deactivated
  }
}
