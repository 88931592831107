import * as Yup from 'yup'
import {texts} from './texts'
import useText from 'hooks/useText'

const useSchema = () => {
  const {TX} = useText()
  return Yup.object().shape({
    question: Yup.string().trim().required(TX(texts.faq_form_question_required)),
    answer: Yup.string().trim().required(TX(texts.faq_form_answer_required)),
  })
}

export {useSchema}
