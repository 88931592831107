import {FC} from 'react'

type CheckboxProps = {
  className?: string
  label?: string
  onClick?: () => void
  color?: string
  value?: boolean
  backgroundIsTransparent?: boolean
  name?: string
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox: FC<CheckboxProps> = ({
  className = '',
  label = '',
  name = '',
  onClick,
  color,
  value = false,
  backgroundIsTransparent = false,
  disabled = false,
  onChange = () => undefined,
  ...props
}) => {
  return (
    <div className='form-check form-check-custom form-check-solid' onClick={onClick}>
      <input
        className={['form-check-input cursor-pointer', className].join(' ')}
        type='checkbox'
        checked={value}
        disabled={disabled}
        onChange={(e) => onChange(e)}
        name={name}
      />
      <label
        className={['form-check-label fw-bold cursor-pointer', color ? '' : 'text-gray-600'].join(
          ' '
        )}
        style={{color: color}}
      >
        {label}
      </label>
    </div>
  )
}

export {Checkbox}
