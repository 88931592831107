export enum BoxStatusEnum {
  activated = 'activated',
  deactivated = 'deactivated',
}

export enum BoxCreatorTypeEnum {
  ADMIN = 'admin',
  CUSTOMER = 'customer',
}

export enum BoxTypeEnum {
  STANDARD = 'standard',
  CUSTOM = 'custom',
}

export enum GetBoxesRequestDtoSortEnum {
  boxName = 'box.name',
  boxPrice = 'box.price',
  boxWeight = 'box.weight',
  boxStartDate = 'box.startDate',
  boxEndDate = 'box.endDate',
  boxCreatedAt = 'box.createdAt',
  boxStatus = 'box.status',
}
