import {Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      '& div': {
        height: 45,
        '& fieldset': {
          border: 'none',
        },
      },
    },
  }
})
