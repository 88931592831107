import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  create_seller_title: 'pages.protected.production-line.supplies.sellers.create_seller_title',
  first_name: 'pages.protected.production-line.supplies.sellers.first_name',
  last_name: 'pages.protected.production-line.supplies.sellers.last_name',
  phone_number: 'pages.protected.production-line.supplies.sellers.phone_number',
  province: 'pages.protected.production-line.supplies.sellers.province',
  city: 'pages.protected.production-line.supplies.sellers.city',
  address: 'pages.protected.production-line.supplies.sellers.address',
  submit: 'pages.protected.production-line.supplies.sellers.submit',
  cancel: 'pages.protected.production-line.supplies.sellers.cancel',
  first_name_schema: 'pages.protected.supplies.sellers.create-seller-modal.first_name_schema',
  mobile_number_required: "pages.protected.supplies.sellers.create-seller-modal.mobile_number_required",
  success: 'pages.protected.supplies.sellers.create-seller-modal.success',
  mobile_error: 'pages.public.login.mobile_error',
}
