import {WeeklyPlan, IWeeklyPlan} from './WeeklyPlan'

export interface ICourierWeeklyPlan {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  isActive?: boolean
  weeklyPlanId?: string
  weeklyPlan?: IWeeklyPlan
  courierId?: string
}

export class CourierWeeklyPlan {
  public props: ICourierWeeklyPlan = {}

  constructor(data?: ICourierWeeklyPlan) {
    if (data) {
      this.props = data
    }
  }
  getId(): string {
    return this.props.id || ''
  }
  getCourierId(): string {
    return this.props.courierId || ''
  }
  getWeeklyPlanId(): string {
    return this.props.weeklyPlanId || ''
  }
  getCreatedAt(): Date {
    return this.props.createdAt || new Date()
  }
  getUpdatedAt(): Date {
    return this.props.updatedAt || new Date()
  }
  getIsActive(): boolean {
    return this.props.isActive || false
  }
  getWeeklyPlan(): WeeklyPlan {
    return new WeeklyPlan(this.props.weeklyPlan || undefined)
  }
}
