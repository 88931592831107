import {
  ICustomer,
  IPackingTarget,
  IAddress,
  PackingTarget,
  Address,
  Customer,
  IAdmin,
  IPayment,
  IWalletTransaction,
  Payment,
  Admin,
  WalletTransaction,
  ICourier,
  Courier,
  ISubscription,
  Subscription,
  IDeliveryChainCustomer,
  DeliveryChainCustomer,
} from 'models'
import {ITicket, Ticket} from './Ticket'
import {DeliveryBox, IDeliveryBox} from './DeliveryBox'
import {DeliveryStateEnum, DeliveryStatusEnum, DeliveryTypeEnum} from 'enums'

export interface IDelivery {
  id?: string
  createdAt?: string
  updatedAt?: string
  date?: string
  status?: DeliveryStatusEnum
  state?: DeliveryStateEnum
  type?: DeliveryTypeEnum
  confirmCode?: string
  notes?: string
  routePointNumber?: string
  subscriptionId?: string
  redeliveryId?: string
  paymentId?: string
  addressId?: string
  customerId?: string
  courierId?: string
  adminId?: string
  boxPriceId?: string
  packagingTargetId?: string
  redelivery?: IDelivery
  redeliveries?: IDelivery[]
  payment?: IPayment
  packagingTarget?: IPackingTarget
  address?: IAddress
  customer?: ICustomer
  admin?: IAdmin
  walletTransactions?: IWalletTransaction[]
  courier?: ICourier
  subscription?: ISubscription
  ticket?: ITicket
  deliveryBoxes?: IDeliveryBox[]
  deliveryChainCustomer?: IDeliveryChainCustomer
  courierNotes?: string
  customerNotes?: string
  totalPrice?: number
}

export class Delivery {
  public props: IDelivery = {}

  constructor(data?: IDelivery) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getDate(): Date | undefined {
    return this.props.date ? new Date(this.props.date) : undefined
  }

  getStatus(): DeliveryStatusEnum | undefined {
    return this.props.status
  }

  getState(): DeliveryStateEnum | undefined {
    return this.props.state
  }

  getType(): DeliveryTypeEnum | undefined {
    return this.props.type
  }

  getConfirmCode(): string {
    return this.props.confirmCode || ''
  }

  getNotes(): string {
    return this.props.notes || ''
  }

  getRoutePointNumber(): string {
    return this.props.routePointNumber || ''
  }

  getReDeliveryId(): string {
    return this.props.redeliveryId || ''
  }

  getCourierId(): string {
    return this.props.courierId || ''
  }

  getPaymentId(): string {
    return this.props.paymentId || ''
  }

  getAddressId(): string {
    return this.props.addressId || ''
  }

  getCustomerId(): string {
    return this.props.customerId || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getBoxPriceId(): string {
    return this.props.boxPriceId || ''
  }

  getSubscriptionId(): string {
    return this.props.subscriptionId || ''
  }

  getPackagingTargetId(): string {
    return this.props.packagingTargetId || ''
  }

  getReDelivery(): Delivery {
    return new Delivery(this.props.redelivery || {})
  }

  getDeliveryChainCustomer(): DeliveryChainCustomer {
    return new DeliveryChainCustomer(this.props.deliveryChainCustomer || {})
  }

  getReDeliveries(): Delivery[] {
    return (this.props.redeliveries || []).map((redelivery: IDelivery) => new Delivery(redelivery))
  }

  getPayment(): Payment {
    return new Payment(this.props.payment || {})
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getPackagingTarget(): PackingTarget {
    return new PackingTarget(this.props.packagingTarget || {})
  }

  getAddress(): Address {
    return new Address(this.props.address || {})
  }

  getCustomer(): Customer {
    return new Customer(this.props.customer || {})
  }

  getWalletTransactions(): WalletTransaction[] {
    return (this.props.walletTransactions || []).map(
      (walletTransaction: IWalletTransaction) => new WalletTransaction(walletTransaction)
    )
  }

  getCourier(): Courier {
    return new Courier(this.props.courier || {})
  }

  getSubscription(): Subscription {
    return new Subscription(this.props.subscription || {})
  }

  getTicket(): Ticket {
    return new Ticket(this.props.ticket || {})
  }

  getDeliveryBoxes(): DeliveryBox[] {
    return (this.props.deliveryBoxes || []).map(
      (deliveryBox: IDeliveryBox) => new DeliveryBox(deliveryBox)
    )
  }

  getCourierNotes(): string {
    return this.props.courierNotes || ''
  }

  getCustomerNotes(): string {
    return this.props.customerNotes || ''
  }

  getTotalPrice(): number {
    return this.props.totalPrice || 0
  }

  // custom
  getDisplayStatus(): string {
    return displayDeliveryStatusEnum(this.getStatus())
  }

  getDisplayState(): string {
    return displayDeliveryStateEnum(this.getState())
  }
}

export const displayDeliveryStatusEnum = (
  deliveryStatusEnum?: DeliveryStatusEnum | string
): string => {
  switch (deliveryStatusEnum) {
    case DeliveryStatusEnum.processing:
      return 'در حال پردازش'
    case DeliveryStatusEnum.cancel:
      return 'لغو شده'
    case DeliveryStatusEnum.customer_absence:
      return 'نبود مشتری'
    case DeliveryStatusEnum.success:
      return 'کامل شده'
    default:
      return ''
  }
}

export const displayDeliveryStateEnum = (deliveryStateEnum?: DeliveryStateEnum): string => {
  switch (deliveryStateEnum) {
    case DeliveryStateEnum.pending:
      return 'در حال پردازش'
    case DeliveryStateEnum.cancelled:
      return 'لغو شده'
    case DeliveryStateEnum.ready:
      return 'آماده'
    case DeliveryStateEnum.out_for_delivery:
      return 'در حال ارسال'
    case DeliveryStateEnum.failed_delivery:
      return 'ارسال ناموفق'
    case DeliveryStateEnum.delivered:
      return 'ارسال شده'
    default:
      return ''
  }
}

export const displayShiftNameDeliveryDate = (index: string | number) => {
  switch (index) {
    case 1:
      return 'نوبت اول'
    case 2:
      return 'نوبت دوم'
    case 3:
      return 'نوبت سوم'
    case 4:
      return 'نوبت چهارم'
    case 5:
      return 'نوبت پنجم'
    case 6:
      return 'نوبت ششم'
    case 7:
      return 'نوبت هفتم'
    case 8:
      return 'نوبت هشتم'
    case 9:
      return 'نوبت نهم'
    default:
      return '-'
  }
}
