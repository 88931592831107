import {Admin, IAdmin} from './Admin'
import {IOrderLine, OrderLine} from './OrderLine'
import {IPhotoUse, PhotoUse} from './PhotoUse'
import {IQuote, Quote} from './Quote'

export interface IOrder {
  id?: string
  createdAt?: string
  updatedAt?: string
  totalPrice?: number
  totalWeight?: number
  date?: Date
  quoteId?: string
  adminId?: string
  admin?: IAdmin
  quote?: IQuote
  orderLines?: IOrderLine[]
  receiptPhotoUses?: IPhotoUse[]
  paymentPhotoUses?: IPhotoUse[]
}

export class Order {
  public props: IOrder = {}

  constructor(data?: IOrder) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getTotalPrice(): number {
    return this.props.totalPrice || 0
  }

  getTotalWeight(): number {
    return this.props.totalWeight || 0
  }

  getDate(): Date | undefined {
    return this.props.date ? new Date(this.props.date) : undefined
  }

  getQuoteId(): string {
    return this.props.quoteId || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getQuote(): Quote {
    return new Quote(this.props.quote || {})
  }

  getOrderLines(): OrderLine[] {
    return (this.props.orderLines || []).map((orderLine: IOrderLine) => new OrderLine(orderLine))
  }

  getReceiptPhotoUses(): PhotoUse[] {
    return (this.props.receiptPhotoUses || []).map((photoUse: IPhotoUse) => new PhotoUse(photoUse))
  }

  getPaymentPhotoUses(): PhotoUse[] {
    return (this.props.paymentPhotoUses || []).map((photoUse: IPhotoUse) => new PhotoUse(photoUse))
  }
}
