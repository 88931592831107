import {useState} from 'react'
import {KTSVG} from 'utils/template/helpers'
import {CreateLinesModal} from 'pages/protected/ProductionLine/Sorting/Lines/components/CreateLines'

import useText from 'hooks/useText'

import {texts} from './texts'

const ToolbarLine = () => {
  const [showCreateLineModal, setShowCreateLineModal] = useState<boolean>(false)
  const {TX} = useText()

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div
        className='btn btn-sm main-gradient-background fw-bold d-flex align-items-center px-4 gap-1'
        onClick={() => setShowCreateLineModal(true)}
      >
        <KTSVG
          path='/media/icons/duotune/art/add-circle-sarsabad.svg'
          className='svg-icon-1 me-0 text-white'
        />
        <div className='text-white fw-bold'>{TX(texts.new_line)}</div>
      </div>
      <CreateLinesModal
        show={showCreateLineModal}
        handleClose={() => {
          setShowCreateLineModal(false)
        }}
      />
    </div>
  )
}

export {ToolbarLine}
