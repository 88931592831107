import apiConfig from 'services/config'
import ClientApi from './clientApi'
import queryString, {StringifiableRecord} from 'query-string'
// models
import {IDto, IFAQ, IFAQCategory} from 'models'
// dtos
import {CreateFaqCategoryRequestDto, CreateFaqRequestDto, UpdateFaqRequestDto} from 'dtos'

const axios = new ClientApi()

const {rootAddress} = apiConfig

const prefix = '/dashboard/faq'

export const getFAQ = async (query?: StringifiableRecord): Promise<IDto<IFAQ>> =>
  await axios.http.get(
    queryString.stringifyUrl(
      {
        url: rootAddress + prefix,
        query,
      },
      {
        arrayFormat: 'comma',
      }
    )
  )

export const getFAQCategories = async (): Promise<IFAQCategory[]> =>
  axios.http.get(rootAddress + prefix + '/categories')

export const createFAQ = async (body: CreateFaqRequestDto) =>
  axios.http.post(rootAddress + prefix, body)

export const getFAQDetail = async (faqId: string): Promise<IFAQ> =>
  axios.http.get(rootAddress + prefix + '/' + faqId)

export const deleteFAQ = async (faqId: string) =>
  axios.http.delete(rootAddress + prefix + '/' + faqId)

export const updateFAQ = async (faqId: string, body: UpdateFaqRequestDto) =>
  axios.http.put(rootAddress + prefix + '/' + faqId, body)

export const createFAQCategory = async (body: CreateFaqCategoryRequestDto) =>
  axios.http.post(rootAddress + prefix + '/category', body)
