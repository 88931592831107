import * as React from 'react'
import AdapterJalali from '@date-io/date-fns-jalali'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {TextField} from '@mui/material'
import {useStyles} from './style'
import {Typography} from '../Typography'
import './style.css'

type DatePickerTypeProps = {
  value?: Date | null
  name?: string
  loading?: boolean
  onChange?: (value: Date | null) => void
  setValue?: (T: any) => void
  error?: boolean
  errorText?: string
  labelClassName?: string
  label?: string
  maxDate?: Date
  minDate?: Date
} & Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange'>

const JalaliDatePicker: React.FC<DatePickerTypeProps> = ({
  value = new Date(),
  name = '',
  loading = false,
  setValue,
  onChange = () => null,
  error = false,
  errorText = '',
  labelClassName = '',
  label = '',
  maxDate,
  minDate,
  ...props
}) => {
  const {classes} = useStyles()
  return (
    <div className='fv-row w-100'>
      {label && (
        <label
          className={[
            'd-flex align-items-center fs-5 fw-semibold mb-2',
            labelClassName,
            props.required ? 'required' : '',
          ].join(' ')}
        >
          <span>{label}</span>
        </label>
      )}
      <LocalizationProvider dateAdapter={AdapterJalali}>
        <DatePicker
          mask='____/__/__'
          value={value}
          onChange={(newValue) => {
            onChange(newValue)
          }}
          disabled={props.disabled}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              className={[
                'form-control form-control-lg form-control-solid h-25',
                classes.root,
              ].join(' ')}
              error={error}
              disabled={props.disabled}
              // helperText={errorText}
              name={name}
            />
          )}
          maxDate={maxDate}
          minDate={minDate}
        />
      </LocalizationProvider>
      {error && (
        <Typography type='span' className='fs-7 mt-5' color='rgb(231, 76, 60)'>
          {errorText}
        </Typography>
      )}
    </div>
  )
}

export {JalaliDatePicker}
