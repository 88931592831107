import {FC} from 'react'
import {toAbsoluteUrl} from 'utils/template/helpers'

type AvatarProps = {
  className?: string
  src?: string
  alt?: string
  size?: number
  largeScreenSize?: number
  onClick?: () => void
}

const Avatar: FC<AvatarProps> = ({
  className = '',
  src = '/media/png/default.png',
  alt = 'sarsabad',
  size = 45,
  largeScreenSize = 45,
  onClick = () => null,
}) => {
  return (
    <div
      className={[
        `symbol symbol-${size}px symbol-lg-${largeScreenSize}px symbol-fixed position-relative`,
        className,
      ].join(' ')}
      onClick={onClick}
    >
      <img src={toAbsoluteUrl(src)} alt={alt} />
    </div>
  )
}

export {Avatar}
