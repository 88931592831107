import {Courier, ICourier} from './Courier'

export interface IVehicle {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  name?: string
  model?: string
  plateNumber?: string
  courierId?: string
  courier?: ICourier
}

export class Vehicle {
  public props: IVehicle = {}

  constructor(data?: IVehicle) {
    if (data) {
      this.props = data
    }
  }
  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date {
    return this.props.createdAt || new Date()
  }

  getUpdatedAt(): Date {
    return this.props.updatedAt || new Date()
  }

  getName(): string {
    return this.props.name || ''
  }

  getModel(): string {
    return this.props.model || ''
  }

  getPlateNumber(): string {
    return this.props.plateNumber || ''
  }

  getCourierId(): string {
    return this.props.courierId || ''
  }

  getCourier(): Courier {
    return new Courier(this.props.courier || {})
  }
}
