import {UnitTypeEnum} from 'enums'

export interface IProductUnit {
  id?: string
  createdAt?: string
  updateAt?: string
  name?: UnitTypeEnum
  faName?: string
  isActive?: boolean
}

export class ProductUnit {
  public props: IProductUnit = {}

  constructor(data?: IProductUnit) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updateAt ? new Date(this.props.updateAt) : undefined
  }

  getName(): UnitTypeEnum {
    return this.props.name || UnitTypeEnum.kilogram
  }

  getFaName(): string {
    return this.props.faName || ''
  }

  getIsActive(): boolean {
    return this.props.isActive || false
  }
}
