import {useState} from 'react'
import {KTSVG} from 'utils/template/helpers'
import {InitializeScaleModal} from 'pages/protected/ProductionLine/Warehouse/Scales/main/components/InitializeScaleModal'

import useText from 'hooks/useText'

import {texts} from './texts'
import services, {ServerError} from 'services'
import {useMutation} from 'react-query'
import {useSnackbar} from 'notistack'
import useToolbarRefetch from 'hooks/useToolbarRefetch'

const ToolbarScale = () => {
  const [showCreateScaleModal, setShowCreateScaleModal] = useState<boolean>(false)
  const {TX} = useText()

  const {enqueueSnackbar} = useSnackbar()

  const handleHeartBeat = async () => await services.scales.heartbeatCheck()

  const {setIsRefetchOn} = useToolbarRefetch()

  const {isLoading: isLoadingHeartBeat, mutate: checkHeartBeat} = useMutation(
    ['heartBeatCheck'],
    handleHeartBeat,
    {
      onError: (error) => {
        const {message} = error as ServerError
        enqueueSnackbar(message, {
          variant: 'error',
        })
      },
      onSuccess: (data) => {
        setIsRefetchOn(true)
        enqueueSnackbar(TX(texts.refetch_scale_success), {
          variant: 'success',
        })
      },
    }
  )

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div
        className='btn btn-sm btn-active-light-primary light-green-background background-inherit main-green fw-bold d-flex align-items-center px-4 gap-1'
        onClick={() => {
          if (!isLoadingHeartBeat) {
            checkHeartBeat()
          }
        }}
      >
        <KTSVG
          path='/media/icons/duotune/general/setting-sarsabad.svg'
          className='svg-icon-1 me-0 text-white'
        />
        <div className='fw-bold'> {TX(texts.refetch_scale)} </div>
      </div>
      <div
        className='btn btn-sm main-gradient-background fw-bold d-flex align-items-center px-4 gap-1'
        onClick={() => setShowCreateScaleModal(true)}
      >
        <KTSVG
          path='/media/icons/duotune/art/add-circle-sarsabad.svg'
          className='svg-icon-1 me-0 text-white'
        />
        <div className='text-white fw-bold'>{TX(texts.new_scale)}</div>
      </div>
      <InitializeScaleModal
        show={showCreateScaleModal}
        handleClose={() => {
          setShowCreateScaleModal(false)
        }}
      />
    </div>
  )
}

export {ToolbarScale}
