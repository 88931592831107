import {IsNotEmpty, IsNumber, IsPositive} from 'class-validator'

export class SetOrderLineWeightEmptyBoxesRequestDto {
  @IsNumber({}, {message: 'وزن باکس الزامی است'})
  @IsPositive({message: 'وزن باید عدد مثبت باشد'})
  @IsNotEmpty({message: 'وزن باکس الزامی است'})
  totalWeightEmptyBoxes: number

  constructor() {
    this.totalWeightEmptyBoxes = 0
  }
}
