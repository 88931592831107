import {IsEnum} from 'class-validator'
import {VendorStatusEnum} from 'enums'

export class UpdateVendorStatusRequestDto {
  @IsEnum(VendorStatusEnum, {message: 'وضعیت تامین کننده الزامی است'})
  status: VendorStatusEnum

  constructor() {
    this.status = VendorStatusEnum.active
  }
}
