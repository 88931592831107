import {
  IDailyTargetProduct,
  IQuoteProduct,
  DailyTargetProduct,
  QuoteProduct,
  IOrder,
  Order,
  IAdmin,
  Admin,
} from 'models'
import {IQuoteBox, QuoteBox} from './QuoteBox'
import {QuoteStatusEnum, QuoteTypeEnum} from 'enums'

export interface IQuote {
  id?: string
  createdAt?: string
  updatedAt?: string
  status?: QuoteStatusEnum
  isOriginalOffer?: boolean
  date?: string
  type?: QuoteTypeEnum
  quoteNumber?: string
  comments?: string
  adminId?: string
  dailyTargetId?: string
  admin?: IAdmin
  dailyTarget?: IDailyTargetProduct
  quoteProducts?: IQuoteProduct[]
  quoteBoxes?: IQuoteBox[]
  order?: IOrder
}

export class Quote {
  public props: IQuote = {}

  constructor(data?: IQuote) {
    if (data) {
      this.props = data
    }
  }

  getId(): string {
    return this.props.id || ''
  }

  getCreatedAt(): Date | undefined {
    return this.props.createdAt ? new Date(this.props.createdAt) : undefined
  }

  getUpdatedAt(): Date | undefined {
    return this.props.updatedAt ? new Date(this.props.updatedAt) : undefined
  }

  getStatus(): QuoteStatusEnum | undefined {
    return this.props.status
  }

  getIsOriginalOffer(): boolean {
    return this.props.isOriginalOffer || false
  }

  getDate(): Date | undefined {
    return this.props.date ? new Date(this.props.date) : undefined
  }

  getType(): QuoteTypeEnum {
    return this.props.type || QuoteTypeEnum.daily_target
  }

  getQuoteNumber(): string {
    return this.props.quoteNumber || ''
  }

  getComments(): string {
    return this.props.comments || ''
  }

  getAdminId(): string {
    return this.props.adminId || ''
  }

  getAdmin(): Admin {
    return new Admin(this.props.admin || {})
  }

  getDailyTargetId(): string {
    return this.props.dailyTargetId || ''
  }

  getDailyTargetProduct(): DailyTargetProduct {
    return new DailyTargetProduct(this.props.dailyTarget || {})
  }

  getQuoteProducts(): QuoteProduct[] {
    return (this.props.quoteProducts || []).map((quoteProduct) => new QuoteProduct(quoteProduct))
  }

  getQuoteBoxes(): QuoteBox[] {
    return (this.props.quoteBoxes || []).map((quoteBox: IQuoteBox) => new QuoteBox(quoteBox))
  }

  getOrder(): Order {
    return new Order(this.props.order || {})
  }

  // custom

  getDisplayStatus(): string {
    return displayQuoteStatusEnum(this.getStatus())
  }

  getDisplayType(): string {
    return displayQuoteTypeEnum(this.getType())
  }
}

export const displayQuoteStatusEnum = (quoteStatusEnum?: QuoteStatusEnum): string => {
  switch (quoteStatusEnum) {
    case QuoteStatusEnum.accepted:
      return 'تایید شده'
    case QuoteStatusEnum.pending:
      return 'بدون پاسخ'
    case QuoteStatusEnum.rejected:
      return 'رد شده'
    case QuoteStatusEnum.waiting_for_completion:
      return 'در انتظار تکمیل'
    default:
      return ''
  }
}

export const displayQuoteTypeEnum = (quoteTypeEnum?: QuoteTypeEnum): string => {
  switch (quoteTypeEnum) {
    case QuoteTypeEnum.daily_target:
      return 'محصولات روز'
    case QuoteTypeEnum.manual:
      return 'سفارشی'
    default:
      return ''
  }
}
