import {PaginationProps, Theme} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

interface ITheme {
  size?: PaginationProps['size']
}

export const useStyles = makeStyles<ITheme>()((theme: Theme, {size}) => {
  return {
    paginationButton: {
      direction: 'ltr',
      marginTop: '0.5rem',
      marginBottom: '1.25rem',
      '& .MuiPagination-ul': {
        justifyContent: 'center',
        '& .MuiButtonBase-root': {
          border: '0',
          borderRadius: '0.475rem',
          color: '#029c45',
          backgroundColor: theme.palette.common.white,
          '&:hover': {
            backgroundColor: '#f4f6fa',
          },
          '&.Mui-selected': {
            color: theme.palette.common.white,
            backgroundColor: '#029c45',
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[400],
          },
          '&.Mui-selected.Mui-disabled': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.grey[300],
          },
        },
        '& .MuiPaginationItem-ellipsis': {
          border: '0',
          borderRadius: '12px',
          color: '#029c45',
          backgroundColor: theme.palette.common.white,
          height: size === 'large' ? '40px' : '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&.Mui-disabled': {
            color: theme.palette.grey[400],
          },
        },
      },
    },
  }
})
