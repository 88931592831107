//components
import {Modal} from 'components/elements/Modal'
import {AutoComplete} from 'components/elements/AutoComplete'
import {Button} from 'components/elements/Button'
//hooks
import useText from 'hooks/useText'
//locals
import {texts} from './texts'
import useData from './useData'
import {Controller} from 'react-hook-form'

export type Props = {
  show: boolean
  handleClose: () => void
}

const AddCustomerModal = ({show, handleClose}: Props) => {
  const {TX} = useText()
  const data = useData({show, handleClose})

  return (
    <Modal
      id='create-courier-modal'
      show={show}
      handleClose={() => {
        handleClose()
        data.reset()
      }}
      title={TX(texts.new_customer)}
    >
      <>
        <form onSubmit={data.onSubmit}>
          <div className='container ' id='kt_modal_create_app_stepper'>
            <Controller
              name='deliveryId'
              control={data.control}
              render={({field}) => (
                <AutoComplete
                  {...field}
                  loading={data.customersLoading}
                  options={data.deliveries
                    .filter(
                      (delivery) =>
                        !data.deliveryChain
                          .getDeliveryChainCustomers()
                          .map((deliveryChainCustomers) =>
                            deliveryChainCustomers.getCustomer().getId()
                          )
                          .includes(delivery.getCustomer().getId())
                    )
                    .map((delivery) => ({
                      value: delivery.getId(),
                      label: delivery.getCustomer().getFullName(),
                    }))}
                  label={TX(texts.customer)}
                  value={data.watch().deliveryId}
                  onChange={(option: {value: string; label: string} | any) =>
                    data.setValue(`deliveryId`, option?.value ? option?.value : '', {
                      shouldValidate: true,
                      shouldDirty: true,
                    })
                  }
                  inputValue={data.deliverySearch}
                  onInputChange={(newValue: string) => data.setDeliverySearch(newValue)}
                />
              )}
            />
          </div>
          <div className='d-flex flex-row-reverse justify-content-center pt-10 gap-15'>
            <Button
              text={TX(texts.cancel)}
              className='main-red-background btn btn-lg background-inherit justify-content-center'
              onClick={() => {
                handleClose()
                data.reset()
              }}
              type='button'
            />

            <Button
              text={TX(texts.submit)}
              className='btn btn-lg btn-primary main-gradient-background'
              isLoading={data.isLoading}
              disabled={!data.isValid || !data.isDirty}
            />
          </div>
        </form>
      </>
    </Modal>
  )
}

export default AddCustomerModal
