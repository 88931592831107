import {IsEnum, IsNotEmpty, IsString} from 'class-validator'
import {ContextEnum} from 'enums'

export class CreateOrderLineWeighingSequenceRequestDto {
  @IsString({message: 'ترازو الزامی است'})
  @IsNotEmpty({message: 'ترازو الزامی است'})
  scaleId: string

  @IsEnum(ContextEnum, {message: 'کانتکس (نوع وزن کشی) الزامی است'})
  context: ContextEnum

  constructor() {
    this.scaleId = ''
    this.context = ContextEnum.inventory_arrived
  }
}
