import queryString, {StringifiableRecord} from 'query-string'
import apiConfig from 'services/config'
import ClientApi from './clientApi'
// models
import {ICourier, IDeliveryPlan, IDto, IRegion} from 'models'
// dtos
import {
  CreateRegionDeliveryPlanRequestDto,
  CreateRegionRequestDto,
  UpdateRegionDeliveryPlanRequestDto,
  UpdateRegionRequestDto,
} from 'dtos'

const {rootAddress} = apiConfig
const prefix = '/dashboard/regions'

const axios = new ClientApi()

export interface ICoordinates {
  lat: string
  long: string
}

export const getDeliveryPlans = async (): Promise<IDeliveryPlan[]> =>
  await axios.http.get(rootAddress + prefix + `/delivery-plans`)

export const getCouriers = async (
  regionId: string,
  query: StringifiableRecord
): Promise<IDto<ICourier>> =>
  axios.http.get(
    queryString.stringifyUrl(
      {url: rootAddress + prefix + '/' + regionId + '/couriers', query},
      {arrayFormat: 'comma'}
    )
  )

export const getRegions = async (query: StringifiableRecord): Promise<IDto<IRegion>> =>
  await axios.http.get(queryString.stringifyUrl({url: rootAddress + prefix, query}))

export const createRegion = async (body: CreateRegionRequestDto): Promise<IRegion> =>
  await axios.http.post(rootAddress + prefix, body)

export const getRegionDetail = async (regionId: string): Promise<IRegion> =>
  await axios.http.get(rootAddress + prefix + '/' + regionId)

export const editRegion = async (
  regionId: string,
  body: UpdateRegionRequestDto
): Promise<IRegion> => await axios.http.put(rootAddress + prefix + '/' + regionId, body)

export const getRegionDeliveryPlans = async (regionId: string): Promise<IDeliveryPlan[]> =>
  await axios.http.get(rootAddress + prefix + '/' + regionId + `/delivery-plans`)

export const addCourierRegionDeliveryPlan = async (
  regionId: string,
  body: CreateRegionDeliveryPlanRequestDto
): Promise<IRegion> =>
  await axios.http.post(rootAddress + prefix + '/' + regionId + '/delivery-plan', body)

export const editCourierRegionDeliveryPlan = async (
  regionId: string,
  regionDeliveryPlanId: string,
  body: UpdateRegionDeliveryPlanRequestDto
): Promise<IRegion> =>
  await axios.http.put(
    rootAddress + prefix + '/' + regionId + '/delivery-plans/' + regionDeliveryPlanId,
    body
  )

export const activateCourierRegionDeliveryPlan = async (
  regionId: string,
  regionDeliveryPlanId: string
): Promise<IRegion> =>
  await axios.http.patch(
    rootAddress + prefix + '/' + regionId + '/delivery-plans/' + regionDeliveryPlanId + '/activate'
  )

export const deactivateCourierRegionDeliveryPlan = async (
  regionId: string,
  regionDeliveryPlanId: string
): Promise<IRegion> =>
  await axios.http.patch(
    rootAddress +
      prefix +
      '/' +
      regionId +
      '/delivery-plans/' +
      regionDeliveryPlanId +
      '/deactivate'
  )
