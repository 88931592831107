import {Texts} from 'providers/text'

export const texts: {[key: string]: keyof Texts} = {
  faq_category_modal_btn:
    'pages.protected.system-preferences.faq.components.create-faq-category-modal.btn-title',
  faq_form_category: 'pages.protected.system-preferences.faq.main.table-column-faq-category',
  create_faq_cancel: 'pages.protected.system-preferences.faq.components.create-faq-modal.cancel',
  create_faq_category_submit:
    'pages.protected.system-preferences.faq.components.create-faq-category-modal.submit',
  faq_form_category_required:
    'pages.protected.system-preferences.faq.components.create-faq-modal.form-faq-category-required-error',
  creation_success:
    'pages.protected.system-preferences.faq.components.create-faq-category-modal.success-creation',
}
